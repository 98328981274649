export const addToCart = async (dispatch, cartPayload) => {
  const storage = localStorage.getItem('cart');
  const testCondition = storage.length > 0 ? JSON.parse(storage) : storage;
  const condition = Array.isArray(testCondition);
  var data = condition ? JSON.parse(storage) : [];

  data.push(cartPayload);

  dispatch({ type: 'CART_ADDED', payload: data });
  localStorage.setItem('cart', JSON.stringify(data));
  return data;
};

export const updateCart = async (dispatch, cartPayload) => {
  var data = cartPayload;
  dispatch({ type: 'CART_UPDATED', payload: data });
  localStorage.setItem('cart', JSON.stringify(data));
  return data;
};

export const clearCart = async dispatch => {
  dispatch({ type: 'CART_CLEARED' });
  localStorage.setItem('cart', []);
};
