import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Popover,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router';
import theme from 'src/assets/theme';
import { formatAmount } from 'src/utils/helperFunctions';
import ShareBtn from 'src/components/Buttons/ShareButton';
import CustomButton from 'src/components/Buttons/Button';

const { colors } = theme;

const styles = {
  header: {
    backgroundColor: colors.bgColor
  },
  headerText: {
    fontWeight: 'bold',
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    borderBottom: 'none'
  },
  text: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    '.MuiListItemText-primary': {
      fontFamily: ['Ubuntu', 'sans-serif'].join(',')
    },
    border: 0,
    padding: '8px 16px'
  },
  actionItem: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    '.MuiListItemText-primary': {
      fontFamily: ['Ubuntu', 'sans-serif'].join(','),
      fontSize: '0.9rem'
    },
    color: colors.black
  },
  eventRow: {
    borderBottom: '1px solid',
    borderColor: colors.borderColor,
    '&:last-child td, &:last-child th': { border: 0 }
  },
  listItem: {
    padding: 0
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: colors.borderColor,
    borderRadius: '4px',
    boxShadow: 24,
    py: 2,
    px: 3
  }
};

const headers = [
  { title: 'Event Title' },
  { title: 'Event ID' },
  { title: 'Bookings' },
  { title: 'Income (FCFA)' },
  { title: '' }
];

const BasicList = ({ data, setAnchorEl, setShowDeleteModal }) => {
  const navigate = useNavigate();
  return (
    <List>
      <ListItem sx={styles.listItem}>
        <ListItemButton
          onClick={() => {
            navigate('./edit-event', { state: { data } });
            setAnchorEl(null); // Close the popover after clicking on the button
          }}
        >
          <ListItemText sx={styles.actionItem} primary="Edit" />
        </ListItemButton>
      </ListItem>
      <ListItem sx={styles.listItem}>
        <ListItemButton>
          <ShareBtn
            url={`https://iventily.com/${data.eventId}`}
            text="Copy Event Link"
            style={styles.actionItem}
            state={setAnchorEl}
          />
        </ListItemButton>
      </ListItem>
      {data.ticketSold !== 0 && (
        <ListItem sx={styles.listItem}>
          <ListItemButton
            onClick={() => {
              setAnchorEl(null); // Close the popover after clicking on the button
              navigate('./bookings', { state: { data } });
            }}
          >
            <ListItemText sx={styles.actionItem} primary="Bookings" />
          </ListItemButton>
        </ListItem>
      )}
      <ListItem sx={styles.listItem}>
        <ListItemButton
          onClick={() => {
            setAnchorEl(null); // Close the popover after clicking on the button
            setShowDeleteModal(true);
          }}
        >
          <ListItemText sx={styles.actionItem} primary="Delete" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

const EventOptions = ({ data, setShowDeleteModal, setEventData }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setEventData(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'event-action-items' : undefined;

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ minWidth: 350, padding: 0 }}
      >
        <BasicList
          data={data}
          setAnchorEl={setAnchorEl}
          setShowDeleteModal={setShowDeleteModal}
        />
      </Popover>
    </div>
  );
};

export default function EventsTable({ events, deleteEvent }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [eventData, setEventData] = useState();
  const handleModalClose = () => setShowDeleteModal(false);
  return (
    <TableContainer sx={{ minWidth: '300px' }}>
      <Table
        aria-label="events table"
        sx={{
          overflow: 'scroll'
        }}
      >
        <TableHead>
          <TableRow sx={styles.header}>
            <TableCell sx={styles.headerText}>Created On</TableCell>
            {headers.map(header => (
              <TableCell
                sx={styles.headerText}
                key={header.title}
                align="right"
              >
                {header.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((row, index) => (
            <TableRow key={index} sx={styles.eventRow}>
              <TableCell sx={styles.text} component="th" scope="row">
                {moment(row.createdAt).format('DD/MM/YYYY')}
                {' at '}
                {moment(row.createdAt).format('h:mm:ss A')}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {row.title}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {row.eventId}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {row.ticketSold}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {formatAmount(row.totalReceived)}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                <EventOptions
                  data={row}
                  setEventData={setEventData}
                  setShowDeleteModal={setShowDeleteModal}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Modal
        open={showDeleteModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={[styles.actionItem, { fontSize: '1rem', fontWeight: 'bold' }]}
          >
            Delete Event
          </Typography>
          <Typography sx={[styles.actionItem, { mt: 1, fontSize: 14 }]}>
            Are sure you want to delete this event? This action is irreversible!
          </Typography>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <CustomButton
              btnTxt="No, cancel"
              variant="outlined"
              btnStyle={{
                borderColor: colors.error,
                color: colors.black
              }}
              on={() => handleModalClose()}
            />
            <CustomButton
              btnTxt="Yes, delete"
              btnStyle={{
                backgroundColor: colors.error,
                ml: 2
              }}
              on={() => {
                handleModalClose();
                deleteEvent(eventData._id);
              }}
            />
          </Box>
        </Box>
      </Modal>
    </TableContainer>
  );
}
