import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography, Button, MenuItem } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'src/context';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '50%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50
  },
  inputText: {
    fontSize: 20,
    color: '#181819',
    fontWeight: 900,
    letterSpacing: 2
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderLeft: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 15
  },
  text: {
    fontSize: 15,
    color: '#3e4040'
  },
  mainText: {
    fontSize: 15,
    color: '#3f51b5'
  },
  buttons: {
    marginTop: 10
  },
  mainbox: {
    marginBottom: 10,
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}));

const BootstrapSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    borderBottom: 'none',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const BootstrapTextField = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '100px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const Vendor = () => {
  const classes = useStyles();
  const { token, userDetails } = useAuthState();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = React.useState({
    businessName: null,
    businessType: null,
    activity: null,
    goodsType: null,
    address1: null,
    address2: null,
    city: null
  });

  React.useEffect(() => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/businessInfo',
      data: values,
      headers: { 'x-access-token': token }
    }).then(res => {
      setValues(res.data);
    });
  }, []);
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/businessInfo',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setValues(res.data);
        setIsLoading(false);
        navigate('/app/activate/vendor/legal', { replace: 'false' });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  return (
    <Page title="Account Activation">
      <Box className={classes.root} sx={{ flexGrow: 1 }}>
        <Box className={classes.mainbox}>
          <Typography
            style={{
              color: 'rgb(26 27 37)',
              fontSize: 35,
              fontWeight: 700,
              fontFamily: 'Ubuntu'
            }}
          >
            Activate your account
          </Typography>
          <Typography
            style={{
              color: '#3e4040',
              fontSize: 14,
              textAlign: 'justify',
              fontWeight: 400,
              fontFamily: 'Ubuntu'
            }}
          >
            Hello {userDetails.username}, Your account is pending activation.
            You won't be able to perform certain transactions. Start by
            activating your account in very simple steps.
          </Typography>
        </Box>
        <Typography
          style={{
            color: '#3e4040',
            fontSize: 20,
            fontWeight: 700,
            fontFamily: 'Ubuntu',
            marginBottom: 20
          }}
        >
          Business account
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <Box className={classes.mainBoxActive}>
              <Box className={classes.boxActive}>
                <Typography className={classes.mainText} variant="p">
                  Business structure
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Legal fulfilment
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Account confirmation
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            style={{
              // backgroundColor: 'rgb(247 250 252)',
              borderRadius: 4,
              padding: 25,
              border: '1px solid #e7eaf5'
            }}
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
          >
            <Box>
              <Typography
                variant="h3"
                style={{
                  marginTop: -5,
                  color: '#3e4040'
                }}
              >
                Tell us about your business
              </Typography>
              <Box style={{ marginTop: 20, marginBottom: 20, fontSize: 16 }}>
                <Typography variant="p" style={{ fontWeight: 'bold' }}>
                  If you don't have a business yet, you can use your personal
                  details.
                </Typography>
                <br /> <br />
                <Typography
                  style={{ color: 'gray', textAlign: 'justify' }}
                  variant="p"
                >
                  In order to be verified on Fapshi, we need to learn more about
                  you and your business. We collect this information to comply
                  with requirements from regulators and financial partners and
                  the{' '}
                  <a href="https://fapshi.com/terms-and-conditions/">
                    terms of our Services Agreement.
                  </a>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                {error && (
                  <Typography
                    variant="p"
                    style={{
                      color: '#eb6558',
                      fontWeight: 500,
                      fontSize: 14
                    }}
                  >
                    {eresponse}
                  </Typography>
                )}
                <Box></Box>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Business name</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Tell us your business's name"
                  margin="normal"
                  name="businessName"
                  value={values.businessName}
                  onChange={handleChange}
                  id="bootstrap-input-address"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">
                    What does your business do ?
                  </Typography>
                </InputLabel>
                <BootstrapTextField
                  fullWidth
                  placeholder="Give us a summary of your business"
                  margin="normal"
                  multiline
                  rows={2}
                  name="goodsType"
                  value={values.goodsType}
                  onChange={handleChange}
                  type="text"
                  id="bootstrap-input-address"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">
                    Category (What type of goods do you sell ?)
                  </Typography>
                </InputLabel>
                <BootstrapSelect
                  fullWidth
                  margin="normal"
                  name="activity"
                  type="text"
                  defaultValue={values.activity}
                  value={values.activity}
                  onChange={handleChange}
                  id="bootstrap-input-activity"
                  required
                  style={{ marginRight: 8 }}
                >
                  <MenuItem
                    value="Restaurants and fast food"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Restaurants and fast food
                  </MenuItem>
                  <MenuItem
                    value="General electronics"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    General electronics
                  </MenuItem>
                  <MenuItem
                    value="Phones and accessories"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Phones and accessories
                  </MenuItem>
                  <MenuItem
                    value="Computers and accessories"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Computers and accessories
                  </MenuItem>
                  <MenuItem
                    value="IT services"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    IT services
                  </MenuItem>
                  <MenuItem
                    value="Business consultant"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Business consultant
                  </MenuItem>
                  <MenuItem
                    value="Mens Clothing"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Mens Clothing
                  </MenuItem>
                  <MenuItem
                    value="Female wears"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Female wears
                  </MenuItem>
                  <MenuItem
                    value="Baby wears"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Baby wears
                  </MenuItem>
                  <MenuItem
                    value="Fashion and design"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Fashion and design
                  </MenuItem>
                  <MenuItem
                    value="Design and Decor"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Design and Decor
                  </MenuItem>
                  <MenuItem
                    value="Carpentry and woodwork"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Carpentry and woodwork
                  </MenuItem>
                  <MenuItem
                    value="Artistic activities"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Artistic activities
                  </MenuItem>
                  <MenuItem
                    value="House hold equipments"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Household equipments
                  </MenuItem>
                  <MenuItem
                    value="General equipments"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    General equipments
                  </MenuItem>
                  <MenuItem
                    value="Brocante"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Brocante
                  </MenuItem>
                  <MenuItem
                    value="School or university"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    School or university
                  </MenuItem>
                  <MenuItem
                    value="Financial services"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Financial services
                  </MenuItem>
                  <MenuItem
                    value="Natural Products Store"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Natural Products Store
                  </MenuItem>
                  <MenuItem
                    value="Super market"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Super market
                  </MenuItem>
                  <MenuItem
                    value="Hotel services"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Hotel services
                  </MenuItem>
                  <MenuItem
                    value="Business consultant"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Business consultant
                  </MenuItem>
                  <MenuItem
                    value="Not for profit"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Not for profit
                  </MenuItem>
                  <MenuItem
                    value="Association"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Association
                  </MenuItem>
                  <MenuItem
                    value="Drug store or pharmacy"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Drug store or pharmacy
                  </MenuItem>
                </BootstrapSelect>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Business address</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Address line 1"
                  margin="normal"
                  type="text"
                  name="address1"
                  value={values.address1}
                  onChange={handleChange}
                  id="bootstrap-input-address"
                  required
                  style={{ marginRight: 8 }}
                />
                <BootstrapInput
                  fullWidth
                  placeholder="Address line 2"
                  margin="normal"
                  type="text"
                  name="address2"
                  value={values.address2}
                  onChange={handleChange}
                  id="bootstrap-input-address"
                  required
                  style={{ marginTop: 5, marginRight: 8 }}
                />
                <BootstrapInput
                  fullWidth
                  placeholder="City"
                  margin="normal"
                  type="text"
                  id="bootstrap-input-address"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  required
                  style={{ marginTop: 5, marginRight: 8 }}
                />
                <BootstrapInput
                  fullWidth
                  placeholder="Country"
                  margin="normal"
                  type="text"
                  id="bootstrap-input-address"
                  name="country"
                  value="Cameroon"
                  disabled
                  required
                  style={{ marginTop: 5, marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-type"
                  style={{ marginTop: 7 }}
                >
                  <Typography
                    variant="p"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    Type of business
                  </Typography>
                </InputLabel>
                <BootstrapSelect
                  fullWidth
                  margin="normal"
                  name="businessType"
                  defaultValue={values.businessType}
                  value={values.businessType}
                  onChange={handleChange}
                  type="text"
                  id="bootstrap-input-typr"
                  required
                  style={{ marginRight: 8 }}
                >
                  <MenuItem
                    value="Sole proprietorship"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Sole proprietorship
                  </MenuItem>
                  <MenuItem
                    value="Public limited compay (PLC, SA)"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Public limited compay (PLC, SA)
                  </MenuItem>
                  <MenuItem
                    value="Private limited company(Inc, LTD, LLC, SARL)"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Private limited company(Inc, LTD, LLC, SARL)
                  </MenuItem>
                  <MenuItem
                    value="Other"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Other
                  </MenuItem>
                </BootstrapSelect>
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                  type="submit"
                  onClick={handleSubmit}
                  fullWidth
                  disabled={
                    values.businessName == null ||
                    values.businessType == null ||
                    values.activity == null ||
                    values.goodsType == null ||
                    values.city == null ||
                    values.address1 == null
                      ? true
                      : false
                  }
                >
                  {isLoading ? <CircularProgress size="14px" /> : 'Next'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Vendor;
