import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  // Avatar,
  // Card,
  // Box,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Hidden
} from '@material-ui/core';
import { FaExchangeAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: { cursor: 'pointer' },
  avatar: {
    backgroundColor: '#f4f6f8',
    height: 35,
    width: 35
  },
  mainBox: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  differenceIcon: {
    color: '#545454b8'
  },
  differenceValue: {
    color: '#545454b8',
    marginRight: theme.spacing(1)
  }
}));

const Transfers = ({ transfers, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <CardContent
      className={clsx(classes.root, className)}
      {...rest}
      onClick={() => navigate('/app/transfers', { replace: false })}
    >
      <Grid
        container
        className={classes.mainBox}
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            style={{
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5rem'
            }}
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            Transfers
            <FaExchangeAlt style={{ marginLeft: '5px', color: '#d8af0c' }} />
          </Typography>
          <Hidden lgUp>
            <Typography color="textPrimary" variant="h2">
              {transfers >= 0 && formatAmount(transfers)}
              <span style={{ fontSize: 18, fontWeight: 700 }}> FCFA</span>
            </Typography>
          </Hidden>
          <Hidden mdDown>
            <Typography color="textPrimary" variant="h1">
              {transfers >= 0 && formatAmount(transfers)}
              <span style={{ fontSize: 18, fontWeight: 700 }}> FCFA</span>
            </Typography>
          </Hidden>
        </Grid>
      </Grid>
    </CardContent>
  );
};

Transfers.propTypes = {
  className: PropTypes.string
};

export default Transfers;
