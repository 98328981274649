import { Box, InputBase } from '@mui/material';
import React from 'react';
import theme from 'src/assets/theme';

const { colors } = theme;

const styles = {
  label: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 14
  },
  input: {
    borderRadius: 1,
    border: '1px solid',
    borderColor: colors.inputBorderColor,
    height: '2.5rem',
    padding: '10px 12px',
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 12,
    boxShadow: 'none',
    marginTop: '0.5rem',
    width: '100%'
  },
  textarea: {
    borderRadius: 1,
    border: '1px solid',
    borderColor: colors.inputBorderColor,
    padding: '10px 12px',
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 12,
    boxShadow: 'none',
    marginTop: '0.5rem',
    width: '100%'
  }
};

const FormInput = ({
  inputLabel,
  inputPlaceholder,
  multitext = false,
  disable = false,
  type,
  value,
  onChange,
  style,
  props
}) => {
  return (
    <Box sx={{ mb: 2 }}>
      <label style={styles.label}>{inputLabel}</label>
      <InputBase
        sx={[multitext ? styles.textarea : styles.input, style]}
        placeholder={inputPlaceholder}
        multiline={multitext}
        type={type}
        disabled={disable}
        value={value}
        onChange={onChange}
        {...props}
      />
    </Box>
  );
};

export default FormInput;
