import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0
    },
    display: 'inline-flex',
    paddingTop: 0,
    paddingBottom: 0,
    height: 20
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: 'inherit'
    },
    '&:focus': {
      color: '#3f51b5',
      backgroundColor: '#ffffff'
    }
  },
  icon: {
    marginRight: theme.spacing(0.3),
    color: 'rgb(118 119 129)',
    fontSize: 10,
    marginTop: -1,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: 8
    }
  },
  title: {
    marginRight: 'auto',
    color: '#1a1b25',
    fontWeight: 700,
    letterSpacing: 1,
    fontSize: 15,
    wordWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: 15
    },
    '&:hover': {
      color: '#3f51b5',
      padding: '0 0px',
      fontWeight: 700,
      borderRadius: 20
    },
    '&:focus': {
      color: '#3f51b5'
    }
  },
  active: {
    color: '#3f51b5'
  }
}));

const NavItem = ({ className, href, icon: Icon, title, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.item, className)} disableGutters {...rest}>
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="14" />}
        <span activeClassName={classes.active} className={classes.title}>
          {title}
        </span>
      </Button>
    </Box>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
