import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { InputBase } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Input, Row, Col, Divider, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from '@material-ui/core/Button';
import Avatar from 'src/components/Avatar';
import MultipleImage from 'src/components/MultipleImage';
import 'src/App.css';
import { useAuthState } from 'src/context';
import Snackbar from 'src/components/Snackbar';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { getRevenue, getPrice } from 'src/components/FapshiRevenue';

const { Title } = Typography;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '20px',
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      width: '750px'
    }
  },
  header: {
    MarginTop: '-100px'
  },
  box: {
    paddingTop: 0
  },
  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '35px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  },
  button: {
    textTransform: 'none',
    borderRadius: 8,
    fontSize: 14,
    margin: 2,
    height: 35,
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  },
  actions: {
    [theme.breakpoints.down('md')]: {
      float: 'right'
    }
  }
}));

const AddProductForm = props => {
  const [imageUrl, setImageUrl] = useState('image');
  const [imageGallery, setGallery] = useState([]);
  const handleParentCallback = data => {
    setImageUrl(data);
  };
  const handleMultipleParentCallback = data => {
    setGallery(data);
  };
  const { axios } = props;
  const { token } = useAuthState();
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState('optional');
  const navigate = useNavigate();
  const [price, setPrice] = useState('');
  const [revenue, setRevenue] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const classes = useStyles();
  const handlePriceChange = event => {
    const target = event.target.value;
    setPrice(target);
    const rev = getRevenue(target);
    setRevenue(rev);
  };

  const handleRevenueChange = event => {
    const target = event.target.value;
    setRevenue(target);
    const price = getPrice(target);
    setPrice(price);
  };

  const handleProductName = event => {
    setProductName(event.target.value);
  };
  const handleProductDescription = event => {
    setProductDescription(event.target.value);
  };

  const values = {
    productName: productName,
    price: parseInt(price),
    productImage: imageUrl,
    description: productDescription,
    gallery: imageGallery
  };

  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSLoading, setIsSLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    console.log(values);
    try {
      const response = await axios({
        method: 'post',
        url: 'https://api.fapshi.com/product',
        data: values,
        headers: { 'x-access-token': token }
      });
      if (response.status === 201) {
        setProductDescription(null);
        setProductName(null);
        setPrice(0);
        setRevenue(0);
        setSnack(true);
        setIsLoading(false);
        setError(false);
        navigate('/app/products', { replace: false });
      } else {
        setError(true);
        setEresponse(response.response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError(true);
      setEresponse(error.response?.data.message);
      console.log(error);
    }
  };

  const handleAddSubmit = async () => {
    setIsSLoading(true);
    console.log(values);
    try {
      const response = await axios({
        method: 'post',
        url: 'https://api.fapshi.com/product',
        data: values,
        headers: { 'x-access-token': token }
      });
      if (response.status === 201) {
        setProductDescription(null);
        setProductName(null);
        setPrice(0);
        setRevenue(0);
        setSnack(true);
        setIsSLoading(false);
        setError(false);
      } else {
        setError(true);
        setEresponse(response.response.data.message);
      }
    } catch (error) {
      setIsSLoading(false);
      setError(true);
      setEresponse(error.response?.data.message);
      console.log(error);
    }
  };
  const handleClose = () => {
    setSnack(false);
  };

  return (
    <Box className={classes.box} style={{ paddingTop: 0 }}>
      <Container className={classes.root}>
        <Title level={4}>Product information</Title>
        <Snackbar
          data="Product added successfully"
          open={snack}
          close={handleClose}
          duration={6000}
        />
        {error && (
          <Typography
            variant="p"
            style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
          >
            {eresponse}
          </Typography>
        )}
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            requiredMarkValue: requiredMark
          }}
          onValuesChange={onRequiredTypeChange}
          requiredMark={requiredMark}
        >
          <Row>
            <Col span={8}>
              <Form.Item
                label="Name"
                required
                tooltip="Give a consice product name"
              >
                <Input
                  onChange={() => handleProductName(event)}
                  placeholder="enter product name"
                  value={productName}
                  className={classes.input}
                />
              </Form.Item>
              <Form.Item
                label="Description"
                tooltip={{
                  title:
                    'Enter the product description. Give details a customer will want to see',
                  icon: <InfoCircleOutlined />
                }}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Enter product description"
                  onChange={() => handleProductDescription(event)}
                  value={productDescription}
                  className={classes.input}
                />
              </Form.Item>
              <Form.Item
                label="Display image"
                tooltip={{
                  title:
                    'Select the display image of the product you wish to sell',
                  icon: <InfoCircleOutlined />
                }}
                required
              >
                <Avatar parentCallback={handleParentCallback} axios={axios} />
              </Form.Item>
              <Form.Item
                label="Product gallery"
                tooltip={{
                  title: 'Add product gallery',
                  icon: <InfoCircleOutlined />
                }}
                extra="Max upload(4)"
              >
                <MultipleImage parentCallback={handleMultipleParentCallback} />
              </Form.Item>
              <Divider />
            </Col>
          </Row>
          <Col span={8}>
            <Title level={4}>Price information</Title>
            <Form.Item
              label="Price"
              required
              tooltip="The Price refers to amount your customer will be charged"
            >
              <InputBase
                className={classes.input}
                addonAfter="FCFA"
                value={price}
                onChange={event => handlePriceChange(event)}
                type="number"
              />
            </Form.Item>
            <Form.Item
              label="Revenue"
              required
              tooltip="Revenue refers to income after rate has been deducted"
            >
              <InputBase
                addonAfter="FCFA"
                value={revenue}
                onChange={event => handleRevenueChange(event)}
                type="number"
                className={classes.input}
              />
            </Form.Item>
            <Form.Item
              label="Price description"
              tooltip={{
                title:
                  'Enter the product price description. Give details a customer will want to see',
                icon: <InfoCircleOutlined />
              }}
            >
              <Input.TextArea
                className={classes.input}
                rows={2}
                placeholder="Enter price description"
              />
            </Form.Item>
            <Form.Item>
              <Box className={classes.actions}>
                <Button
                  size="small"
                  color="default"
                  variant="outlined"
                  onClick={handleSubmit}
                  className={classes.button}
                  style={{ textTransform: 'none', marginRight: 10 }}
                >
                  {isLoading ? (
                    <CircularProgress size="14px" />
                  ) : (
                    'Save product'
                  )}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={handleAddSubmit}
                  style={{ textTransform: 'none' }}
                  className={classes.button}
                >
                  {isSLoading ? (
                    <CircularProgress size="14px" />
                  ) : (
                    ' Save & add another'
                  )}
                </Button>
              </Box>
            </Form.Item>
          </Col>
        </Form>
      </Container>
    </Box>
  );
};

export default AddProductForm;
