import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  makeStyles,
  Typography,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'src/context';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '80%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50
  },
  inputText: {
    fontSize: 20,
    color: '#181819',
    fontWeight: 900,
    letterSpacing: 2
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 12
  },
  text: {
    fontSize: 15,
    color: '#546e7a'
  },
  mainText: {
    fontSize: 15,
    color: '#3f51b5'
  },
  buttons: {
    marginTop: 10
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const BootstrapSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const Structure = () => {
  const classes = useStyles();
  const [active, setActive] = React.useState(true);
  const { token, userDetails } = useAuthState();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = React.useState({
    address: null,
    city: null,
    phone: null,
    type: null,
    country: null
  });
  React.useEffect(() => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/merchant/structure',
      headers: { 'x-access-token': token }
    }).then(res => {
      setValues(res.data);
    });
  }, []);
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/merchant/structure',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setValues(res.data);
        setIsLoading(false);
        navigate('/app/activate/representative', { replace: 'true' });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  return (
    <Page title="Account Activation">
      <Box className={classes.root} sx={{ flexGrow: 1 }}>
        <Box style={{ marginBottom: 10 }}>
          <Typography
            style={{
              color: 'rgb(26 27 37)',
              fontSize: 35,
              fontWeight: 700,
              fontFamily: 'Ubuntu'
            }}
          >
            Activate your accounts
          </Typography>
          <Typography
            style={{
              color: '#546e7a',
              fontSize: 14,
              textAlign: 'justify',
              fontWeight: 400,
              fontFamily: 'Ubuntu'
            }}
          >
            Hello, for you to have access to our APIs (Merchant) or be a vendor
            on our platform, you must comply with us by providing some details
            about you, your business or its representatives. This usually takes
            at most 10mins. We are sorry for the inconvenience. It is important
            to us and our partners that we know whom we are dealing with.
          </Typography>
        </Box>
        <Box style={{ marginBottom: 30 }}>
          <Button
            color="primary"
            style={{ borderRadius: 20, marginRight: 5 }}
            variant={active ? 'contained' : 'outlined'}
            size="small"
            onClick={() => {
              setActive(true);
              userDetails.isMerchant
                ? navigate('/app/activate/confirmation', { replace: false })
                : navigate('/app/activate/structure', { replace: false });
            }}
          >
            Activate Merchant Account
          </Button>
          <Button
            color="primary"
            style={{ borderRadius: 20 }}
            variant={active ? 'outlined' : 'contained'}
            onClick={() => {
              setActive(false);
              userDetails.isVendor
                ? navigate('/app/activate/vendor/confirmation', {
                    replace: false
                  })
                : navigate('/app/activate/vendor', { replace: false });
            }}
            size="small"
          >
            Activate Vendor Account
          </Button>
        </Box>
        <Typography
          style={{
            color: '#546e7a',
            fontSize: 20,
            fontWeight: 700,
            fontFamily: 'Ubuntu',
            marginBottom: 20
          }}
        >
          Merchant account
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <Box className={classes.mainBoxActive}>
              <Box className={classes.boxActive}>
                <Typography className={classes.mainText} variant="p">
                  Business structure
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Business represenative
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Business details
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Fulfilment details
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Legal fulfilment
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Confirmation
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            style={{
              // backgroundColor: 'rgb(247 250 252)',
              borderRadius: 4,
              padding: 25,
              border: '1px solid #e7eaf5'
            }}
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
          >
            <Box>
              <Typography
                variant="h3"
                style={{
                  marginTop: -5,
                  color: '#546e7a'
                }}
              >
                Tell us about your business
              </Typography>
              <br />
              <Typography
                style={{ color: 'gray', textAlign: 'justify' }}
                variant="p"
              >
                Before you can accept payments with Fapshi, we need to learn
                more about you and your business. We collect this information to
                comply with requirements from regulators and financial partners
                and the
                <a href="https://fapshi.com/terms-and-conditions/">
                  {' '}
                  terms of our Services Agreement
                </a>
                .
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box>
                {error && (
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      color: '#eb6558',
                      fontWeight: 500,
                      fontSize: 14,
                      marginTop: 7
                    }}
                  >
                    <Typography variant="p"> {eresponse}</Typography>
                  </InputLabel>
                )}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#546e7a',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">
                    Registered business address
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Address line 1"
                  margin="normal"
                  name="address"
                  type="text"
                  value={values.address}
                  onChange={handleChange}
                  id="bootstrap-input-address"
                  required
                  style={{ marginRight: 8 }}
                />
                <BootstrapInput
                  fullWidth
                  placeholder="City"
                  margin="normal"
                  name="city"
                  type="text"
                  id="bootstrap-input-city"
                  value={values.city}
                  onChange={handleChange}
                  required
                  style={{ marginTop: 5, marginRight: 8 }}
                />
                <BootstrapInput
                  fullWidth
                  placeholder="Country"
                  margin="normal"
                  name="country"
                  type="text"
                  id="bootstrap-input-country"
                  value={values.country}
                  onChange={handleChange}
                  required
                  style={{ marginTop: 5, marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#546e7a',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Company phone number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Ex: +237676211496"
                  margin="normal"
                  name="phone"
                  type="number"
                  id="bootstrap-input-phone"
                  value={values.phone}
                  onChange={handleChange}
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#546e7a',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Type of business</Typography>
                </InputLabel>
                <BootstrapSelect
                  fullWidth
                  margin="normal"
                  name="type"
                  id="bootstrap-input-type"
                  value={values.type}
                  onChange={handleChange}
                  required
                  style={{ marginRight: 8 }}
                >
                  <MenuItem
                    value={10}
                    style={{
                      marginTop: 7,
                      color: '#546e7a',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Sole proprietorship
                  </MenuItem>
                  <MenuItem
                    value={20}
                    style={{
                      marginTop: 7,
                      color: '#546e7a',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Public limited compay (PLC, SA)
                  </MenuItem>
                  <MenuItem
                    value={30}
                    style={{
                      marginTop: 7,
                      color: '#546e7a',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Private limited company(Inc, LTD, LLC, SARL)
                  </MenuItem>
                  <MenuItem
                    value={40}
                    style={{
                      marginTop: 7,
                      color: '#546e7a',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Other
                  </MenuItem>
                </BootstrapSelect>
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIcon>send</ArrowForwardIcon>}
                  type="submit"
                  onClick={handleSubmit}
                  fullWidth
                  disabled={
                    values.address == null ||
                    values.city == null ||
                    values.phone == null ||
                    values.type == null ||
                    values.country == null
                      ? true
                      : false
                  }
                >
                  {isLoading ? <CircularProgress size="14px" /> : 'Next'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Structure;
