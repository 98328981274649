import React, { useState, useEffect, useRef } from 'react';
import Page from 'src/components/Page';
import NoResults from 'src/components/NoResults';
import {
  Container,
  Typography,
  Box,
  Divider,
  Grid,
  Hidden
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Descriptions } from 'antd';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuthState } from 'src/context';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import { ArrowLeft as ArrowLeftIcon } from 'react-feather';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import StoreTab from 'src/components/StoreTab';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { alpha } from '@mui/material/styles';
import Snackbar from 'src/components/Snackbar';
import { Package as ProductIcon } from 'react-feather';
import Bar from 'src/components/Bar';
import { capitalize, formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
      width: 'auto'
    }
  },
  inputfield: {
    width: '100%'
  },
  card: {
    marginTop: 0,
    padding: '10px 5px 5px 5px !important',
    border: '1px solid #dddfe685',
    marginBottom: '-20px',
    [theme.breakpoints.down('md')]: {
      width: 600
    }
  },
  descriptions: { height: 30 },
  bar: {
    margin: '10px 0px 10px 0px'
  },
  introtitle: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 26,
    fontWeight: 700,
    color: '#505056e3',
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      width: 700
    }
  },
  root: {
    width: '100%',
    margin: '30px 0px',
    [theme.breakpoints.down('md')]: {
      width: 1000,
      overflowX: 'auto'
    }
  },
  button: {
    float: 'right',
    textTransform: 'none',
    fontSize: 18,
    borderRadius: 10,
    height: 35,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  },
  buttonI: {
    textTransform: 'none',
    display: 'block',
    textAlign: 'left',
    minWidth: 120,
    overflowY: 'hidden'
  },
  btn: {
    height: 25,
    marginTop: 20,
    color: '#6664ffe3'
  },
  btn2: {
    height: 25,
    marginTop: 20,
    color: '#ff8864e3'
  },
  alert: {
    display: 'inline-block'
  },
  buttonText: {
    fontSize: 14
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    width: 40,
    border: '1px solid #fff',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  },
  boxBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
    [theme.breakpoints.down('md')]: { width: 350 }
  }
}));

const ProductsView = () => {
  const { token, userDetails } = useAuthState();
  var productRef = useRef([]);
  const [product, setProduct] = useState([]);
  const [progressDisplay, setProgressDisplay] = useState(false);

  productRef = product;
  const getProduct = () => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/product',
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setProgressDisplay(false);
        setProduct(res.data);
      })
      .catch(err => {
        setProgressDisplay(false);
        console.log(err);
      });
  };
  useEffect(() => {
    setProgressDisplay(true);
    if (productRef.current !== product) getProduct();
  }, []);

  const classes = useStyles();

  const [size] = useState('default');

  const [verified, setVerified] = useState(true);

  const navigate = useNavigate();

  const handleClick = () => {
    if (verified) {
      navigate('/app/products/add-product', { replace: 'true' });
    }
  };

  const handleViewClick = products => {
    navigate('/app/products/view-product', {
      replace: 'true',
      state: { products }
    });
  };

  const handleDelete = productId => {
    const filter = product.filter(data => {
      return data.id != productId;
    });
    console.log(filter);
    console.log(productId);
    axios({
      method: 'delete',
      url: 'https://api.fapshi.com/product/' + productId,
      headers: { 'x-access-token': token }
    }).then(() => {
      setProduct(filter);
    });
    setVerified(true);
  };

  const handleEdit = products => {
    navigate('/app/products/edit-product', {
      replace: 'true',
      state: { products }
    });
  };

  var i = 1;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bid, setBid] = React.useState([]);

  const handleIconClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setBid(data);
  };

  const handleIconClose = () => {
    setAnchorEl(null);
  };
  const openI = Boolean(anchorEl);
  const id = openI ? 'simple-popover' : undefined;

  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);

  const [errors, setErrors] = useState(false);
  const [snacks, setSnacks] = useState(false);
  const [eresponses, setEresponses] = useState(null);

  const handleAddToStore = id => {
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/store/addStore/' + id,
      data: {
        vendorName: name
      },
      headers: {
        'x-access-token': token
      }
    }).then(() => {
      axios({
        method: 'get',
        url: 'https://api.fapshi.com/product',
        headers: { 'x-access-token': token }
      })
        .then(res => {
          setProduct(res.data);
          setSnack(true);
        })
        .catch(err => {
          setError(true);
          setEresponse(err.response?.data.message);
        });
    });
  };

  const handleRemoveFromStore = id => {
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/store/removeStore/' + id,
      data: {
        vendorName: name
      },
      headers: {
        'x-access-token': token
      }
    }).then(() => {
      axios({
        method: 'get',
        url: 'https://api.fapshi.com/product',
        headers: { 'x-access-token': token }
      })
        .then(res => {
          setProduct(res.data);
          setSnacks(true);
        })
        .catch(err => {
          setErrors(true);
          setEresponses(err.response?.data.message);
        });
    });
  };

  const handleClose = () => {
    setSnack(false);
  };

  const handleCloses = () => {
    setSnacks(false);
  };
  return (
    <Page title="Products">
      <Container>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={4} xl={2}>
              <StoreTab val="products" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={8} xl={10}>
            <Bar
              title="Products"
              btnText={userDetails.isVendor ? 'Add product' : false}
              onClick={handleClick}
              icon="add"
              styling={{ marginBottom: 30 }}
            />
            <Box style={{ width: '100%' }}>
              <Box
                style={{
                  display: progressDisplay ? 'block' : 'none'
                }}
              >
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    backdropFilter: 'blur(2px)',
                    backgroundColor: '#ffffff59'
                  }}
                  open={open}
                >
                  <CircularProgress
                    size="1.5rem"
                    style={{
                      display: progressDisplay ? 'block' : 'none',
                      marginLeft: '20%',
                      marginTop: '15%',
                      marginBottom: '10%',
                      color: '#3f51b5'
                    }}
                  />
                </Backdrop>
              </Box>
              <Box
                className={classes.container}
                style={{
                  display: progressDisplay ? 'none' : 'block'
                }}
              >
                {snacks && (
                  <Snackbar
                    duration={3000}
                    open={snacks}
                    close={handleCloses}
                    data="Product removed from store"
                  />
                )}
                {snack && (
                  <Snackbar
                    duration={3000}
                    open={snack}
                    close={handleClose}
                    data="Product added to store"
                  />
                )}

                {product.length > 0 ? (
                  product.map(data => (
                    <>
                      <Box className={classes.card}>
                        <Descriptions
                          title={
                            <Typography
                              variant="p"
                              style={{ fontWeight: 500, color: '#505056e3' }}
                            >{`${i++}. ${capitalize(
                              data.productName
                            )} (${formatAmount(data.price) +
                              ' FCFA'})`}</Typography>
                          }
                          size={size}
                          className={classes.descriptions}
                          extra={
                            <div style={{ display: 'inline-flex' }}>
                              {data.inStore ? (
                                <Button
                                  onClick={() => handleRemoveFromStore(data.id)}
                                  color="default"
                                  style={{
                                    textTransform: 'none',
                                    marginTop: '1px',
                                    marginRight: '10px',
                                    fontSize: 14,
                                    borderRadius: 4
                                  }}
                                  size="small"
                                  variant="outlined"
                                  className={classes.btn2}
                                  startIcon={
                                    <ArrowLeftIcon
                                      size="14"
                                      style={{
                                        color: '#ff8864e3'
                                      }}
                                    />
                                  }
                                >
                                  {errors && (
                                    <Typography
                                      variant="p"
                                      style={{
                                        color: '#eb6558',
                                        fontWeight: 900,
                                        fontSize: 16
                                      }}
                                    >
                                      {eresponses}
                                    </Typography>
                                  )}
                                  Remove from store
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => handleAddToStore(data.id)}
                                  color="default"
                                  style={{
                                    textTransform: 'none',
                                    marginTop: '1px',
                                    marginRight: '10px',
                                    fontSize: 14,
                                    borderRadius: 4
                                  }}
                                  size="small"
                                  variant="outlined"
                                  className={classes.btn}
                                  endIcon={
                                    <ArrowRightIcon
                                      size="14"
                                      style={{
                                        color: '#6664ffe3'
                                      }}
                                    />
                                  }
                                >
                                  {error && (
                                    <Typography
                                      variant="p"
                                      style={{
                                        color: '#eb6558',
                                        fontWeight: 900,
                                        fontSize: 16
                                      }}
                                    >
                                      {eresponse}
                                    </Typography>
                                  )}
                                  Add to store
                                </Button>
                              )}
                              <IconButton
                                className={classes.iconButton}
                                style={{ height: 23 }}
                                onClick={event => handleIconClick(event, data)}
                              >
                                <MoreHorizIcon
                                  style={{ fontSize: 20, cursor: 'pointer' }}
                                />
                              </IconButton>
                            </div>
                          }
                        ></Descriptions>
                      </Box>
                      <br />
                    </>
                  ))
                ) : (
                  <>
                    <NoResults
                      title="products created"
                      content="Products are things you want to sell or be paid for; mostly physical goods"
                      link="https://documentation.fapshi.com/#products"
                      icon={
                        <ProductIcon
                          size={35}
                          style={{
                            fontSize: 40,
                            border: '1px solid #bdc3c9',
                            boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                            borderRadius: 10,
                            color: '#bdc3c9',
                            padding: 5
                          }}
                        />
                      }
                    />
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={openI}
          anchorEl={anchorEl}
          onClose={handleIconClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Button
            className={classes.buttonI}
            fullWidth
            onClick={() => handleViewClick(bid)}
          >
            <Typography className={classes.buttonText} variant="p">
              {' '}
              View product
            </Typography>
          </Button>
          <Divider style={{ backgroundColor: '#f4f4f5' }} />
          <Button
            fullWidth
            className={classes.buttonI}
            onClick={() => handleEdit(bid)}
          >
            <Typography variant="p" className={classes.buttonText}>
              {' '}
              Edit product
            </Typography>
          </Button>
          <Divider style={{ backgroundColor: '#f4f4f5' }} />
          <Button
            fullWidth
            className={classes.buttonI}
            onClick={() => handleDelete(bid.id)}
          >
            <Typography
              className={classes.buttonText}
              variant="p"
              style={{ color: '#c71e00' }}
            >
              Delete product
            </Typography>
          </Button>
          <Divider style={{ backgroundColor: '#f4f4f5' }} />
        </Popover>
      </Container>
    </Page>
  );
};

export default ProductsView;
