import axios from 'src/axios';

export const UserService = (dispatch, token) => {
  axios({
    method: 'get',
    url: '/userService/',
    headers: { 'x-access-token': token }
  })
    .then(res => {
      const data = res.data;
      if (res.status === 200) {
        dispatch({
          type: 'SERVICE_SUCCESS',
          payload: data
        });
        localStorage.setItem('userService', JSON.stringify(data));
        return data;
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const getUserService = (dispatch, data) => {
  dispatch({ type: 'GET_SERVICE', payload: data });
  localStorage.setItem('getUserService', JSON.stringify(data));
  return data;
};

export const setApiKey = (dispatch, data) => {
  dispatch({ type: 'GET_KEY', payload: data });
  localStorage.setItem('setApiKey', JSON.stringify(data));
  return data;
};
