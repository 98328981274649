let service = localStorage.getItem('userService')
  ? JSON.parse(localStorage.getItem('userService'))
  : '';

let singleService = localStorage.getItem('getUserService')
  ? JSON.parse(localStorage.getItem('getUserService'))
  : '';

let setApiKey = localStorage.getItem('setApiKey')
  ? JSON.parse(localStorage.getItem('setApiKey'))
  : '';

export const initialState = {
  userService: '' || service,
  singleUserService: '' || singleService,
  secretKey: '' || setApiKey
};

export const ServiceReducer = (initialState, action) => {
  switch (action.type) {
    case 'SERVICE_SUCCESS':
      return {
        ...initialState,
        userService: action.payload
      };
    case 'GET_SERVICE':
      return {
        ...initialState,
        singleUserService: action.payload
      };
    case 'GET_KEY':
      return {
        ...initialState,
        secretKey: action.payload
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
