import React, { useState } from 'react';
import {
  Box,
  Typography,
  Hidden,
  Grid,
  Button,
  Popover
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { alpha, styled } from '@mui/material/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthState } from 'src/context';
// import Image from './svg/banner.svg';
import axios from 'src/axios';
import Snackbar from 'src/components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { Tooltip } from 'antd';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 5,
    marginLeft: 20,
    height: 170,
    backgroundColor: '#ffffff'
  },
  username: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 28,
    fontWeight: 900,
    color: 'rgb(26 27 37)',
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    }
  },
  button: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 500,
    color: 'black',
    marginTop: '5px',
    borderRadius: 20,
    height: 30,
    backgroundColor: '#ffffff',
    border: '1px solid #dae0e7',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 5%)',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
    '&:focus': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)'
    }
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // backgroundImage: `url(${Image})`,
    // backgroundRepeat: 'repeat-x',
    height: 140,
    backdropFilter: 'blur(3px)',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginRight: 20
    }
  },
  boxOne: {
    // boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 5%)',
    height: 125,
    border: '1px solid #f4f4f5',
    width: '49%',
    borderRadius: 4,
    padding: 10,
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 15,
      marginRight: 10,
      padding: 10,
      height: 125
    }
  },
  boxTwo: {
    // boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 5%)',
    height: 125,
    border: '1px solid #f4f4f5',
    width: '49%',
    borderRadius: 4,
    padding: 10,
    backgroundColor: '#ffffff',
    marginLeft: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 10,
      marginRight: 20,
      padding: 10,
      height: 125,
      marginLeft: 0
    }
  },
  tooltip: {
    backgroundColor: '#ffffff'
  }
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '8px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#768095'
    }
  }
}));
const Welcome = () => {
  const classes = useStyles();
  const { token, userDetails } = useAuthState();
  const [code, setCode] = useState(null);
  const [anchorAEl, setAnchorAEl] = React.useState(null);
  const openAI = Boolean(anchorAEl);
  const aid = openAI ? 'simple-popover' : undefined;
  const [errors, setErrors] = useState(false);
  const [snacks, setSnacks] = useState(false);
  const [eresponses, setEresponses] = useState(null);
  const [isLoadings, setIsLoadings] = useState(false);
  const [errore, setErrore] = useState(false);
  const [phone, setPhone] = React.useState();
  const [eresponsee, setEresponsee] = useState(null);
  const [isLoadinge, setIsLoadinge] = useState(false);
  const [isCode, setIsCode] = useState(true);

  const [operator, setOperator] = React.useState('Mobile money');
  const handleSnacksClose = () => {
    setSnacks(false);
  };
  const handleAddClose = () => {
    setIsCode(true);
    setAnchorAEl(null);
    setErrors(false);
    setErrore(false);
    setCode(null);
  };
  const handleCode = () => {
    setIsLoadinge(true);
    axios({
      method: 'post',
      data: { code },
      url: 'https://api.fapshi.com/verifyTopUp/mtn',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setIsCode(true);
        setSnacks(true);
        setIsLoadinge(false);
        setAnchorAEl(null);
      })
      .catch(err => {
        setIsLoadinge(false);
        setErrors(true);
        setEresponses(err.response?.data.message);
      });
  };

  const addTopUpAccount = () => {
    if (operator === 'Mobile money') {
      setIsLoadings(true);
      axios({
        method: 'post',
        data: { phone },
        url: 'https://api.fapshi.com/addTopUp/mtn',
        headers: { 'x-access-token': token }
      })
        .then(() => {
          setIsCode(false);
          setIsLoadinge(false);
        })
        .catch(err => {
          setIsLoadinge(false);
          setErrore(true);
          setEresponsee(err.response?.data.message);
        });
    }
  };

  const navigate = useNavigate();
  const [anchorPEl, setAnchorPEl] = React.useState(null);
  const [snack, setSnack] = useState(false);
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const openP = Boolean(anchorPEl);
  const pid = openP ? 'simple-popover' : undefined;

  const handlePinClose = () => {
    setAnchorPEl(null);
    setError(false);
  };
  const handlePinOpen = event => {
    setAnchorPEl(event.currentTarget);
  };
  const formik = useFormik({
    initialValues: {
      pin: ''
    },
    validationSchema: Yup.object().shape({
      pin: Yup.string()
    }),
    onSubmit: values => {
      setIsLoading(true);
      axios({
        method: 'post',
        url: '/addPin',
        data: values,
        headers: { 'x-access-token': token }
      })
        .then(() => {
          setSnack(true);
          setIsLoading(false);
          setAnchorPEl(null);
        })
        .catch(err => {
          setIsLoading(false);
          setError(true);
          setEresponse(err.response?.data.message);
          if (err.response.status == 401) navigate('/login', { replace: true });
        });
    }
  });
  const handleSnackClose = () => {
    setSnack(false);
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Hidden mdDown>
            <Box
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                paddingRight: 10
              }}
            >
              <Typography className={classes.username}>
                {`Next, add a pin for payouts and transfers`}
              </Typography>
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Box
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                paddingRight: 10
              }}
            >
              <Typography className={classes.username}>
                {`Next, add a transfer & payout pin`}
              </Typography>
            </Box>
          </Hidden>
          <br />
          <Box>
            <Typography
              variant="p"
              style={{ width: '50px', color: '#505056e3', fontSize: 18 }}
            >
              For withdrawals and transfers, you need to set a pin. This is
              strictly for your security.
            </Typography>
          </Box>
          {userDetails.hasSetPin ? (
            <Tooltip
              placement="bottomLeft"
              title="Contact support to change pin"
              color="#3f51b5"
              key="#3f51b5"
            >
              <Box>
                <Button
                  size="small"
                  className={classes.button}
                  onClick={handlePinOpen}
                  disabled
                >
                  Set your pin here
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip
              placement="bottomLeft"
              title="Once set, you would have to contact support to change it"
              color="#3f51b5"
              key="#3f51b5"
            >
              <Box>
                <Button
                  size="small"
                  className={classes.button}
                  onClick={handlePinOpen}
                >
                  Set your pin here{' '}
                </Button>
              </Box>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Box style={{}}>
            <svg
              viewBox="0 0 168 108"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: '218px',
                height: '160px',
                marginLeft: '70%',
                border: '1px dashed #f7f7f7'
              }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 89.3565V48.4629L228 0V40.8936L0 89.3565Z"
                fill="#fbfdff"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M67 107.115V66.2216L228 32V72.8936L67 107.115Z"
                fill="#fbfdff"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M228 40.8936V32L67 66.2216V75.1152L228 40.8936Z"
                fill="#3f51b5"
              ></path>
            </svg>
          </Box>
          <Box>
            <Popover
              style={{ marginTop: 5 }}
              id={aid}
              open={openAI}
              anchorEl={anchorAEl}
              // onClose={handleAddClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              {isCode ? (
                <>
                  <DialogContent
                    style={{
                      padding: 20,
                      width: 250
                    }}
                  >
                    {errore && (
                      <Box style={{ marginLeft: 10 }}>
                        <Typography
                          variant="p"
                          style={{
                            color: '#eb6558',
                            fontWeight: 500,
                            fontSize: 14
                          }}
                        >
                          {eresponsee}
                        </Typography>
                      </Box>
                    )}
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-item"
                      style={{
                        color: '#3e4040',
                        fontWeight: 400,
                        fontSize: 20
                      }}
                    >
                      <Typography variant="p">Operator</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="operator"
                      id="item"
                      type="text"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled
                      variant="outlined"
                      size="small"
                      margin="normal"
                      name="operator"
                      type="text"
                      id="bootstrap-input-operaor"
                      required
                      value="Mobile money"
                      style={{ marginRight: 8 }}
                      onChange={e => setOperator(e.target.value)}
                    />
                    {/* <BootstrapSelect
                          fullWidth
                          placeholder="Choose operator"
                          margin="normal"
                          name="operator"
                          type="select"
                          id="bootstrap-input-operaor"
                          required
                          value={operator}
                          style={{ marginRight: 8 }}
                          onChange={e => setOperator(e.target.value)}
                        >
                          <MenuItem value="mtn">Mobile money</MenuItem>
                          <MenuItem value="orange">Orange money</MenuItem>
                        </BootstrapSelect> */}
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-item"
                      style={{
                        color: '#3e4040',
                        fontWeight: 400,
                        fontSize: 20,
                        marginTop: 7
                      }}
                    >
                      <Typography variant="p">Phone number</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="item"
                      id="item"
                      type="text"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Enter phone number"
                      size="small"
                      onChange={e => setPhone(e.target.value)}
                      value={phone}
                    />
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: '1px solid #f4f4f5'
                    }}
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      style={{ textTransform: 'none' }}
                      onClick={handleAddClose}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      style={{ textTransform: 'none' }}
                      onClick={addTopUpAccount}
                      className={classes.button}
                    >
                      {isLoadinge ? (
                        <CircularProgress size="14px" />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <Box>
                    <DialogContent
                      style={{
                        padding: 20,
                        width: 250
                      }}
                    >
                      {
                        <Snackbar
                          open={snacks}
                          handleClose={handleSnacksClose}
                          data="Operation successful"
                        />
                      }
                      {errors && (
                        <Typography
                          variant="p"
                          style={{
                            color: '#eb6558',
                            fontWeight: 500,
                            fontSize: 14
                          }}
                        >
                          {eresponses}
                        </Typography>
                      )}
                      <Typography style={{ color: '#3e4040' }} variant="p">
                        An SMS has been sent to your number with a verification
                        code.
                      </Typography>
                      <BootstrapInput
                        name="item"
                        id="item"
                        type="text"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        placeholder="Enter code"
                        size="small"
                        onChange={e => setCode(e.target.value)}
                        value={code}
                      />
                    </DialogContent>
                  </Box>
                  <DialogActions
                    style={{
                      borderTop: '1px solid #f4f4f5'
                    }}
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      style={{ textTransform: 'none' }}
                      onClick={handleAddClose}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      style={{ textTransform: 'none' }}
                      onClick={handleCode}
                      className={classes.button}
                    >
                      {isLoadings ? (
                        <CircularProgress size="14px" />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </DialogActions>
                </>
              )}
            </Popover>
          </Box>
          <Box
            style={{
              boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
            }}
          >
            <Snackbar
              duration={6000}
              close={handleSnackClose}
              data="Pin created successfully"
              open={snack}
            />
            <Popover
              style={{ marginTop: 5 }}
              id={pid}
              open={openP}
              anchorEl={anchorPEl}
              onClose={handlePinClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              style={{ marginTop: -40 }}
            >
              <Box
                style={{
                  boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
                  borderRadius: '5',
                  border: '1px solid #0f15c514',
                  width: 220,
                  backgroundColor: '#ffffff'
                }}
              >
                <form
                  // style={{ textAlign: 'center' }}
                  onSubmit={formik.handleSubmit}
                >
                  <DialogTitle
                    id="form-dialog-title"
                    style={{
                      borderBottom: '1px solid rgb(229 231 243)',
                      padding: 10
                    }}
                  >
                    <Typography
                      style={{
                        letterSpacing: 1,
                        fontSize: '16px',
                        fontWeight: 900,
                        color: '#3e4040'
                      }}
                      variant="p"
                    >
                      Set
                    </Typography>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: '16px',
                        letterSpacing: 1,
                        color: '#3e4040'
                      }}
                    >
                      {' '}
                      PIN{' '}
                    </Typography>
                    <Typography
                      style={{
                        letterSpacing: 1,
                        fontSize: '16px',
                        fontWeight: 900,
                        color: '#3e4040'
                      }}
                      variant="p"
                    >
                      code
                    </Typography>
                  </DialogTitle>

                  {error && (
                    <Box style={{ marginLeft: 15 }}>
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 900,
                          fontSize: 16
                        }}
                      >
                        {eresponse}
                      </Typography>
                    </Box>
                  )}
                  <DialogContent
                    style={{
                      padding: 20
                    }}
                  >
                    <FormControl>
                      <FormGroup>
                        <FormLabel className={classes.label}>
                          <Box style={{ marginBottom: 7 }}>
                            <Typography variant="p">
                              Enter a 5 digit pin
                            </Typography>
                          </Box>
                        </FormLabel>
                        <BootstrapInput
                          error={Boolean(
                            formik.touched.pin && formik.errors.pin
                          )}
                          helperText={formik.touched.pin && formik.errors.pin}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.pin}
                          name="pin"
                          id="pin"
                          type="password"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          placeholder="Example: 12345"
                          size="small"
                          required
                        />
                      </FormGroup>
                    </FormControl>
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: ' 1px solid rgb(239 239 239)',
                      padding: 10
                    }}
                  >
                    <Button
                      onClick={handlePinClose}
                      color="primary"
                      variant="outlined"
                      size="small"
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      className={classes.button}
                    >
                      {isLoading ? <CircularProgress size="14px" /> : 'Submit'}
                    </Button>
                  </DialogActions>
                </form>
              </Box>
            </Popover>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Welcome;
