import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import NavItem from 'src/layouts/DashboardLayout/NavBar/NavItem.js';
import {
  Repeat as BillingIcon,
  Activity as TransactionIcon,
  Edit as InvoiceIcon,
  Repeat as TransferIcon
} from 'react-feather';
import { GiPayMoney } from 'react-icons/gi';

const useStyles = makeStyles({
  boxActive: {
    borderLeft: '2px solid #3f51b5',
    height: 20,
    paddingRight: 20
  },
  boxes: {
    borderLeft: '1px solid #f5f5ff',
    height: 'auto',
    paddingRight: 20,
    '&:hover': {
      borderLeft: '1px solid #c7c7c7'
    }
  },
  box: { margin: '45px 70px 0px 0px', position: 'fixed' },
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  mainBox: {},
  mainBoxActive: {
    marginBottom: 2
  },
  text: {
    fontSize: 17,
    color: '#3e4040'
  },
  mainText: {
    fontSize: 17,
    color: '#3f51b5',
    fontWeight: 500
  },
  buttons: {
    marginTop: 10
  }
});

const NoResults = ({ val }) => {
  const [active, setActive] = React.useState('payments');
  React.useEffect(() => {
    setActive(val);
  }, [val]);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.box}>
        <Typography
          style={{
            color: '#3e4040',
            fontSize: 20,
            fontWeight: 700,
            fontFamily: 'Ubuntu',
            marginBottom: 10,
            marginLeft: 7
          }}
        >
          Payments
        </Typography>
        <Box
          className={
            active == 'transfers' ? classes.mainBoxActive : classes.mainBox
          }
        >
          <Box
            className={
              active == 'transfers' ? classes.boxActive : classes.boxes
            }
          >
            <Box
              className={
                active == 'transfers' ? classes.mainText : classes.text
              }
              variant="p"
            >
              <NavItem
                className={classes.itemlistfirst}
                href="/app/transfers"
                title={
                  <Typography
                    variant="p"
                    className={
                      active == 'transfers' ? classes.mainText : classes.text
                    }
                  >
                    {'Transfers'}
                  </Typography>
                }
                icon={TransferIcon}
              />
            </Box>
          </Box>
        </Box>
        <Box
          className={active == 'bulk' ? classes.mainBoxActive : classes.mainBox}
        >
          <Box className={active == 'bulk' ? classes.boxActive : classes.boxes}>
            <Box
              className={active == 'bulk' ? classes.mainText : classes.text}
              variant="p"
            >
              <NavItem
                className={classes.itemlistfirst}
                href="/app/bulk-payments"
                title={
                  <Typography
                    variant="p"
                    className={
                      active == 'bulk' ? classes.mainText : classes.text
                    }
                  >
                    {'Bulk Payments'}
                  </Typography>
                }
                icon={GiPayMoney}
              />
            </Box>
          </Box>
        </Box>
        <Box
          className={
            active == 'invoices' ? classes.mainBoxActive : classes.mainBox
          }
        >
          <Box
            className={active == 'invoices' ? classes.boxActive : classes.boxes}
          >
            <Box
              className={active == 'invoices' ? classes.mainText : classes.text}
              variant="p"
            >
              <NavItem
                className={classes.itemlistfirst}
                href="/app/invoices"
                title={
                  <Typography
                    variant="p"
                    className={
                      active == 'invoices' ? classes.mainText : classes.text
                    }
                  >
                    {'Invoices'}
                  </Typography>
                }
                icon={InvoiceIcon}
              />
            </Box>
          </Box>
        </Box>
        <Box
          className={
            active == 'links' ? classes.mainBoxActive : classes.mainBox
          }
        >
          <Box
            className={active == 'links' ? classes.boxActive : classes.boxes}
          >
            <Box
              className={active == 'links' ? classes.mainText : classes.text}
              variant="p"
            >
              <NavItem
                className={classes.itemlistfirst}
                href="/app/payment-link"
                title={
                  <Typography
                    variant="p"
                    className={
                      active == 'links' ? classes.mainText : classes.text
                    }
                  >
                    {'Payment Link'}
                  </Typography>
                }
                icon={BillingIcon}
              />
            </Box>
          </Box>
        </Box>
        <Typography
          style={{
            color: '#3e4040',
            fontSize: 18,
            fontWeight: 700,
            fontFamily: 'Ubuntu',
            marginBottom: 10,
            marginLeft: 7,
            marginTop: 30
          }}
        >
          Merchants
        </Typography>
        <Box
          className={
            active == 'transactions' ? classes.mainBoxActive : classes.mainBox
          }
        >
          <Box
            className={
              active == 'transactions' ? classes.boxActive : classes.boxes
            }
          >
            <Box
              className={
                active == 'transactions' ? classes.mainText : classes.text
              }
              variant="p"
            >
              <NavItem
                className={classes.itemlistfirst}
                href="/app/transactions"
                title={
                  <Typography
                    variant="p"
                    className={
                      active == 'transactions' ? classes.mainText : classes.text
                    }
                  >
                    {'Transactions'}
                  </Typography>
                }
                icon={TransactionIcon}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NoResults;
