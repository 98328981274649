import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'src/axios';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
// import { useAuthState } from 'src/context';
import Logo from './logo.svg';
import { useAuthState } from 'src/context';

import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  error: {
    textAlign: 'left'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 100,
    marginLeft: '35%',
    [theme.breakpoints.down('md')]: {
      marginTop: 120,
      marginLeft: 5
    }
  },
  box: {
    width: '35%',
    marginLeft: '35%',
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: 310,
    marginTop: '0%',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      marginLeft: 10,
      marginRight: 50,
      padding: 0
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '17px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 11%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd',
      border: '1px solid #ced4da'
    }
  }
}));

const NewPassword = () => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const { token } = useAuthState();
  React.useEffect(() => {
    axios({
      method: 'get',
      url: '/token',
      headers: { 'x-access-token': token }
    }).then(res => {
      if (res.status === 200) navigate('/app/dashboard', { replace: true });
    });
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { code, secret } = location.state;

  const formik = useFormik({
    initialValues: {
      pass: '',
      passTwo: ''
    },
    validationSchema: Yup.object().shape({
      pass: Yup.string().required('Verification code is required')
    }),
    onSubmit: values => {
      if (values.pass !== values.passTwo) {
        setError(true);
        setEresponse('Passwords do not match');
      } else {
        const data = {
          newPass: values.pass,
          secret
        };
        axios
          .put(`/change-password/${code}`, data)
          .then(() => {
            navigate('/login', { replace: false });
          })
          .catch(error => {
            setError(true);
            setEresponse(error.response?.data.message);
          });
      }
    }
  });

  return (
    <Page className={classes.root} title="New password">
      <img className={classes.logo} src={Logo} alt="logo" />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        textAlign="center"
        className={classes.box}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h3">
                Password confirmation
              </Typography>
            </Box>
            {error ? (
              <Typography
                variant="p"
                style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
              >
                {eresponse}
              </Typography>
            ) : (
              <></>
            )}
            <BootstrapInput
              error={Boolean(formik.touched.code && formik.errors.code)}
              fullWidth
              helperText={formik.touched.code && formik.errors.code}
              label="Passcode"
              margin="normal"
              placeholder="New password"
              name="pass"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.pass}
              variant="outlined"
              size="small"
              required
            />
            <BootstrapInput
              error={Boolean(formik.touched.code && formik.errors.code)}
              fullWidth
              helperText={formik.touched.code && formik.errors.code}
              label="Passcode"
              margin="normal"
              placeholder="Confirm password"
              name="passTwo"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.passTwo}
              variant="outlined"
              size="small"
              required
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{ textTransform: 'none', borderRadius: 10 }}
              >
                Confirm
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default NewPassword;
