import { createTheme } from '@mui/material';
import typography from './typography';

const theme = createTheme({
  palette: {
    primary: {
      light: '#6573c3',
      main: '#3f51b5',
      dark: '#2c387e'
    },
    secondary: {
      light: '#f73378',
      main: '#3f51b5',
      dark: '#ab003c'
    }
  },
  typography
});

export default theme;
