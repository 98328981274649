import React from 'react';
import { Typography, Box } from '@material-ui/core';

const AlertBar = () => {
  return (
    <div>
      <Box
        style={{
          height: 30,
          backgroundColor: 'rgb(251 253 255)', //
          display: 'flex',
          borderBottom: '1px solid rgb(239 238 243)',
          paddingLeft: '46%',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundColor: '#000',
            padding: '0px 10px',
            borderRadius: 3,
            border: '1px solid #000',
            height: 20
          }}
        >
          <Box
            style={{
              marginTop: -2
            }}
          >
            <Typography
              style={{
                color: '#ffffff',
                fontSize: 12,
                fontWeight: 'bold',
                marginTop: 2,
                cursor: 'pointer',
                textTransform: 'uppercase'
              }}
              variant="p"
            >
              Beta
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AlertBar;
