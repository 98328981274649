import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NoResults from 'src/components/NoResults';
import {
  Container,
  DialogActions,
  Box,
  Grid,
  Popover,
  Hidden
} from '@material-ui/core';
import Bar from 'src/components/Bar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuthState } from 'src/context';
import axios from 'src/axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import PaymentsTab from 'src/components/PaymentsTab';
import Backdrop from '@mui/material/Backdrop';
import { CreditCard as PaymentIcon } from 'react-feather';
import { capitalize, formatAmount, ucFirst } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '15rem',
      textAlign: 'center'
    },
    border: '1px  solid #e0e0e03d'
  },
  label: {
    marginLeft: 8
  },
  button: {
    textTransform: 'none',
    display: 'block',
    fontSize: 14,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    height: 30,
    '&:hover': {
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    borderRadius: 10,
    width: 40,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '80%',
    height: '6px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#768095'
    }
  }
}));

const PaymentsView = () => {
  const classes = useStyles();
  const { token } = useAuthState();
  const navigate = useNavigate();
  const [progressDisplay, setProgressDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const formik = useFormik({
    initialValues: {
      email: '',
      pin: '',
      amount: ''
    },
    validationSchema: Yup.object().shape({
      reference: Yup.string()
    }),
    onSubmit: values => {
      setIsLoading(true);
      axios({
        method: 'get',
        url: `/transDetails/${values.reference}`,
        headers: { 'x-access-token': token }
      })
        .then(res => {
          setIsLoading(false);
          if (
            res.data.productName === 'Donation' ||
            res.data.productName === 'Payment link'
          ) {
            navigate('/app/donations/view', {
              replace: 'true',
              state: { paymentData: res.data }
            });
          } else if (res.data.productName === 'Invoice') {
            navigate('/app/invoices/view', {
              replace: 'true',
              state: { paymentData: res.data }
            });
          } else {
            navigate('/app/payments/view', {
              replace: 'true',
              state: { paymentData: res.data }
            });
          }
        })
        .catch(err => {
          setIsLoading(false);
          setError(true);
          setEresponse(err.response?.data.message);
        });
    }
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openI = Boolean(anchorEl);
  const id = openI ? 'simple-popover' : undefined;

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [transfers, setTransfers] = useState([]);

  useEffect(() => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: '/product-bought',
      headers: { 'x-access-token': token }
    })
      .then(response => {
        setProgressDisplay(false);
        const data = response.data;
        setTransfers(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleRedirect = ref => {
    axios({
      method: 'get',
      url: `/transDetails/${ref}`,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        if (
          res.data.productName === 'Donation' ||
          res.data.productName === 'Payment link'
        ) {
          navigate('/app/donations/view', {
            replace: 'true',
            state: { paymentData: res.data }
          });
        } else if (res.data.productName === 'Invoice') {
          navigate('/app/invoices/view', {
            replace: 'true',
            state: { paymentData: res.data }
          });
        } else {
          navigate('/app/payments/view', {
            replace: 'true',
            state: { paymentData: res.data }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Page title="Transfers">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="payments" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={9} xl={10}>
            <Bar
              title="Payments"
              btnText="Make a payment"
              onClick={handleOpen}
            />
            <Box
              style={{
                boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
              }}
            >
              <Popover
                style={{ marginTop: 5 }}
                id={id}
                open={openI}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <Box
                  style={{
                    border: '1px solid #0f15c514',
                    width: 250
                  }}
                >
                  <form
                    // style={{ textAlign: 'center' }}
                    className={classes.root}
                    onSubmit={formik.handleSubmit}
                  >
                    <DialogTitle
                      id="form-dialog-title"
                      style={{
                        borderBottom: '1px solid rgb(229 231 243)',
                        padding: 10,
                        fontSize: '12px'
                      }}
                    >
                      <Typography
                        style={{
                          color: '#546e7a',
                          fontWeight: 500,
                          fontSize: 14
                        }}
                        variant="p"
                      >
                        Pay with
                      </Typography>
                      <Typography
                        variant="p"
                        style={{
                          fontSize: '16px',
                          letterSpacing: 1
                        }}
                      >
                        {' '}
                        #Fapshi
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      {error && (
                        <Typography
                          variant="p"
                          style={{
                            color: '#eb6558',
                            fontWeight: 500,
                            fontSize: 14
                          }}
                        >
                          {eresponse}
                        </Typography>
                      )}
                      <FormControl>
                        <FormGroup>
                          <BootstrapInput
                            error={Boolean(
                              formik.touched.reference &&
                                formik.errors.reference
                            )}
                            helperText={
                              formik.touched.reference &&
                              formik.errors.reference
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.reference}
                            name="reference"
                            id="reference"
                            type="text"
                            InputLabelProps={{
                              shrink: true
                            }}
                            variant="outlined"
                            placeholder="Enter reference code"
                            size="small"
                            required
                          />
                        </FormGroup>
                      </FormControl>
                    </DialogContent>
                    <DialogActions
                      style={{
                        borderTop: ' 1px solid rgb(239 239 239)',
                        padding: 10
                      }}
                    >
                      <Button
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                        size="small"
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                        className={classes.button}
                      >
                        {' '}
                        {isLoading ? (
                          <CircularProgress my={2} size="10px" />
                        ) : (
                          'Submit'
                        )}
                      </Button>
                    </DialogActions>
                  </form>
                </Box>
              </Popover>
            </Box>
            <Box
              style={{
                display: progressDisplay ? 'block' : 'none'
              }}
            >
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: theme => theme.zIndex.drawer + 1,
                  backdropFilter: 'blur(2px)',
                  backgroundColor: '#ffffff59'
                }}
                open={open}
              >
                <CircularProgress
                  size="1.5rem"
                  style={{
                    display: progressDisplay ? 'block' : 'none',
                    marginLeft: '20%',
                    marginTop: '15%',
                    marginBottom: '10%',
                    color: '#3f51b5'
                  }}
                />
              </Backdrop>
            </Box>
            <List
              style={{
                display: progressDisplay ? 'none' : 'block'
              }}
              className={classes.root}
            >
              {transfers.length > 0 ? (
                transfers &&
                transfers.map(transfer => (
                  <React.Fragment>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={
                          <Typography style={{ letterSpacing: 1 }} variant="h6">
                            {`Transaction code: ${transfer.transferId}`}
                          </Typography>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {transfer.date}
                            </Typography>
                            <Typography variant="P">
                              {` — Made a payment of ${formatAmount(
                                transfer.total
                              )} FCFA for ${ucFirst(
                                transfer.productName
                              )} to ${capitalize(transfer.vendorName)} on ${
                                transfer.dateConfirmed
                              }`}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <IconButton
                        className={classes.iconButton}
                        style={{
                          height: 20
                        }}
                        onClick={() => handleRedirect(transfer.transferId)}
                      >
                        <MoreHorizIcon
                          style={{ fontSize: 22, cursor: 'pointer' }}
                        />
                      </IconButton>
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                ))
              ) : (
                <NoResults
                  title="payments made"
                  content="With a Fapshi account, you can pay for things through Fapshi Pay."
                  link="https://documentation.fapshi.com/#payments"
                  icon={
                    <PaymentIcon
                      size={35}
                      style={{
                        fontSize: 40,
                        border: '1px solid #bdc3c9',
                        boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                        borderRadius: 10,
                        color: '#bdc3c9',
                        padding: 5
                      }}
                    />
                  }
                />
              )}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PaymentsView;
