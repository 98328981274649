import React from 'react';
import Page from 'src/components/Page';
import { Container, Grid, Hidden } from '@material-ui/core';
import SimpleItems from './VariableItems';
import PaymentsTab from 'src/components/PaymentsTab';

const Variable = () => {
  return (
    <Page title="Variable Link">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="links" />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            xl={10}
            style={{
              borderRight: '1px solid #eaeaea6e'
            }}
          >
            <SimpleItems />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Variable;
