import React, { useState } from 'react';
import { Image } from 'antd';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Button } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import image from './images.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputBase from '@material-ui/core/InputBase';
import { alpha, styled } from '@mui/material/styles';
import axios from 'axios';
import { useAuthState } from 'src/context';
import Avatar from './Avatar';
import Snackbar from 'src/components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import ShareBtn from 'src/components/Buttons/ShareButton';
import CustomButton from 'src/components/Buttons/Button';
import { capitalize } from 'src/utils/helperFunctions';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: 'rgb(247 250 252)',
    border: '1px solid #f4f4f5',
    alignItems: 'center'
  },
  primary: {
    fontSize: 10,
    fontWeight: 700,
    fontFamily: 'Ubuntu',
    color: 'gray'
  },
  secondary: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  heading: {
    fontSize: 25
  },
  imgBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff8c' //rgba(0,0,30,0.2)
  },
  button: {
    textTransform: 'none',
    display: 'block',
    fontSize: 14,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    height: 30,
    '&:hover': {
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  }
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    marginTop: 5,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd'
    }
  }
}));

const StoreProducts = ({ data }) => {
  const classes = useStyles();
  const { token } = useAuthState();

  const { vendorPhone, vendorAddress, vendorDesc } = data;
  const [values, setValues] = React.useState({
    phone: vendorPhone,
    address: vendorAddress,
    description: vendorDesc
  });
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };
  const [open, setOpen] = React.useState(false);
  const handlePinOpen = () => {
    setOpen(true);
    setValues({
      ...values,
      phone: data.vendorPhone,
      address: data.vendorAddress,
      description: data.vendorDesc
    });
  };
  const handlePinClose = () => {
    setOpen(false);
    setError(false);
  };
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = e => {
    setIsLoading(true);
    e.preventDefault();
    axios({
      method: 'put',
      data: values,
      url: 'https://api.fapshi.com/editVendor',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsLoading(false);
        setValues({});
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  const handleClose = () => {
    setSnack(false);
  };
  return (
    <Box sx={{ flexGrow: 1, minWidth: 710, maxWidth: 710 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.imgBox}>
            <Box>
              <Typography
                style={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400 }}
                className={classes.heading}
              >
                Vendor Details
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box>
                <ShareBtn
                  url={`https://stores.fapshi.com/${data.vendorSlug}`}
                  text="Share Link"
                  type="btn"
                  icon="share"
                  style={{ mb: 0, mr: 2 }}
                />
              </Box>
              <Box>
                <a
                  href={'https://stores.fapshi.com/' + data.vendorSlug}
                  target="_blank"
                >
                  <CustomButton
                    btnTxt="Preview"
                    btnStyle={{ py: '2px', px: 2, mb: 0, mr: 2 }}
                  />
                </a>
              </Box>
              <Box>
                <IconButton
                  style={{ fontSize: 16 }}
                  color="primary"
                  size="small"
                  onClick={handlePinOpen}
                >
                  <EditIcon style={{ fontSize: 20 }} /> Edit
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box>
            <Dialog
              open={open}
              aria-labelledby="form-dialog-title"
              BackdropProps={{
                classes: {
                  root: classes.backDrop
                }
              }}
            >
              <Box
                style={{
                  boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
                  borderRadius: '5',
                  border: '1px solid #0f15c514',
                  width: 300,
                  padding: 5,
                  backgroundColor: '#ffffff'
                }}
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle
                    id="form-dialog-title"
                    style={{
                      borderBottom: '1px solid rgb(229 231 243)',
                      padding: 10,
                      fontSize: '14px'
                    }}
                  >
                    <Typography
                      style={{ fontSize: '16px', letterSpacing: 0.3 }}
                      variant="p"
                    >
                      Edit your vendor credentials
                    </Typography>
                    <br />
                    {snack && (
                      <Snackbar
                        duration={6000}
                        open={snack}
                        close={handleClose}
                        data="Product edited successfully"
                      />
                    )}
                    {error && (
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 900,
                          fontSize: 16
                        }}
                      >
                        {eresponse}
                      </Typography>
                    )}
                  </DialogTitle>
                  <DialogContent
                    style={{
                      padding: 20
                    }}
                  >
                    <FormControl>
                      <FormGroup>
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input-phone"
                          style={{
                            marginTop: 7,
                            color: '#546e7a',
                            fontWeight: 400,
                            fontSize: 20
                          }}
                        >
                          <Typography variant="p">
                            Vendor phone number
                          </Typography>
                        </InputLabel>
                        <BootstrapInput
                          name="phone"
                          id="phone"
                          type="number"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          placeholder="Change phone number"
                          size="small"
                          value={values.phone}
                          onChange={handleChange}
                        />
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input-address"
                          style={{
                            marginTop: 7,
                            color: '#546e7a',
                            fontWeight: 400,
                            fontSize: 20
                          }}
                        >
                          <Typography variant="p">Address location</Typography>
                        </InputLabel>
                        <BootstrapInput
                          name="address"
                          id="address"
                          type="text"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          placeholder="Change address location"
                          size="small"
                          value={values.address}
                          onChange={handleChange}
                        />
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input-description"
                          style={{
                            marginTop: 7,
                            color: '#546e7a',
                            fontWeight: 400,
                            fontSize: 20
                          }}
                        >
                          <Typography variant="p">Store description</Typography>
                        </InputLabel>
                        <BootstrapInput
                          name="description"
                          id="description"
                          type="text"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          placeholder="Change store description"
                          size="small"
                          value={values.description}
                          onChange={handleChange}
                          multiline
                        />
                        <Box>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input-description"
                            style={{
                              marginTop: 7,
                              color: '#546e7a',
                              fontWeight: 400,
                              fontSize: 20
                            }}
                          >
                            <Typography variant="p">Store logo</Typography>
                          </InputLabel>{' '}
                          <Avatar axios={axios} />
                        </Box>
                      </FormGroup>
                    </FormControl>
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: ' 1px solid rgb(239 239 239)',
                      padding: 10
                    }}
                  >
                    <Button
                      onClick={handlePinClose}
                      color="primary"
                      variant="outlined"
                      size="small"
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      className={classes.button}
                    >
                      {isLoading ? (
                        <CircularProgress size="14px" />
                      ) : (
                        'Edit and save'
                      )}{' '}
                    </Button>
                  </DialogActions>
                </form>
              </Box>
            </Dialog>
          </Box>
          <Box className={classes.box}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Vendor name
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {capitalize(data.vendorName)}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Vendor phone
                    </Typography>
                  }
                  secondary={
                    <Typography
                      className={classes.secondary}
                      style={{ fontFamily: 'Ubuntu' }}
                    >
                      +(237) {data.vendorPhone}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Vendor address
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {data.vendorAddress}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <Box className={classes.imgBox}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Description
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {data.vendorDesc}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Box>
              <Image
                style={{
                  border: '1px solid rgb(228 230 239)',
                  width: 80,
                  height: 80,
                  objectFit: 'cover',
                  marginTop: 10
                }}
                // width={200}
                src={data.vendorLogo ? data.vendorLogo : image}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StoreProducts;
