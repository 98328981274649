import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PaymentsImage from './svg/payment-method.svg';
import { FaPlusCircle } from 'react-icons/fa';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { makeStyles, Box, Button, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    width: '100%'
  },
  button: {
    textTransform: 'none',
    color: '#2c2e2e',
    fontWeight: 900,
    fontSize: 13,
    '&:hover': {
      backgroundColor: '#ffffff',
      borderRadius: 8,
      height: 35
    }
  },
  buttonOne: {
    textTransform: 'none',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    borderRadius: 8,
    height: 35,
    padding: 10,
    color: '#2c2e2e',
    fontWeight: 900,
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#ffffff',
      borderRadius: 8,
      height: 35
    }
  },
  headingOne: {
    fontFamily: 'Ubuntu',
    fontSize: 14,
    fontWeight: 900
  },
  iconButton: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    borderRadius: 4,
    backgroundColor: '#ffffff'
  },
  headingTwo: {
    fontFamily: 'Ubuntu',
    fontSize: 18,
    fontWeight: 900,
    color: '#353638'
  },
  headingThree: {
    fontFamily: 'Ubuntu',
    fontSize: 14
  }
}));

export default function LinkDirections() {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      <List
        style={{ borderRight: '1px dashed #f4f4f5' }}
        sx={{ width: '100%', maxWidth: 360 }}
      >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Invoices" src={PaymentsImage} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography className={classes.headingOne}>Payments</Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline', fontFamily: 'Ubuntu' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  className={classes.headingTwo}
                >
                  Create payment links
                </Typography>
                <Typography className={classes.headingThree}>
                  {' — Receive payments by creating custom payment links'}
                </Typography>
                <Box
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginTop: '0.5rem'
                  }}
                >
                  <Button
                    className={classes.buttonOne}
                    startIcon={<FaPlusCircle size="14" />}
                    onClick={() =>
                      navigate('/app/payment-link', { replace: false })
                    }
                  >
                    Explore
                  </Button>
                  <a
                    href="https://documentation.fapshi.com/#simple-link"
                    target="_blank"
                  >
                    <Button
                      className={classes.button}
                      endIcon={
                        <FaExternalLinkAlt
                          size="16"
                          className={classes.iconButton}
                        />
                      }
                    >
                      Learn more
                    </Button>
                  </a>
                </Box>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </>
  );
}
