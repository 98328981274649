import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { visuallyHidden } from '@mui/utils';
import NoResults from 'src/components/NoResults';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ImCross } from 'react-icons/im';
import { formatAmount, ucFirst } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography
                style={{
                  fontFamily: 'ubuntu',
                  fontSize: 16,
                  fontWeight: 900
                }}
              >
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default function PopupTable({ details }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = details.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const classes = useStyles();

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {details.length !== 0 ? (
          <>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  className={classes.table}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={details.length}
                  />
                  <TableBody>
                    {details.map(rows => (
                      <TableRow
                        hover
                        onClick={event => handleClick(event, rows.transferId)}
                        role="checkbox"
                        tabIndex={-1}
                        key={rows.transferId}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="p"
                            style={{ color: '#3e4040', fontSize: 16 }}
                          >
                            {ucFirst(rows.name)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="p"
                            style={{ color: '#3e4040', fontSize: 16 }}
                          >
                            {rows.depositNumber}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="p"
                            style={{ color: '#3e4040', fontSize: 16 }}
                          >
                            {rows.amount >= 0 && formatAmount(rows.amount)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={dense}
                  onChange={handleChangeDense}
                />
              }
              label={
                <Typography
                  variant="p"
                  style={{ color: '#3e4040', fontSize: 16 }}
                >
                  {'Dense padding'}
                </Typography>
              }
            />
          </>
        ) : (
          <>
            <NoResults
              title="results available"
              content="Go back to the previous page and upload your CSV"
              link="https://documentation.fapshi.com/"
              icon={
                <ImCross
                  size={25}
                  style={{
                    fontSize: 25,
                    border: '1px solid #bdc3c9',
                    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                    borderRadius: 10,
                    color: '#b50404',
                    padding: 5
                  }}
                />
              }
            />
          </>
        )}
      </Box>
    </>
  );
}
