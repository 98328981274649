// const images = {
//   fapshi_logo: require('./images/fapshi_logo.png'),
//   momo: require('./images/mobile-money.jpg'),
//   om: require('./images/om.png'),
//   bank: require('./images/bank-transfer.png')
// };

const strings = {
  currency: 'FCFA'
};

const colors = {
  primaryColor: '#3f51b5',
  primaryDark: '#2c387e',
  primaryLight: '#ebedf8',
  bgColor: '#f8f7fa',
  darkGrey: '#A0A2A5',
  white: '#fffefd',
  textColor: '#2b2b2b',
  placeholderText: '#828282',
  black: '#1b1c1e',
  lightBlack: '#303030',
  lightText: '#49454f',
  lightGrey: '#F9F9F9',
  grey: '#F2F2F2',
  success: '#198754',
  error: '#a82e2e',
  errorDark: '#862424',
  borderColor: '#f5f5f5',
  inputBorderColor: '#ced4da'
};

// const font = {
//   extraBold: 'Heebo-ExtraBold',
//   bold: 'Heebo-Bold',
//   medium: 'Heebo-Medium',
//   regular: 'Heebo-Regular',
//   light: 'Heebo-Light',
// }

// const fontSizes = {
//   smallText: screen_height * 0.013,
//   text: screen_height * 0.018,
//   name: screen_height * 0.02,
//   heading: screen_height * 0.023,
//   subheading: screen_height * 0.022,
//   mainHeading: screen_height * 0.045,
// }

// const fontStyles = {
//   smallText: {
//     fontFamily: font.regular,
//     fontSize: fontSizes.smallText,
//     color: colors.lightBlack,
//   },
//   text: {
//     fontFamily: font.regular,
//     fontSize: fontSizes.text,
//     color: colors.textColor,
//     lineHeight: hp('3%'),
//   },
//   subheading: {
//     fontFamily: font.medium,
//     fontSize: fontSizes.subheading,
//     color: colors.black,
//     lineHeight: hp('4%'),
//   },
//   heading: {
//     fontFamily: font.bold,
//     fontSize: fontSizes.heading,
//     fontWeight: '700',
//     color: colors.black,
//     lineHeight: hp('3%'),
//   },
//   mainHeading: {
//     fontFamily: font.extraBold,
//     fontSize: fontSizes.mainHeading,
//     color: colors.black,
//     lineHeight: hp('6%'),
//   },
//   name: {
//     fontSize: fontSizes.name,
//     fontFamily: font.medium,
//     color: colors.black,
//     lineHeight: hp('3%'),
//   },
// }

const bordered = {
  border: '1px solid',
  borderColor: '#f5f5f5'
};

export default {
  strings,
  // images,
  // font,
  // fontStyles,
  // fontSizes,
  colors,
  bordered
};
