import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import NavItem from 'src/layouts/DashboardLayout/NavBar/NavItem.js';
import {
  ShoppingCart as StoreIcon,
  ShoppingBag as OrderIcon,
  Package as ProductIcon
} from 'react-feather';

const useStyles = makeStyles({
  boxActive: {
    borderLeft: '2px solid #3f51b5',
    height: 20,
    paddingRight: 20
  },
  boxes: {
    borderLeft: '1px solid #f5f5ff',
    paddingRight: 20,
    '&:hover': {
      borderLeft: '1px solid #c7c7c7'
    }
  },
  box: { margin: '20px 70px 0px 0px', position: 'fixed' },
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  // mainBox: {
  //   marginBottom: 2
  // },
  mainBoxActive: {
    marginBottom: 2
  },
  text: {
    fontSize: 17,
    color: '#3e4040'
  },
  mainText: {
    fontSize: 17,
    color: '#3f51b5',
    fontWeight: 500
  },
  buttons: {
    marginTop: 10
  }
});

const StoreTab = ({ val }) => {
  const [active, setActive] = React.useState('stores');
  React.useEffect(() => {
    setActive(val);
  }, [val]);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.box}>
        <Typography
          style={{
            color: '#3e4040',
            fontSize: 20,
            fontWeight: 700,
            fontFamily: 'Ubuntu',
            marginBottom: 10,
            marginLeft: 7
          }}
        >
          Store
        </Typography>
        <Box
          className={
            active == 'stores' ? classes.mainBoxActive : classes.mainBox
          }
        >
          <Box
            className={active == 'stores' ? classes.boxActive : classes.boxes}
          >
            <Box
              className={active == 'stores' ? classes.mainText : classes.text}
              variant="p"
            >
              <NavItem
                className={classes.itemlistfirst}
                href="/app/store"
                title="Store"
                icon={StoreIcon}
              />
            </Box>
          </Box>
        </Box>
        <Box
          className={
            active == 'products' ? classes.mainBoxActive : classes.mainBox
          }
        >
          <Box
            className={active == 'products' ? classes.boxActive : classes.boxes}
          >
            <Box
              className={active == 'products' ? classes.mainText : classes.text}
              variant="p"
            >
              <NavItem
                className={classes.itemlistfirst}
                href="/app/products"
                title="Products"
                icon={ProductIcon}
              />
            </Box>
          </Box>
        </Box>
        <Box
          className={
            active == 'orders' ? classes.mainBoxActive : classes.mainBox
          }
        >
          <Box
            className={active == 'orders' ? classes.boxActive : classes.boxes}
          >
            <Box
              className={active == 'orders' ? classes.mainText : classes.text}
              variant="p"
            >
              <NavItem
                className={classes.itemlistfirst}
                href="/app/store/orders"
                title="Orders"
                icon={OrderIcon}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StoreTab;
