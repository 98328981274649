import React from 'react';
import { Container, Typography, Box, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { alpha } from '@mui/material/styles';
import MtnLogo from './images/mtn.svg';
import OrangeLogo from './images/orange.svg';
import { FaCreditCard } from 'react-icons/fa';
import { RiWechatPayFill } from 'react-icons/ri';
import { FaAlipay } from 'react-icons/fa';
import { FaGooglePay } from 'react-icons/fa';
import { FaApplePay } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ActivationForm from 'src/components/ActivationForm';
import { useAuthState } from 'src/context';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  introtitle: {
    color: '#1a1f36',
    [theme.breakpoints.down('md')]: {
      lineHeight: 1
    }
  },
  introtext: {
    fontSize: 16
  },
  inputfield: {
    width: '100%'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 7,
    height: 35,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ced4da',
      borderRadius: 7
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 7
    }
  },
  iconbox: {
    maxWidth: 40,
    maxHeight: 40
  },
  cardbox: {
    margin: '5px 0px 10px 0px'
  },
  flexbox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  flexboxtwo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  iconbutton: {
    textTransform: 'none',
    display: 'block',
    marginLeft: 50,
    borderRadius: 20,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ced4da',
      borderRadius: 20
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 7
    }
  },
  dialogbox: {
    marginLeft: 20,
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: 10
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentMethods = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { userDetails } = useAuthState();

  return (
    <Container>
      <Box style={{ marginBottom: 12 }}>
        <Typography variant="h5">Payment methods</Typography>
      </Box>
      <Box style={{ marginBottom: 0 }}>
        <Typography variant="h4" className={classes.introtitle}>
          Manage your payment methods without code
        </Typography>
      </Box>
      <br />
      <Box style={{ marginBottom: 12 }}>
        <Typography variant="p" className={classes.introtext}>
          Turn payment methods on or off with one click — no engineering time
          required. Only payment methods that support the currency and
          configuration of the payment will be shown. View fees on our{' '}
          <a
            style={{ textDecoration: 'underline', color: '#3f51b5' }}
            href="https://fapshi.com/pricing"
            target="_blank"
          >
            pricing page.
          </a>
        </Typography>
      </Box>
      <Box className={classes.cardbox} style={{ marginTop: 30 }}>
        <Typography variant="h5">Cards</Typography>
        <Typography variant="p">
          Popular for consumers and businesses to pay online or in person.
          Fapshi supports global and local card networks.
        </Typography>
      </Box>
      <Divider
        style={{
          backgroundColor: '#5f616817'
        }}
      />
      <Box className={classes.flexbox}>
        <Box>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            <ListItem>
              <ListItemAvatar>
                <FaCreditCard size={40} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="p">Credit card</Typography>}
                secondary={
                  <Typography variant="p">
                    Accept Visa, Mastercard, American Express, Discover, Diners
                    Club, and JCB payments from customers worldwide.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
        <Box>
          {userDetails.cardAllowed ? (
            <Button disabled variant="outlined" className={classes.button}>
              Turn off
            </Button>
          ) : (
            <Button
              onClick={handleClickOpen}
              variant="outlined"
              className={classes.button}
            >
              Turn on
            </Button>
          )}
        </Box>
      </Box>
      <Box className={classes.cardbox}>
        <Typography variant="h5">Mobile money</Typography>
        <Typography variant="p">
          Popular for consumers and businesses to pay via their local mobile
          networks. Popular in Africa, available in Cameroon.
        </Typography>
      </Box>
      <Divider
        style={{
          backgroundColor: '#5f616817'
        }}
      />
      <Box className={classes.flexbox}>
        <Box>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            <ListItem>
              <ListItemAvatar>
                <Box className={classes.iconbox}>
                  <img className={classes.iconbox} src={MtnLogo} />
                </Box>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="p">Mobile money</Typography>}
                secondary={
                  <Typography variant="p">
                    Accept MTN Mobile Money payments from customers in Cameroon.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
        <Box>
          <Button disabled variant="outlined" className={classes.button}>
            Turn off
          </Button>
        </Box>
      </Box>
      <Divider
        style={{
          backgroundColor: '#5f616817'
        }}
      />
      <Box className={classes.flexbox}>
        <Box>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            <ListItem>
              <ListItemAvatar>
                <Box className={classes.iconbox}>
                  <img className={classes.iconbox} src={OrangeLogo} />
                </Box>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="p">Orange money</Typography>}
                secondary={
                  <Typography variant="p">
                    Accept Orange Money payments from customers in Cameroon.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
        <Box>
          <Button disabled variant="outlined" className={classes.button}>
            Turn off
          </Button>
        </Box>
      </Box>
      <Box className={classes.cardbox}>
        <Typography variant="h5">Wallets</Typography>
        <Typography variant="p">
          Improve conversion and reduce fraud on mobile. Customers pay with a
          stored card or balance.
        </Typography>
      </Box>
      <Divider
        style={{
          backgroundColor: '#5f616817'
        }}
      />
      <Box className={classes.flexbox}>
        <Box>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            <ListItem>
              <ListItemAvatar>
                <FaApplePay size={40} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="p">Apple Pay</Typography>}
                secondary={
                  <Typography variant="p">
                    Accept payments globally with Apple Pay.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
        <Box>
          {userDetails.cardAllowed ? (
            <Button disabled variant="outlined" className={classes.button}>
              Turn off
            </Button>
          ) : (
            <Button
              onClick={handleClickOpen}
              variant="outlined"
              className={classes.button}
            >
              Turn on
            </Button>
          )}
        </Box>
      </Box>
      <Divider
        style={{
          backgroundColor: '#5f616817'
        }}
      />
      <Box className={classes.flexbox}>
        <Box>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            <ListItem>
              <ListItemAvatar>
                <FaGooglePay size={40} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="p">Google Pay</Typography>}
                secondary={
                  <Typography variant="p">
                    Accept payments globally with Google Pay.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
        <Box>
          {userDetails.cardAllowed ? (
            <Button disabled variant="outlined" className={classes.button}>
              Turn off
            </Button>
          ) : (
            <Button
              onClick={handleClickOpen}
              variant="outlined"
              className={classes.button}
            >
              Turn on
            </Button>
          )}
        </Box>
      </Box>
      <Divider
        style={{
          backgroundColor: '#5f616817'
        }}
      />
      <Box className={classes.flexbox}>
        <Box>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            <ListItem>
              <ListItemAvatar>
                <FaAlipay size={30} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="p">Ali Pay</Typography>}
                secondary={
                  <Typography variant="p">
                    Accept payments across Asia with Ali Pay. Popular in China.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
        <Box>
          {userDetails.cardAllowed ? (
            <Button disabled variant="outlined" className={classes.button}>
              Turn off
            </Button>
          ) : (
            <Button
              onClick={handleClickOpen}
              variant="outlined"
              className={classes.button}
            >
              Turn on
            </Button>
          )}
        </Box>
      </Box>
      <Divider
        style={{
          backgroundColor: '#5f616817'
        }}
      />
      <Box className={classes.flexbox}>
        <Box>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            <ListItem>
              <ListItemAvatar>
                <RiWechatPayFill size={30} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="p">WeChat Pay</Typography>}
                secondary={
                  <Typography variant="p">
                    Accept payments across Asia with WeChat Pay. Popular in
                    China.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
        <Box>
          {userDetails.cardAllowed ? (
            <Button disabled variant="outlined" className={classes.button}>
              Turn off
            </Button>
          ) : (
            <Button
              onClick={handleClickOpen}
              variant="outlined"
              className={classes.button}
            >
              Turn on
            </Button>
          )}
        </Box>
      </Box>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box className={classes.dialogbox}>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              className={classes.iconbutton}
              style={{ border: '1px solid #ced4da' }}
            >
              <CloseIcon />
            </IconButton>
            <ActivationForm />
          </Box>
        </Dialog>
      </div>
    </Container>
  );
};

export default PaymentMethods;
