import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography, Button, MenuItem, Grid } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { useAuthState } from 'src/context';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'src/components/Page';
import Snackbar from 'src/components/Snackbar';
import Select from '@mui/material/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '60%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50,
    backgroundColor: '#000'
  },
  inputText: {
    fontSize: 20,
    color: '#181819',
    fontWeight: 900,
    letterSpacing: 2
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderLeft: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 15
  },
  text: {
    fontSize: 15,
    color: '#3e4040'
  },
  mainText: {
    fontSize: 15,
    color: '#3f51b5'
  },
  buttons: {
    marginTop: 10,
    backgroundColor: '#000'
  },
  mainbox: {
    marginBottom: 10,
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  uploadIcon: {},
  [theme.breakpoints.down('md')]: {
    width: 'auto'
  },
  boxesOne: {
    borderTop: '1px solid #e9e9e9',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    padding: 20,
    borderRadius: 4
  },
  boxesTwo: {
    borderTop: '1px solid #e9e9e9',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    padding: 20
  },
  boxesThree: {
    borderTop: '1px solid #e9e9e9',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    padding: 20
  },
  boxesFour: {
    padding: 20
  },
  boxesFive: {
    borderRight: '1px solid #e9e9e9',
    borderLeft: '1px solid #e9e9e9',
    padding: 20
  },
  boxesSix: {
    padding: 20,
    marginLeft: 0
  },
  boxflex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #e9e9e9',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    },
    borderRadius: 4
  },
  mainboxes: {
    height: 600,
    padding: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  textOne: {
    fontSize: 16,
    fontWeight: 500
  },
  textTwo: {
    fontSize: 20,
    fontWeight: 900,
    fontFamily: 'Ubuntu'
  },
  gridStyle: {
    borderLeft: '1px solid #5f616817',
    borderRadius: 4,
    padding: 25,
    marginRight: '4rem',
    [theme.breakpoints.down('md')]: {
      marginRight: 'auto'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));
const BootstrapSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '10px',
    padding: '10px 12px',
    borderBottom: 'none',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));
const Addbank = () => {
  const classes = useStyles();
  const { token, userDetails } = useAuthState();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    ownerName: null,
    bankName: null,
    bankCode: null,
    branchCode: null,
    accountNum: null,
    key: null
  });

  const pass = location.state?.pass;

  React.useEffect(() => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/cashout/bank-details',
      headers: { 'x-access-token': token }
    }).then(res => {
      setValues(res.data);
    });
  }, []);

  React.useEffect(() => {
    console.log(pass);
    if (!pass) {
      navigate('/app/dashboard', { replace: true });
    }
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const value = {
      ownerName: values.ownerName,
      bankName: values.bankName,
      bankCode: values.bankCode,
      branchCode: values.branchCode,
      accountNum: values.accountNum,
      key: values.key,
      password: pass
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/cashout/addbank',
      data: value,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setValues(res.data);
        setIsLoading(false);
        setSnack(true);
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const handleSnackClose = () => {
    setSnack(false);
  };

  return (
    <Page title="Add Bank Account">
      <Box className={classes.root} sx={{ flexGrow: 1 }}>
        <Box className={classes.mainbox}>
          <Typography
            style={{
              color: 'rgb(26 27 37)',
              fontSize: 24,
              fontWeight: 700,
              fontFamily: 'Ubuntu'
            }}
          >
            Add a bank account for payouts.
          </Typography>
          <Typography
            style={{
              color: '#3e4040',
              fontSize: 14,
              textAlign: 'justify',
              fontWeight: 400,
              fontFamily: 'Ubuntu'
            }}
          >
            Hi {userDetails.username}, to receive bank payouts, you need to set
            your bank details. Contact your bank to get the details below.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid
            className={classes.gridStyle}
            item
            xs={12}
            sm={12}
            lg={4}
            xl={4}
          >
            <Box sx={{ mt: 2 }}>
              <Snackbar
                duration={6000}
                close={handleSnackClose}
                data="Operation successful"
                open={snack}
              />
              {error && (
                <Typography
                  variant="p"
                  style={{
                    color: '#eb6558',
                    fontWeight: 700,
                    fontSize: 16
                  }}
                >
                  {eresponse}
                </Typography>
              )}
              <Box>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-name"
                  style={{ marginTop: 7 }}
                >
                  <Typography
                    variant="p"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    Select your bank
                  </Typography>
                </InputLabel>
                <BootstrapSelect
                  fullWidth
                  margin="normal"
                  name="bankName"
                  value={values.bankName}
                  onChange={handleChange}
                  type="select"
                  id="bootstrap-input-name"
                  required
                  style={{ marginRight: 8 }}
                >
                  <MenuItem
                    value="Access Bank Cameroon"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Access Bank Cameroon
                  </MenuItem>
                  <MenuItem
                    value="Afriland First Bank"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Afriland First Bank
                  </MenuItem>
                  <MenuItem
                    value="Atlantic Bank Cameroon"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Atlantic Bank Cameroon
                  </MenuItem>
                  <MenuItem
                    value="BICEC Bank"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    BICEC Bank
                  </MenuItem>
                  <MenuItem
                    value="BC-PME SA"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    BC-PME SA
                  </MenuItem>
                  <MenuItem
                    value="BGFI Bank Cameroon"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    BGFI Bank Cameroon
                  </MenuItem>
                  <MenuItem
                    value="SCB Cameroun"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    SCB Cameroun
                  </MenuItem>
                  <MenuItem
                    value="CCA Bank"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    CCA Bank
                  </MenuItem>
                  <MenuItem
                    value="Citibank"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Citibank
                  </MenuItem>
                  <MenuItem
                    value="Commercial Bank of Cameroon"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Commercial Bank of Cameroon
                  </MenuItem>
                  <MenuItem
                    value="Ecobank Cameroon"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Ecobank Cameroon
                  </MenuItem>
                  <MenuItem
                    value="NFC Bank"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    NFC Bank
                  </MenuItem>
                  <MenuItem
                    value="SCB Bank"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    SCB Bank
                  </MenuItem>
                  <MenuItem
                    value="Wineex Bank Cameroon"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Wineex Bank Cameroon
                  </MenuItem>
                  <MenuItem
                    value="SGBC Bank"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    SGBC Bank
                  </MenuItem>
                  <MenuItem
                    value="Union Bank of Cameroon"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Union Bank of Cameroon
                  </MenuItem>
                  <MenuItem
                    value="United Bank for Africa"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    United Bank for Africa
                  </MenuItem>
                </BootstrapSelect>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-holder"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Account number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Account number"
                  type="number"
                  margin="normal"
                  name="accountNum"
                  value={values.accountNum}
                  onChange={handleChange}
                  id="bootstrap-input-holder"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-owner"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Account holder's name</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Holder name"
                  margin="normal"
                  type="text"
                  name="ownerName"
                  value={values.ownerName}
                  onChange={handleChange}
                  id="bootstrap-input-owner"
                  required
                  style={{ marginRight: 8 }}
                />
                {/* <InputLabel
                  shrink
                  htmlFor="bootstrap-input-iban"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">IBAN</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="IBAN"
                  margin="normal"
                  type="text"
                  name="iban"
                  value={values.iban}
                  onChange={handleChange}
                  id="bootstrap-input-iban"
                  required
                  style={{ marginRight: 8 }}
                /> */}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-code"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Bank code</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Bank code"
                  margin="normal"
                  type="text"
                  name="bankCode"
                  value={values.bankCode}
                  onChange={handleChange}
                  id="bootstrap-input-code"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-branch"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Branch code</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Branch Code"
                  margin="normal"
                  type="text"
                  name="branchCode"
                  value={values.branchCode}
                  onChange={handleChange}
                  id="bootstrap-input-branch"
                  required
                  style={{ marginRight: 8 }}
                />{' '}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-key"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Account key</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Account Key"
                  margin="normal"
                  type="text"
                  name="key"
                  value={values.key}
                  onChange={handleChange}
                  id="bootstrap-input-key"
                  required
                  style={{ marginRight: 8 }}
                />
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  fullWidth
                >
                  {isLoading ? <CircularProgress size="14px" /> : 'Submit'}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6} Grid>
            {values.ownerName && (
              <Box className={classes.mainboxes}>
                <Typography
                  variant="p"
                  style={{ fontSize: 18, fontWeight: 700 }}
                >
                  Bank details
                </Typography>
                <Box className={classes.boxesOne}>
                  <Typography variant="p" className={classes.textOne}>
                    Bank name
                  </Typography>
                  <Typography className={classes.textTwo}>
                    {values.bankName}
                  </Typography>
                </Box>
                <Box className={classes.boxesTwo}>
                  <Typography variant="p" className={classes.textOne}>
                    Account number
                  </Typography>
                  <Typography className={classes.textTwo}>
                    {values.accountNum}
                  </Typography>
                </Box>
                <Box className={classes.boxesThree}>
                  <Typography variant="p" className={classes.textOne}>
                    Account holder
                  </Typography>
                  <Typography className={classes.textTwo}>
                    {values.ownerName}
                  </Typography>
                </Box>
                <Box className={classes.boxflex}>
                  <Box className={classes.boxesFour}>
                    <Typography variant="p" className={classes.textOne}>
                      Bank code
                    </Typography>
                    <Typography className={classes.textTwo}>
                      {values.bankCode}
                    </Typography>
                  </Box>
                  <Box className={classes.boxesFive}>
                    <Typography variant="p" className={classes.textOne}>
                      Branch code
                    </Typography>
                    <Typography className={classes.textTwo}>
                      {values.branchCode}
                    </Typography>
                  </Box>
                  <Box className={classes.boxesSix}>
                    <Typography variant="p" className={classes.textOne}>
                      Bank key
                    </Typography>
                    <Typography className={classes.textTwo}>
                      {values.key}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Addbank;
