import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from './axiosInstance';

const useEndpoint = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [controller, setController] = useState();
  const [getRequest, setGetRequest] = useState();

  const modifyData = async ({
    url,
    requestMethod,
    requestData,
    action,
    errMsg,
    successMsg,
    idName,
    id
  }) => {
    requestMethod === 'get' && setGetRequest(requestMethod);
    try {
      setLoading(action ? `${action}...` : true);

      const ctrl = new AbortController();
      setController(ctrl);

      const res = await axiosInstance[requestMethod.toLowerCase()](
        url,
        requestData && requestData,
        { signal: ctrl.signal }
      );
      if (requestMethod === 'get') {
        setData(res.data);
      }
      if (requestMethod === 'delete') {
        id ? setData(data.filter(item => item[idName] !== id)) : setData([]);
        toast.success(successMsg);
      }
      setSuccess(true);

      if (requestMethod === 'post' || requestMethod === 'put') {
        setSuccess(true);
        setData(res.data);
        toast.success(successMsg);

        if (res.data === null || res.data === '' || res.data.length === 0) {
          return true;
        } else {
          return res.data;
        }
      }

      return true;
    } catch (err) {
      if (err.isAxiosError) {
        if (err.response) {
          setError(err.response.data.message);
          setTimeout(() => {
            setError(false);
          }, 10000);
          requestMethod !== 'get' && errMsg && console.log('error');
          toast.error(
            err.response.data.message ? err.response.data.message : errMsg
          );
        } else {
          toast.error(
            err.response.data.message ? err.response.data.message : errMsg
          );
        }
      } else {
        toast.error(
          err.response.data.message ? err.response.data.message : errMsg
        );
      }
    } finally {
      setLoading(false);
      setGetRequest(false);
    }
  };

  useEffect(() => {
    return () => {
      controller && controller.abort();
    };
  }, [controller]);

  return {
    data,
    loading,
    error,
    success,
    getRequest,
    setError,
    setSuccess,
    modifyData
  };
};

export default useEndpoint;
