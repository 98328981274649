import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography, Button } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'src/context';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { FaCheck } from 'react-icons/fa';
import Page from 'src/components/Page';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '50%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 12
  },
  text: {
    fontSize: 15,
    color: '#3e4040',
    fontWeight: 400
  },
  mainText: {
    fontSize: 15,
    color: '#3f51b5',
    fontWeight: 400
  },
  buttons: {
    marginTop: 10,
    textTransform: 'none',
    fontSize: 18
  },
  headingText: {
    color: '#3e4040',
    fontWeight: 400,
    fontSize: 18
  }
}));

const Fulfilment = () => {
  const classes = useStyles();
  const { userDetails } = useAuthState();
  const navigate = useNavigate();

  return (
    <Page title="Account Activation">
      <Box className={classes.root} sx={{ flexGrow: 1 }}>
        <Box className={classes.mainbox}>
          <Typography
            style={{
              color: 'rgb(26 27 37)',
              fontSize: 35,
              fontWeight: 700,
              fontFamily: 'Ubuntu'
            }}
          >
            Activate your account
          </Typography>
          {userDetails.isVendor ? (
            <Typography
              style={{
                color: '#3e4040',
                fontSize: 14,
                textAlign: 'justify',
                fontWeight: 400,
                fontFamily: 'Ubuntu'
              }}
            >
              Hello {userDetails.username}, Your account is now activated.
            </Typography>
          ) : (
            <Typography
              style={{
                color: '#3e4040',
                fontSize: 14,
                textAlign: 'justify',
                fontWeight: 400,
                fontFamily: 'Ubuntu'
              }}
            >
              Hello {userDetails.username}, Your account is pending activation.
              You won't be able to perform certain transactions. Start by
              activating your account in very simple steps.
            </Typography>
          )}
        </Box>
        <Typography
          style={{
            color: '#3e4040',
            fontSize: 20,
            fontWeight: 700,
            fontFamily: 'Ubuntu',
            marginBottom: 20
          }}
        >
          Business account
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Business structure
                </Typography>
              </Box>
            </Box>

            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Legal fulfilment
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBoxActive}>
              <Box className={classes.boxActive}>
                <Typography className={classes.mainText} variant="p">
                  Account confirmation
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            style={{
              // backgroundColor: 'rgb(247 250 252)',
              borderRadius: 4,
              padding: 25,
              border: '1px solid #e7eaf5'
            }}
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
          >
            <Box>
              <Typography
                variant="h3"
                style={{
                  marginTop: -5,
                  color: '#3e4040'
                }}
              >
                Confirmation
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Account status</Typography>
                </InputLabel>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <Chip
                      label={
                        userDetails.isVendor
                          ? 'Account verified'
                          : 'Pending verification'
                      }
                      color="success"
                      deleteIcon={<FaCheck />}
                    />
                  </Stack>
                </Stack>
                {!userDetails.isVendor && (
                  <Typography
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 16,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    You'll receive an email once your account has been reviewed.
                    This is usually within 1-2 business days, but might take
                    longer with respect to the amount of requests we receive.
                  </Typography>
                )}
                <Box
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    className={classes.buttons}
                    variant="outlined"
                    color="default"
                    startIcon={<ArrowBackIcon>send</ArrowBackIcon>}
                    type="submit"
                    onClick={() =>
                      navigate('/app/activate/vendor/legal', {
                        replace: 'false'
                      })
                    }
                    size="small"
                    disabled={userDetails.isVendor ? true : false}
                  >
                    Return to previous page
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Fulfilment;
