import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography, Button } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Table from './Table';
import axios from 'axios';
import { useAuthState } from 'src/context';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '80%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 12
  },
  text: {
    fontSize: 18,
    color: '#3e4040',
    fontWeight: 400
  },
  mainText: {
    fontSize: 18,
    color: '#3f51b5',
    fontWeight: 400
  },
  buttons: {
    marginTop: 10,
    textTransform: 'none',
    fontSize: 18
  },
  headingText: {
    color: '#3e4040',
    fontWeight: 400,
    fontSize: 18
  }
}));

const Verify = ({ id }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);
  const { token } = useAuthState();
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    getProduct();
  }, []);
  const getProduct = () => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/bulk-details/' + id,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setDetails(res.data);
        res.data.length != 0 ? setIsActive(false) : setIsActive(true);
      })
      .catch(() => {
        setIsActive(true);
      });
  };

  const handleSubmit = () => {
    navigate('/app/bulk-payments/confirm', {
      replace: true,
      state: { id }
    });
  };

  return (
    <Box className={classes.root} sx={{ flexGrow: 1 }}>
      <Typography
        style={{
          color: '#3e4040',
          fontSize: 20,
          fontWeight: 700,
          fontFamily: 'Ubuntu',
          marginBottom: 20
        }}
      >
        Payment details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <Box className={classes.mainBox}>
            <Box className={classes.box}>
              <Typography className={classes.text} variant="p">
                Upload CSV
              </Typography>
            </Box>
          </Box>
          <Box className={classes.mainBox}>
            <Box className={classes.box}>
              <Typography className={classes.mainText} variant="p">
                Review details
              </Typography>
            </Box>
          </Box>
          <Box className={classes.mainBox}>
            <Box className={classes.box}>
              <Typography className={classes.text} variant="p">
                Confirm payment
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          style={{
            borderRadius: 4,
            padding: 25
          }}
          item
          xs={12}
          sm={12}
          lg={8}
          xl={8}
        >
          <Box>
            <Typography
              variant="h3"
              style={{
                marginTop: -5,
                color: '#3e4040'
              }}
            >
              Verify details
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box>
              <Table details={details} />
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  color="default"
                  startIcon={<ArrowBackIcon>send</ArrowBackIcon>}
                  type="submit"
                  onClick={() =>
                    navigate('/app/bulk-payments/add', {
                      replace: false,
                      state: { id }
                    })
                  }
                  size="small"
                >
                  Previous
                </Button>
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIcon>send</ArrowForwardIcon>}
                  type="submit"
                  onClick={handleSubmit}
                  size="small"
                  disabled={isActive}
                >
                  {'Next'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Verify;
