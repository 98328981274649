import React from 'react';

const LocalLoader = ({ text }) => {
  return (
    <div
      style={{
        marginTop: '1rem',
        fontSize: 12,
        width: '100%'
      }}
    >
      <p>{text}...</p>
    </div>
  );
};

export default LocalLoader;
