import { Spin } from 'antd';

const Loader = ({ text, children }) => {
  return (
    <Spin tip={text + '...'} spinning={text ? true : false} size="large">
      {children}
    </Spin>
  );
};
export default Loader;
