import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthDispatch, logout } from 'src/context';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { alpha, styled } from '@mui/material/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NavItem from 'src/layouts/DashboardLayout/NavBar/NavItem.js';
import Divider from '@material-ui/core/Divider';
import { useFormik } from 'formik';
import AlertBar from './AlertBar';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as Yup from 'yup';
import {
  UserCheck as ProfileIcon,
  Settings as SettingsIcon,
  LogOut as LogoutIcon,
  ExternalLink as DocumentationIcon,
  PhoneOutgoing as SupportIcon
} from 'react-feather';

import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Grid
} from '@material-ui/core';
import {
  HelpCircle as HelpIcon,
  Bell as NotificationIcon,
  User as UserIcon,
  MessageCircle as FeedbackIcon
} from 'react-feather';
import { useAuthState } from 'src/context';
import { useServiceState } from 'src/context';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import axios from 'src/axios';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import TopNav from './NavBar/TopNav';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './logo.svg';
import Snackbar from 'src/components/Snackbar';
import { FaWhatsapp } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgb(247 250 252)',
    borderBottom: 'solid 1px rgb(247 250 252)',
    height: 30
  },
  search: {
    position: 'relative',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      right: '0.3%',
      width: '180px'
    },
    [theme.breakpoints.up('lg')]: {
      right: '.5%',
      width: '300px',
      marginLeft: 20
    },
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 4%)',
    border: '1px solid #35354612',
    height: 27,
    borderRadius: 20
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#3e4040'
  },
  inputRoot: {
    color: 'black',
    fontFamily: 'Ubuntu'
  },
  createBtn: {
    marginRight: 0
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    height: 30,
    '&:hover': {
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff8c' //rgba(0,0,30,0.2)
  },
  buttonI: {
    textTransform: 'none',
    display: 'block',
    fontSize: 15,
    textAlign: 'left',
    color: '#3e4040',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#f4f4f5',
      color: '#3f51b5'
    }
  },
  logo: {
    width: '80px',
    height: '40px',
    marginRight: 20
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100ch'
    },
    [theme.breakpoints.down('md')]: {
      width: '140px',
      fontSize: 10
    },
    marginTop: -1,
    fontSize: 13
  },
  icon: {
    width: 30,
    height: 30,
    [theme.breakpoints.down('md')]: {
      width: 20,
      height: 20
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      border: '1px solid #f4f4f5',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)'
    }
  },
  buttonLink: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '8px 8px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: 'inherit'
    },
    '&:active': {
      color: '#3f51b5',
      backgroundColor: '#ffffff'
    },
    '&:focus': {
      color: '#3f51b5',
      backgroundColor: '#ffffff'
    }
  },
  iconLink: {
    marginRight: theme.spacing(0.3),
    color: '#3e4040',
    fontSize: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: 8
    }
  },
  title: {
    marginRight: 'auto',
    color: '#3e4040',
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 15,
    wordWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: 15
    },
    '&:hover': {
      color: '#3f51b5',
      padding: '0 0px',
      borderRadius: 20
    },
    '&:active': {
      color: '#3f51b5'
      // backgroundColor: '#f4f4f5'
    }
  },
  active: {
    color: '#3f51b5'
  },
  item: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0
    },
    display: 'inline-flex',
    paddingTop: 0,
    paddingBottom: 0,
    height: 20
  },
  whatsappBtn: {
    bottom: 40,
    position: 'fixed',
    right: 40,
    zIndex: 100,
    color: '#ffffff',
    fontSize: 50,
    [theme.breakpoints.down('md')]: {
      right: 20,
      bottom: 100
    }
  },
  iconWBtn: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  },
  navGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 0,
    marginTop: '-1rem',
    alignItems: 'center',

    [theme.breakpoints.up('lg')]: {
      alignSelf: 'center',
      marginTop: 0
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '10px',
    padding: '10px 12px',
    marginTop: 5,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: '#768095'
      }
    }
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [help, setHelp] = useState(null);
  const [notification, setNotification] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPEl, setAnchorPEl] = useState(null);

  const { userDetails } = useAuthState();
  const { singleUserService } = useServiceState();

  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const { token } = useAuthState();

  const handleHelpClick = event => {
    setHelp(event.currentTarget);
  };

  const handleNotificationClick = event => {
    setNotification(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openP = Boolean(anchorPEl);
  const pid = openP ? 'pin-popover' : undefined;

  const handlePinClose = () => {
    setAnchorPEl(null);
    setError(false);
  };

  const handlePinOpen = event => {
    setAnchorPEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setHelp(null);
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  const dispatch = useAuthDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
      pin: '',
      amount: ''
    },
    validationSchema: Yup.object().shape({
      pin: Yup.string()
    }),
    onSubmit: values => {
      axios({
        method: 'post',
        url: '/addPin',
        data: values,
        headers: { 'x-access-token': token }
      })
        .then(() => {
          setSnack(true);
          setIsLoading(false);
          setAnchorEl(null);
          setAnchorPEl(null);
          setError(false);
        })
        .catch(err => {
          setIsLoading(false);
          setError(true);
          setEresponse(err.response?.data.message);
          if (err.response.status == 401) navigate('/login', { replace: true });
        });
    }
  });
  const handleLogout = async e => {
    e.preventDefault;
    try {
      await logout(dispatch);
      navigate('/login', { replace: true });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const [anchorCEl, setAnchorCEl] = useState(null);
  const openI = Boolean(anchorCEl);
  const id = openI ? 'simple-popover' : undefined;

  const handleIconClick = event => {
    setAnchorCEl(event.currentTarget);
  };

  const handleIconClose = () => {
    setAnchorCEl(null);
  };

  const [notificationData, setNotificationData] = useState([]);
  useEffect(() => {
    axios({
      method: 'get',
      url: '/notif',
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setNotificationData(res.data);
      })
      .catch(error => {
        if (error.response?.status == 401)
          navigate('/login', { replace: true });
      });
  }, []);

  const handleClearNotifications = () => {
    axios({
      method: 'delete',
      url: '/notif',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setNotificationData([]);
      })
      .catch(error => {
        if (error.response?.status == 401)
          navigate('/login', { replace: true });
      });
  };
  const handleSnackClose = () => {
    setSnack(false);
  };
  return (
    <>
      <AppBar className={clsx(classes.root, className)} {...rest}>
        <AlertBar />
        <Toolbar
          style={{
            backgroundColor: '#ffff',
            minHeight: '40px',
            borderBottom: '1px solid rgb(239 238 243)',
            boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 2%)'
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={8}>
              <Hidden mdDown>
                <TopNav />
              </Hidden>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.navGrid}>
              <Hidden lgUp>
                <Box
                  onClick={() => navigate('/app/dashboard')}
                  style={{ marginRight: 'auto', marginLeft: 0 }}
                >
                  <img className={classes.logo} src={Logo} alt="logo" />
                </Box>
              </Hidden>
              <Box className={classes.createBtn}>
                <Button
                  style={{
                    textTransform: 'none',
                    marginRight: 10,
                    borderRadius: 20,
                    height: 25,
                    backgroundColor: '#3f51b5',
                    fontSize: 15
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={event => handleIconClick(event)}
                >
                  Quickies
                  <KeyboardArrowDownIcon
                    style={{ fontSize: 24, marginLeft: 2, marginTop: 2 }}
                  />
                </Button>
              </Box>
              <Popover
                style={{ marginTop: 5 }}
                id={id}
                open={openI}
                anchorEl={anchorCEl}
                onClose={handleIconClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                {!userDetails.hasSetPin && (
                  <Button
                    onClick={e => {
                      handleIconClose();
                      handlePinOpen(e);
                    }}
                    fullWidth
                    className={classes.buttonI}
                  >
                    <Typography variant="p" style={{ fontWeight: 500 }}>
                      Set a pin (payouts & transfers)
                    </Typography>
                  </Button>
                )}
                <Divider style={{ backgroundColor: '#f4f4f5' }} />
                <Button
                  className={classes.buttonI}
                  fullWidth
                  onClick={() => {
                    handleIconClose();
                    navigate('/app/events/create-event', { replace: true });
                  }}
                >
                  <Typography variant="p">Sell event tickets</Typography>
                </Button>
                <Divider style={{ backgroundColor: '#f4f4f5' }} />
                <Button
                  fullWidth
                  className={classes.buttonI}
                  onClick={() => {
                    handleIconClose();
                    navigate('/app/payment-link/donation', { replace: true });
                  }}
                >
                  <Typography variant="p">Collect contributions</Typography>
                </Button>
                <Divider style={{ backgroundColor: '#f4f4f5' }} />
                <Button
                  onClick={() => {
                    handleIconClose();
                    navigate('/app/products/add-product', { replace: true });
                  }}
                  fullWidth
                  className={classes.buttonI}
                >
                  <Typography variant="p">Sell a product</Typography>
                </Button>
              </Popover>
              <Box
                style={{
                  boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
                  right: '0 !important'
                }}
              >
                <Snackbar
                  duration={6000}
                  close={handleSnackClose}
                  data="Pin created successfully"
                  open={snack}
                />
                <Popover
                  id={pid}
                  open={openP}
                  anchorEl={anchorPEl}
                  onClose={handlePinClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <Box
                    style={{
                      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
                      borderRadius: '5',
                      border: '1px solid #0f15c514',
                      width: 220,
                      backgroundColor: '#ffffff'
                    }}
                  >
                    <form onSubmit={formik.handleSubmit}>
                      <DialogTitle
                        id="form-dialog-title"
                        style={{
                          borderBottom: '1px solid rgb(229 231 243)',
                          padding: 10
                        }}
                      >
                        <Typography
                          style={{
                            letterSpacing: 1,
                            fontSize: '16px',
                            fontWeight: 900,
                            color: '#3e4040'
                          }}
                          variant="p"
                        >
                          Set
                        </Typography>
                        <Typography
                          variant="p"
                          style={{
                            fontSize: '16px',
                            letterSpacing: 1,
                            color: '#3e4040'
                          }}
                        >
                          {' '}
                          PIN{' '}
                        </Typography>
                        <Typography
                          style={{
                            letterSpacing: 1,
                            fontSize: '16px',
                            fontWeight: 900,
                            color: '#3e4040'
                          }}
                          variant="p"
                        >
                          code
                        </Typography>
                      </DialogTitle>

                      {error && (
                        <Typography
                          variant="p"
                          style={{
                            color: '#eb6558',
                            fontWeight: 900,
                            fontSize: 16
                          }}
                        >
                          {eresponse}
                        </Typography>
                      )}
                      <DialogContent
                        style={{
                          padding: 20
                        }}
                      >
                        <FormControl>
                          <FormGroup>
                            <FormLabel className={classes.label}>
                              <Typography variant="p">
                                Enter a 5 digit pin
                              </Typography>
                            </FormLabel>
                            <BootstrapInput
                              error={Boolean(
                                formik.touched.pin && formik.errors.pin
                              )}
                              helperText={
                                formik.touched.pin && formik.errors.pin
                              }
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.pin}
                              name="pin"
                              id="pin"
                              type="password"
                              InputLabelProps={{
                                shrink: true
                              }}
                              variant="outlined"
                              placeholder="Ex: 12345"
                              size="small"
                              required
                            />
                          </FormGroup>
                        </FormControl>
                      </DialogContent>
                      <DialogActions
                        style={{
                          borderTop: ' 1px solid rgb(239 239 239)',
                          padding: 10
                        }}
                      >
                        <Button
                          onClick={handlePinClose}
                          color="primary"
                          variant="outlined"
                          size="small"
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          size="small"
                          className={classes.button}
                        >
                          {isLoading ? (
                            <CircularProgress size="14px" />
                          ) : (
                            'Submit'
                          )}
                        </Button>
                      </DialogActions>
                    </form>
                  </Box>
                </Popover>
              </Box>
              <IconButton
                onClick={handleNotificationClick}
                className={classes.icon}
                style={{ color: '#4f566b' }}
              >
                <Badge
                  badgeContent={notificationData?.length}
                  color="primary"
                  size="small"
                >
                  <NotificationIcon size="20" fill="#3f51b5" color="#3f51b5" />
                </Badge>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={notification}
                keepMounted
                open={Boolean(notification)}
                onClose={handleNotificationClose}
              >
                <DialogTitle>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      variant="p"
                      style={{
                        color: '#3f51b5',
                        letterSpacing: 0.6,
                        fontSize: 20
                      }}
                    >
                      {'Notifications'}
                    </Typography>
                    {notificationData?.length > 0 ? (
                      <Button
                        onClick={handleClearNotifications}
                        style={{ textTransform: 'none' }}
                      >
                        Clear all
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Box>
                </DialogTitle>
                <Divider style={{ backgroundColor: '#f4f4f5' }} />
                {notificationData?.length > 0 ? (
                  notificationData?.map(data => (
                    <>
                      {' '}
                      <Box style={{ width: 350 }}>
                        <DialogTitle>
                          <Typography
                            variant="p"
                            style={{
                              letterSpacing: 0.6,
                              fontSize: 20,
                              textTransform: 'lowercase'
                            }}
                          >
                            {data.title}
                          </Typography>
                        </DialogTitle>
                        <DialogContent>
                          <Typography variant="p">{data.message}</Typography>
                        </DialogContent>
                        <Divider style={{ backgroundColor: '#f4f4f5' }} />
                      </Box>
                    </>
                  ))
                ) : (
                  <DialogContent>
                    <Typography variant="p">
                      {'No notifications yet'}
                    </Typography>
                  </DialogContent>
                )}
              </Menu>
              <IconButton className={classes.icon} onClick={handleHelpClick}>
                <Badge color="primary">
                  <HelpIcon size="24" fill="#3f51b5" color="#ffffff" />
                </Badge>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={help}
                keepMounted
                open={Boolean(help)}
                onClose={handleHelpClose}
              >
                <MenuItem>
                  <Box className={classes.item}>
                    <a href="https://fapshi.com/contact" target="_blank">
                      {<FeedbackIcon className={classes.iconLink} size="14" />}
                      <span
                        activeClassName={classes.active}
                        className={classes.title}
                      >
                        Feedback?
                      </span>
                    </a>
                  </Box>
                </MenuItem>
                <MenuItem>
                  <Box className={classes.item}>
                    <a href="https://support.fapshi.com" target="_blank">
                      {<SupportIcon className={classes.iconLink} size="14" />}
                      <span
                        activeClassName={classes.active}
                        className={classes.title}
                      >
                        Need Help?
                      </span>
                    </a>
                  </Box>
                </MenuItem>
                <MenuItem>
                  <Box className={classes.item}>
                    <a href="https://documentation.fapshi.com" target="_blank">
                      {
                        <DocumentationIcon
                          className={classes.iconLink}
                          size="14"
                        />
                      }
                      <span
                        activeClassName={classes.active}
                        className={classes.title}
                      >
                        Documentation
                      </span>
                    </a>
                  </Box>
                </MenuItem>
              </Menu>
              <IconButton className={classes.icon} onClick={handleClick}>
                <Badge color="primary">
                  <UserIcon size="20" fill="#3f51b5" color="#3f51b5" />
                </Badge>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <Box style={{ padding: '0px 10px' }}>
                  <Typography variant="h5">{userDetails.username}</Typography>
                </Box>
                <Box style={{ padding: '5px 10px 2px 10px' }}>
                  <Typography style={{ fontFamily: 'Ubuntu', fontSize: 15 }}>
                    Support pin: {userDetails.supportPin}
                  </Typography>
                </Box>
                {singleUserService && (
                  <MenuItem disabled>
                    <Typography variant="p">
                      {singleUserService.data.permission}
                    </Typography>
                  </MenuItem>
                )}
                <Divider />
                <MenuItem>
                  <NavItem
                    href="/app/account"
                    title="Profile"
                    icon={ProfileIcon}
                  />
                </MenuItem>
                <MenuItem>
                  <NavItem
                    href="/app/settings"
                    title="Settings"
                    icon={SettingsIcon}
                  />
                </MenuItem>
                <MenuItem>
                  <NavItem
                    href="#"
                    onClick={handleLogout}
                    title="Logout"
                    icon={LogoutIcon}
                  />
                </MenuItem>
              </Menu>
              {/* </Hidden> */}
              <Hidden lgUp>
                <IconButton onClick={onMobileNavOpen}>
                  <MenuIcon
                    size="20"
                    fill="#4f566b"
                    style={{ color: '#4f566b' }}
                  />
                </IconButton>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box className={classes.whatsappBtn}>
        <a href="https://wa.me/237673669111" target="_blank">
          <IconButton className={classes.iconWBtn}>
            <FaWhatsapp size="50" />
          </IconButton>
        </a>
      </Box>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
