import * as React from 'react';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import ProductTransactions from './ProductTransactions';
import { alpha } from '@mui/material/styles';
import CustomButton from 'src/components/Buttons/Button';
import ShareBtn from 'src/components/Buttons/ShareButton';
import { capitalize, formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: 'rgb(247 250 252)',
    border: '1px solid #f4f4f5',
    maxWidth: 752,
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  flex: {
    display: 'inline-flex',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  primary: {
    fontSize: 10,
    fontWeight: 700,
    fontFamily: 'Ubuntu',
    color: 'gray'
  },
  secondary: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 14
  },
  heading: {
    fontSize: 25
  },
  imgBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '1rem'
  },
  button: {
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  }
}));

const SingleProductDetails = ({ data }) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const handleEditClick = products => {
    navigate('/app/products/edit-product', {
      replace: 'false',
      state: { products }
    });
  };
  const handleClick = () => {
    navigate(`/app/product/${data.productId}`, {
      replace: 'false'
    });
  };
  return (
    <Box style={{ maxWidth: 752 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.imgBox}>
            <Box>
              <Typography
                style={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400 }}
                sx={{ mb: 2 }}
                className={classes.heading}
              >
                Product Details
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box>
                <ShareBtn
                  url={`https://checkout.fapshi.com/products/${data.productId}`}
                  text="Share Link"
                  type="btn"
                  icon="share"
                  style={{ mb: 0, mr: 2 }}
                />
              </Box>
              <CustomButton
                btnTxt="Preview"
                btnStyle={{ py: '2px', px: 2, mb: 0, mr: 2 }}
                on={handleClick}
              />
              <Box>
                <IconButton
                  style={{ fontSize: 16 }}
                  color="primary"
                  size="small"
                  onClick={() => handleEditClick(data)}
                >
                  <EditIcon style={{ fontSize: 20 }} /> Edit
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box className={classes.box}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Product ID
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {data.productId}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Name
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {capitalize(data.productName)}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Price
                    </Typography>
                  }
                  secondary={
                    <Typography
                      className={classes.secondary}
                      style={{ fontFamily: 'Ubuntu' }}
                    >
                      {formatAmount(data.price)} FCFA
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Revenue
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {formatAmount(data.revenue)} FCFA
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <Box className={classes.box}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Sales
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {data.timesBought}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Date Published
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {data.createdOn}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Income Generated
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {formatAmount(data.genRevenue)} FCFA
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <Box className={classes.imgBox}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Description
                    </Typography>
                  }
                  secondary={
                    <Box>
                      <Typography
                        sx={{}}
                        style={{ fontFamily: 'Ubuntu', fontSize: 14 }}
                        className={classes.secondary}
                      >
                        {data.description}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            </List>
            <Box>
              <Image
                style={{
                  border: '1px solid rgb(228 230 239)',
                  width: 80,
                  height: 80,
                  objectFit: 'cover',
                  marginTop: 10
                }}
                // width={200}
                src={data.productImage}
              />
            </Box>
          </Box>
          <Box>
            <ProductTransactions data={data} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleProductDetails;
