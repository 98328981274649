import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles, Box } from '@material-ui/core';
import Page from 'src/components/Page';
import Balance from './Balance';
import Payouts from './Payouts';
import Transfers from './Transfers';
import Welcome from './Welcome';
import TopPin from './TopPin';
import Accordation from './Accordation';
import Divider from '@material-ui/core/Divider';
import DirectionsIndex from './DirectionsIndex';
import { useAuthState } from 'src/context';
import axios from 'src/axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    width: '100%'
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const { token } = useAuthState();
  const [result, setResult] = useState({});
  const [open, setOpen] = React.useState(false);

  // useEffect(() => {
  //   window.location.reload();
  // }, []);

  useEffect(() => {
    setOpen(true);
    axios({
      method: 'get',
      url: '/totalTransfer/',
      headers: { 'x-access-token': token }
    })
      .then(response => {
        setResult(response.data);
        setOpen(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <Page className={classes.root}>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1,
          backdropFilter: 'blur(3px)',
          backgroundColor: '#ffffff59'
        }}
        open={open}
      >
        <CircularProgress size={18} color="primary" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid container spacing={5}>
          <Grid item lg={4} sm={12} xl={4} xs={12}>
            <Balance />
          </Grid>
          <Grid item lg={4} sm={6} xl={4} xs={6}>
            <Payouts payout={result.totalWithdraw} />
          </Grid>
          <Grid item lg={4} sm={6} xl={4} xs={6}>
            <Transfers transfers={result.totalTransfer} />
          </Grid>
        </Grid>
      </Container>
      <Divider
        style={{ margin: '0px 0px 20px 0px', backgroundColor: '#f4f4f5' }}
      />
      <Welcome />
      <Box style={{ backgroundColor: '#ffffff' }}>
        <DirectionsIndex />
      </Box>
      <TopPin />

      <Box
        style={{
          backgroundColor: 'rgb(250 251 253)',
          clipPath: 'polygon(0 94%, 100% 2%, 98% 5%, 0 95%)',
          width: '100%',
          marginTop: 20,
          height: 100
        }}
      ></Box>
      <Box>
        <Accordation />
      </Box>
    </Page>
  );
};

export default Dashboard;
