import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  IconButton,
  Box,
  makeStyles,
  Grid,
  Button,
  Divider
} from '@material-ui/core';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { addToCart, useCartDispatch } from 'src/context';
import { useCartState } from 'src/context';
import Dialog from '@mui/material/Dialog';
// import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import Footer from './Footer';
import { capitalize, formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  container: {},
  iconButton: {
    color: '#3f51b5',
    width: 35,
    height: 35,
    margin: '2px 3px 0 0',
    border: '1px solid #f4f4f5',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ced4da'
    }
  },
  icon: {
    fontSize: '24px'
  },
  button: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    border: '1px solid #ced4da',
    borderRadius: 8,
    marginRight: 4,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ffffff'
    }
  },
  cardContent: {
    width: 'auto',
    height: 'auto',
    backgroundColor: '#f9f9f9',
    padding: 0,
    position: 'center center',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 3%)',
    border: '1px solid rgb(234 235 255)',
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      width: 347
    }
  },
  grid: {
    borderRight: '1px dashed rgb(234 235 255)',
    marginTop: -25,
    [theme.breakpoints.down('md')]: {
      border: 'none',
      paddingRight: 10
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SingleCard = ({ data }) => {
  const classes = useStyles();

  const products = data.products;

  const dispatch = useCartDispatch();
  const { cart } = useCartState();

  const handleAddToCart = cartData => {
    console.log(cart);
    const condition = cart.every(function(c) {
      // console.log(this);
      return c._id != this._id;
      // console.log([typeof c.id, typeof this.id]);
    }, cartData);
    console.log(condition);
    if (condition) addToCart(dispatch, cartData);
  };

  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState([]);

  const handleClickOpen = data => {
    setOpen(true);
    setDetails(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {products &&
        products.map(product => (
          <Grid item xs={12} sm={12} lg={3} xl={3} className={classes.grid}>
            <Card className={classes.cardContent}>
              <CardMedia
                style={{
                  height: 250,
                  objectFit: 'cover',
                  position: "'center center"
                }}
                component="img"
                image={product.productImage}
                alt="x-box"
              />
              <CardContent
                style={{
                  borderTop: '1px solid rgb(234 235 255)',
                  backgroundColor: '#ffffff'
                }}
              >
                <Typography gutterBottom variant="p">
                  {capitalize(product.productName)}
                </Typography>
                <br />
                <Typography variant="p" color="text.secondary">
                  {formatAmount(product.price)} FCFA
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => handleAddToCart(product)}
                    className={classes.iconButton}
                  >
                    <ShoppingCartOutlinedIcon style={{ fontSize: 20 }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      handleClickOpen(product);
                    }}
                    className={classes.iconButton}
                  >
                    <PageviewOutlinedIcon style={{ fontSize: 20 }} />
                  </IconButton>
                  <a
                    href={
                      'https://api.whatsapp.com/send?text= Hello please visit my store on fapshi, here is the link: https://fapshi.com/stores/' +
                      data.vendorName
                    }
                    data-action="share/whatsapp/share"
                    target="_blank"
                  >
                    <IconButton className={classes.iconButton}>
                      <ShareOutlinedIcon style={{ fontSize: 20 }} />
                    </IconButton>
                  </a>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      <Box>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{ backgroundColor: 'rgb(247, 250, 252)' }}
        >
          {/* <AppBar sx={{ position: 'relative' }}> */}
          <Toolbar
            style={{
              boxShadow: 'none',
              minHeight: 40,
              backgroundColor: '#ffffff',
              borderBottom: '1px solid rgb(234 235 255)'
            }}
          >
            <Typography
              style={{
                fontFamily: 'ubuntu',
                fontWeight: 500,
                color: 'rgb(97 58 245)',
                fontSize: 18
              }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              {capitalize(details.productName)}
            </Typography>
            <Button
              style={{
                textTransform: 'none',
                backgroundColor: '#ffffff'
              }}
              autoFocus
              size="small"
              onClick={handleClose}
            >
              Close
            </Button>
          </Toolbar>
          {/* </AppBar> */}
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '20px 0px 100px 0'
            }}
          >
            <Card sx={{ width: 400 }}>
              <CardMedia
                style={{ objectFit: 'cover' }}
                component="img"
                height="400"
                image={details?.productImage}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  style={{
                    fontFamily: 'ubuntu',
                    fontWeight: 500,
                    fontSize: 16,
                    color: '#505056e3'
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {capitalize(details.productName)}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'ubuntu',
                    fontWeight: 700,
                    fontSize: 22,
                    color: 'black'
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {formatAmount(details.price)}
                  <span style={{ fontSize: 14, color: '#505056e3' }}>
                    {' '}
                    FCFA
                  </span>
                </Typography>
                <Divider
                  style={{ backgroundColor: '#f4f4f5', margin: '10px 0' }}
                />
                <Button
                  style={{
                    textTransform: 'none',
                    backgroundColor: '#ffffff'
                  }}
                  className={classes.button}
                  autoFocus
                  variant="outlined"
                  color="default"
                  onClick={() => handleAddToCart(details)}
                >
                  Add to cart
                </Button>
                <Divider
                  style={{ backgroundColor: '#f4f4f5', margin: '10px 0' }}
                />
                <Typography
                  style={{
                    fontFamily: 'ubuntu',
                    fontWeight: 500,
                    fontSize: 14,
                    color: '#505056e3'
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {details.description}
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Footer />
        </Dialog>
      </Box>
    </>
  );
};

export default SingleCard;
