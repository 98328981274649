import React from 'react';
import Page from 'src/components/Page';
import { Box, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddProductForm from './AddProductForm';
import axios from 'axios';
import StoreTab from 'src/components/StoreTab';
import BackIcon from 'src/components/Buttons/BackIcon';

const useStyles = makeStyles({
  introtitle: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 22,
    fontWeight: 600,
    color: '#1a1f36'
  },
  inputfield: {
    width: '100%'
  },
  root: {
    width: '97%',
    margin: '20px 0px 20px 20px'
  }
});

const AddProduct = () => {
  const classes = useStyles();

  return (
    <Page className={classes.introtitle} title="Add Product">
      <Box className={classes.root}>
        <Grid container>
          <Hidden mdDown>
            <Grid items xs={12} sm={12} lg={3} xl={3}>
              <StoreTab val="products" />
            </Grid>
          </Hidden>
          <Grid items xs={12} sm={12} lg={6} xl={6}>
            <BackIcon path="/app/products" text="Return" />
            <AddProductForm axios={axios} />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default AddProduct;
