import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Logo from './logo.svg';
import axios from 'src/axios';
import Popover from '@material-ui/core/Popover';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useAuthState } from 'src/context';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '50%',
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(10)
    }
  },
  box: {
    width: '35%',
    marginLeft: '35%',
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: 310,
    marginTop: '10%',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      marginLeft: 10,
      marginRight: 50,
      marginTop: '20%',
      padding: '70px 5px 70px 5px',
      height: 350
    }
  },
  logo: {
    width: 110,
    height: 40
  },
  error: {
    textAlign: 'left'
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '17px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd',
      border: '1px solid #ced4da'
    }
  }
}));

const CodeVerification = () => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const { token } = useAuthState();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: Yup.object().shape({
      code: Yup.string()
        .min(6)
        .max(6)
        .required('Verification code is required')
    }),
    onSubmit: values => {
      const data = {
        email: location.state.email,
        code: values.code
      };
      setIsLoading(true);
      console.log(data);
      axios
        .post(`/auth/confirmEmail`, data)
        .then(res => {
          setIsLoading(false);
          if (res.status === 200) {
            navigate('/verify/success', { replace: true });
          }
        })
        .catch(error => {
          setError(true);
          setIsLoading(false);
          setEresponse(error.response?.data.message);
        });
    }
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openI = Boolean(anchorEl);
  const id = openI ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setFormEmail(' ');
  };

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
  };
  const [formEmail, setFormEmail] = useState(null);
  const handleResendCode = () => {
    axios({
      method: 'post',
      data: { email: formEmail },
      url: '/user/resend-account-confirmation-code/',
      headers: { 'x-access-token': token }
    }).then(() => {
      setAnchorEl(null);
    });
  };
  return (
    <Page className={classes.root} title="Verification">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        textAlign="center"
        className={classes.box}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box mb={3}>
              <img className={classes.logo} src={Logo} alt="logo"></img>
              <Typography color="textPrimary" variant="h3">
                Sign up verification
              </Typography>
              <Typography variant="p" color="textSecondary" gutterBottom>
                A passcode has been sent to your email (Check your spam or junk
                folder too). Fill in this code and confirm your registration.
              </Typography>
            </Box>
            {error ? (
              <Typography
                variant="p"
                style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
              >
                {eresponse}
              </Typography>
            ) : (
              <></>
            )}
            <BootstrapInput
              error={Boolean(formik.touched.code && formik.errors.code)}
              fullWidth
              helperText={formik.touched.code && formik.errors.code}
              label="Passcode"
              margin="normal"
              placeholder="Enter verification code"
              name="code"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="code"
              value={formik.values.code}
              variant="outlined"
              size="small"
              required
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{ textTransform: 'none' }}
              >
                {isLoading ? <CircularProgress my={2} size="20px" /> : 'Verify'}
              </Button>
            </Box>
            <Typography color="textSecondary" variant="p">
              Didn&apos;t recieve a code?{' '}
              <Link onClick={handleOpen} variant="h6">
                Resend code
              </Link>
            </Typography>
          </form>
        </Container>
        <Popover
          style={{ marginTop: 5 }}
          id={id}
          open={openI}
          anchorEl={anchorEl}
          // onClose={handleAddClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <DialogContent
            style={{
              padding: 20,
              width: 250
            }}
          >
            <BootstrapInput
              name="item"
              id="item"
              type="text"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              placeholder="Enter email address"
              size="small"
              onChange={e => setFormEmail(e.target.value)}
              value={formEmail}
              required
            />
          </DialogContent>
          <DialogActions
            style={{
              borderTop: '1px solid #f4f4f5'
              // backgroundColor: 'rgb(247 250 252)'
            }}
          >
            <Button
              size="small"
              color="primary"
              variant="outlined"
              style={{ textTransform: 'none', borderRadius: 10 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{ textTransform: 'none', borderRadius: 10 }}
              onClick={handleResendCode}
            >
              Confirm
            </Button>
          </DialogActions>
        </Popover>
      </Box>
    </Page>
  );
};

export default CodeVerification;
