import { useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu
} from '@mui/material';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import theme from 'src/assets/theme';
import CustomButton from './Button';

const { colors } = theme;

const useStyles = makeStyles({
  excelBtn: {
    backgroundColor: 'inherit',
    border: 'none',
    cursor: 'pointer',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    width: '100%'
  }
});

const styles = {
  actionItem: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    '.MuiListItemText-primary': {
      fontFamily: ['Ubuntu', 'sans-serif'].join(','),
      fontSize: '0.9rem',
      fontWeight: 'bold'
    },
    color: colors.black
  },
  listItemBtn: {
    padding: 0
  }
};

const exportPdf = (table, filename) => {
  const doc = new jsPDF({ orientation: 'landscape' });

  doc.autoTable({
    html: `#${table}`
  });

  doc.save(filename + '.pdf');
};

const ExportTableBtn = ({ id, table, filename, sheet }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleModalOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleModalClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const menuId = open ? 'export-event-action-items' : undefined;

  return (
    <div>
      <CustomButton
        btnTxt="Export"
        endIcon={<KeyboardArrowDownIcon />}
        on={handleModalOpen}
        btnStyle={{ mb: 0 }}
      />
      <Menu
        id={menuId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleModalClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          minWidth: 180,
          padding: 2,
          border: 1,
          borderColor: colors.borderColor
        }}
      >
        <List
          sx={{
            border: 1,
            borderColor: colors.borderColor,
            borderRadius: 1
          }}
        >
          <ListItem key="excel" sx={{ pb: 0 }}>
            <ListItemButton
              sx={styles.listItemBtn}
              onClick={() => {
                setAnchorEl(null); // Close the popover after clicking on the button
              }}
            >
              <ReactHTMLTableToExcel
                id={id}
                className={classes.excelBtn}
                table={table}
                filename={filename}
                sheet={sheet}
                buttonText="Excel Sheet"
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="pdf" sx={{ pb: 0 }}>
            <ListItemButton
              sx={styles.listItemBtn}
              onClick={() => {
                exportPdf(table, filename);
                setAnchorEl(null); // Close the popover after clicking on the button
              }}
            >
              <ListItemText sx={styles.actionItem} primary="PDF" />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
    </div>
  );
};

export default ExportTableBtn;
