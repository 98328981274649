import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import { useAuthState } from 'src/context';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  Hidden
} from '@material-ui/core';
import Page from 'src/components/Page';
import PaymentsTab from 'src/components/PaymentsTab';
import Snackbar from 'src/components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { getRevenue, getPrice } from 'src/components/FapshiRevenue';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      maxWidth: 350,
      marginLeft: 0
    }
  },
  introbox: {},
  error: {
    textAlign: 'left'
  },
  box: {},
  button: {
    textTransform: 'none',
    borderRadius: 8,
    fontSize: 14,
    margin: 2,
    height: 35,
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  },
  inputText: {
    fontSize: 20,
    color: '#3e4040',
    fontWeight: 900,
    letterSpacing: 1
  },
  inputLabel: {
    color: '#3e4040',
    fontWeight: 500,
    fontSize: 20,
    fontFamily: 'Ubuntu'
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd'
    }
  }
}));

const DonationItems = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useAuthState();
  const location = useLocation();
  const data = location.state.data;
  const [switcher, setSwitcher] = React.useState(false);
  const [trans, setTrans] = React.useState(data.showTrans);
  const [price, setPrice] = useState(data?.fixAmt);
  const [revenue, setRevenue] = useState(getRevenue(data.fixAmt));

  const handlePriceChange = event => {
    const target = event.target.value;
    setPrice(target);
    const rev = getRevenue(target);
    setRevenue(rev);
  };

  const handleRevenueChange = event => {
    const target = event.target.value;
    setRevenue(target);
    const price = getPrice(target);
    setPrice(price);
  };

  const [values, setValues] = useState({
    name: data?.organisation,
    note: data?.description,
    website: data?.websiteLink,
    minamount: data?.minAmt,
    date: data?.expiryDate,
    phone: data.phone
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleTransSwitcher = () => {
    setTrans(!trans);
  };
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = e => {
    setIsLoading(true);
    e.preventDefault();
    const val = {
      organisation: values.name,
      phone: values.phone,
      description: values.note,
      websiteLink: values.website,
      minAmt: parseInt(values.minamount),
      fixAmt: parseInt(price),
      expiryDate: values.date,
      showTrans: trans,
      type: 'Donation'
    };
    axios({
      method: 'put',
      data: val,
      url: 'https://api.fapshi.com/donation/' + data?._id,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsLoading(false);
        setValues({});
        navigate('/app/payment-link', { replace: false });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const handleSwitcher = () => {
    setSwitcher(!switcher);
    if (switcher) setValues({ ...values, minamount: 0 });
    if (!switcher) setPrice(0);
  };

  const handleReturnButton = () => {
    navigate('/app/payment-link', { replace: true });
  };
  return (
    <Container>
      <Grid container>
        <Hidden mdDown>
          <Grid item xs={12} sm={12} lg={3} xl={2}>
            <PaymentsTab val="links" />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={12}
          lg={9}
          xl={10}
          style={{
            borderRight: '1px solid #eaeaea6e'
          }}
        >
          <Page className={classes.root} title="Edit Donation Link">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              className={classes.box}
              style={{ width: 480 }}
            >
              <Box style={{ marginLeft: 20 }}>
                <Button
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  style={{ marginTop: 40, textTransform: 'none' }}
                  onClick={handleReturnButton}
                >
                  Return
                </Button>
              </Box>
              <Container maxWidth="sm" className={classes.container}>
                <Typography
                  style={{
                    marginBottom: 30,
                    color: '#3e4040',
                    fontSize: 25,
                    fontWeight: 900,
                    fontFamily: 'Ubuntu'
                  }}
                >
                  {' '}
                  Donation Link Details
                </Typography>
                {snack && <Snackbar data="Donation edited successfully" />}
                {error && (
                  <Typography
                    variant="p"
                    style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                  >
                    {eresponse}
                  </Typography>
                )}
                <form>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    style={{ marginTop: 7 }}
                  >
                    <Typography className={classes.inputLabel} variant="p">
                      Donation page name
                    </Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    placeholder="Enter page name"
                    margin="normal"
                    name="name"
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    id="bootstrap-input"
                    required
                  />

                  {!switcher ? (
                    <>
                      <Box>
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input-price"
                          style={{ marginTop: 7 }}
                          className={classes.inputLabel}
                        >
                          <Typography
                            className={classes.inputLabel}
                            variant="p"
                          >
                            Donation phone number
                          </Typography>
                        </InputLabel>
                        <BootstrapInput
                          fullWidth
                          placeholder="ex: 67123456"
                          margin="normal"
                          name="phone"
                          onChange={handleChange}
                          type="number"
                          value={values.phone}
                          id="bootstrap-input-price"
                          required
                          style={{ marginRight: 20 }}
                        />
                      </Box>
                      <Box style={{ display: 'inline-flex' }}>
                        <Box>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input-price"
                            style={{ marginTop: 7 }}
                            className={classes.inputLabel}
                          >
                            <Typography
                              className={classes.inputLabel}
                              variant="p"
                            >
                              Fixed amount
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            fullWidth
                            placeholder="100"
                            margin="normal"
                            name="amount"
                            onChange={handlePriceChange}
                            type="number"
                            value={price}
                            id="bootstrap-input-price"
                            required
                            style={{ marginRight: 10, width: 210 }}
                          />
                        </Box>
                        <Box>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input-revenue"
                            style={{ marginTop: 7 }}
                            className={classes.inputLabel}
                          >
                            <Typography
                              className={classes.inputLabel}
                              variant="p"
                            >
                              Revenue
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            fullWidth
                            placeholder="100"
                            margin="normal"
                            name="revenue"
                            onChange={handleRevenueChange}
                            type="number"
                            value={revenue}
                            id="bootstrap-input-price"
                            required
                            style={{ width: 210 }}
                          />
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box style={{ display: 'inline-flex' }}>
                        <Box>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input-price"
                            style={{ marginTop: 7 }}
                          >
                            <Typography
                              className={classes.inputLabel}
                              variant="p"
                            >
                              Minimum amount (optional)
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            fullWidth
                            placeholder="1000"
                            margin="normal"
                            name="minamount"
                            onChange={handleChange}
                            type="number"
                            value={values.minamount}
                            id="bootstrap-input-price"
                            required
                            style={{ width: 178 }}
                          />
                        </Box>
                        <Box>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input-revenue"
                            style={{ marginTop: 7 }}
                            className={classes.inputLabel}
                          >
                            <Typography
                              className={classes.inputLabel}
                              variant="p"
                            >
                              Donation phone number
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            placeholder="ex : 671234567"
                            margin="normal"
                            name="phone"
                            onChange={handleChange}
                            type="number"
                            value={values.phone}
                            id="bootstrap-input-price"
                            required
                            style={{ width: 176 }}
                          />
                        </Box>
                      </Box>
                    </>
                  )}
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-switch"
                    style={{ marginTop: 7 }}
                  >
                    <Typography className={classes.inputLabel} variant="p">
                      let donor decide
                    </Typography>
                  </InputLabel>
                  <Switch
                    checked={switcher}
                    onChange={() => {
                      handleSwitcher(event);
                    }}
                    name="switch"
                    size="small"
                    className={classes.inputLabel}
                  />
                  <Box style={{ marginTop: 10 }}>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputLabel} variant="p">
                        Due date(optional)
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      margin="normal"
                      name="date"
                      placeholder="https://fapshi.com"
                      onChange={handleChange}
                      type="date"
                      value={values.date}
                      id="bootstrap-input"
                      required
                    />{' '}
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputLabel} variant="p">
                        Website Link(Optional)
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      margin="normal"
                      name="website"
                      placeholder="https://fapshi.com"
                      onChange={handleChange}
                      type="text"
                      value={values.website}
                      id="bootstrap-input"
                      required
                    />{' '}
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputLabel} variant="p">
                        Description
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="Additional info"
                      margin="normal"
                      name="note"
                      onChange={handleChange}
                      type="text"
                      value={values.note}
                      id="bootstrap-input"
                      required
                      multiline
                      rows={2}
                    />{' '}
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-switch"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputLabel} variant="p">
                        Show donations ?
                      </Typography>
                    </InputLabel>
                    <Switch
                      checked={trans}
                      onChange={() => {
                        handleTransSwitcher(event);
                      }}
                      name="trans"
                      size="small"
                      className={classes.inputLabel}
                    />
                    <Box style={{ marginTop: 10 }}>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        className={classes.button}
                        size="small"
                        style={{ float: 'right' }}
                      >
                        {isLoading ? (
                          <CircularProgress size="14px" />
                        ) : (
                          'Confirm'
                        )}{' '}
                      </Button>
                      <Button
                        color="primary"
                        type="reset"
                        className={classes.button}
                        size="small"
                        style={{ float: 'right' }}
                        onClick={() =>
                          navigate('/app/payment-link', { replace: false })
                        }
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Container>
            </Box>
          </Page>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DonationItems;
