import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Page from 'src/components/Page';
import { Container, Grid, Hidden } from '@material-ui/core';
import Verify from './Verify';
import PaymentsTab from 'src/components/PaymentsTab';
import { alpha } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  introtitle: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 22,
    fontWeight: 600,
    color: '#1a1f36'
  },

  inputfield: {
    width: '100%'
  },
  typography: {
    padding: theme.spacing(2)
  },
  addButton: {
    width: '100%',
    display: 'block',
    textAlign: 'left',
    paddingRight: theme.spacing(4),
    fontWeight: '400',
    textTransform: 'lowercase'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    textAlign: 'left',
    borderRadius: 0,
    height: 50,
    overflowY: 'hidden'
  },
  btext: {
    color: 'blue',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  buttonText: {
    fontSize: 18
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    width: 40,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  }
}));

const AddBulkPayments = () => {
  const classes = useStyles();
  const location = useLocation();
  const bulkId = location.state?.id;

  return (
    <Page title="Verify" className={classes.root}>
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="bulk" />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            xl={10}
            style={{ marginTop: '5rem' }}
          >
            <Verify id={bulkId} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBulkPayments;
