import React from 'react';
import Page from 'src/components/Page';
import { Container, Grid, Hidden } from '@material-ui/core';
import DuplicateItem from './DuplicateItem';
import PaymentsTab from 'src/components/PaymentsTab';

const DuplicateInvoice = () => {
  return (
    <Page title="Invoice">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={3}>
              <PaymentsTab val="invoices" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={5} xl={3}>
            <DuplicateItem />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DuplicateInvoice;
