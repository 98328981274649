import React, { useState, useEffect } from 'react';
import { loginUser, useAuthDispatch } from 'src/context';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Logo from './logo.svg';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import { useAuthState } from 'src/context';
import axios from 'src/axios';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '80%',
    zIndex: 1,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      width: '97%',
      margin: '0px 5px',
      padding: '35px 5px'
    }
  },
  grid: {
    height: 900,
    [theme.breakpoints.down('md')]: {
      height: '50%'
    }
  },
  gridOne: {
    marginLeft: 200,
    zIndex: 0.6,
    [theme.breakpoints.down('md')]: {
      marginLeft: 200,
      clipPath: 'none',
      backgroundColor: '#ffffff'
    }
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150,
    [theme.breakpoints.down('md')]: {
      marginTop: 120,
      marginLeft: 5
    }
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  box: {},
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 10,
    height: 40
  },
  inputText: {
    fontSize: 20,
    color: '#181819',
    fontWeight: 900,
    letterSpacing: 2
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '17px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd',
      border: '1px solid #ced4da'
    }
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useAuthState();
  useEffect(() => {
    axios({
      method: 'get',
      url: '/token',
      headers: { 'x-access-token': token }
    }).then(res => {
      if (res.status === 200) navigate('/app/dashboard', { replace: true });
    });
  }, []);
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAuthDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: async values => {
      setIsLoading(true);
      setDisabled(true);
      try {
        const responseData = await loginUser(dispatch, values);
        if (responseData.status === 200) {
          setIsLoading(true);
          setDisabled(true);
          responseData.data.accessToken
            ? navigate('/app/dashboard', {
                replace: true
              })
            : navigate('/two-step-verification', {
                replace: true,
                state: { verificationId: responseData.data.id }
              });
        } else {
          setIsLoading(false);
          setDisabled(false);
          setError(true);
          setEresponse(responseData.response.data.message);
        }
      } catch (err) {
        console.log(err);
      }
    }
  });

  const [isPass, setIsPass] = useState(true);
  const handleClickShowPassword = () => {
    setIsPass(!isPass);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <Grid className={classes.grid} container>
      <Hidden mdDown>
        <Grid
          style={{
            marginLeft: 100,
            borderRight: '1px dashed rgb(234 235 255)',
            borderLeft: '1px solid rgb(234 235 255)'
          }}
          item
          lg={4}
          sm={10}
          xl={4}
          xs={10}
        >
          <Box className={classes.introbox} mb={2}>
            <br />
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
              style={{
                letterSpacing: '.5',
                fontWeight: '400',
                width: 280,
                textAlign: 'center',
                marginLeft: 120,
                marginTop: 200
              }}
            >
              Welcome! Already registered? If yes, you can now sign in to access
              your dashboard.
            </Typography>
          </Box>
        </Grid>
      </Hidden>
      <Grid
        item
        lg={6}
        sm={12}
        xl={6}
        xs={12}
        style={{
          borderRight: '1px solid rgb(234 235 255)'
        }}
        className={classes.gridOne}
      >
        <img className={classes.logo} src={Logo} alt="logo" />
        <Page
          className={classes.root}
          title="Fapshi Login"
          titleText="Sign in to the Fapshi dashboard."
          description="Sign in to the Fapshi dashboard to collect payments, manage funds, and more."
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            className={classes.box}
          >
            <Container maxWidth="sm">
              <form onSubmit={formik.handleSubmit}>
                {error ? (
                  <Typography
                    variant="p"
                    style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                  >
                    {eresponse}
                  </Typography>
                ) : (
                  <></>
                )}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Email address
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  placeholder="Enter your email"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                  id="bootstrap-input"
                  required
                />
                <InputLabel
                  style={{ marginTop: 20 }}
                  shrink
                  htmlFor="outlined-adornment-password"
                >
                  <Typography className={classes.inputText} variant="p">
                    Password
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  error={Boolean(
                    formik.touched.password && formik.errors.password
                  )}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  placeholder="Enter your password"
                  margin="normal"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={isPass ? 'password' : 'text'}
                  value={formik.values.password}
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          border: '1px solid #ced4da',
                          borderRadius: 8,
                          height: 39
                        }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {isPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                />
                <Box my={3}>
                  <Button
                    color="primary"
                    disabled={disabled}
                    fullWidth
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    size="large"
                  >
                    <Typography variant="p">
                      {' '}
                      {isLoading ? (
                        <CircularProgress my={2} size="18px" />
                      ) : (
                        'Sign in now'
                      )}
                    </Typography>
                  </Button>
                </Box>

                <Typography color="textSecondary" variant="p">
                  Don&apos;t have an account?{' '}
                  <Link component={RouterLink} to="/register" variant="p">
                    Sign up
                  </Link>
                </Typography>
                <br />
                <Typography color="textSecondary" variant="p">
                  <Link
                    component={RouterLink}
                    to="/forgot-password"
                    variant="p"
                  >
                    Forgotten your password ?
                  </Link>
                </Typography>
              </form>
            </Container>
          </Box>
        </Page>
      </Grid>
    </Grid>
  );
};

export default LoginView;
