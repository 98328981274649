import React, { useState } from 'react';
import { useAuthState } from 'src/context';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
// import Switch from '@material-ui/core/Switch';
import Snackbar from 'src/components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { getRevenue, getPrice } from 'src/components/FapshiRevenue';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  Divider,
  IconButton
} from '@material-ui/core';
import Page from 'src/components/Page';
import { formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto'
    }
  },
  introbox: {},
  error: {
    textAlign: 'left'
  },
  box: {
    width: 480,
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  button: {
    textTransform: 'none',
    borderRadius: 8,
    fontSize: 16,
    margin: 2,
    height: 35,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    border: '1px solid #ced4da'
  },
  iconButton: {
    fontSize: 16,
    margin: 2,
    height: 15,
    width: 15,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    border: '1px solid #ced4da'
  },
  inputText: {
    color: '#3e4040',
    fontWeight: 500,
    fontSize: 24,
    fontFamily: 'Ubuntu'
  },
  inputTextOne: {
    color: '#3e4040',
    fontWeight: 700,
    fontSize: 25,
    fontFamily: 'Ubuntu'
  },

  inputLabel: {
    color: '#3e4040',
    fontWeight: 500,
    fontSize: 20,
    fontFamily: 'Ubuntu'
  },
  bottomBox: {
    marginTop: 10,
    width: 375,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  bottomBoxOne: {
    marginBottom: 70,
    marginTop: 10,
    width: 375,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  hiddenBoxOne: {
    width: '23rem',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  hiddenBoxTwo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '23.2rem',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    height: '10px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));
const BootstrapInputs = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    height: '10px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const VariableItems = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useAuthState();
  const [amountData, setAmountData] = useState([]);
  // const [switcher, setSwitcher] = React.useState(true);

  const [values, setValues] = useState({
    name: null,
    note: null,
    website: null,
    amount: null,
    description: null,
    date: null,
    phone: null
  });
  const [amount, setAmount] = useState(null);
  const [revenue, setRevenue] = useState(null);

  const handlePriceChange = event => {
    const target = event.target.value;
    setAmount(target);
    const rev = getRevenue(target);
    setRevenue(rev);
  };
  const handleRevenueChange = event => {
    const target = event.target.value;
    setRevenue(target);
    const price = getPrice(target);
    setAmount(price);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handlePriceAdd = () => {
    const data = {
      amount: parseInt(amount),
      desc: values.description,
      id: Math.ceil(Math.random() * 1000)
    };
    amountData.push(data);
    setAmountData(amountData);
    setValues({ ...values, description: null });
    setAmount(null);
    setRevenue(null);
  };

  const handleRemoveItem = id => {
    const rs = amountData.filter(res => res.id !== id);
    setAmountData(rs);
  };

  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = e => {
    setIsLoading(true);
    e.preventDefault();
    const val = {
      organisation: values.name,
      phone: values.phone,
      description: values.note,
      expiryDate: values.date,
      type: 'Payment link',
      amounts: amountData
    };
    axios({
      method: 'post',
      data: val,
      url: 'https://api.fapshi.com/newDonation',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsLoading(false);
        setValues({});
        navigate('/app/payment-link', { replace: false });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const handleReturnButton = () => {
    navigate('/app/payment-link', { replace: true });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Page className={classes.root} title="Create Variable Link">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.box}
          >
            <Container maxWidth="sm" className={classes.container}>
              <Box style={{ display: 'flex' }}>
                <Button
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  style={{ marginTop: -5, height: 30, textTransform: 'none' }}
                  onClick={handleReturnButton}
                >
                  Return
                </Button>
              </Box>
              <Typography
                style={{
                  marginBottom: 30,
                  color: '#3e4040',
                  fontSize: 25,
                  fontWeight: 900,
                  fontFamily: 'Ubuntu'
                }}
              >
                {' '}
                Variable Link Details
              </Typography>
              {snack && <Snackbar data="Link created successfully" />}
              {error && (
                <Typography
                  variant="p"
                  style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                >
                  {eresponse}
                </Typography>
              )}
              <form>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Payment link name
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter a name for your link"
                  margin="normal"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  id="bootstrap-input"
                  required
                  autoFocus
                />
                <Box style={{ marginTop: 20 }}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    style={{ marginTop: 7 }}
                  >
                    <Typography className={classes.inputTextOne} variant="p">
                      Price details
                    </Typography>
                  </InputLabel>
                </Box>
                {amountData.length > 0 && (
                  <>
                    {amountData.map(items => {
                      return (
                        <Box className={classes.hiddenBoxOne}>
                          <Box className={classes.hiddenBoxTwo}>
                            <Box>
                              <Box>
                                <Typography variant="p">Amount </Typography>
                                <Typography
                                  variant="p"
                                  style={{ fontWeight: 700 }}
                                >
                                  {formatAmount(items.amount)} FCFA
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="p">Label </Typography>
                                <Typography
                                  variant="p"
                                  style={{ fontWeight: 700 }}
                                >
                                  {items.desc}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={() => handleRemoveItem(items.id)}
                                className={classes.iconButton}
                              >
                                <RemoveCircleIcon
                                  style={{ color: '#d40000' }}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                          <Divider
                            style={{ backgroundColor: 'rgb(247 249 250)' }}
                          />
                        </Box>
                      );
                    })}
                  </>
                )}
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputText} variant="p">
                        Amount
                      </Typography>
                    </InputLabel>
                    <BootstrapInputs
                      placeholder="Enter amount"
                      margin="normal"
                      name="amount"
                      onChange={handlePriceChange}
                      type="number"
                      value={amount}
                      id="bootstrap-input"
                      required
                    />
                  </Box>
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputText} variant="p">
                        Revenue
                      </Typography>
                    </InputLabel>
                    <BootstrapInputs
                      placeholder="Revenue"
                      margin="normal"
                      name="revenue"
                      onChange={handleRevenueChange}
                      type="number"
                      value={revenue}
                      id="bootstrap-input"
                      required
                    />
                  </Box>
                </Box>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 12 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Amount label
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Ex: VIP ticket, premium etc."
                  margin="normal"
                  name="description"
                  type="text"
                  onChange={handleChange}
                  value={values.description}
                  id="bootstrap-input"
                  required
                />
                <Box className={classes.bottomBoxOne}>
                  <Button
                    color="primary"
                    type="reset"
                    className={classes.button}
                    size="small"
                    style={{ float: 'right' }}
                    onClick={handlePriceAdd}
                    disabled={
                      amount === null || values.description === null
                        ? true
                        : false
                    }
                  >
                    Save & add another
                  </Button>
                </Box>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Due date(optional)
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter amount"
                  margin="normal"
                  name="date"
                  onChange={handleChange}
                  type="date"
                  value={values.date}
                  id="bootstrap-input"
                  required
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 15 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Description
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Link description"
                  margin="normal"
                  name="note"
                  onChange={handleChange}
                  row={4}
                  type="text"
                  value={values.note}
                  id="bootstrap-input"
                  required
                  multiline
                  rows={2}
                />
                {/* <InputLabel
                  shrink
                  htmlFor="bootstrap-input-switch"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputLabel} variant="p">
                    Show payments transactions details ?
                  </Typography>
                </InputLabel> */}
                {/* <Switch
                  checked={switcher}
                  onChange={() => {
                    handleSwitcher(event);
                  }}
                  name="switch"
                  size="small"
                  className={classes.inputLabel}
                /> */}
                <Box className={classes.bottomBox}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    size="small"
                    style={{ float: 'right' }}
                    onClick={handleSubmit}
                  >
                    {isLoading ? <CircularProgress size="14px" /> : 'Confirm'}{' '}
                  </Button>
                  <Button
                    color="primary"
                    type="reset"
                    className={classes.button}
                    size="small"
                    style={{ float: 'right' }}
                    onClick={() =>
                      navigate('/app/payment-link', { replace: false })
                    }
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Page>
      </Grid>
    </Grid>
  );
};

export default VariableItems;
