import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'src/axios';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Logo from './logo.svg';
import { Link } from '@material-ui/core';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useAuthState } from 'src/context';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    height: '50%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 100,
    marginLeft: '35%',
    [theme.breakpoints.down('md')]: {
      marginTop: 120,
      marginLeft: 5
    }
  },
  error: {
    textAlign: 'left'
  },
  box: {
    width: '35%',
    marginLeft: '35%',
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: 310,
    marginTop: '0%',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      marginLeft: 10,
      marginRight: 50,
      padding: 0
    }
  },
  link: {
    marginLeft: '35%',
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      marginLeft: 10
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '17px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd',
      border: '1px solid #ced4da'
    }
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorTwo, setErrorTwo] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [eresponseTwo, setEresponseTwo] = useState(null);
  const [passId, setPassId] = useState(0);
  const { token } = useAuthState();
  const navigate = useNavigate();

  React.useEffect(() => {
    axios({
      method: 'get',
      url: '/token',
      headers: { 'x-access-token': token }
    }).then(res => {
      if (res.status === 200) navigate('/app/dashboard', { replace: true });
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('email code is required')
    }),
    onSubmit: values => {
      const data = {
        email: values.email
      };
      axios
        .post(`/user/send-password-code`, data)
        .then(res => {
          setPassId(res.data.id);
          setIsCode(false);
          setError(false);
        })
        .catch(err => {
          setError(true);
          setEresponse(err.response.data.message);
        });
    }
  });

  const formikOne = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required('Code is required')
    }),
    onSubmit: values => {
      const data = {
        passCode: values.code
      };
      axios
        .put(`/user/check-password-code/${passId}`, data)
        .then(res => {
          setErrorTwo(false);
          const secret = res.data.secret;
          navigate('/new-password', {
            replace: false,
            state: { code: passId, secret }
          });
        })
        .catch(err => {
          setErrorTwo(true);
          setEresponseTwo(err.response.data.message);
        });
    }
  });

  const [isCode, setIsCode] = useState(true);

  return (
    <Page className={classes.root} title="Forgot password">
      <img className={classes.logo} src={Logo} alt="logo" />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        textAlign="center"
        className={classes.box}
      >
        <Container maxWidth="sm">
          {isCode ? (
            <form onSubmit={formik.handleSubmit}>
              <Box mb={3}>
                <Typography variant="h2">Forgotten your password ?</Typography>
                <br />
                <Typography color="textPrimary" variant="p">
                  Enter the email address associated with your account and we'll
                  send you a verification code to proceed.
                </Typography>
              </Box>
              {error ? (
                <Typography
                  variant="p"
                  style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                >
                  {eresponse}
                </Typography>
              ) : (
                <></>
              )}
              <BootstrapInput
                error={Boolean(formik.touched.code && formik.errors.code)}
                fullWidth
                helperText={formik.touched.code && formik.errors.code}
                label="email"
                margin="normal"
                placeholder="Enter your sign in email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
                variant="outlined"
                size="small"
                required
              />
              <Box my={2}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ textTransform: 'none', borderRadius: 10 }}
                >
                  Confirm
                </Button>
              </Box>
            </form>
          ) : (
            <form onSubmit={formikOne.handleSubmit}>
              <Box mb={3}>
                <Typography variant="h2">Reset your password </Typography>
              </Box>
              <Typography variant="p" color="textSecondary" gutterBottom>
                A passcode has been sent your email. Please fill in this code
                and confirm your email.
              </Typography>
              <br />
              {errorTwo ? (
                <Typography
                  variant="p"
                  style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                >
                  {eresponseTwo}
                </Typography>
              ) : (
                <></>
              )}
              <BootstrapInput
                error={Boolean(formikOne.touched.code && formikOne.errors.code)}
                fullWidth
                helperText={formikOne.touched.code && formikOne.errors.code}
                label="Passcode"
                margin="normal"
                placeholder="Enter verification code"
                name="code"
                onBlur={formikOne.handleBlur}
                onChange={formikOne.handleChange}
                type="text"
                value={formikOne.values.code}
                variant="outlined"
                size="small"
                required
              />
              <Box my={2}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ textTransform: 'none', borderRadius: 10 }}
                >
                  Confirm
                </Button>
              </Box>
            </form>
          )}
        </Container>
      </Box>
      <Box className={classes.link}>
        <Typography color="textSecondary" variant="p">
          Don&apos;t have an account?{' '}
          <Link component={RouterLink} to="/register" variant="p">
            Sign up
          </Link>
        </Typography>
      </Box>
    </Page>
  );
};

export default ForgotPassword;
