import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Button,
  Container,
  Divider,
  Hidden
} from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputBase from '@material-ui/core/InputBase';
import { alpha, styled } from '@mui/material/styles';
import Popover from '@material-ui/core/Popover';
import StoreTab from 'src/components/StoreTab';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuthState } from 'src/context';
import { FaCartPlus } from 'react-icons/fa';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Snackbar from 'src/components/Snackbar';
import { formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: 'rgb(247 250 252)',
    // border: '1px solid #f4f4f5',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  root: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      maxWidth: 300
    }
  },
  primary: {
    fontSize: 10,
    fontWeight: 700,
    fontFamily: 'Ubuntu',
    color: 'gray'
  },
  secondary: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  heading: {
    fontSize: 25
  },
  imgBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff8c' //rgba(0,0,30,0.2)
  },
  button: {
    textTransform: 'none',
    fontSize: 14,
    borderRadius: 10,
    height: 30,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    '&:hover': {
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '8px',
    padding: '10px 12px',
    marginTop: 5,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#768095'
    }
  }
}));

const OrderDetails = () => {
  const classes = useStyles();

  const [opens, setOpen] = React.useState(false);

  const [content, setContent] = useState([]);

  const handlePinClose = () => {
    setOpen(false);
  };
  const [anchorCEl, setAnchorCEl] = React.useState(null);
  const openI = Boolean(anchorCEl);
  const id = openI ? 'simple-popover' : undefined;
  const [indexId, setIndexId] = useState(0);
  const [val, setVal] = useState({ price: null, quantity: null });

  const [anchorAEl, setAnchorAEl] = React.useState(null);
  const openAI = Boolean(anchorAEl);
  const aid = openAI ? 'simple-popover' : undefined;

  const handleAddOpen = e => {
    setAnchorAEl(e.currentTarget);
  };

  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [errorOne, setErrorOne] = useState(false);
  const [snackOne, setSnackOne] = useState(false);
  const [eresponseOne, setEresponseOne] = useState(null);
  const [isLoadingOne, setIsLoadingOne] = useState(false);

  const [snackTwo, setSnackTwo] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);

  const [errorThree, setErrorThree] = useState(false);
  const [snackThree, setSnackThree] = useState(false);
  const [eresponseThree, setEresponseThree] = useState(null);
  const [isLoadingThree, setIsLoadingThree] = useState(false);

  const handlePinOpen = data => {
    setIndexId(data.id);
    setVal({ ...val, price: data.unitPrice, quantity: data.qty });
    setPinOpen();
  };

  const handleAddClose = () => {
    setAnchorAEl(null);
    setErrorOne(false);
  };

  const setPinOpen = () => {
    setOpen(true);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setVal({ ...val, [name]: value });
  };

  const handleEditSubmit = e => {
    e.preventDefault();
    const index = content.findIndex(c => {
      return c.id == indexId;
    });
    if (index == -1) return handlePinClose();

    content[index].unitPrice = parseInt(val.price);
    content[index].qty = parseInt(val.quantity);
    setContent(content);
    const t = content.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);
    setTotal(t);
    setIsLoading(true);
    axios({
      method: 'put',
      data: { content },
      url: 'https://api.fapshi.com/order/' + orders._id,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setOpen(false);
        setSnack(true);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const handleIconClick = e => {
    setAnchorCEl(e.currentTarget);
  };

  const handleIconClose = () => {
    setAnchorCEl(null);
  };

  const location = useLocation();
  const oid = location.state?.id;
  const { token } = useAuthState();
  const [orders, setOrders] = useState([]);
  const [progressDisplay, setProgressDisplay] = useState(false);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    getOrders();
  }, [total]);
  const getOrders = async () => {
    setProgressDisplay(true);
    var response = await axios({
      method: 'get',
      url: 'https://api.fapshi.com/order/' + oid,
      headers: { 'x-access-token': token }
    });
    if (response.status === 200) {
      setProgressDisplay(false);
    }
    setOrders(response.data);
    setContent(response.data?.content);
    const t = response.data.content.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);
    setTotal(t);

    console.log(response);
    console.log;
    return response;
  };

  const [aVal, setAval] = useState({
    item: null,
    unitPrice: null,
    qty: null
  });
  const handleAddOrders = e => {
    const { name, value } = e.target;
    setAval({ ...aVal, [name]: value });
  };
  const addOrders = () => {
    const { item, qty, unitPrice } = aVal;
    const data = {
      id: Math.ceil(Math.random() * 1000),
      item,
      unitPrice: parseInt(unitPrice),
      qty: parseInt(qty)
    };
    content.push(data);
    setContent(content);
    const t = content.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);
    setTotal(t);
    setIsLoadingOne(true);
    axios({
      method: 'put',
      data: { content },
      url: 'https://api.fapshi.com/order/' + orders._id,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnackOne(true);
        setIsLoadingOne(false);
      })
      .catch(err => {
        setIsLoadingOne(false);
        setErrorOne(true);
        setEresponseOne(err.response?.data.message);
      });
  };
  const handleClose = () => {
    setSnack(false);
  };
  const handleCloseOne = () => {
    setSnackOne(false);
  };
  const handleCloseTwo = () => {
    setSnackTwo(false);
  };
  const handleCloseThree = () => {
    setSnackThree(false);
  };

  const deleteOrderItems = id => {
    const newContent = content.filter(c => {
      return c.id != String(id);
    });
    const t = newContent.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);
    setTotal(t);
    setContent(newContent);
    const value = { content: newContent };
    setIsLoadingTwo(true);
    axios({
      method: 'put',
      data: value,
      url: 'https://api.fapshi.com/order/' + orders._id,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnackTwo(true);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoadingTwo(false);
        console.log(err);
      });
  };

  const [shipping, setShipping] = useState(null);
  const handleConfirm = () => {
    const value = { shipping: parseInt(shipping) };
    setIsLoadingThree(true);
    axios({
      method: 'post',
      data: value,
      url: 'https://api.fapshi.com/orderConfirm/' + orders._id,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnackThree(true);
        setIsLoadingThree(false);
      })
      .catch(err => {
        setIsLoadingThree(false);
        setErrorThree(true);
        setEresponseThree(err.response?.data.message);
      });
  };

  return (
    <Container
      style={{ margin: '25px 0px 0px 30px' }}
      sx={{ flexGrow: 1, minWidth: 600 }}
      className={classes.root}
    >
      <Grid container spacing={2}>
        <Hidden mdDown>
          <Grid item xs={12} sm={12} lg={3} xl={3}>
            <StoreTab val="orders" />
          </Grid>
        </Hidden>
        <Box
          style={{
            display: progressDisplay ? 'block' : 'none'
          }}
        >
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: theme => theme.zIndex.drawer + 1,
              backdropFilter: 'blur(2px)',
              backgroundColor: '#ffffff59'
            }}
            open={open}
          >
            <CircularProgress
              size="1.5rem"
              style={{
                display: progressDisplay ? 'block' : 'none',
                marginLeft: '20%',
                marginTop: '15%',
                marginBottom: '10%',
                color: '#3f51b5'
              }}
            />
          </Backdrop>
        </Box>
        <Grid
          style={{
            display: progressDisplay ? 'none' : 'block'
          }}
          item
          xs={12}
          sm={12}
          lg={9}
          xl={9}
        >
          <Box>
            <Typography
              style={{ fontFamily: 'Ubuntu', fontSize: 22, fontWeight: 900 }}
              sx={{ mb: 2 }}
              className={classes.heading}
            >
              Order Details
            </Typography>
          </Box>
          <Box className={classes.imgBox}>
            <Box>
              <Typography
                style={{ fontFamily: 'Ubuntu', fontSize: 15, fontWeight: 400 }}
                sx={{ mb: 2 }}
                className={classes.heading}
              >
                Customer Details
              </Typography>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Box style={{ margin: '10px 10px 0px 0px' }}></Box>
              <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
                {orders.isDelivered && (
                  <Box className={classes.alert}>
                    <Stack direction="row" spacing={2}>
                      <Chip
                        label={<Typography variant="p">Delivered</Typography>}
                        style={{
                          marginLeft: 5,
                          marginTop: 5,
                          marginRight: 5,
                          color: '#ffffff',
                          backgroundColor: '#8902c1'
                        }}
                        size="small"
                        variant="filled"
                      />
                    </Stack>
                  </Box>
                )}
                {orders.isConfirmed ? (
                  <Box className={classes.alert}>
                    <Stack direction="row" spacing={2}>
                      <Chip
                        label={<Typography variant="p">Confirmed</Typography>}
                        style={{
                          marginLeft: 5,
                          marginTop: 5,
                          marginRight: 5,
                          color: '#ffffff',
                          backgroundColor: '#1a8300'
                        }}
                        size="small"
                        variant="filled"
                      />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Button
                      style={{
                        // borderRadius: 20,
                        height: 30,
                        textTransform: 'none',
                        fontSize: 14,
                        borderRadius: 10,
                        boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)'
                      }}
                      size="small"
                      variant="outlined"
                      color="default"
                      onClick={handleIconClick}
                    >
                      Confirm order
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            {snackThree && (
              <Snackbar
                duration={6000}
                open={snackThree}
                close={handleCloseThree}
                data="Order confirmed successfully"
              />
            )}
            <Popover
              style={{ marginTop: 5 }}
              id={id}
              open={openI}
              anchorEl={anchorCEl}
              onClose={handleIconClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <DialogContent
                style={{
                  padding: 20,
                  width: 250
                }}
              >
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-price"
                  style={{
                    color: '#546e7a',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Delivery fee (optional) </Typography>
                  <br />

                  {errorThree && (
                    <Typography
                      variant="p"
                      style={{
                        color: '#eb6558',
                        fontWeight: 900,
                        fontSize: 16
                      }}
                    >
                      {eresponseThree}
                    </Typography>
                  )}
                </InputLabel>
                <BootstrapInput
                  name="shipping"
                  id="shipping"
                  type="number"
                  onChange={e => setShipping(e.target.value)}
                  value={shipping}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  placeholder="Enter your fee"
                  size="small"
                />
              </DialogContent>
              <DialogActions
                style={{
                  borderTop: '1px solid #f4f4f5'
                }}
              >
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  style={{ textTransform: 'none' }}
                  onClick={handleIconClose}
                  className={classes.button}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none' }}
                  onClick={handleConfirm}
                  className={classes.button}
                >
                  {isLoadingThree ? (
                    <CircularProgress size="14px" />
                  ) : (
                    'Confirm'
                  )}
                </Button>
              </DialogActions>
            </Popover>
          </Box>
          <Box className={classes.box}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Email
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {orders.clientEmail}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Name
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {orders.clientName}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Phone
                    </Typography>
                  }
                  secondary={
                    <Typography
                      className={classes.secondary}
                      style={{ fontFamily: 'Ubuntu' }}
                    >
                      {orders.clientPhone}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.primary}
                    >
                      Address
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontFamily: 'Ubuntu' }}
                      className={classes.secondary}
                    >
                      {orders.address}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <Box>
            {snack && (
              <Snackbar
                duration={6000}
                open={snack}
                close={handleClose}
                data="Order edited successfully"
              />
            )}
            <Dialog
              open={opens}
              aria-labelledby="form-dialog-title"
              BackdropProps={{
                classes: {
                  root: classes.backDrop
                }
              }}
            >
              <Box
                style={{
                  boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
                  borderRadius: '5',
                  border: '1px solid #0f15c514',
                  width: 300,
                  padding: 5,
                  backgroundColor: '#ffffff'
                }}
              >
                <form
                // style={{ textAlign: 'center' }}
                >
                  <DialogTitle
                    id="form-dialog-title"
                    style={{
                      borderBottom: '1px solid rgb(229 231 243)',
                      padding: 10,
                      fontSize: '14px'
                    }}
                  >
                    <Typography
                      style={{ fontSize: '16px', letterSpacing: 0.3 }}
                      variant="p"
                    >
                      Edit order details
                    </Typography>
                    <br />

                    {error && (
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 900,
                          fontSize: 16
                        }}
                      >
                        {eresponse}
                      </Typography>
                    )}
                  </DialogTitle>
                  <DialogContent
                    style={{
                      padding: 20
                    }}
                  >
                    <FormControl>
                      <FormGroup>
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input-price"
                          style={{
                            marginTop: 7,
                            color: '#546e7a',
                            fontWeight: 400,
                            fontSize: 20
                          }}
                        >
                          <Typography variant="p">Unit price</Typography>
                        </InputLabel>
                        <BootstrapInput
                          name="price"
                          id="price"
                          type="number"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          placeholder="Change price"
                          size="small"
                          value={val.price}
                          onChange={handleChange}
                        />
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input-qty"
                          style={{
                            marginTop: 7,
                            color: '#546e7a',
                            fontWeight: 400,
                            fontSize: 20
                          }}
                        >
                          <Typography variant="p">Quantity</Typography>
                        </InputLabel>
                        <BootstrapInput
                          name="quantity"
                          id="qty"
                          type="number"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          placeholder="Change quantity"
                          size="small"
                          value={val.quantity}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </FormControl>
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: ' 1px solid rgb(239 239 239)',
                      padding: 10
                    }}
                  >
                    <Button
                      onClick={handlePinClose}
                      color="primary"
                      variant="outlined"
                      size="small"
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      className={classes.button}
                      onClick={handleEditSubmit}
                    >
                      {isLoading ? (
                        <CircularProgress size="14px" />
                      ) : (
                        'Edit and save'
                      )}
                    </Button>
                  </DialogActions>
                </form>
              </Box>
            </Dialog>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box>
              <Typography
                style={{ fontFamily: 'Ubuntu', fontSize: 15, fontWeight: 400 }}
                sx={{ mb: 1, mt: 2 }}
                className={classes.heading}
              >
                Products details (total: {formatAmount(total)} FCFA)
              </Typography>
            </Box>
            <Box>
              <IconButton
                color="primary"
                style={{
                  // border: '1px solid #f4f4f5',
                  boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)'
                }}
                size="small"
                onClick={handleAddOpen}
              >
                <FaCartPlus size="25" />
              </IconButton>
            </Box>
          </Box>
          <Box>
            {snackOne && (
              <Snackbar
                duration={6000}
                open={snackOne}
                close={handleCloseOne}
                data="Order Item added successfully"
              />
            )}
            <Popover
              style={{ marginTop: 5 }}
              id={aid}
              open={openAI}
              anchorEl={anchorAEl}
              onClose={handleAddClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <DialogContent
                style={{
                  padding: 20,
                  width: 250
                }}
              >
                {errorOne && (
                  <Typography
                    variant="p"
                    style={{
                      color: '#eb6558',
                      fontWeight: 900,
                      fontSize: 16
                    }}
                  >
                    {eresponseOne}
                  </Typography>
                )}
                <br />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-item"
                  style={{
                    color: '#546e7a',
                    fontWeight: 400,
                    fontSize: 20,
                    marginTop: 7
                  }}
                >
                  <Typography variant="p">Item name </Typography>
                </InputLabel>
                <BootstrapInput
                  name="item"
                  id="item"
                  type="text"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  placeholder="Enter product name"
                  size="small"
                  onChange={handleAddOrders}
                  value={aVal.item}
                />
                <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-price"
                      style={{
                        color: '#546e7a',
                        fontWeight: 400,
                        fontSize: 20,
                        marginTop: 7
                      }}
                    >
                      <Typography variant="p">Quantity</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="qty"
                      id="qty"
                      type="number"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="qty"
                      size="small"
                      onChange={handleAddOrders}
                      value={aVal.qty}
                      style={{ width: '80%' }}
                    />
                  </Box>
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-price"
                      style={{
                        color: '#546e7a',
                        fontWeight: 400,
                        fontSize: 20,
                        marginTop: 7
                      }}
                    >
                      <Typography variant="p">Price</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="unitPrice"
                      id="unitPrice"
                      type="number"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="1000"
                      size="small"
                      onChange={handleAddOrders}
                      value={aVal.unitPrice}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions
                style={{
                  borderTop: '1px solid #f4f4f5'
                }}
              >
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  style={{ textTransform: 'none' }}
                  onClick={handleAddClose}
                  className={classes.button}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none' }}
                  onClick={addOrders}
                  className={classes.button}
                >
                  {isLoadingOne ? (
                    <CircularProgress size="14px" />
                  ) : (
                    'Add and save'
                  )}
                </Button>
              </DialogActions>
            </Popover>
          </Box>
          <Box className={classes.box}></Box>
          {content.map(data => {
            return (
              <>
                {content.length == 1 ? (
                  <>
                    <Box className={classes.imgBox}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.primary}
                              >
                                Name
                              </Typography>
                            }
                            secondary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.secondary}
                              >
                                {data.item}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.primary}
                              >
                                Unit price
                              </Typography>
                            }
                            secondary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.secondary}
                              >
                                {data.unitPrice}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.primary}
                              >
                                quantity
                              </Typography>
                            }
                            secondary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.secondary}
                              >
                                {data.qty}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.primary}
                              >
                                Total
                              </Typography>
                            }
                            secondary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.secondary}
                              >
                                {formatAmount(data.unitPrice * data.qty)} FCFA
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <Box>
                        <IconButton
                          style={{
                            boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
                            border: '1px solid #f4f4f5'
                          }}
                          color="primary"
                          size="small"
                          onClick={() => handlePinOpen(data)}
                        >
                          <EditIcon style={{ fontSize: 20 }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider
                      style={{ backgroundColor: 'rgb(192 190 201 / 12%)' }}
                    />
                  </>
                ) : (
                  <>
                    <Box className={classes.imgBox}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.primary}
                              >
                                Name
                              </Typography>
                            }
                            secondary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.secondary}
                              >
                                {data.item}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.primary}
                              >
                                Unit price
                              </Typography>
                            }
                            secondary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.secondary}
                              >
                                {formatAmount(data.unitPrice)}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.primary}
                              >
                                quantity
                              </Typography>
                            }
                            secondary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.secondary}
                              >
                                {data.qty}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.primary}
                              >
                                Total
                              </Typography>
                            }
                            secondary={
                              <Typography
                                style={{ fontFamily: 'Ubuntu' }}
                                className={classes.secondary}
                              >
                                {formatAmount(data.unitPrice * data.qty)} FCFA
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <Box>
                        <IconButton
                          style={{
                            border: '1px solid #f4f4f5',
                            boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
                          }}
                          color="primary"
                          size="small"
                          onClick={() => handlePinOpen(data)}
                        >
                          <EditIcon style={{ fontSize: 20 }} />
                        </IconButton>
                      </Box>
                      <Box>
                        {snackTwo && (
                          <Snackbar
                            duration={4000}
                            open={snackTwo}
                            close={handleCloseTwo}
                            data="Order deleted"
                          />
                        )}
                        <IconButton
                          style={{
                            border: '1px solid #f4f4f5',
                            boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
                          }}
                          color="primary"
                          size="small"
                          onClick={() => deleteOrderItems(data.id)}
                        >
                          {isLoadingTwo ? (
                            <CircularProgress size="14px" />
                          ) : (
                            <DeleteIcon
                              style={{ fontSize: 20, color: 'rgb(199, 30, 0)' }}
                            />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider
                      style={{ backgroundColor: 'rgb(192 190 201 / 12%)' }}
                    />
                  </>
                )}
              </>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default OrderDetails;
