import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles(theme => ({
  list: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '70%',
    maxWidth: 360,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%'
    }
  },
  icons: {
    color: '#3f51b5'
  },
  heading: {
    fontFamily: 'ubuntu'
  }
}));

export default function ServiceList() {
  const classes = useStyles();
  return (
    <List className={classes.list} sx={{ width: '100%' }}>
      <Typography
        style={{
          fontSize: 27,
          textAlign: 'center',
          marginLeft: 20,
          fontWeight: 700
        }}
        variant="p"
      >
        Fapshi at a glance.
      </Typography>
      <ListItem>
        <ListItemAvatar>
          <CheckCircleIcon className={classes.icons} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.heading}>
              Get started quickly
            </Typography>
          }
          secondary={
            <Typography variant="p" className={classes.heading}>
              {' '}
              Integrate with developer-friendly APIs or pre-built solutions.
            </Typography>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <CheckCircleIcon className={classes.icons} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.heading}>
              Get an online store at zero cost
            </Typography>
          }
          secondary={
            <Typography variant="p" className={classes.heading}>
              Curate your products into a store. Fapshi gives you the
              possibility to own a unique and easy-to-customize online store for
              free.
            </Typography>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <CheckCircleIcon className={classes.icons} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.heading}>
              Manage Contributions
            </Typography>
          }
          secondary={
            <Typography variant="p" className={classes.heading}>
              Create payment links for all kinds of contributions. Fapshi
              manages the collection and updates for you.
            </Typography>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <CheckCircleIcon className={classes.icons} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.heading}>
              SIMless money transfers
            </Typography>
          }
          secondary={
            <Typography variant="p" className={classes.heading}>
              Send money to and receive money from any fapshi account via our
              fast and secure channels.
            </Typography>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <CheckCircleIcon className={classes.icons} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.heading}>
              Support any business model
            </Typography>
          }
          secondary={
            <Typography variant="p" className={classes.heading}>
              E-commerce, subscriptions, SaaS platforms, marketplaces, and
              more—all within a unified platform.
            </Typography>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <CheckCircleIcon className={classes.icons} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.heading}>
              Join a network businesses
            </Typography>
          }
          secondary={
            <Typography variant="p" className={classes.heading}>
              Fapshi is trusted by ambitious businesses and companies of every
              size.
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
}
