import React from 'react';
import { Box, Typography, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthState } from 'src/context';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 5,
    marginLeft: 20,
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginTop: 30
    },
    backgroundColor: '#ffffff'
  },
  username: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 24,
    fontWeight: 900,
    color: 'rgb(26 27 37)',
    [theme.breakpoints.down('md')]: {
      fontSize: 25
    }
  },
  button: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 900,
    marginTop: '5px',
    borderRadius: 20,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  },
  textBox: {
    width: '45%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  styleBox: {
    marginTop: -170,
    [theme.breakpoints.down('md')]: {
      marginTop: -265
    }
  }
}));

const Welcome = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { userDetails } = useAuthState();
  return (
    <Box className={classes.root}>
      <Hidden mdDown>
        <Box
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            paddingRight: 10
          }}
        >
          {userDetails.isVendor ? (
            <Typography className={classes.username}>
              {`Hey, ${userDetails.username},`}
              <br />
              {'You are here again, welcome back!'}
            </Typography>
          ) : (
            <Typography className={classes.username}>
              {`Hello, ${userDetails.username},`}
              <br />
              {'welcome to Fapshi!'}
            </Typography>
          )}
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            paddingRight: 10
          }}
        >
          {userDetails.isVendor ? (
            <Typography className={classes.username}>
              {`Hey, ${userDetails.username},`}
              <br />
              {'You are back. Welcome!'}
            </Typography>
          ) : (
            <Typography className={classes.username}>
              {`Hello, ${userDetails.username},`}
              <br />
              {'welcome to Fapshi!'}
            </Typography>
          )}
        </Box>
      </Hidden>
      <br />
      <Box>
        <Hidden mdDown>
          <Box className={classes.textBox}>
            {userDetails.isVendor ? (
              <Typography
                variant="p"
                style={{ width: '40px', color: '#505056e3', fontSize: 16 }}
              >
                We are glad you are back. Are you hosting an event or program?
              </Typography>
            ) : (
              <Typography
                variant="p"
                style={{ width: '40px', color: '#505056e3', fontSize: 20 }}
              >
                Your account is pending activation. You won't be able to perform
                certain transactions. Start by activating your account in very
                simple steps.
              </Typography>
            )}
          </Box>
        </Hidden>
        <Hidden lgUp>
          <Box
            style={{
              paddingRight: 10,
              marginTop: 20,
              backgroundColor: '#ffffff'
            }}
          >
            {userDetails.isVendor ? (
              <Typography
                variant="p"
                style={{ width: '40px', color: '#505056e3', fontSize: 16 }}
              >
                We are glad you are back. Are you hosting an event or program?
              </Typography>
            ) : (
              <Typography
                variant="p"
                style={{ width: '40px', color: '#505056e3', fontSize: 16 }}
              >
                Your account is pending activation. You won't be able to perform
                certain transactions. Start by activating your account in very
                simple steps.
              </Typography>
            )}
          </Box>
        </Hidden>

        {!userDetails.isVendor ? (
          <Button
            size="small"
            color="default"
            variant="outlined"
            className={classes.button}
            onClick={() => {
              userDetails.isVendor
                ? navigate('/app/activate/vendor/confirmation', {
                    replace: false
                  })
                : navigate('/app/activate/vendor', { replace: false });
            }}
            style={{
              marginTop: '1rem',
              marginBottom: '2rem',
              textTransform: 'none',
              color: '#ffffff',
              backgroundColor: '#3f51b5'
            }}
          >
            {'Activate your account here '}
            <ArrowRightIcon size="13" style={{ marginLeft: 4 }} />
          </Button>
        ) : (
          <Button
            size="small"
            color="default"
            variant="outlined"
            className={classes.button}
            onClick={() => {
              navigate('/app/events/create-event', { replace: false });
            }}
            style={{
              marginTop: '1rem',
              textTransform: 'none',
              color: '#ffffff',
              backgroundColor: '#3f51b5'
            }}
          >
            {'Create an event'}
            <ArrowRightIcon size="13" style={{ marginLeft: 4 }} />
          </Button>
        )}
      </Box>
      {userDetails.isVendor ? (
        <Box className={classes.styleBox}>
          <svg
            viewBox="0 0 168 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              width: '218px',
              height: '160px',
              marginLeft: '85%',
              border: '1px dashed #f7f7f7'
            }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 89.3565V48.4629L228 0V40.8936L0 89.3565Z"
              fill="#3f51b5"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M67 107.115V66.2216L228 32V72.8936L67 107.115Z"
              fill="#fbfdff"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M228 40.8936V32L67 66.2216V75.1152L228 40.8936Z"
              fill="#3f51b5"
            ></path>
          </svg>
        </Box>
      ) : (
        <Box style={{ marginTop: -160 }}>
          <svg
            viewBox="0 0 168 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              width: '218px',
              height: '160px',
              marginLeft: '85%',
              border: '1px dashed #f7f7f7'
            }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 89.3565V48.4629L228 0V40.8936L0 89.3565Z"
              fill="#3f51b5"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M67 107.115V66.2216L228 32V72.8936L67 107.115Z"
              fill="#fbfdff"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M228 40.8936V32L67 66.2216V75.1152L228 40.8936Z"
              fill="#3f51b5"
            ></path>
          </svg>
        </Box>
      )}
    </Box>
  );
};

export default Welcome;
