export default {
  body: { fontFamily: ['Ubuntu', 'sans-serif'].join(','), color: '#546e7a' },
  h1: {
    fontWeight: 700,
    fontSize: 35,
    color: '#1a1f36',
    letterSpacing: '-0.24px',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h2: {
    fontWeight: 700,
    fontSize: 29,
    color: '#1a1f36',
    letterSpacing: '-0.24px',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h3: {
    fontWeight: 700,
    fontSize: 24,
    color: '#1a1f36',
    letterSpacing: '.9px',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h4: {
    fontWeight: 900,
    fontSize: 28,
    color: '#1a1f36',
    display: 'inline-block',
    letterSpacing: '1px',
    lineHeight: 0.5,
    wordWrap: 'break-word',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h5: {
    fontWeight: 700,
    fontSize: 18,
    letterSpacing: '.5px',
    color: '#1a1f36',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h6: {
    fontWeight: 600,
    fontSize: 14,
    color: '#3e4040',
    letterSpacing: '.5px'.replace,
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  overline: {
    fontWeight: 500
  },
  p: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 16,
    color: '#3e4040'
  },
  // smallText: {
  //   fontFamily: font.regular,
  //   fontSize: fontSizes.smallText,
  //   color: colors.lightBlack,
  // },
  // text: {
  //   fontFamily: font.regular,
  //   fontSize: fontSizes.text,
  //   color: colors.textColor,
  //   lineHeight: hp('3%'),
  // },
  // subheading: {
  //   fontFamily: font.medium,
  //   fontSize: fontSizes.subheading,
  //   color: colors.black,
  //   lineHeight: hp('4%'),
  // },
  // heading: {
  //   fontFamily: font.bold,
  //   fontSize: fontSizes.heading,
  //   fontWeight: '700',
  //   color: colors.black,
  //   lineHeight: hp('3%'),
  // },
  mainHeading: {
    // fontFamily: "'Ubuntu', 'sans-serif'",
    fontWeight: 700,
    fontSize: '2.125rem',
    lineHeight: 1.235,
    letterSpacing: '0.00735em'
  }
  // name: {
  //   fontSize: fontSizes.name,
  //   fontFamily: font.medium,
  //   color: colors.black,
  //   lineHeight: hp('3%'),
  // },
};
