import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography, Button, MenuItem } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { useAuthState } from 'src/context';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'src/components/Page';
import { Upload, message, Button as B } from 'antd';
import { Icon } from '@ant-design/compatible';
import Snackbar from 'src/components/Snackbar';
import Select from '@mui/material/Select';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '60%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50,
    backgroundColor: '#000'
  },
  inputText: {
    fontSize: 20,
    color: '#181819',
    fontWeight: 900,
    letterSpacing: 2
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderLeft: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 15
  },
  text: {
    fontSize: 15,
    color: '#3e4040'
  },
  mainText: {
    fontSize: 15,
    color: '#3f51b5'
  },
  buttons: {
    marginTop: 10,
    backgroundColor: '#000'
  },
  mainbox: {
    marginBottom: 10,
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  uploadIcon: {},
  [theme.breakpoints.down('md')]: {
    width: 'auto'
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));
const BootstrapSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '10px',
    padding: '10px 12px',
    borderBottom: 'none',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));
const ActivationForm = () => {
  const classes = useStyles();
  const { token, userDetails } = useAuthState();
  const [error, setError] = useState(false);
  const [errorTwo, setErrorTwo] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [eresponseTwo, setEresponseTwo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [snack, setSnack] = useState(false);
  const [snackTwo, setSnackTwo] = useState(false);
  const [values, setValues] = React.useState({
    name: null,
    adress: null,
    type: null,
    phone: null,
    city: null,
    regNumber: null,
    textPayerNum: null,
    bankAcc: null,
    website: null
  });

  const [repValue, setRepValue] = React.useState({
    name: null,
    adress: null,
    phone: null,
    email: null,
    idCardNum: null,
    Nationality: null
  });

  const propOne = {
    name: 'file',
    action: 'https://api.fapshi.com/uploadbank/docs',
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const propFive = {
    name: 'file',
    action: 'https://api.fapshi.com/upload-docs/coi',
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const propFront = {
    name: 'image',
    action: 'https://api.fapshi.com/business/upload/front',
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const propBack = {
    name: 'image',
    action: 'https://api.fapshi.com/business/upload/back',
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const propPicture = {
    name: 'image',
    action: 'https://api.fapshi.com/business/upload/picture',
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const cprops = {
    name: 'file',
    action: 'https://api.fapshi.com/uploadbusiness/docs',
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  React.useEffect(() => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/business/info',
      headers: { 'x-access-token': token }
    }).then(res => {
      setValues(res.data);
    });
  }, []);
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };

  React.useEffect(() => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/business/representative',
      headers: { 'x-access-token': token }
    }).then(res => {
      setRepValue(res.data);
    });
  }, []);
  const handleChanges = ({ target }) => {
    const { name, value } = target;
    setRepValue({ ...repValue, [name]: value });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/business/info',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setValues(res.data);
        setIsLoading(false);
        setIsEnabled(false);
        setSnack(true);
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  const handleSubmitTwo = () => {
    setIsLoadingTwo(true);
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/business/representative',
      data: repValue,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setRepValue(res.data);
        setIsLoadingTwo(false);
        setSnackTwo(true);
        axios({
          method: 'post',
          url: 'https://api.fapshi.com/business/confirmrequest',
          data: repValue,
          headers: { 'x-access-token': token }
        });
      })
      .catch(err => {
        setIsLoadingTwo(false);
        setErrorTwo(true);
        setEresponseTwo(err.response?.data.message);
      });
  };

  const handleSnackClose = () => {
    setSnack(false);
  };
  const handleSnackTwoClose = () => {
    setSnackTwo(false);
  };
  return (
    <Page title="Compliance">
      <Box className={classes.root} sx={{ flexGrow: 1 }}>
        <Box className={classes.mainbox}>
          <Typography
            style={{
              color: 'rgb(26 27 37)',
              fontSize: 24,
              fontWeight: 700,
              fontFamily: 'Ubuntu'
            }}
          >
            Activate international payments on your account.
          </Typography>
          <Typography
            style={{
              color: '#3e4040',
              fontSize: 14,
              textAlign: 'justify',
              fontWeight: 400,
              fontFamily: 'Ubuntu'
            }}
          >
            Hi {userDetails.username}, in order to accept credit cards and other
            international payments on your account, you need to provide some
            details for accountability and compliance purposes.
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid
            style={{
              borderLeft: '1px solid #5f616817',
              borderRadius: 4,
              padding: 25
            }}
            item
            xs={12}
            sm={12}
            lg={4.1}
            xl={4.1}
          >
            <Box>
              <Typography
                variant="h5"
                style={{
                  marginTop: -5,
                  color: '#3e4040'
                }}
              >
                Step one - Business details
              </Typography>
              <br />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Snackbar
                duration={6000}
                close={handleSnackClose}
                data="Operation successful"
                open={snack}
              />
              {error && (
                <Typography
                  variant="p"
                  style={{
                    color: '#eb6558',
                    fontWeight: 500,
                    fontSize: 14
                  }}
                >
                  {eresponse}
                </Typography>
              )}
              <Box>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-name"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Business name</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Business name"
                  margin="normal"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  id="bootstrap-input-name"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Valid address</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Address line"
                  margin="normal"
                  type="text"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  id="bootstrap-input-address"
                  required
                  style={{ marginRight: 8 }}
                />
                <BootstrapInput
                  fullWidth
                  placeholder="City"
                  margin="normal"
                  type="text"
                  id="bootstrap-input-city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  required
                  style={{ marginTop: 5, marginRight: 8 }}
                />
                <BootstrapInput
                  fullWidth
                  placeholder="Country"
                  margin="normal"
                  type="text"
                  id="bootstrap-input-address"
                  name="country"
                  value="Cameroon"
                  disabled
                  required
                  style={{ marginTop: 5, marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-phone"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Phone number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Phone number"
                  margin="normal"
                  type="text"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  id="bootstrap-input-phone"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-inputwebsite"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Website</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="ex: https://mywebsite.com"
                  margin="normal"
                  type="text"
                  name="website"
                  value={values.website}
                  onChange={handleChange}
                  id="bootstrap-input-website"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-type"
                  style={{ marginTop: 7 }}
                >
                  <Typography
                    variant="p"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    Type of business
                  </Typography>
                </InputLabel>
                <BootstrapSelect
                  fullWidth
                  margin="normal"
                  name="type"
                  defaultValue={values.type}
                  value={values.type}
                  onChange={handleChange}
                  type="text"
                  id="bootstrap-input-type"
                  required
                  style={{ marginRight: 8 }}
                >
                  <MenuItem
                    value="Sole proprietorship"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Sole proprietorship
                  </MenuItem>
                  <MenuItem
                    value="Public limited compay (PLC, SA)"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Public limited compay (PLC, SA)
                  </MenuItem>
                  <MenuItem
                    value="Private limited company(Inc, LTD, LLC, SARL)"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Private limited company(Inc, LTD, LLC, SARL)
                  </MenuItem>
                  <MenuItem
                    value="Other"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 18,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    Other
                  </MenuItem>
                </BootstrapSelect>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-tax"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Tax payers identifcation</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Tax payers number"
                  margin="normal"
                  type="text"
                  name="taxPayerNum"
                  value={values.taxPayerNum}
                  onChange={handleChange}
                  id="bootstrap-input-tax"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-regNumber"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Registration number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Business registration number"
                  margin="normal"
                  type="text"
                  name="regNumber"
                  value={values.regNumber}
                  onChange={handleChange}
                  id="bootstrap-input-registrations"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-bank"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Bank account number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Bank Account"
                  margin="normal"
                  type="text"
                  name="bankAcc"
                  value={values.bankAcc}
                  onChange={handleChange}
                  id="bootstrap-input-bank"
                  required
                  style={{ marginRight: 8 }}
                />
                <br /> <br />
                <Upload {...propOne}>
                  <B style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}>
                    <Icon className={classes.uploadIcon} type="upload" />
                    Upload attestation of business bank account
                  </B>
                </Upload>
                <Box style={{ marginTop: 5 }}>
                  <small
                    style={{
                      fontWeight: 400,
                      fontSize: 14
                    }}
                  >
                    The bank account name should be thesame as your business
                    name. Personal accounts not accepted.
                  </small>
                </Box>
                <Upload {...propFive}>
                  <B style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}>
                    <Icon className={classes.uploadIcon} type="upload" /> Upload
                    certificate of incorporation or license
                  </B>
                </Upload>
                <Box style={{ marginTop: 5 }}>
                  <small
                    style={{
                      fontWeight: 400,
                      fontSize: 14
                    }}
                  >
                    For Sole proprietors, you can submit your business license.
                  </small>
                </Box>
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  fullWidth
                >
                  {isLoading ? <CircularProgress size="14px" /> : 'Submit'}
                </Button>
              </Box>
            </Box>
            <Box style={{ marginTop: 40 }}>
              <Typography
                variant="h5"
                style={{
                  marginTop: -5,
                  color: '#3e4040'
                }}
              >
                Step two - Business representative
              </Typography>
              <br />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Snackbar
                duration={6000}
                close={handleSnackTwoClose}
                data="Sucessfully submitted"
                open={snackTwo}
              />
              {errorTwo && (
                <Typography
                  variant="p"
                  style={{
                    color: '#eb6558',
                    fontWeight: 500,
                    fontSize: 14
                  }}
                >
                  {eresponseTwo}
                </Typography>
              )}
              <Box>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-name"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Your full names</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Tell us your names"
                  margin="normal"
                  name="name"
                  value={repValue.name}
                  onChange={handleChanges}
                  id="bootstrap-input-name"
                  required
                  style={{ marginRight: 8 }}
                  disabled={isEnabled}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Email</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Email address"
                  margin="normal"
                  type="text"
                  name="email"
                  value={repValue.email}
                  onChange={handleChanges}
                  id="bootstrap-input-email"
                  required
                  style={{ marginRight: 8 }}
                  disabled={isEnabled}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Home address</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Address line"
                  margin="normal"
                  type="text"
                  name="address"
                  value={repValue.address}
                  onChange={handleChanges}
                  id="bootstrap-input-address"
                  required
                  style={{ marginRight: 8 }}
                  disabled={isEnabled}
                />
                <BootstrapInput
                  fullWidth
                  placeholder="Country"
                  margin="normal"
                  type="text"
                  id="bootstrap-input-country"
                  name="nationality"
                  value={repValue.nationality}
                  required
                  style={{ marginTop: 5, marginRight: 8 }}
                  disabled={isEnabled}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Phone number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Phone number"
                  margin="normal"
                  type="text"
                  name="phone"
                  value={repValue.phone}
                  onChange={handleChanges}
                  id="bootstrap-input-phone"
                  required
                  style={{ marginRight: 8 }}
                  disabled={isEnabled}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-id"
                  style={{
                    marginTop: 7,
                    color: '#3e4040',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">ID number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="idCardNum"
                  margin="normal"
                  type="text"
                  name="idCardNum"
                  value={repValue.idCardNum}
                  onChange={handleChanges}
                  id="bootstrap-input-id"
                  required
                  style={{ marginRight: 8 }}
                  disabled={isEnabled}
                />
                <Box style={{ marginTop: 10 }}>
                  <Typography
                    style={{
                      marginTop: 12,
                      fontWeight: 400,
                      fontSize: 18
                    }}
                    variant="p"
                  >
                    National ID
                  </Typography>
                  <br />
                  <small
                    style={{
                      fontWeight: 400,
                      fontSize: 14
                    }}
                  >
                    Upload your Id. Make sure the ID card belongs to you. We
                    will use it for accountability purposes.
                  </small>
                  <br />
                  <br />
                  <Upload {...propFront}>
                    <B
                      style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}
                    >
                      <Icon type="upload" /> Upload a picture of the front side
                      of your ID
                    </B>
                  </Upload>
                  <br />
                  <Upload {...propBack}>
                    <B
                      style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}
                    >
                      <Icon type="upload" /> Upload a picture of the back side
                      of your ID
                    </B>
                  </Upload>
                  <br />
                  <Upload {...propPicture}>
                    <B
                      style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}
                    >
                      <Icon type="upload" /> Upload a picture of YOU holding
                      your ID
                    </B>
                  </Upload>
                </Box>
              </Box>
              <br />
              <Box style={{ marginTop: 10 }}>
                <Typography
                  style={{
                    marginTop: 12,
                    fontWeight: 400,
                    fontSize: 18
                  }}
                  variant="p"
                >
                  Proof of business representative.
                </Typography>
                <br />
                <small
                  style={{
                    fontWeight: 400,
                    fontSize: 14
                  }}
                >
                  Upload a document which proves that you own atleast 20% of
                  this business. This could be a notarized article of
                  association, notarized statement of allocation of shares, etc.
                </small>
                <br />
                <br />
                <Upload {...cprops}>
                  <B style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}>
                    <Icon type="upload" /> Upload a proof of business
                    representative.
                  </B>
                </Upload>
              </Box>
              <Button
                className={classes.buttons}
                variant="contained"
                type="submit"
                color="primary"
                onClick={handleSubmitTwo}
                fullWidth
                disabled={isEnabled}
              >
                {isLoadingTwo ? <CircularProgress size="14px" /> : 'Submit'}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} xl={3} Grid></Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default ActivationForm;
