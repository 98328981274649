import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { visuallyHidden } from '@mui/utils';
import NoResults from 'src/components/NoResults';
import { Typography } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Activity as TransactionIcon } from 'react-feather';
import { capitalize, formatAmount } from 'src/utils/helperFunctions';

const headCells = [
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Transaction code'
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Name'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount (FCFA)'
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status'
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date'
  },
  {
    id: 'method',
    numeric: true,
    disablePadding: false,
    label: 'Method'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography
                style={{
                  color: '#3e4040',
                  fontFamily: 'ubuntu',
                  fontSize: 18,
                  fontWeight: 900
                }}
              >
                {headCell.label}
              </Typography>

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default function PopupTable({ progressDisplay, details }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = details.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const newDate = date => {
    const d = new Date(date);
    return d.toLocaleString();
  };

  return (
    <>
      <Box
        style={{
          display: progressDisplay ? 'block' : 'none'
        }}
      >
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
            backdropFilter: 'blur(2px)',
            backgroundColor: '#ffffff59'
          }}
          open={open}
        >
          <CircularProgress
            size="1rem"
            style={{
              display: progressDisplay ? 'block' : 'none',
              marginLeft: '20%',
              marginTop: '15%',
              marginBottom: '10%',
              color: '#3f51b5'
            }}
          />
        </Backdrop>
      </Box>
      <Box
        style={{
          display: progressDisplay ? 'none' : 'block'
        }}
        sx={{ width: '100%' }}
      >
        {details.length !== 0 ? (
          <>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={details.length}
                  />
                  <TableBody style={{ backgroundColor: '#ffffff' }}>
                    {details?.map(rows => (
                      <TableRow
                        hover
                        onClick={event => handleClick(event, rows.transferId)}
                        role="checkbox"
                        tabIndex={-1}
                        key={rows.transferId}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="p"
                            style={{ color: '#3e4040', fontSize: 18 }}
                          >
                            {rows.transferId}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="p"
                            style={{ color: '#3e4040', fontSize: 18 }}
                          >
                            {capitalize(rows.payerName)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="p"
                            style={{ color: '#3e4040', fontSize: 18 }}
                          >
                            {formatAmount(rows.amount)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="p"
                            style={{ color: '#3e4040', fontSize: 18 }}
                          >
                            {rows.status}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="p"
                            style={{ color: '#3e4040', fontSize: 18 }}
                          >
                            {newDate(rows.dateConfirmed)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="p"
                            style={{ color: '#3e4040', fontSize: 18 }}
                          >
                            {rows.medium}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={dense}
                  onChange={handleChangeDense}
                />
              }
              label={
                <Typography
                  variant="p"
                  style={{ color: '#3e4040', fontSize: 18 }}
                >
                  {'Dense padding'}
                </Typography>
              }
            />
          </>
        ) : (
          <>
            <NoResults
              title="transactions made"
              content="Transactions are simply records of purchase made with our API's or merchants "
              link="https://documentation.fapshi.com/#transactions"
              icon={
                <TransactionIcon
                  size={35}
                  style={{
                    fontSize: 40,
                    border: '1px solid #bdc3c9',
                    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                    borderRadius: 10,
                    color: '#bdc3c9',
                    padding: 5
                  }}
                />
              }
            />
          </>
        )}
      </Box>
    </>
  );
}
