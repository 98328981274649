import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Logo from './logo.svg';
import axios from 'src/axios';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useAuthState } from 'src/context';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '50%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  box: {
    width: '35%',
    marginLeft: '35%',
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: 310,
    marginTop: '10%',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      marginLeft: 10,
      marginRight: 50,
      marginTop: '30%',
      padding: '70px 5px 70px 5px',
      height: 350
    }
  },
  logo: {
    width: 110,
    height: 40
  },
  error: {
    textAlign: 'left'
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '17px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd',
      border: '1px solid #ced4da'
    }
  }
}));

const CodeVerification = () => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [errorOne, setErrorOne] = useState(false);
  const [eresponseOne, setEresponseOne] = useState(null);
  const { token } = useAuthState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOne, setIsLoadingOne] = useState(false);
  const [formEmail, setFormEmail] = useState(null);
  const [isCode, setIsCode] = useState(true);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: Yup.object().shape({
      code: Yup.string()
        .min(6)
        .max(6)
        .required('Verification code is required')
    }),
    onSubmit: values => {
      const data = {
        email: formEmail,
        code: values.code
      };
      setIsLoading(true);
      console.log(data);
      axios
        .post(`/auth/confirmEmail`, data)
        .then(res => {
          setIsLoading(false);
          if (res.status === 200) {
            navigate('/verify/success', { replace: true });
          }
        })
        .catch(error => {
          setError(true);
          setIsLoading(false);
          setEresponse(error.response?.data.message);
        });
    }
  });

  const handleResendCode = () => {
    setIsLoadingOne(true);
    axios({
      method: 'post',
      data: { email: formEmail },
      url: '/user/resend-account-confirmation-code/',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setIsLoadingOne(false);
        setIsCode(false);
      })
      .catch(error => {
        setIsLoadingOne(false);
        setErrorOne(true);
        setEresponseOne(error.response?.data.message);
      });
  };
  return (
    <Page className={classes.root} title="Verification">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        textAlign="center"
        className={classes.box}
      >
        <Container maxWidth="sm">
          {!isCode ? (
            <form onSubmit={formik.handleSubmit}>
              <Box mb={3}>
                <img className={classes.logo} src={Logo} alt="logo"></img>
                <Typography color="textPrimary" variant="h3">
                  Sign up verification
                </Typography>
                <Typography variant="p" color="textSecondary" gutterBottom>
                  A passcode has been sent to your email. Fill in this code to
                  verify your email.
                </Typography>
              </Box>
              {error ? (
                <Typography
                  variant="p"
                  style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                >
                  {eresponse}
                </Typography>
              ) : (
                <></>
              )}
              <BootstrapInput
                error={Boolean(formik.touched.code && formik.errors.code)}
                fullWidth
                helperText={formik.touched.code && formik.errors.code}
                label="Passcode"
                margin="normal"
                placeholder="Enter verification code"
                name="code"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="code"
                value={formik.values.code}
                variant="outlined"
                size="small"
                required
              />
              <Box my={2}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ textTransform: 'none' }}
                >
                  {isLoading ? (
                    <CircularProgress my={2} size="20px" />
                  ) : (
                    'Verify'
                  )}
                </Button>
              </Box>
            </form>
          ) : (
            <Box>
              <Box mb={3}>
                <img className={classes.logo} src={Logo} alt="logo"></img>
                <Typography color="textPrimary" variant="h3">
                  Sign up verification
                </Typography>
                <Typography variant="p" color="textSecondary" gutterBottom>
                  Enter the email you used to sign up. We will send a
                  verification code.
                </Typography>
              </Box>
              {errorOne ? (
                <Typography
                  variant="p"
                  style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                >
                  {eresponseOne}
                </Typography>
              ) : (
                <></>
              )}
              <BootstrapInput
                name="item"
                id="item"
                type="text"
                InputLabelProps={{
                  shrink: true
                }}
                variant="outlined"
                placeholder="Enter email address"
                size="small"
                fullWidth
                onChange={e => setFormEmail(e.target.value)}
                value={formEmail}
                required
              />
              <Box my={2}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={handleResendCode}
                  variant="contained"
                  style={{ textTransform: 'none' }}
                >
                  {isLoadingOne ? (
                    <CircularProgress my={2} size="20px" />
                  ) : (
                    'Verify'
                  )}
                </Button>
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </Page>
  );
};

export default CodeVerification;
