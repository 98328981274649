import axios from 'src/axios';

export const loginUser = async (dispatch, loginPayload) => {
  try {
    var response = await axios.post(`/auth/signin`, loginPayload);
    const data = response.data;
    if (response.status === 200) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      localStorage.setItem('currentUser', JSON.stringify(data));
    }
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
    response = error;
  }

  return response ? response : [];
};

export const verificationLogin = async (dispatch, verificationPayload) => {
  const data = verificationPayload;
  try {
    dispatch({ type: 'LOGIN_SUCCESS', payload: data });
    localStorage.setItem('currentUser', JSON.stringify(data));
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
};

export const logout = async dispatch => {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
  localStorage.removeItem('getUserService');
  localStorage.removeItem('singleUserService');
};
