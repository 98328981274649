import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@material-ui/core/Typography';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Hidden } from '@material-ui/core';
import * as Yup from 'yup';
import axios from 'src/axios';
import { useAuthState } from 'src/context';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import PaymentsTab from 'src/components/PaymentsTab';
import InputBase from '@material-ui/core/InputBase';
import { alpha, styled } from '@mui/material/styles';
import Snackbar from 'src/components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { formatAmount, ucFirst } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderBottom: 'solid 1px #ffffff'
  },
  search: {
    position: 'relative',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    right: '18.6%',
    border: '1px solid #35354612',
    width: '450px',
    height: 40,
    borderRadius: 3,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#4f566b'
  },
  label: {
    marginBottom: -15
  },
  inputRoot: {
    color: 'black',
    fontFamily: 'Ubuntu'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    fontSize: 14,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    '&:hover': {
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff8c' //rgba(0,0,30,0.2)
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100ch'
    },
    marginTop: 3
  },
  text: { fontFamily: 'Ubuntu', fontSize: 14, color: '#546e7a' }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '8px',
    padding: '10px 12px',
    marginTop: 5,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#768095'
    }
  }
}));

export default function Confirmation() {
  const classes = useStyles();
  const { token } = useAuthState();
  const location = useLocation();
  const navigate = useNavigate();
  const dataValues = location.state?.paymentData;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      pin: '',
      amount: ''
    },
    validationSchema: Yup.object().shape({
      pin: Yup.string()
    }),

    onSubmit: values => {
      values = {
        pin: values.pin,
        transferId: dataValues.transferId
      };
      axios({
        method: 'post',
        url: '/fapshi/confirmpay',
        data: values,
        headers: { 'x-access-token': token }
      })
        .then(() => {
          setSnack(true);
          setIsLoading(false);
          setAnchorEl(null);
          navigate('/app/payments', { replace: false });
        })
        .catch(err => {
          setIsLoading(false);
          setError(true);
          setEresponse(err.response?.data.message);
        });
    }
  });

  const handleSnackClose = () => {
    setSnack(false);
  };
  return (
    <Container style={{ fontFamily: 'Ubuntu' }}>
      <Grid container>
        <Hidden mdDown>
          <Grid item xs={12} sm={12} lg={3} xl={3}>
            <PaymentsTab val="payments" />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} lg={9} xl={9}>
          <TableContainer
            style={{ width: '100%', margin: '40px 22px' }}
            component={Paper}
          >
            {dataValues && (
              <>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" colSpan={5}>
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 400,
                            fontFamily: 'Ubuntu'
                          }}
                        >
                          Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Typography className={classes.text}>
                          Payment type
                        </Typography>
                      </TableCell>
                      {dataValues.productName === 'Donation' ? (
                        <TableCell align="left">
                          <Typography className={classes.text}>
                            Organisation
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell align="left">
                          <Typography className={classes.text}>
                            Transfer ID
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <Typography className={classes.text}>
                          Donation Id
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.text}>
                          Date initiated
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.text}>Status</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow key={dataValues?.transferId}>
                      <TableCell>
                        <Typography className={classes.text}>
                          {ucFirst(dataValues.productName)}
                        </Typography>
                      </TableCell>
                      {dataValues.productName === 'Donation' ? (
                        <TableCell align="left">
                          <Typography className={classes.text}>
                            {dataValues.organisation}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell align="left">
                          <Typography className={classes.text}>
                            {dataValues.transferId}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <Typography className={classes.text}>
                          {dataValues.donationId}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.text}>
                          {dataValues.dateInitiated}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Stack spacing={1} alignItems="center">
                          <Stack direction="row" spacing={1}>
                            <Chip
                              style={{
                                backgroundColor: '#20ab4a',
                                textTransform: 'none'
                              }}
                              label={
                                <Typography
                                  style={{
                                    color: '#ffffff',
                                    textTransform: 'lowercase'
                                  }}
                                  className={classes.text}
                                >
                                  {dataValues.status}
                                </Typography>
                              }
                              color="primary"
                              size="small"
                            />
                          </Stack>
                        </Stack>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography className={classes.text}>Tax</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.text}>
                          {`0.00%`}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.text}>0 FCFA</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography className={classes.text}>Total</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.text}>
                          {formatAmount(dataValues.amount)} FCFA
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {dataValues.status !== 'SUCCESSFUL' ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ pull: 'right', textTransform: 'none', margin: 10 }}
                    onClick={handleOpen}
                    className={classes.button}
                  >
                    Confirm payment
                  </Button>
                ) : (
                  <></>
                )}
                <Box
                  style={{
                    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
                  }}
                >
                  <Snackbar
                    duration={6000}
                    close={handleSnackClose}
                    data="Payments successful"
                    open={snack}
                  />
                  <Popover
                    style={{ marginTop: 44 }}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                  >
                    <Box
                      style={{
                        boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
                        border: '1px solid #0f15c514',
                        width: 250
                      }}
                    >
                      {error && (
                        <Typography
                          variant="p"
                          style={{
                            color: '#eb6558',
                            fontWeight: 900,
                            fontSize: 16
                          }}
                        >
                          {eresponse}
                        </Typography>
                      )}
                      <form
                        className={classes.root}
                        onSubmit={formik.handleSubmit}
                      >
                        <DialogContent>
                          <FormControl>
                            <FormGroup>
                              <FormLabel className={classes.label}>
                                <Typography
                                  variant="p"
                                  className={classes.text}
                                >
                                  Enter your pin to validate this payment
                                </Typography>
                              </FormLabel>
                              <br />
                              <BootstrapInput
                                error={Boolean(
                                  formik.touched.pin && formik.errors.pin
                                )}
                                helperText={
                                  formik.touched.pin && formik.errors.pin
                                }
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.pin}
                                name="pin"
                                id="pin"
                                type="password"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                variant="outlined"
                                placeholder="Ex: 12345"
                                size="small"
                                required
                              />
                            </FormGroup>
                          </FormControl>
                        </DialogContent>
                        <DialogActions
                          style={{
                            borderTop: ' 1px solid rgb(239 239 239)',
                            padding: 10
                          }}
                        >
                          <Button
                            onClick={handleClose}
                            color="primary"
                            variant="outlined"
                            size="small"
                            className={classes.button}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="small"
                            className={classes.button}
                          >
                            {isLoading ? (
                              <CircularProgress size="14px" />
                            ) : (
                              'Confirm'
                            )}
                          </Button>
                        </DialogActions>
                      </form>
                    </Box>
                  </Popover>
                </Box>
              </>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
