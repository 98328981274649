import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { UploadOutlined } from '@ant-design/icons';

import theme from 'src/assets/theme';

const { colors } = theme;

const styles = {
  btn: {
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: 'none',
    border: '1px solid',
    borderColor: colors.grey,
    px: 2,
    py: '4px',
    textTransform: 'none',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  }
};

const ShareBtn = ({ url, text, style, state, type, icon }) => {
  const handleClick = () => {
    navigator.clipboard.writeText(url);
    toast.success('Link Copied to clipboard!');
    state(null); // Close the popover after clicking on the button
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={[{ fontSize: '0.9rem' }, type && styles.btn, style]}
      >
        {icon === 'share' && <UploadOutlined />}
        {' ' + text}
      </Box>
    </>
  );
};

export default ShareBtn;
