import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import { useAuthState } from 'src/context';
import { formatAmount, ucFirst } from 'src/utils/helperFunctions';

const headCells = [
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Transaction code'
  },
  {
    id: 'cname',
    numeric: true,
    disablePadding: false,
    label: 'Client'
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email'
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone'
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Unit Price (FCFA)'
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: false,
    label: 'Quantity'
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Total (FCFA)'
  },
  {
    id: 'mode',
    numeric: true,
    disablePadding: false,
    label: 'Mode'
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status'
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Paid on'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = props => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            )
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
          style={{ fontFamily: 'Ubuntu' }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h5"
          id="tableTitle"
          component="div"
          style={{ fontFamily: 'Ubuntu', fontSize: 16 }}
        >
          Transactions
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

export default function ProductTransactions({ data }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);
  const { token } = useAuthState();

  const [details, setDetails] = useState([]);
  useEffect(() => {
    getProduct();
  }, []);
  const getProduct = async () => {
    var response = await axios({
      method: 'get',
      url: 'https://api.fapshi.com/productTransDetails/' + data.productId,
      headers: { 'x-access-token': token }
    });
    setDetails(response.data);
    console.log(response);
    console.log(details);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = details.map(n => n.transferId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    <Box sx={{ width: '100%' }}>
      {details.length !== 0 ? (
        <>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{ minWidth: 1500 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={details.length}
                />
                <TableBody>
                  {details.map(rows => (
                    <>
                      <TableRow hover role="checkbox">
                        <TableCell component="th" scope="row" padding="normal">
                          {rows.transferId}
                        </TableCell>
                        <TableCell align="right">
                          {ucFirst(rows.clientName)}
                        </TableCell>
                        <TableCell align="right">{rows.clientEmail}</TableCell>
                        <TableCell align="right" padding="normal">
                          {rows.clientPhone}
                        </TableCell>
                        <TableCell align="right">
                          {formatAmount(rows.unitPrice)}
                        </TableCell>
                        <TableCell align="right">{rows.quantity}</TableCell>
                        <TableCell align="right">
                          {formatAmount(rows.total)}
                        </TableCell>
                        <TableCell align="right">{rows.medium}</TableCell>
                        <TableCell align="right">{rows.status}</TableCell>
                        <TableCell align="right">
                          {rows.dateConfirmed}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={dense}
                onChange={handleChangeDense}
              />
            }
            label="Dense padding"
          />
        </>
      ) : (
        <>
          <TableContainer>
            <Table
              sx={{ minWidth: 400 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <Box style={{ width: 350 }}>
                <Typography
                  style={{ fontSize: 16, color: 'black' }}
                  variant="p"
                >
                  No transactions for this product.
                </Typography>
              </Box>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
}
