import React, { useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import axios from 'axios';
import { useAuthState } from 'src/context';
import Snackbar from 'src/components/Snackbar';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { getRevenue, getPrice } from 'src/components/FapshiRevenue';

const useStyles = makeStyles(theme => ({
  root: {},
  introbox: {},
  error: {
    textAlign: 'left'
  },
  box: {
    width: 480,
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  button: {
    textTransform: 'none',
    borderRadius: 8,
    fontSize: 14,
    margin: 2,
    height: 35,
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  },
  inputText: {
    fontSize: 20,
    color: '#181819',
    fontWeight: 900,
    letterSpacing: 1
  },
  btn: {
    marginTop: 10,
    width: 330,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '70%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const SimpleItems = () => {
  const classes = useStyles();
  const { token } = useAuthState();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: null,
    email: null,
    note: null,
    phone: null,
    date: null
  });
  const [amount, setAmount] = useState(null);
  const [revenue, setRevenue] = useState('');
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = e => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handlePriceChange = event => {
    const target = event.target.value;
    setAmount(target);
    const rev = getRevenue(target);
    setRevenue(rev);
  };
  const handleRevenueChange = event => {
    const target = event.target.value;
    setRevenue(target);
    const price = getPrice(target);
    setAmount(price);
  };
  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    const val = {
      clientName: values.name,
      clientEmail: values.email,
      phone: values.phone,
      reason: values.note,
      total: parseInt(amount),
      dueDate: values.date
    };
    axios({
      method: 'post',
      data: val,
      url: 'https://api.fapshi.com/invoice',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsLoading(false);
        setValues({});
        navigate('/app/invoices', { replace: false });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          borderRight: '2px solid #eaeaea6e'
        }}
      >
        <Page className={classes.root} title="Login">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.box}
            style={{}}
          >
            <Container maxWidth="sm">
              <Box style={{ display: 'flex' }}>
                <Typography variant="h5" style={{ marginBottom: 30 }}>
                  Invoice details
                </Typography>
              </Box>
              {snack && <Snackbar data="Invoice sent successfully" />}
              {error && (
                <Typography
                  variant="p"
                  style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                >
                  {eresponse}
                </Typography>
              )}
              <form>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Customer name
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter customer name"
                  margin="normal"
                  name="name"
                  type="text"
                  value={values.name}
                  id="bootstrap-input"
                  required
                  autoFocus
                  onChange={handleChange}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Customer email
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter customer email"
                  margin="normal"
                  name="email"
                  type="text"
                  value={values.email}
                  id="bootstrap-input"
                  required
                  onChange={handleChange}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Phone
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter phone"
                  margin="normal"
                  name="phone"
                  type="number"
                  value={values.phone}
                  id="bootstrap-input"
                  required
                  onChange={handleChange}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Amount
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter amount"
                  margin="normal"
                  name="amount"
                  type="number"
                  value={amount}
                  id="bootstrap-input"
                  required
                  onChange={handlePriceChange}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Revenue
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Revenue"
                  margin="normal"
                  name="revenue"
                  type="number"
                  value={revenue}
                  id="bootstrap-input"
                  required
                  onChange={handleRevenueChange}
                />
                {/* <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Due date
                    <span style={{ fontWeight: 500, fontSize: 18 }}>
                      {' (not required)'}
                    </span>
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  margin="normal"
                  name="date"
                  type="date"
                  value={values.date}
                  id="bootstrap-input"
                  required
                  onChange={handleChange}
                />{' '} */}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Note
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Additional info"
                  margin="normal"
                  name="note"
                  row={4}
                  type="text"
                  value={values.note}
                  id="bootstrap-input"
                  required
                  multiline
                  onChange={handleChange}
                />{' '}
                <Box className={classes.btn}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    size="small"
                    style={{ float: 'right' }}
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <CircularProgress size="14px" />
                    ) : (
                      'Save and send invoice'
                    )}
                  </Button>
                  <Button
                    color="primary"
                    type="reset"
                    onClick={() =>
                      navigate('/app/invoices', { replace: false })
                    }
                    className={classes.button}
                    size="small"
                    style={{ float: 'right' }}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Page>
      </Grid>
    </Grid>
  );
};

export default SimpleItems;
