import { useEffect } from 'react';
import Page from 'src/components/Page';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'src/components/Buttons/Button';
import EventsTable from './EventsTable';
import { apiConfig } from 'src/utils/apiConfig';
import texts from 'src/assets/texts';
import useEndpoint from 'src/utils/useEndpoint';
import Loader from 'src/components/Loader';
import LocalLoader from 'src/components/LocalLoader';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 40
  },
  table: {
    marginTop: '2rem'
  },
  text: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 14
  }
});

const Events = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  // Use custom endpoint hook to fetch events
  const { data: events, loading, getRequest, modifyData } = useEndpoint();

  const getEvents = () =>
    modifyData({
      url: apiConfig.eventUrl,
      action: texts.fetching,
      requestMethod: 'get',
      errMsg: texts.unabletofetchdetails
    });

  useEffect(() => {
    getEvents();
  }, []);

  // Use custom endpoint hook to delete event
  const deleteEvent = id => {
    modifyData({
      url: `${apiConfig.eventUrl}/${id}`,
      requestMethod: 'delete',
      action: texts.deleting,
      idName: '_id',
      id: id,
      successMsg: texts.deleted,
      errMsg: texts.unabletodelete
    });
  };

  const handleCreateEvent = () => {
    navigate('./create-event');
  };

  return (
    <Page title="Events" style={{ marginTop: 0 }}>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item lg={1} xl={2}></Grid>
          <Grid item xs={12} sm={12} lg={10} xl={8}>
            <Box className={classes.box}>
              <Typography variant="h1">Events</Typography>
              <CustomButton
                btnTxt="Create Event"
                on={handleCreateEvent}
                variant="contained"
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="p" className={classes.text}>
                You can choose to share your event in two ways: <br />
                1. Ask those who want to book to copy and paste your{' '}
                <strong>Event ID</strong> on{' '}
                <a href="https://iventily.com" target="_blank">
                  iventily.com
                </a>
                <br />
                2. Copy & share the <strong>Event Link</strong>.
              </Typography>
            </Box>
            <Box className={classes.table}>
              <EventsTable events={events} deleteEvent={deleteEvent} />
              {loading &&
                (getRequest ? (
                  <LocalLoader
                    text={'Please wait while we fetch your events...'}
                  />
                ) : (
                  <Loader text={loading} />
                ))}
              {loading
                ? null
                : events.length === 0 && (
                    <Box
                      sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                    >
                      <Typography variant="p" className={classes.text}>
                        You have not created any events. When you do, they'll
                        appear here.
                      </Typography>
                    </Box>
                  )}
            </Box>
          </Grid>
          <Grid item lg={1} xl={2}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Events;
