import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@mui/material/InputLabel';

import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel
} from '@material-ui/core';
import TableData from './Table';
import BalanceTab from 'src/components/BalanceTab';
import axios from 'src/axios';
import { useAuthState } from 'src/context';
import { makeStyles } from '@material-ui/core/styles';
// import Snackbar from 'src/components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import CustomButton from 'src/components/Buttons/Button';
import { formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50%',
      textAlign: 'center',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
    },
    width: '100%',
    border: '1px  solid #e0e0e03d'
    // backgroundColor: '#fdfdff'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    fontSize: 14,
    borderRadius: 10,
    height: 30,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    '&:hover': {
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  },
  introtitle: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 26,
    fontWeight: 700,
    color: '#1a1f36',
    display: 'inline-block'
  },
  inputfield: {
    width: '100%'
  },
  root: {
    width: '100%',
    margin: '50px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  items: {
    display: 'inline-block'
  },
  buttonn: {
    float: 'right',
    textTransform: 'none',
    fontSize: 14,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    border: '1px solid #dfdee5',
    height: 25
  },
  box: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    padding: 20
  },
  showBox: {
    width: 500,
    overflowX: 'hidden'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  flexBox: {
    display: 'inline-flex'
  },
  textBox: {
    marginBottom: 35
  },
  firstBox: {
    marginBottom: 10
  },
  secondText: {
    fontWeight: 700,
    fontFamily: 'Ubuntu'
  },
  textTwo: {
    fontFamily: 'Ubuntu'
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    width: 40,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  },
  selectInput: {
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#768095'
    },
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ])
  },
  position: 'relative',
  backgroundColor: '#ffffff'
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '8px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#768095'
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    }
  }
}));

const TopupsView = () => {
  const [localAmount, setLocalAmount] = useState(0);
  const [intlAmount, setIntlAmount] = useState(0);
  const [localCharges, setLocalCharges] = useState(0);
  const [intlCharges, setIntlCharges] = useState(0);
  const [select, setSelect] = useState(null);
  const { token } = useAuthState();
  const [openI, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [details, setDetails] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsCode(false);
    setError(false);
  };
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isCode, setIsCode] = useState(false);
  const [topUpDollar, setTopUpDollar] = useState(0);
  const [bankTopUpRate, setBankTopUpRate] = useState(0);

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/rates',
      headers: { 'x-access-token': token }
    }).then(res => {
      setTopUpDollar(res.data.rates.topupDollarRate);
      setBankTopUpRate(res.data.rates.bankTopupRate);
    });
  }, [bankTopUpRate, topUpDollar]);

  const handleLocalAmountChange = ({ target }) => {
    setLocalAmount(parseInt(target.value));
    const result = Math.ceil(target.value * bankTopUpRate);
    setLocalCharges(result);
  };

  const handleIntlAmountChange = ({ target }) => {
    setIntlAmount(parseInt(target.value));
    const result =
      parseInt(Number((target.value / topUpDollar).toFixed(2)) * 100) / 100;
    setIntlCharges(result);
  };

  const convertToIntl = amount => {
    return parseInt(Number((amount / topUpDollar).toFixed(2)) * 100) / 100;
  };

  const handleSelectChange = ({ target }) => {
    setSelect(target.value);
    setCurrency(target.value);
    setLocalAmount(0);
    setIntlAmount(0);
    setIntlCharges(0);
    setLocalCharges(0);
  };

  const handleTopup = () => {
    setIsLoading(true);
    const val = {
      amount: parseInt(localAmount) || parseInt(intlAmount),
      currency: select
    };
    axios({
      method: 'post',
      data: val,
      url: 'https://api.fapshi.com/bank-topup',
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setIsCode(true);
        setIsLoading(false);
        setDetails(res.data);
        setError(false);
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const handleBack = () => {
    setIsCode(false);
    setError(false);
  };

  return (
    <Page title="Top-ups">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <BalanceTab val="topups" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={9} xl={10}>
            <Box className={classes.root}>
              <Typography variant="h4" className={classes.introtitle}>
                {'Top-ups'}
              </Typography>
              <CustomButton
                btnTxt="Make a top-up"
                on={handleOpen}
                btnStyle={{ mb: 0 }}
              />
            </Box>
            <Box
              style={{
                boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
              }}
            >
              <Dialog
                BackdropProps={{
                  classes: {
                    root: classes.backDrop
                  }
                }}
                open={openI}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className={classes.box}
              >
                <DialogTitle
                  id="form-dialog-title"
                  style={{
                    borderBottom: '1px solid rgb(229 231 243)',
                    padding: 10
                  }}
                >
                  <Typography style={{}} variant="h6">
                    Add funds to your
                    <span style={{ color: '#0e0e0e' }}> Fapshi</span> balance
                  </Typography>
                </DialogTitle>
                {error && (
                  <Typography
                    variant="p"
                    style={{
                      color: '#eb6558',
                      fontSize: 14,
                      marginLeft: 10,
                      fontWeight: 700,
                      paddingTop: 5
                    }}
                  >
                    {eresponse}
                  </Typography>
                )}
                {!isCode ? (
                  <Box>
                    <DialogContent>
                      <Box>
                        <FormLabel className={classes.label}>
                          <Typography variant="h6">Currency</Typography>
                        </FormLabel>
                        <Box>
                          <select
                            className={classes.selectInput}
                            style={{
                              width: '100%',
                              height: 30,
                              fontSize: 16,
                              borderRadius: 10,
                              border: '1px solid #ced4da',
                              marginBottom: 10
                            }}
                            onChange={handleSelectChange}
                          >
                            <option value="FCFA">Select</option>
                            <option value="fcfa">FCFA</option>
                            <option value="usd">USD</option>
                          </select>
                        </Box>
                        {currency == 'fcfa' ? (
                          <Box>
                            <Box>
                              <FormLabel className={classes.label}>
                                <Typography variant="h6">
                                  Amount in FCFA
                                </Typography>
                              </FormLabel>
                              <BootstrapInput
                                value={localAmount}
                                onChange={handleLocalAmountChange}
                                id="amount"
                                name="number"
                                type="number"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                variant="outlined"
                                placeholder="enter amount"
                                size="small"
                                required
                              />
                            </Box>
                            <Box>
                              {' '}
                              <InputLabel
                                shrink
                                htmlFor="bootstrap-input-item"
                                style={{
                                  fontWeight: 700,
                                  fontSize: 20,
                                  marginTop: 10,
                                  color: '#3e4040'
                                }}
                              >
                                <Typography variant="p">
                                  Equivalent to send in FCFA
                                </Typography>
                              </InputLabel>
                              <BootstrapInput
                                value={localCharges}
                                name="charges"
                                type="number"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                variant="outlined"
                                placeholder="FCFA equivalent"
                                size="small"
                                disabled
                              />
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box>
                              <FormLabel className={classes.label}>
                                <Typography variant="h6">
                                  Amount in FCFA
                                </Typography>
                              </FormLabel>
                              <BootstrapInput
                                value={intlAmount}
                                onChange={handleIntlAmountChange}
                                id="amount"
                                name="number"
                                type="number"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                variant="outlined"
                                placeholder="enter amount"
                                size="small"
                                required
                              />
                            </Box>
                            <Box>
                              {' '}
                              <InputLabel
                                shrink
                                htmlFor="bootstrap-input-item"
                                style={{
                                  fontWeight: 700,
                                  fontSize: 20,
                                  marginTop: 10,
                                  color: '#3e4040'
                                }}
                              >
                                <Typography variant="p">
                                  Equivalent to send in USD
                                </Typography>
                              </InputLabel>
                              <BootstrapInput
                                value={intlCharges}
                                name="charges"
                                type="number"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                variant="outlined"
                                placeholder="Dollar equivalent"
                                size="small"
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </DialogContent>
                    <DialogActions
                      style={{
                        borderTop: '1px solid rgb(229 231 243)',
                        padding: 10
                      }}
                    >
                      <Button
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                        size="small"
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                        className={classes.button}
                        onClick={handleTopup}
                      >
                        {isLoading ? <CircularProgress size="14px" /> : 'Add'}
                      </Button>
                    </DialogActions>
                  </Box>
                ) : (
                  <>
                    <Box
                      style={{
                        border: '1px solid #ffe5e5',
                        borderRadius: 4,
                        margin: 10,
                        backgroundColor: '#fff4f4',
                        padding: 5,
                        width: details?.currency == 'fcfa' ? 560 : 480
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontFamily: 'Ubuntu',
                          fontSize: 15
                        }}
                      >
                        Note
                      </Typography>
                      <Typography
                        style={{ fontFamily: 'Ubuntu', fontSize: 15 }}
                      >
                        We use the <strong>Reference Code</strong> to trace each
                        transaction. Please ensure to include the reference code
                        when making the transfer. If we do not receive the
                        amount mentioned below, your account will be credited
                        with respect to the amount received.
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        borderRadius: 4,
                        margin: 10,
                        padding: 5,
                        width: 450
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontFamily: 'Ubuntu',
                          fontSize: 15
                        }}
                      >
                        Contact your bank
                      </Typography>
                      <Typography
                        style={{ fontFamily: 'Ubuntu', fontSize: 15 }}
                      >
                        Initiate a wire transfer with your bank using the
                        information below.
                      </Typography>
                    </Box>
                    {details?.currency == 'fcfa' ? (
                      <DialogContent style={{ width: 600, height: 380 }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} lg={4} xl={4}>
                            <Typography className={classes.textTwo}>
                              {'Amount'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'Account number'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'Beneficiary name'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'Beneficiary address'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'SWIFT code'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'Bank name'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'Bank code'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'Branch code'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'RIB'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'IBAN'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'Bank address'}
                            </Typography>
                            <Typography className={classes.textTwo}>
                              {'Reference code'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={8} xl={8}>
                            <Typography className={classes.secondText}>
                              {formatAmount(details?.amount)} FCFA
                            </Typography>
                            <Typography className={classes.secondText}>
                              {details?.accountNumber}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {details?.accountName}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {'Molyko Buea, SWR'}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {details?.swiftCode}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {details.bankName}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {details?.bankCode}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {details?.branchCode}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {details?.rib}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {details?.iban}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {'Limbe, SWR'}
                            </Typography>
                            <Typography className={classes.secondText}>
                              {details?.referenceCode}
                            </Typography>
                          </Grid>
                        </Grid>
                        <DialogActions
                          style={{
                            padding: 10
                          }}
                        >
                          <Button
                            onClick={handleBack}
                            color="primary"
                            variant="outlined"
                            size="small"
                            className={classes.button}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="small"
                            className={classes.button}
                            onClick={handleClose}
                          >
                            {isLoading ? (
                              <CircularProgress size="14px" />
                            ) : (
                              'Done'
                            )}
                          </Button>
                        </DialogActions>
                      </DialogContent>
                    ) : (
                      <DialogContent style={{ width: 500, height: 460 }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} lg={4} xl={4}>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.textTwo}>
                                {'Amount'}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.textTwo}>
                                {'Account number'}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.textTwo}>
                                {'Account type'}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography>{'Beneficiary name'}</Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.textTwo}>
                                {'Beneficiary address'}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography>{'SWIFT code'}</Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.textTwo}>
                                {'Routing number'}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.textTwo}>
                                {'Bank name'}
                              </Typography>
                            </Box>
                            <Box className={classes.textBox}>
                              <Typography className={classes.textTwo}>
                                {'Bank address'}
                              </Typography>
                            </Box>
                            <Typography className={classes.textTwo}>
                              {'Reference code'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={8} xl={8}>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                ${convertToIntl(details?.amount)}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.accountNumber}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.accountType}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.beneficiaryName}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.beneficiaryAddress}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.swift_bic_code}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.routingNumber}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.branchCode}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.rib}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.iban}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.bankName}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.bankAddress}
                              </Typography>
                            </Box>
                            <Box className={classes.firstBox}>
                              <Typography className={classes.secondText}>
                                {details?.referenceCode}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <DialogActions
                          style={{
                            borderTop: '1px solid rgb(229 231 243)',
                            padding: 10
                          }}
                        >
                          <Button
                            onClick={handleBack}
                            color="primary"
                            variant="outlined"
                            size="small"
                            className={classes.button}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="small"
                            className={classes.button}
                            onClick={handleClose}
                          >
                            {isLoading ? (
                              <CircularProgress size="14px" />
                            ) : (
                              'Done'
                            )}
                          </Button>
                        </DialogActions>
                      </DialogContent>
                    )}
                  </>
                )}
              </Dialog>
            </Box>
            <TableData />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default TopupsView;
