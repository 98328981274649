import React, { useEffect } from 'react';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import Page from 'src/components/Page';
import texts from 'src/assets/texts';
import theme from 'src/assets/theme';
import { apiConfig } from 'src/utils/apiConfig';
import useEndpoint from 'src/utils/useEndpoint';
import { capitalize } from 'src/utils/helperFunctions';
import BookingsTable from './BookingsTable';
import BackIcon from 'src/components/Buttons/BackIcon';
import LocalLoader from 'src/components/LocalLoader';
import Loader from 'src/components/Loader';
import ExportTableBtn from 'src/components/Buttons/ExportTable';

const { colors } = theme;

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40
  },
  table: {
    marginTop: '1rem'
  },
  sectionTitle: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontWeight: 'bold',
    fontSize: 18
  },
  exportBtn: {
    borderRadius: '4px',
    border: 'none',
    padding: '0.3rem 0.8rem',
    cursor: 'pointer',
    backgroundColor: colors.primaryColor,
    textTransform: 'none',
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primaryDark
    }
  }
});

const Bookings = () => {
  const classes = useStyles();
  const {
    state: { data }
  } = useLocation();
  const { eventId, title } = data;
  const { data: bookings, loading, getRequest, modifyData } = useEndpoint();

  // Use custom endpoint hook to get all bookings
  const getBookings = () => {
    modifyData({
      url: `${apiConfig.eventTransUrl}/${eventId}`,
      requestMethod: 'get',
      action: texts.fetching,
      errMsg: texts.unabletofetchdetails
    });
  };

  useEffect(() => {
    getBookings();
  }, []);

  return (
    <Page title="Events" style={{ marginTop: 0 }}>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item lg={1}></Grid>
          <Grid items xs={12} sm={12} lg={10}>
            <Box
              className={classes.box}
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'flex-start', sm: 'center' }
              }}
            >
              <BackIcon path={'./..'} text={'Back to Events'} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: { xs: '100%', sm: '65%', lg: '60%' }
                }}
              >
                {title && (
                  <Typography variant="h5" className={classes.sectionTitle}>
                    Bookings for {capitalize(title)}
                  </Typography>
                )}
                {bookings && (
                  <ExportTableBtn
                    id="export-bookings-button"
                    table="bookings-table"
                    filename={title + ' Bookings'}
                    sheet="bookings"
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.table}>
              {bookings && <BookingsTable data={bookings} eventId={eventId} />}
              {loading &&
                (getRequest ? (
                  <LocalLoader
                    text={'Please wait while we fetch the data...'}
                  />
                ) : (
                  <Loader text={loading} />
                ))}

              {loading
                ? null
                : bookings.length === 0 && (
                    <Box
                      sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                    >
                      <Typography variant="p" className={classes.text}>
                        You have not created any events. When you do, they'll
                        appear here.
                      </Typography>
                    </Box>
                  )}
            </Box>
          </Grid>
          <Grid item lg={1}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Bookings;
