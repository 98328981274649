import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
// import Languages from './Languages';
import ProfileDetails from './ProfileDetails';
import Divider from '@material-ui/core/Divider';
import Authentication from './Authentication';
import Password from './Password';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Settings = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Profile">
      <Container maxWidth="lg">
        <Box className={classes.root}>
          <Typography variant="h4" className={classes.introtitle}>
            Profile
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={12} md={6} xs={12}>
            <ProfileDetails />
            <br />
            <Password />
            <Divider style={{ margin: '60px 0px 20px 0px' }} />
            <Authentication />
            <Divider style={{ margin: '20px 0px' }} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Settings;
