import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NoResults from 'src/components/NoResults';
import { Container, DialogActions, Box, Grid, Hidden } from '@material-ui/core';
import Bar from 'src/components/Bar';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { useAuthState } from 'src/context';
import axios from 'src/axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import PaymentsTab from 'src/components/PaymentsTab';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from 'src/components/Snackbar';
import { Repeat as TransferIcon } from 'react-feather';
import InputLabel from '@mui/material/InputLabel';
import { capitalize, formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50%',
      textAlign: 'center',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
    },
    width: '100%',
    border: '1px  solid #e0e0e03d'
    // backgroundColor: '#fdfdff'
  },
  label: {
    marginLeft: 8
  },
  button: {
    textTransform: 'none',
    display: 'block',
    fontSize: 14,
    height: 30,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    '&:hover': {
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  },
  box: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
  },
  showBox: {
    width: 400,
    overflowX: 'hidden'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    width: 40,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '10px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#768095'
    }
  }
}));

const TransferListView = () => {
  const [openI, setOpen] = React.useState(false);
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { token } = useAuthState();
  const [progressDisplay, setProgressDisplay] = useState(false);
  const [details, setDetails] = useState({});
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setError(false);
  };
  const [show, setShow] = React.useState(false);
  const handleShow = data => {
    setShow(true);
    setDetails(data);
  };
  const handleShowClose = () => {
    setShow(false);
  };
  const [charges, setCharges] = useState(0);
  const [amount, setAmount] = useState(0);
  const handleAmountChange = ({ target }) => {
    const { value } = target;
    setAmount(value);
    const charge = Math.ceil(value * 0.005);
    setCharges(charge);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      pin: '',
      note: ''
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      const dataValues = {
        ...values,
        amount: parseInt(amount)
      };
      axios({
        method: 'post',
        url: '/transfer',
        data: dataValues,
        headers: { 'x-access-token': token }
      })
        .then(() => {
          setSnack(true);
          setIsLoading(false);
          setOpen(false);
          resetForm({ values: '' });
        })
        .catch(err => {
          setIsLoading(false);
          setError(true);
          setEresponse(err.response?.data.message);
        });
    }
  });

  const [transfers, setTransfers] = useState([]);

  useEffect(() => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: '/transfer',
      headers: { 'x-access-token': token }
    })
      .then(response => {
        setProgressDisplay(false);
        const data = response.data;
        setTransfers(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  const handleSnackClose = () => {
    setSnack(false);
  };
  return (
    <Page title="Transfers">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="transfers" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={9} xl={10}>
            <Bar
              title="Transfers"
              btnText="Make a transfer"
              onClick={handleClickOpen}
            />
            <Snackbar
              data="Transfer completed successfully"
              close={handleSnackClose}
              open={snack}
            />
            <Box
              style={{
                boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
              }}
            >
              <Dialog
                BackdropProps={{
                  classes: {
                    root: classes.backDrop
                  }
                }}
                open={openI}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className={classes.box}
              >
                <form
                  // style={{ textAlign: 'center' }}
                  className={classes.root}
                  onSubmit={formik.handleSubmit}
                >
                  <DialogTitle
                    id="form-dialog-title"
                    style={{
                      borderBottom: '1px solid rgb(229 231 243)',
                      padding: 10
                    }}
                  >
                    <Typography style={{}} variant="h6">
                      Send money with{' '}
                      <span style={{ color: '#0e0e0e' }}>#Fapshi</span>
                    </Typography>
                  </DialogTitle>

                  {error && (
                    <Typography
                      variant="p"
                      style={{
                        color: '#eb6558',
                        fontWeight: 500,
                        fontSize: 14,
                        marginLeft: 10
                      }}
                    >
                      {eresponse}
                    </Typography>
                  )}
                  <DialogContent>
                    <FormControl>
                      <FormGroup>
                        <Box>
                          <FormLabel className={classes.label}>
                            <Typography variant="h6">Email</Typography>
                          </FormLabel>
                          <BootstrapInput
                            error={Boolean(
                              formik.touched.email && formik.errors.email
                            )}
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            name="email"
                            id="email"
                            type="email"
                            InputLabelProps={{
                              shrink: true
                            }}
                            variant="outlined"
                            placeholder="email"
                            size="small"
                            required
                          />
                        </Box>
                        <Box>
                          <Box>
                            <FormLabel className={classes.label}>
                              <Typography variant="h6">Pin</Typography>
                            </FormLabel>
                            <BootstrapInput
                              error={Boolean(
                                formik.touched.pin && formik.errors.pin
                              )}
                              helperText={
                                formik.touched.pin && formik.errors.pin
                              }
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.pin}
                              id="pin"
                              type="password"
                              InputLabelProps={{
                                shrink: true
                              }}
                              variant="outlined"
                              placeholder="enter pin"
                              size="small"
                              min={0}
                              max={5}
                              style={{ marginRight: 5 }}
                              required
                            />
                          </Box>
                          <Box>
                            <FormLabel className={classes.label}>
                              <Typography variant="h6">Amount</Typography>
                            </FormLabel>
                            <BootstrapInput
                              error={Boolean(
                                formik.touched.amount && formik.errors.amount
                              )}
                              helperText={
                                formik.touched.amount && formik.errors.amount
                              }
                              onBlur={formik.handleBlur}
                              onChange={handleAmountChange}
                              value={amount}
                              id="amount"
                              type="number"
                              InputLabelProps={{
                                shrink: true
                              }}
                              variant="outlined"
                              placeholder="enter amount"
                              size="small"
                              required
                              min={100}
                            />
                          </Box>
                          <Box>
                            {' '}
                            <InputLabel
                              shrink
                              htmlFor="bootstrap-input-item"
                              style={{
                                fontWeight: 700,
                                fontSize: 20,
                                marginTop: 10,
                                color: '#3e4040'
                              }}
                            >
                              <Typography variant="p">Charges</Typography>
                            </InputLabel>
                            <BootstrapInput
                              name="item"
                              id="item"
                              type="number"
                              InputLabelProps={{
                                shrink: true
                              }}
                              variant="outlined"
                              placeholder="Charges"
                              size="small"
                              value={charges}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <FormLabel className={classes.label}>
                            <Typography variant="h6">Note</Typography>
                          </FormLabel>
                          <BootstrapInput
                            error={Boolean(
                              formik.touched.amount && formik.errors.amount
                            )}
                            helperText={
                              formik.touched.amount && formik.errors.amount
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.note}
                            id="note"
                            type="text"
                            InputLabelProps={{
                              shrink: true
                            }}
                            variant="outlined"
                            placeholder="Reason for transfer"
                            size="small"
                            multiline
                            rows={1}
                            fullWidth
                            required
                          />
                        </Box>
                      </FormGroup>
                    </FormControl>
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: '1px solid rgb(229 231 243)',
                      padding: 10
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                      size="small"
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      className={classes.button}
                    >
                      {isLoading ? <CircularProgress size="14px" /> : 'Send'}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Box>
            <Box
              style={{
                display: progressDisplay ? 'block' : 'none'
              }}
            >
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: theme => theme.zIndex.drawer + 1,
                  backdropFilter: 'blur(2px)',
                  backgroundColor: '#ffffff59'
                }}
                open={open}
              >
                <CircularProgress
                  size="1.5rem"
                  style={{
                    display: progressDisplay ? 'block' : 'none',
                    marginLeft: '20%',
                    marginTop: '15%',
                    marginBottom: '10%',
                    color: '#3f51b5'
                  }}
                />
              </Backdrop>
            </Box>
            <List
              style={{
                display: progressDisplay ? 'none' : 'block'
              }}
              className={classes.root}
            >
              {transfers.length != 0 ? (
                transfers &&
                transfers.map(transfer =>
                  transfer.type === 'receive' ? (
                    <React.Fragment>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon>
                          <AddIcon style={{ color: 'Blue' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              {transfer.senderEmail}
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                className={classes.inline}
                                color="textPrimary"
                                variant="p"
                              >
                                {transfer.date}
                              </Typography>
                              <Typography variant="h6">
                                {` — You received ${formatAmount(
                                  transfer.amount
                                )} FCFA from ${capitalize(
                                  transfer.senderName
                                )}`}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <IconButton
                          className={classes.iconButton}
                          style={{
                            height: 20
                          }}
                          onClick={() => handleShow(transfer)}
                        >
                          <MoreHorizIcon
                            style={{ fontSize: 22, cursor: 'pointer' }}
                          />
                        </IconButton>
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon>
                          <RemoveIcon style={{ color: 'red' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              {transfer.receiverEmail}
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                className={classes.inline}
                                color="textPrimary"
                                variant="p"
                              >
                                {transfer.date}
                              </Typography>
                              <Typography variant="h6">
                                {` — You sent ${formatAmount(
                                  transfer.amount
                                )} FCFA to ${capitalize(
                                  transfer.receiverName
                                )}`}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <IconButton
                          className={classes.iconButton}
                          style={{
                            height: 20
                          }}
                          onClick={() => handleShow(transfer)}
                        >
                          <MoreHorizIcon
                            style={{
                              fontSize: 22,
                              cursor: 'pointer'
                            }}
                          />
                        </IconButton>{' '}
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  )
                )
              ) : (
                <NoResults
                  title="transfers made"
                  content="A transfer occurs when you send money (funds) from your fapshi account, to other Fapshi accounts via fapshi."
                  link="https://documentation.fapshi.com/#payment-links"
                  icon={
                    <TransferIcon
                      size={35}
                      style={{
                        fontSize: 40,
                        border: '1px solid #bdc3c9',
                        boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                        borderRadius: 10,
                        color: '#bdc3c9',
                        padding: 5
                      }}
                    />
                  }
                />
              )}
            </List>
          </Grid>
        </Grid>
        <Box
          style={{
            boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
          }}
        >
          <Dialog
            BackdropProps={{
              classes: {
                root: classes.backDrop
              }
            }}
            open={show}
            onClose={handleShowClose}
            aria-labelledby="form-dialog-title"
            className={classes.box}
          >
            <Box className={classes.showBox}>
              <DialogTitle
                id="form-dialog-title"
                style={{
                  borderBottom: '1px solid rgb(229 231 243)',
                  padding: 10
                }}
              >
                <Typography
                  style={{ fontWeight: 'bold', fontSize: 18 }}
                  variant="h6"
                >
                  Transaction details
                </Typography>
              </DialogTitle>
              <DialogContent>
                {details.type === 'receive' ? (
                  <>
                    <Typography variant="p">
                      {` — You received ${formatAmount(
                        details.amount
                      )} FCFA from ${capitalize(details.senderName)} (${
                        details.senderEmail
                      }) at ${details.date}`}
                    </Typography>
                    <br />
                    <br />
                    {details.note && (
                      <Typography variant="p">
                        {` — Note: ${details.note}`}
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    <Typography variant="p">
                      {` — You sent ${formatAmount(
                        details.amount
                      )} FCFA to ${capitalize(details.receiverName)} (${
                        details.receiverEmail
                      }) at ${details.date}`}
                    </Typography>
                    <br />
                    <br />
                    {details.note && (
                      <Typography variant="p">
                        {` — Note: ${details.note}`}
                      </Typography>
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions
                style={{
                  borderTop: '1px solid rgb(229 231 243)',
                  padding: 10
                }}
              >
                <Button
                  onClick={handleShowClose}
                  color="primary"
                  variant="outlined"
                  size="small"
                  className={classes.button}
                >
                  Close
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
};

export default TransferListView;
