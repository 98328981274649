// Get vendor revenue from amount user pays
export function getRevenue(p) {
  if (isNaN(p)) return p;
  if (p <= 0) return 0;
  p = parseInt(p);
  return Math.floor(0.97 * p);
}

// Get user price from vendor revenue
export function getPrice(r) {
  if (isNaN(r)) return r;
  if (r <= 0) return 0;
  r = parseInt(r);
  return Math.ceil((100 * r) / 97);
}
