import React from 'react';
import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import Directions from './InvoiceDirections';
import ProductDirections from './ProductDirections';
import LinkDirections from './LinkDirections';
import { useAuthState } from 'src/context';
import DonationDirections from './DonationDirections';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    height: 300,
    [theme.breakpoints.up('mdUp')]: {
      paddingBottom: theme.spacing(40),
      marginTop: theme.spacing(0)
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      paddingBottom: theme.spacing(0),
      marginTop: theme.spacing(13)
    },
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(4),
    minHeight: '100%',
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(5),
    border: '1px dashed rgb(248 250 253)',
    width: '100%'
  },
  roots: {
    backgroundColor: '#ffffff',
    height: 300,
    [theme.breakpoints.up('mdUp')]: {
      paddingBottom: theme.spacing(40),
      marginTop: theme.spacing(0)
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      paddingBottom: theme.spacing(0),
      marginTop: theme.spacing(13)
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    minHeight: '100%',
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(5),
    border: '1px dashed rgb(248 250 253)',
    width: '100%'
  },
  typo: {
    marginBottom: 20,
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      lineHeight: 1,
      fontSize: 22
    }
  }
}));

const DirectionsIndex = () => {
  const classes = useStyles();
  const { userDetails } = useAuthState();
  return (
    <>
      {userDetails.isVendor ? (
        <Page className={classes.roots}>
          <Container maxWidth={false}>
            <Typography variant="h4" className={classes.typo}>
              Quick ways to get started
            </Typography>
            <Grid container spacing={5}>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Directions />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <ProductDirections />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <LinkDirections />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <DonationDirections />
              </Grid>
            </Grid>
          </Container>
        </Page>
      ) : (
        <Page className={classes.root} title="Dashboard">
          <Container maxWidth={false}>
            <Typography variant="h4" className={classes.typo}>
              Quick ways to get started
            </Typography>
            <Grid container spacing={5}>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Directions />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <ProductDirections />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <LinkDirections />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <DonationDirections />
              </Grid>
            </Grid>
          </Container>
        </Page>
      )}
    </>
  );
};

export default DirectionsIndex;
