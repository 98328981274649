import { useContext } from 'react';
import { RatesContext } from '../context/RatesContext';

const isMTNNumber = number => {
  if (!number) return false;
  return /^[6]([7|8][\d]{7}|[5][0-4][\d]{6})$/.test(number);
};

const isOrangeNumber = number => {
  if (!number) return false;
  return /^[6]([9][\d]{7}|[5][5-9][\d]{6})$/.test(number);
};

// Return true if an object is empty
const isEmptyObject = obj => {
  if (!obj) return true;
  return JSON.stringify(obj) === '{}';
};

// Capitalize the first letter in a word
const ucFirst = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

// Capitalize each word in a string
const capitalize = text => {
  if (!text) {
    return false;
  } else {
    return text
      .toLowerCase()
      .replace(/(^|\s)[A-Za-zÀ-ÖØ-öø-ÿ]/g, c => c.toUpperCase());
  }
};

const formatAmount = amount => {
  if (amount == 0) {
    return '0';
  } else if (!amount) {
    return false;
  } else {
    return amount.toLocaleString();
  }
};

const CalculateRevenue = amount => {
  const { donationRate } = useContext(RatesContext);
  let rate = donationRate ? donationRate : 3;
  let revenue = 0;
  let charges = 0;

  if (amount <= 0) {
    console.log('Your amount is invalid!');
  } else {
    charges = (amount * parseFloat(rate)) / 100;
  }

  revenue = parseInt(amount) - charges;

  return [Math.floor(revenue), Math.ceil(charges)];
};

const isValidEmail = value => {
  const regx = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;
  return regx.test(value);
};

export {
  isMTNNumber,
  isOrangeNumber,
  isEmptyObject,
  ucFirst,
  capitalize,
  formatAmount,
  CalculateRevenue,
  isValidEmail
};
