import React, { useState } from 'react';
import Page from 'src/components/Page';
import {
  Container,
  Grid,
  Hidden,
  makeStyles,
  Box,
  Button,
  Typography
} from '@material-ui/core';
import ExportBar from 'src/components/ExportBar';
import PaymentsTab from 'src/components/BalanceTab';
import axios from 'axios';
import { useAuthState } from 'src/context';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { Upload, message, Button as B } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@ant-design/compatible';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
  mainbox: {
    marginBottom: 10,
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  button: {
    textTransform: 'none',
    borderRadius: 10,
    fontSize: 16,
    margin: 2,
    height: 35
  },
  buttons: {
    marginTop: 10,
    textTransform: 'none',
    fontSize: 18,
    float: 'right',
    borderRadius: 8
  },
  supportBox: {
    width: 350,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  displays: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  heading: {
    fontWeight: 700,
    fontFamily: 'Ubuntu',
    fontSize: 16
  },
  text: {
    fontFamily: 'Ubuntu',
    fontSize: 16
  },
  contentBox: {
    width: 500,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const SingleDisputeView = () => {
  const classes = useStyles();
  const { token } = useAuthState();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const response = location.state;

  console.log(response);
  const [values, setValues] = React.useState({
    phone: null,
    justification: null
  });

  const handleSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/justify-dispute/' + response._id,
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setValues(res.data);
        setIsLoading(false);
        navigate('/app/disputes', {
          replace: 'true'
        });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };

  const propOne = {
    name: 'file',
    action: 'https://api.fapshi.com/support-docs/' + response._id,
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  return (
    <Page title="Disputes">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="disputes" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={8} xl={9}>
            <ExportBar title="Disputes" />
            <Box className={classes.flexBox}>
              <Box className={classes.contentBox}>
                <div style={{ marginBottom: 20 }}>
                  <Typography variant="h5">Transaction details</Typography>
                </div>
                <Box className={classes.displays}>
                  <div style={{ marginRight: 10 }}>
                    <Typography className={classes.heading}>
                      {'Code'}
                    </Typography>
                  </div>
                  <Typography className={classes.text}>
                    {response?.transferId}
                  </Typography>
                </Box>
                <br />
                <Box className={classes.displays}>
                  <div style={{ marginRight: 10 }}>
                    <Typography className={classes.heading}>
                      {'Type'}
                    </Typography>
                  </div>
                  <Typography className={classes.text}>
                    {response?.transType}
                  </Typography>
                </Box>
                <br />
                <Box className={classes.displays}>
                  <div style={{ marginRight: 10 }}>
                    <Typography className={classes.heading}>
                      {'Status'}
                    </Typography>
                  </div>
                  <Typography className={classes.text}>
                    {response?.status}
                  </Typography>
                </Box>
                <div style={{ marginBottom: 20, marginTop: 30 }}>
                  <Typography variant="h5">Dispute details</Typography>
                </div>
                <Box>
                  <div style={{ marginRight: 10 }}>
                    <Typography className={classes.heading}>
                      {'Subject'}
                    </Typography>
                  </div>
                  <Typography className={classes.text}>
                    {response?.subject}
                  </Typography>
                </Box>
                <br />
                <Box>
                  <div style={{ marginRight: 10 }}>
                    <Typography className={classes.heading}>
                      {'Message'}
                    </Typography>
                  </div>
                  <Typography className={classes.text}>
                    {response?.description}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.supportBox}>
                {error && (
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      color: '#eb6558',
                      fontWeight: 500,
                      fontSize: 20,
                      marginTop: 7
                    }}
                  >
                    <Typography variant="p"> {eresponse}</Typography>
                  </InputLabel>
                )}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-phone"
                  style={{
                    marginTop: 7,
                    fontWeight: 700,
                    fontSize: 20,
                    letterSpacing: 0.5
                  }}
                >
                  <Typography variant="p">Contact number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter a phone number we can call"
                  margin="normal"
                  name="phone"
                  type="text"
                  value={values.phone}
                  onChange={handleChange}
                  id="bootstrap-input-name"
                  required
                  style={{ marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-justification"
                  style={{
                    marginTop: 7,
                    fontWeight: 700,
                    fontSize: 20,
                    letterSpacing: 0.5
                  }}
                >
                  <Typography variant="p">Justification</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Justify with detailed explanation"
                  margin="normal"
                  name="justification"
                  type="text"
                  value={values.justification}
                  onChange={handleChange}
                  id="bootstrap-input-justification"
                  required
                  style={{ marginRight: 8 }}
                  multiline
                  rows={4}
                />
                <Box style={{ marginTop: 10 }}>
                  <Typography
                    style={{
                      marginTop: 12,
                      fontWeight: 700,
                      fontSize: 15,
                      letterSpacing: 0.5,
                      color: '#00000099'
                    }}
                    variant="p"
                  >
                    Support document
                  </Typography>
                  <br />
                  <small
                    style={{
                      fontWeight: 400,
                      fontSize: 14
                    }}
                  >
                    Upload any legal document to support your justification.
                    This could be a receipt or anything.
                  </small>
                  <br />
                  <br />
                  <Upload {...propOne}>
                    <B
                      style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}
                    >
                      <Icon type="upload" /> Upload support document
                    </B>
                  </Upload>
                  <Button
                    className={classes.buttons}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    size="small"
                  >
                    {isLoading ? <CircularProgress size="14px" /> : 'Submit'}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SingleDisputeView;
