import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Page from 'src/components/Page';
import { Container, Typography, Grid, Button, Hidden } from '@material-ui/core';
import Bar from 'src/components/Bar';
import DataTable from './Data';
import Popover from '@material-ui/core/Popover';
import { useNavigate } from 'react-router-dom';
import PaymentsTab from 'src/components/PaymentsTab';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  introtitle: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 22,
    fontWeight: 600,
    color: '#1a1f36'
  },

  inputfield: {
    width: '100%'
  },
  typography: {
    padding: theme.spacing(2)
  },
  addButton: {
    width: '100%',
    display: 'block',
    textAlign: 'left',
    paddingRight: theme.spacing(4),
    fontWeight: '400',
    textTransform: 'lowercase'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    textAlign: 'left',
    overflowY: 'hidden'
  },
  btext: {
    color: 'blue',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  buttonText: {
    fontSize: 14
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    width: 40,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  }
}));

const Invoices = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const navigate = useNavigate();

  const handleSimpleInvoiceClick = () => {
    navigate('/app/invoices/create/simple', { replace: true });
  };

  const handleAdvancedInvoiceClick = () => {
    navigate('/app/invoices/create/advanced', { replace: true });
  };

  return (
    <Page title="Invoicing">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="invoices" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={9} xl={10}>
            <Bar
              title="Invoices"
              btnText="Create an Invoice"
              onClick={handleClick}
              icon={true}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              style={{ marginTop: 4 }}
            >
              <Button
                onClick={handleSimpleInvoiceClick}
                fullWidth
                style={{ borderBottom: '1px solid #f4f4f5' }}
                className={classes.button}
              >
                <Typography className={classes.buttonText} variant="p">
                  {' '}
                  Simple Invoice
                </Typography>
              </Button>
              <Button
                onClick={handleAdvancedInvoiceClick}
                fullWidth
                className={classes.button}
              >
                <Typography className={classes.buttonText} variant="p">
                  {' '}
                  Advanced Invoice
                </Typography>
              </Button>
            </Popover>
            <DataTable />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Invoices;
