import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography, Button } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'src/context';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '60%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 12
  },
  text: {
    fontSize: 18,
    color: '#3e4040',
    fontWeight: 400
  },
  mainText: {
    fontSize: 18,
    color: '#3f51b5',
    fontWeight: 400
  },
  buttons: {
    marginTop: 10,
    textTransform: 'none',
    fontSize: 18
  },
  headingText: {
    color: '#3e4040',
    fontWeight: 400,
    fontSize: 18
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    fontSize: 16,
    width: '48%',
    height: '12px',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const Confirm = ({ id }) => {
  const classes = useStyles();
  const { token } = useAuthState();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pincode, setPincode] = useState(null);

  const handleSubmit = () => {
    console.log(id);
    setIsLoading(true);
    const values = {
      pin: pincode,
      bulkId: id
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/process-bulk',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setIsLoading(false);
        setPincode(null);
        navigate('/app/bulk-payments', {
          replace: 'true'
        });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setPincode(null);
        setEresponse(err.response?.data.message);
      });
  };

  return (
    <Box className={classes.root} sx={{ flexGrow: 1 }}>
      <Typography
        style={{
          color: '#3e4040',
          fontSize: 20,
          fontWeight: 700,
          fontFamily: 'Ubuntu',
          marginBottom: 20
        }}
      >
        Payment details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <Box className={classes.mainBox}>
            <Box className={classes.box}>
              <Typography className={classes.text} variant="p">
                Upload CSV
              </Typography>
            </Box>
          </Box>
          <Box className={classes.mainBox}>
            <Box className={classes.box}>
              <Typography className={classes.text} variant="p">
                Review details
              </Typography>
            </Box>
          </Box>
          <Box className={classes.mainBox}>
            <Box className={classes.box}>
              <Typography className={classes.mainText} variant="p">
                Confirm payment
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          style={{
            borderRadius: 4,
            padding: 25
          }}
          item
          xs={12}
          sm={12}
          lg={5}
          xl={5}
        >
          <Box>
            <Typography
              variant="h3"
              style={{
                marginTop: -5,
                color: '#3e4040'
              }}
            >
              Confirm payment
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box>
              <Typography variant="p">
                Funds will be deducted from your account balance on
                confirmation. If you have not set your pin, navigate to your{' '}
                <a href="/app/dashboard">dashboard</a> to set it.
              </Typography>
              <br />
              <br />

              {error && (
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    color: '#eb6558',
                    fontWeight: 700,
                    fontSize: 20,
                    marginTop: 7
                  }}
                >
                  <Typography variant="p"> {eresponse}</Typography>
                </InputLabel>
              )}
              <InputLabel
                shrink
                htmlFor="bootstrap-input-pincode"
                style={{
                  marginTop: 7,
                  color: '#3e4040',
                  fontWeight: 700,
                  fontSize: 20
                }}
              >
                <Typography variant="p">Enter your pin code</Typography>
              </InputLabel>
              <BootstrapInput
                fullWidth
                placeholder="Pin code"
                margin="normal"
                type="password"
                name="pincode"
                value={pincode}
                id="bootstrap-input-pincode"
                onChange={e => setPincode(e.target.value)}
                required
                style={{ marginRight: 8 }}
              />

              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIcon>send</ArrowForwardIcon>}
                  type="submit"
                  onClick={handleSubmit}
                  size="small"
                >
                  {isLoading ? (
                    <CircularProgress size="14px" />
                  ) : (
                    'Confirm payment'
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Confirm;
