import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import { useAuthState } from 'src/context';
import NoResults from 'src/components/NoResults';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import { FaFileDownload } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { CreditCard as PaymentIcon } from 'react-feather';
import ExportTableBtn from 'src/components/Buttons/ExportTable';
import { capitalize, formatAmount, ucFirst } from 'src/utils/helperFunctions';
import moment from 'moment';
import useEndpoint from 'src/utils/useEndpoint';
import { apiConfig } from 'src/utils/apiConfig';

const headCells = [
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Transaction ID'
  },
  {
    id: 'payer',
    numeric: true,
    disablePadding: false,
    label: 'Name'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount (FCFA)'
  },
  {
    id: 'revenue',
    numeric: true,
    disablePadding: false,
    label: 'Revenue (FCFA)'
  },

  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status'
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date'
  },
  {
    id: 'method',
    numeric: true,
    disablePadding: false,
    label: 'Method'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant="p" style={{ fontSize: 16 }}>
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default function PopupTable({ data }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  // const [dense, setDense] = React.useState(true);
  const [progressDisplay, setProgressDisplay] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = details.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };
  const [details, setDetails] = useState([]);
  const { token } = useAuthState();

  useEffect(() => {
    getProduct();
    getSuccessfulPayments();
  }, []);

  const getProduct = () => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/donationTrans/' + data.donationId,
      headers: { 'x-access-token': token }
    }).then(response => {
      setDetails(response.data);
      setProgressDisplay(false);
    });
  };

  const { data: successfulPayments, modifyData } = useEndpoint();

  // Use custom endpoint hook to delete event
  const getSuccessfulPayments = () => {
    modifyData({
      url: `${apiConfig.donationUrl}/${data.donationId}/?status=successful`,
      requestMethod: 'get'
    });
  };

  // const navigate = useNavigate();

  // const handleDownloads = () => {
  //   navigate('/payment-link/details/download', {
  //     replace: false,
  //     state: { data: details, organisation: data.organisation }
  //   });
  // };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          style={{
            display: progressDisplay ? 'block' : 'none'
          }}
        >
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: theme => theme.zIndex.drawer + 20,
              backdropFilter: 'blur(2px)',
              backgroundColor: '#ffffff59'
            }}
            open={open}
          >
            <CircularProgress
              size="1.5rem"
              style={{
                display: progressDisplay ? 'block' : 'none',
                marginLeft: '20%',
                marginTop: '15%',
                marginBottom: '10%',
                color: '#3f51b5'
              }}
            />
          </Backdrop>
        </Box>
        {details.length !== 0 ? (
          <>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Box>
                {data.organisation && (
                  <Typography
                    style={{
                      fontFamily: 'ubuntu',
                      fontWeight: 700,
                      fontSize: 18
                    }}
                  >
                    Payment Details for {data?.organisation}
                  </Typography>
                )}
              </Box>
              <Box>
                <ExportTableBtn
                  id="export-payments-button"
                  table="payments-table"
                  filename={'Payments for ' + data?.organisation}
                  sheet="payments"
                />
                {/* <Button
                  startIcon={<FaFileDownload />}
                  onClick={handleDownloads}
                >
                  Download
                </Button> */}
              </Box>
            </Box>
            <br />
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  id={successfulPayments ? 'all-payments' : 'payments-table'}
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  // size={dense ? 'small' : 'medium'}
                  size="small"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={details.length}
                  />
                  <TableBody style={{ backgroundColor: 'rgb(247 250 252)' }}>
                    {details.map(row => (
                      <TableRow
                        hover
                        onClick={event => handleClick(event, row.transferId)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.transferId}
                      >
                        <TableCell component="th" scope="row">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {row.transferId}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {capitalize(row.payerName)
                              ? capitalize(row.payerName)
                              : 'Anonymous'}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {row.amount >= 0 && formatAmount(row.amount)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {row.received >= 0 && formatAmount(row.received)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {capitalize(row.status.toLowerCase())}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {moment(row.dateConfirmed).format('DD/MM/YYYY')}
                            {', '}
                            {moment(row.dateConfirmed).format('h:mm:ss A')}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {ucFirst(row.medium)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Table
                  id={successfulPayments ? 'payments-table' : 'no-payments'}
                  sx={{ minWidth: 750, display: 'none' }}
                  aria-labelledby="tableTitle"
                  // size={dense ? 'small' : 'medium'}
                  size="small"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={details.length}
                  />
                  <TableBody style={{ backgroundColor: 'rgb(247 250 252)' }}>
                    {successfulPayments?.map(row => (
                      <TableRow
                        hover
                        onClick={event => handleClick(event, row.transferId)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.transferId}
                      >
                        <TableCell component="th" scope="row">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {row.transferId}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {capitalize(row.payerName)
                              ? capitalize(row.payerName)
                              : 'Anonymous'}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {row.amount >= 0 && formatAmount(row.amount)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {row.received >= 0 && formatAmount(row.received)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {capitalize(row.status.toLowerCase())}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {moment(row.dateConfirmed).format('DD/MM/YYYY')}
                            {', '}
                            {moment(row.dateConfirmed).format('h:mm:ss A')}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="p" style={{ fontSize: 16 }}>
                            {ucFirst(row.medium)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {/* <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={dense}
                  onChange={handleChangeDense}
                />
              }
              label={
                <Typography
                  style={{
                    fontFamily: 'ubuntu',
                    fontWeight: 500,
                    fontSize: 14,
                    color: '#3f51b5'
                  }}
                >
                  Dense padding
                </Typography>
              }
            /> */}
          </>
        ) : (
          <>
            <NoResults
              title="Payments made"
              content="There is no data for this payment link. Please check again after payments."
              link="https://documentation.fapshi.com/#payment-links"
              icon={
                <PaymentIcon
                  size={35}
                  style={{
                    fontSize: 40,
                    border: '1px solid #bdc3c9',
                    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                    borderRadius: 10,
                    color: '#bdc3c9',
                    padding: 5
                  }}
                />
              }
            />
          </>
        )}
      </Box>
    </>
  );
}
