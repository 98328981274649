import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useAuthState } from 'src/context';
import axios from 'src/axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from 'src/components/Snackbar';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ffffff',
    border: '1px solid #858aa70d' //0000ff08
    // boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
  },
  introtitle: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 22,
    fontWeight: 600,
    color: '#1a1f36'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    height: 35,
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    borderRadius: 8
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff8c' //rgba(0,0,30,0.2)
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd'
    }
  }
}));

const ProfileDetails = ({ className, ...rest }) => {
  const { userDetails, token } = useAuthState();
  const { username, email, phone, fullname, country, address } = userDetails;
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const classes = useStyles();
  const [values, setValues] = useState({
    fullname: fullname,
    username: username,
    email: email,
    phone: phone,
    address: address,
    country: country
  });

  const [progressDisplay, setProgressDisplay] = useState(false);
  const [display, setDisplay] = useState(true);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    setDisplay(false);
    setProgressDisplay(true);
    axios({
      method: 'put',
      url: '/user',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(response => {
        const data = response.data;
        setProgressDisplay(false);
        setSnack(true);
        setDisplay(false);
        localStorage.setItem('currentUser', JSON.stringify(data));
        setValues(data);
      })
      .catch(err => {
        setProgressDisplay(false);
        setDisplay(true);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  const handleClose = () => {
    setSnack(false);
  };
  return (
    <>
      <form
        autoComplete="off"
        noValidate
        className={clsx(classes.root, className)}
        {...rest}
      >
        <>
          <CardHeader
            subheader={
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography style={{ color: '#3e4040' }} variant="p">
                  Not all the infos can be edited.
                </Typography>
                <IconButton
                  style={{ border: '1px solid #f4f4f5', marginTop: -20 }}
                  color="primary"
                  size="small"
                  onClick={() => setDisplay(!display)}
                >
                  <EditIcon style={{ fontSize: 20 }} />
                </IconButton>
              </Box>
            }
            title="Personal information"
          />
          <Box style={{ display: display ? 'none' : 'block' }}>
            <CardContent>
              {snack && (
                <Snackbar
                  data="Operation successful"
                  duration={6000}
                  open={snack}
                  close={handleClose}
                />
              )}
              {error && (
                <Typography
                  variant="p"
                  style={{
                    color: '#eb6558',
                    fontWeight: 500,
                    fontSize: 14
                  }}
                >
                  {eresponse}
                </Typography>
              )}
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-username"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    <Typography variant="p">Username</Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    label={<Typography variant="p">Username</Typography>}
                    name="username"
                    onChange={handleChange}
                    value={values.username}
                    variant="outlined"
                    size="small"
                    disabled
                    helperText={
                      <Typography variant="p">
                        This info can't be edited
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-email"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    <Typography variant="p">Email</Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    label={<Typography variant="p">Email address</Typography>}
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    disabled
                    size="small"
                    helperText={
                      <Typography variant="p">
                        This info can't be edited
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-fullname"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    <Typography variant="p">Full names</Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    placeholder="Enter your full names"
                    helperText={
                      <Typography variant="p">Enter your full names</Typography>
                    }
                    label={<Typography variant="p">Name</Typography>}
                    name="fullname"
                    onChange={handleChange}
                    required
                    value={values.fullname}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-phone"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    <Typography variant="p">Phone number</Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    label={<Typography variant="p">Phone number</Typography>}
                    name="phone"
                    onChange={handleChange}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-country"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    <Typography variant="p">Country</Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    label={<Typography variant="p">Country</Typography>}
                    name="country"
                    onChange={handleChange}
                    value={values.country}
                    variant="outlined"
                    size="small"
                    disabled
                    helperText={
                      <Typography variant="p">
                        This info can't be edited
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      marginTop: 7,
                      color: '#3e4040',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    <Typography variant="p">Address</Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    label={<Typography variant="p">Address</Typography>}
                    name="address"
                    onChange={handleChange}
                    required
                    type="text"
                    value={values.address}
                    variant="outlined"
                    size="small"
                    placeholder="Enter your street address"
                  ></BootstrapInput>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                style={{ marginRight: 5 }}
                variant="contained"
                type="reset"
                size="small"
                className={classes.button}
                onClick={() => setDisplay(true)}
              >
                {<Typography variant="p">Cancel</Typography>}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                className={classes.button}
              >
                {progressDisplay ? (
                  <CircularProgress
                    variant="indeterminate"
                    size=".9rem"
                    style={{
                      color: '#ffffff',
                      marginTop: '10%'
                    }}
                  />
                ) : (
                  <Typography variant="p">Save details</Typography>
                )}
              </Button>
            </Box>
          </Box>
        </>
      </form>
    </>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
