import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { useAuthState } from 'src/context';
import {
  Container,
  Typography,
  Divider,
  Box,
  Grid,
  Button,
  Popover,
  Hidden,
  MenuItem,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'src/axios';
import {
  useServiceState,
  useServiceDispatch,
  getUserService
} from 'src/context';
import arrangeNumber from 'src/views/reports/DashboardView/arrangeNum.js';
import { FaArrowUp, FaArrowCircleRight } from 'react-icons/fa';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { alpha, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import PendingTransactions from './PendinTransTable';
import BalanceTab from 'src/components/BalanceTab';
import Snackbar from 'src/components/Snackbar';

const useStyles = makeStyles(theme => ({
  fields: {
    margin: '10px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff8c' //rgba(0,0,30,0.2)
  },
  buttonOne: {
    textTransform: 'none',
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    borderRadius: 10,
    height: 30,
    padding: 10,
    color: 'rgb(26 27 37)',
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 35
  },
  button: {
    float: 'right',
    textTransform: 'none',
    fontSize: 14,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    border: '1px solid #dfdee5',
    height: 30
  },
  addBalance: {
    textTransform: 'none',
    height: 30,
    marginRight: 5,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginTop: 10,
      marginBottom: 10
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      border: '1px dotted #ced4da',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)'
    }
  },
  directions: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 5
    },
    marginLeft: 200
  },
  iconPlus: {
    marginTop: -1,
    marginRight: 3,
    '&:hover': {
      color: '#ced4da'
    }
  },
  transferIcon: {
    marginTop: 2,
    marginLeft: 3,
    color: '#5a5959',
    '&:hover': {
      color: '#ced4da'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '10px',
    padding: '10px 12px',
    marginTop: 5,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#768095'
    }
  }
}));

const BootstrapSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '10px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf',
      borderBottom: 'none'
    }
  }
}));

export const BalancesListView = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorTransferEl, setTransferAnchorEl] = React.useState(null);
  const openI = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const id = openI ? 'simple-popover' : undefined;
  const openTransferI = Boolean(anchorTransferEl);
  // const [openTransfer, setTransferOpen] = React.useState(false);
  const transferId = openTransferI ? 'simple-popover' : undefined;
  const { token } = useAuthState();
  const classes = useStyles();
  const [balance, setbalance] = useState(null);
  const { singleUserService, userService } = useServiceState();
  const [error, setError] = useState(false);
  const [balancerror, setBalanceError] = useState(false);
  const [balanceresponse, setBalanceresponse] = useState(null);
  const [transferError, setTransferError] = useState(false);
  const [transferResponse, setTransferResponse] = useState(null);

  const navigate = useNavigate();
  // const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const [pendingBalance, setPendingBalance] = useState(0);
  const [values, setValues] = React.useState({ service: '' });
  const [serviceId, setServiceId] = useState('');
  const [snack, setSnack] = useState(false);

  useEffect(() => {
    axios({
      method: 'get',
      url: '/getBalance/',
      headers: { 'x-access-token': token }
    })
      .then(response => {
        const data = response.data;
        setbalance(data.balance);
      })
      .catch(error => {
        console.log(error);
      });
  }, [balance]);

  useEffect(() => {
    axios({
      method: 'get',
      url: '/pendingcard/',
      headers: { 'x-access-token': token }
    })
      .then(response => {
        const data = response.data;
        setPendingBalance(data.pendingBalance);
      })
      .catch(error => {
        console.log(error);
      });
  }, [balance]);

  const [amount, setAmount] = React.useState();
  const [serviceAmount, setServiceAmount] = React.useState(null);

  const filteredService = userService.filter(
    item => item.serviceId !== singleUserService?.data?._id
  );

  const handleServiceSwitch = async id => {
    setServiceId(id);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setError(false);
    setOpen(false);
    setAmount('');
    setAnchorEl(null);
  };

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleTransferOpen = e => {
    setTransferAnchorEl(e.currentTarget);
  };

  const handleTransferClose = () => {
    setError(false);
  };

  const handleTransferCloseButton = () => {
    setOpen(false);
    setTransferAnchorEl(null);
    setServiceAmount('');
    setTransferError(false);
  };

  const handleAddClose = () => {
    setError(false);
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
    console.log(userService);
  };

  const verifyAccount = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      data: { password },
      url: 'https://api.fapshi.com/user/password',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setError(false);
        setIsLoading(false);
        navigate('/app/bank-account/add', {
          replace: false,
          state: { pass: password }
        });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const [serviceBalance, setServiceBalance] = useState(
    singleUserService.data?.balance
  );
  const addBalance = async () => {
    setIsBalanceLoading(true);
    const id = singleUserService?.data?._id;
    const content = {
      serviceId: id,
      amount: parseInt(amount)
    };
    axios({
      method: 'post',
      data: content,
      url: 'https://api.fapshi.com/merchCashOut/',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setIsBalanceLoading(false);
        window.location.reload();
      })
      .catch(err => {
        setIsBalanceLoading(false);
        setBalanceError(true);
        setBalanceresponse(err.response?.data.message);
      });
    const serviceResponse = await axios({
      method: 'get',
      url: '/userService/' + singleUserService.data?._id,
      headers: { 'x-access-token': token }
    });
    setServiceBalance(serviceResponse.data.balance);
  };

  const serviceTransfer = async () => {
    setIsTransferLoading(true);
    axios({
      method: 'post',
      data: {
        sender: singleUserService?.data._id,
        receiver: serviceId,
        amount: parseInt(serviceAmount)
      },
      url: 'https://api.fapshi.com/inter-service-transfer',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setTransferError(false);
        setIsLoading(false);
        setSnack(true);
      })
      .catch(err => {
        setIsTransferLoading(false);
        setTransferError(true);
        setTransferResponse(err.response?.data.message);
      });
  };

  const dispatch = useServiceDispatch(event);

  useEffect(async () => {
    const serviceResponse = await axios({
      method: 'get',
      url: '/userService/' + singleUserService.data?._id,
      headers: { 'x-access-token': token }
    });
    setServiceBalance(serviceResponse.data.balance);
    await getUserService(dispatch, serviceResponse);
  }, [serviceBalance]);

  const handleSnackClose = () => {
    setSnack(false);
  };

  return (
    <Page title="Balances" style={{ marginTop: 0 }}>
      <Container maxWidth={false}>
        <br />
        <Box
          style={{
            // boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
            backgroundColor: '#ffffff'
          }}
        >
          <Grid container spacing={3}>
            <Hidden mdDown>
              <Grid item xs={12} sm={12} lg={3} xl={2}>
                <BalanceTab val="balance" />
              </Grid>
            </Hidden>
            <Grid items xs={12} sm={12} lg={7} xl={7}>
              <Box
                className={classes.box}
                style={{
                  // boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
                  backgroundColor: '#ffffff',
                  paddingTop: 40
                }}
              >
                <Typography style={{ marginBottom: 35 }} variant="h4">
                  Balances
                </Typography>
                <Button
                  className={classes.buttonOne}
                  startIcon={<FaArrowUp size="14" color="primary" />}
                  onClick={handleClickOpen}
                >
                  Add a bank account for payouts
                </Button>
              </Box>
              <Box>
                <Dialog
                  BackdropProps={{
                    classes: {
                      root: classes.backDrop
                    }
                  }}
                  open={open}
                  aria-labelledby="form-dialog-title"
                  className={classes.box}
                >
                  <DialogTitle
                    id="form-dialog-title"
                    style={{
                      borderBottom: '1px solid rgb(229 231 243)',
                      padding: 10
                    }}
                  >
                    <Typography
                      style={{
                        color: '#3e4040',
                        fontWeight: 700,
                        fontSize: 20,
                        marginLeft: 10
                      }}
                      variant="h6"
                    >
                      Verification required
                    </Typography>
                  </DialogTitle>

                  <DialogContent>
                    {error && (
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 700,
                          fontSize: 16
                        }}
                      >
                        {eresponse}
                      </Typography>
                    )}
                    <Box style={{ marginBottom: 15 }}>
                      <Typography
                        style={{
                          color: '#3e4040',
                          fontWeight: 400,
                          fontSize: 16
                        }}
                        variant="h6"
                      >
                        To access certain parts of your dashboard, an extra step
                        of Verification is required.
                      </Typography>
                    </Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-password"
                      style={{
                        color: '#3e4040',
                        fontWeight: 700,
                        fontSize: 22
                      }}
                    >
                      <Typography variant="p">Enter your password</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="password"
                      id="password"
                      type="password"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Enter password"
                      size="small"
                      onChange={e => setPassword(e.target.value)}
                      value={password}
                    />
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: '1px solid rgb(229 231 243)',
                      padding: 10
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                      size="small"
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      onClick={verifyAccount}
                      className={classes.button}
                    >
                      {isLoading ? <CircularProgress size="14px" /> : 'Verify'}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
              {/* <Typography
                variant="h5"
                style={{
                  marginBottom: 5,
                  color: 'rgb(16 16 18 / 89%)',
                  fontSize: 22
                }}
              >
                XAF Balance
              </Typography> */}
              <Divider />
              <Box className={classes.fields}>
                <Typography style={{ color: '#505056e3' }} variant="p">
                  Main account balance
                </Typography>
                <Typography
                  style={{
                    color: 'rgb(16 16 18 / 89%)',
                    fontSize: 18,
                    fontWeight: 900
                  }}
                >
                  {arrangeNumber(balance)}{' '}
                  <span style={{ fontSize: 14 }}>XAF</span>
                </Typography>
              </Box>
              {singleUserService && (
                <>
                  <Divider />
                  <Box className={classes.fields}>
                    <Typography variant="p" style={{ color: '#505056e3' }}>
                      Service balance for{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {singleUserService.data?.serviceName}
                      </span>
                    </Typography>
                    <Typography
                      style={{
                        color: 'rgb(16 16 18 / 89%)',
                        fontSize: 18,
                        fontWeight: 900
                      }}
                    >
                      {userService.length > 1 && (
                        <Button
                          onClick={handleTransferOpen}
                          className={classes.addBalance}
                        >
                          Transfer
                          <FaArrowCircleRight
                            className={classes.transferIcon}
                          />
                        </Button>
                      )}
                      <Button
                        onClick={handleOpen}
                        className={classes.addBalance}
                      >
                        <FaArrowUp className={classes.iconPlus} />
                        Add to main balance
                      </Button>
                      {serviceBalance ? arrangeNumber(serviceBalance) : 0}{' '}
                      <span style={{ fontSize: 14 }}>XAF</span>
                    </Typography>
                  </Box>
                </>
              )}
              <Box>
                <Snackbar
                  duration={6000}
                  close={handleSnackClose}
                  data="Transferred successfully"
                  open={snack}
                />
                <Popover
                  style={{ marginTop: 5 }}
                  id={transferId}
                  open={openTransferI}
                  anchorEl={anchorTransferEl}
                  onClose={handleTransferClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <DialogContent
                    style={{
                      padding: 20,
                      width: 300
                    }}
                  >
                    {transferError && (
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 700,
                          fontSize: 16
                        }}
                      >
                        {transferResponse}
                      </Typography>
                    )}

                    <InputLabel
                      shrink
                      htmlFor="bootstrap-select-input-item"
                      style={{
                        color: '#3e4040',
                        fontWeight: 400,
                        fontSize: 20,
                        marginBottom: -15
                      }}
                    >
                      <Typography variant="p">Recipient service</Typography>
                    </InputLabel>

                    {userService?.length > 1 && (
                      <BootstrapSelect
                        labelId="demo-simple-select-outlined-label"
                        id="bootstrap-input-time"
                        name="service"
                        value={values.service}
                        onChange={handleChange}
                        fullWidth
                        style={{ marginTop: 10 }}
                      >
                        {userService &&
                          filteredService?.map(data => (
                            <MenuItem
                              onClick={() =>
                                handleServiceSwitch(data.serviceId)
                              }
                              className={classes.menuItem}
                              key={data._id}
                              value={data.serviceName}
                              style={{
                                borderBottom: '1px solid #f4f4f5',
                                backgroundColor: '#fff'
                              }}
                            >
                              <Typography variant="inherit">
                                {data.serviceName}
                              </Typography>
                            </MenuItem>
                          ))}
                      </BootstrapSelect>
                    )}
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-item"
                      style={{
                        color: '#3e4040',
                        fontWeight: 400,
                        fontSize: 20,
                        marginBottom: -10,
                        marginTop: 10
                      }}
                    >
                      <Typography variant="p">Amount</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="serviceAmount"
                      id="serviceAmount"
                      type="text"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Enter amount"
                      size="small"
                      onChange={e => setServiceAmount(e.target.value)}
                      value={serviceAmount}
                    />
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: '1px solid #f4f4f5'
                    }}
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      style={{ textTransform: 'none' }}
                      onClick={handleTransferCloseButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      style={{ textTransform: 'none' }}
                      onClick={serviceTransfer}
                    >
                      {isTransferLoading ? (
                        <CircularProgress size="14px" />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </DialogActions>
                </Popover>
              </Box>
              <Box>
                <Popover
                  style={{ marginTop: 5 }}
                  id={id}
                  open={openI}
                  anchorEl={anchorEl}
                  onClose={handleAddClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <DialogContent
                    style={{
                      padding: 20,
                      width: 250
                    }}
                  >
                    {balancerror && (
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 700,
                          fontSize: 16
                        }}
                      >
                        {balanceresponse}
                      </Typography>
                    )}
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-item"
                      style={{
                        color: '#3e4040',
                        fontWeight: 400,
                        fontSize: 20
                      }}
                    >
                      <Typography variant="p">Amount</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="item"
                      id="item"
                      type="text"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Enter amount"
                      size="small"
                      onChange={e => setAmount(e.target.value)}
                      value={amount}
                    />
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: '1px solid #f4f4f5'
                    }}
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      style={{ textTransform: 'none' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      style={{ textTransform: 'none' }}
                      onClick={addBalance}
                    >
                      {isBalanceLoading ? (
                        <CircularProgress size="14px" />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </DialogActions>
                </Popover>
              </Box>
              <Divider />
              <Box className={classes.fields} style={{ marginTop: 60 }}>
                <Typography
                  variant="h5"
                  style={{
                    marginBottom: 5,
                    color: 'rgb(16 16 18 / 89%)',
                    fontSize: 22
                  }}
                >
                  Disputes
                </Typography>
                <Divider />
                <Typography
                  variant="p"
                  style={{ color: 'rgb(16 16 18 / 89%)', fontSize: 16 }}
                >
                  Funds on hold
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    color: 'rgb(16 16 18 / 89%)',
                    fontSize: 20,
                    fontWeight: 900
                  }}
                >
                  0<span style={{ fontSize: 14 }}> XAF</span>
                </Typography>
              </Box>
              <Divider />
              <Box className={classes.fields} style={{ marginTop: 60 }}>
                <Typography
                  variant="h5"
                  style={{
                    marginBottom: 5,
                    color: 'rgb(16 16 18 / 89%)',
                    fontSize: 20
                  }}
                >
                  Pending transactions
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    color: 'rgb(16 16 18 / 89%)',
                    fontSize: 20,
                    fontWeight: 900
                  }}
                >
                  {pendingBalance}
                  <span style={{ fontSize: 14 }}> XAF</span>
                </Typography>
              </Box>
              <PendingTransactions />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};
