import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography, Button } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'src/context';
import CircularProgress from '@mui/material/CircularProgress';
import { BsDownload } from 'react-icons/bs';
import { Upload, message, Button as B } from 'antd';
import { Icon } from '@ant-design/compatible';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '80%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 12
  },
  text: {
    fontSize: 18,
    color: '#3e4040',
    fontWeight: 400
  },
  mainText: {
    fontSize: 18,
    color: '#3f51b5',
    fontWeight: 400
  },
  buttons: {
    marginTop: 10,
    textTransform: 'none',
    fontSize: 18
  },
  headingText: {
    color: '#3e4040',
    fontWeight: 400,
    fontSize: 18
  }
}));

const EditDetails = ({ id }) => {
  const classes = useStyles();
  const { token } = useAuthState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [bulkId, setBulkId] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const cprops = {
    name: 'file',
    action: 'https://api.fapshi.com/upload-bulk/' + id,
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      setIsLoading(true);
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setBulkId(info.file.response.bulkId);
        setIsLoading(false);
        setIsActive(false);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = () => {
    navigate('/app/bulk-payments/edit/verify', {
      replace: 'false',
      state: { id: bulkId }
    });
  };

  return (
    <Box className={classes.root} sx={{ flexGrow: 1 }}>
      <Typography
        style={{
          color: '#3e4040',
          fontSize: 20,
          fontWeight: 700,
          fontFamily: 'Ubuntu',
          marginBottom: 20
        }}
      >
        Update CSV file
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <Box className={classes.mainBox}>
            <Box className={classes.box}>
              <Typography className={classes.mainText} variant="p">
                Upload CSV
              </Typography>
            </Box>
          </Box>
          <Box className={classes.mainBox}>
            <Box className={classes.box}>
              <Typography className={classes.text} variant="p">
                Review details
              </Typography>
            </Box>
          </Box>
          <Box className={classes.mainBox}>
            <Box className={classes.box}>
              <Typography className={classes.text} variant="p">
                Confirm payment
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          style={{
            borderRadius: 4,
            padding: 25
          }}
          item
          xs={12}
          sm={12}
          lg={6}
          xl={6}
        >
          <Box>
            <Typography
              variant="h3"
              style={{
                marginTop: -5,
                color: '#3e4040'
              }}
            >
              Upload CSV
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box>
              <InputLabel
                shrink
                htmlFor="bootstrap-input-address"
                style={{
                  marginTop: 7,
                  color: '#3e4040',
                  fontWeight: 700,
                  fontSize: 22
                }}
              >
                <Typography variant="p">Instructions</Typography>
              </InputLabel>
              <ul>
                <li>
                  <Typography
                    style={{ color: '#3e4040', fontWeight: 400, fontSize: 15 }}
                    variant="p"
                  >
                    The file must be CSV
                  </Typography>
                </li>
                <li>
                  <Typography
                    style={{ color: '#3e4040', fontWeight: 400, fontSize: 15 }}
                    variant="p"
                  >
                    The CSV file should contain the following columns -{' '}
                    <b>No </b>
                    (Column numbering), <b>Name</b> (Recipient's name),{' '}
                    <b>Phone</b> (Recipient's MTN Mobile Money account number)
                    and <b>Amount </b>
                    (The amount you want to send)
                  </Typography>
                </li>
                <li>
                  <Typography
                    style={{ color: '#3e4040', fontWeight: 400, fontSize: 15 }}
                    variant="p"
                  >
                    We advice you download the Sample CSV file and edit it with
                    your details.
                  </Typography>
                </li>
              </ul>
              <a
                href={
                  'https://storage.cloud.google.com/fapshi-files/sample.csv'
                }
              >
                <Button
                  startIcon={<BsDownload size={12} />}
                  style={{ textTransform: 'none', fontWeight: 'bold' }}
                >
                  Download a sample CSV
                </Button>
              </a>
              <br />
              <Box style={{ marginTop: 10 }}>
                <small
                  style={{
                    fontWeight: 400,
                    fontSize: 14
                  }}
                >
                  Upload your CSV file which should be exactly the same as the
                  sample CSV. Only CSV files are allowed.
                </small>
                <br />
                <br />
                <Upload {...cprops}>
                  <B style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}>
                    <Icon type="upload" /> Upload your CSV as show in the Sample
                    CSV file.
                  </B>
                </Upload>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIcon>Send</ArrowForwardIcon>}
                  type="submit"
                  onClick={handleSubmit}
                  size="small"
                  disabled={isActive}
                >
                  {isLoading ? <CircularProgress size="14px" /> : 'Next'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditDetails;
