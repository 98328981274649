import React, { useState } from 'react';
// import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button, Divider, makeStyles, Toolbar, Grid } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@mui/material/Slide';
import { CardActionArea } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Footer from './Footer';
import axios from 'axios';
import { useAuthState } from 'src/context';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '80%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },

  inputText: {
    fontSize: 20,
    color: '#181819',
    fontWeight: 900,
    letterSpacing: 2
  },
  boxActive: {
    borderRight: '2px solid #3f51b5',
    height: 17
  },
  box: {},
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 8
  },
  mainBoxActive: {
    marginBottom: 12
  },
  text: {
    fontSize: 15,
    color: '#505056e3'
  },
  mainText: {
    fontSize: 15,
    color: '#3f51b5'
  },
  buttons: {
    marginTop: 10,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ced4da',
      color: 'black'
    }
  },
  button: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    border: '1px solid #ced4da',
    borderRadius: 20,
    height: 25,
    marginRight: 4,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ffffff'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff8c' //rgba(0,0,30,0.2)
  },
  contact: {
    display: 'flex',
    alignItems: 'center',
    marginRight: -10,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      textAlign: 'center'
    }
  },
  report: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    borderRadius: '5',
    border: '1px solid #0f15c514',
    width: 500,
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      width: 400
    }
  },
  gridReview: {
    borderRadius: 4,
    padding: 25,
    border: '1px solid #e7eaf5',
    margin: 40
  },
  cardReview: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 4%)',
    border: '1px solid #ced4da',
    borderRadius: 3,
    width: 700,
    [theme.breakpoints.down('md')]: {
      width: 320,
      margin: '2px 20px 0px 20px'
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      width: 230
    },
    width: '430px',
    height: '12px',
    padding: '10px 12px',
    marginTop: 1,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: 'ced4da'
    }
  }
}));

const BootstrapTextField = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '430px',
    [theme.breakpoints.down('md')]: {
      width: 230
    },
    height: '100px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#ced4da'
    }
  }
}));

export default function NameCard({ data }) {
  const classes = useStyles();
  const { token } = useAuthState();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [rating, setRating] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/storeReview/' + data?.vendorId
    }).then(res => {
      setRating(res.data.reviews);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const [rate, setRate] = React.useState(0);

  const [customerValues, setCustomerValues] = useState({
    name: null,
    email: null,
    phone: null,
    subject: null,
    description: null
  });

  const handleRatings = e => {
    const { value, name } = e.target;
    setCustomerValues({ ...customerValues, [name]: value });
  };

  const handleSubmit = e => {
    setCustomerValues('');
    e.preventDefault();
    const values = {
      username: customerValues.name,
      email: customerValues.email,
      phone: customerValues.phone,
      subject: customerValues.subject,
      description: customerValues.description
    };
    axios({
      method: 'post',
      data: values,
      url: 'https://api.fapshi.com/reportVendor/' + data.vendorId
    });
  };
  const [review, setReview] = useState(null);
  const handleReview = () => {
    const values = {
      rating: rate,
      review
    };
    axios({
      method: 'post',
      data: values,
      url: 'https://api.fapshi.com/storeReview/' + data.vendorId,
      headers: { 'x-access-token': token }
    }).then(() => {
      setReview(null);
      setRate(0);
    });
  };

  return (
    <Box sx={{ minWidth: 275, maxWidth: 'auto' }}>
      <Card
        style={{
          height: 'auto',
          border: '1px solid rgb(234 235 255)',
          boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 3%)'
        }}
        variant="outlined"
      >
        <CardContent
          style={{
            backgroundColor: '#ffffff',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={data?.vendorLogo}
            sx={{ width: 56, height: 56, border: '1px solid #f4f4f5' }}
            style={{}}
          />
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              style={{ fontFamily: 'Ubuntu', fontWeight: 900 }}
              variant="h5"
              mr="5px"
            >
              {data?.vendorName}
            </Typography>
            <Typography
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Ubuntu'
              }}
            >
              (Rating: {data.averageRating} <Star style={{ fontSize: 16 }} />)
            </Typography>
          </Box>
          <Typography variant="h5" className={classes.contact}>
            <Box
              style={{ display: 'flex', alignItems: 'center', marginRight: 0 }}
            >
              <MailOutlineIcon />
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Ubuntu'
                }}
              >
                {data?.vendorEmail}
              </Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <PhoneIcon />
              <Typography>+237 {data?.vendorPhone}</Typography>
            </Box>
          </Typography>
          <Divider />
          <br />
          <Typography variant="p">
            {data?.vendorDesc ||
              'Welcome! Feel free to browse all our products and make a purchase at your convenience'}
          </Typography>
          <Box style={{ marginTop: 10 }}>
            <Button
              color="primary"
              style={{ textTransform: 'none', fontFamily: 'Ubuntu' }}
              onClick={handleClickOpen}
              className={classes.button}
            >
              Leave a rating
            </Button>
            <Button
              style={{
                textTransform: 'none',
                color: '#ff0000',
                fontFamily: 'Ubuntu'
              }}
              className={classes.button}
              onClick={handleDialogOpen}
            >
              Report this vendor
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Box>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
          BackdropProps={{
            classes: {
              root: classes.backDrop
            }
          }}
        >
          <Box className={classes.report}>
            <form>
              <DialogTitle
                id="form-dialog-title"
                style={{
                  borderBottom: '1px solid rgb(229 231 243)',
                  padding: 10,
                  fontSize: '14px'
                }}
              >
                <Typography
                  style={{
                    color: '#546e7a',
                    fontSize: 20,
                    fontFamily: 'ubuntu',
                    fontWeight: 'bold'
                  }}
                  variant="p"
                >
                  Report {data.vendorName}
                </Typography>
              </DialogTitle>
              <DialogContent
                style={{
                  padding: 20
                }}
              >
                <FormControl>
                  <FormGroup>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-name"
                      style={{
                        marginTop: 12,
                        color: '#546e7a',
                        fontWeight: 400,
                        fontSize: 20
                      }}
                    >
                      <Typography variant="p">Full names</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="name"
                      id="name"
                      type="text"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Enter your full names"
                      size="small"
                      value={customerValues.name}
                      onChange={handleRatings}
                    />
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-email"
                      style={{
                        marginTop: 12,
                        color: '#546e7a',
                        fontWeight: 400,
                        fontSize: 20
                      }}
                    >
                      <Typography variant="p">Email</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="email"
                      id="email"
                      type="email"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Enter your email"
                      size="small"
                      value={customerValues.email}
                      onChange={handleRatings}
                    />
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-phone"
                      style={{
                        marginTop: 12,
                        color: '#546e7a',
                        fontWeight: 400,
                        fontSize: 20
                      }}
                    >
                      <Typography variant="p">Phone number</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="phone"
                      id="phone"
                      type="number"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Enter your phone number"
                      size="small"
                      value={customerValues.phone}
                      onChange={handleRatings}
                    />
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-subject"
                      style={{
                        marginTop: 12,
                        color: '#546e7a',
                        fontWeight: 400,
                        fontSize: 20
                      }}
                    >
                      <Typography variant="p">Subject</Typography>
                    </InputLabel>
                    <BootstrapInput
                      name="subject"
                      id="subject"
                      type="text"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Enter a headline for your report"
                      size="small"
                      value={customerValues.subject}
                      onChange={handleRatings}
                    />
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-description"
                      style={{
                        marginTop: 12,
                        color: '#546e7a',
                        fontWeight: 400,
                        fontSize: 20
                      }}
                    >
                      <Typography variant="p">Report description</Typography>
                    </InputLabel>
                    <BootstrapTextField
                      name="description"
                      id="name"
                      type="text"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      placeholder="Tell us what happened"
                      size="small"
                      multiline
                      rows={2}
                      value={customerValues.description}
                      onChange={handleRatings}
                    />
                  </FormGroup>
                </FormControl>
              </DialogContent>
              <DialogActions
                style={{
                  borderTop: ' 1px solid rgb(239 239 239)',
                  padding: 10,
                  float: 'left'
                }}
              >
                <Button
                  onClick={handleDialogClose}
                  color="primary"
                  variant="outlined"
                  size="small"
                  className={classes.button}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  size="small"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </Box>
        </Dialog>
      </Box>
      <Box>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Toolbar
            style={{
              boxShadow: 'none',
              minHeight: 40,
              backgroundColor: '#ffffff',
              borderBottom: '1px solid rgb(234 235 255)'
            }}
          >
            <Typography
              style={{
                fontFamily: 'ubuntu',
                fontWeight: 900,
                color: '#3f51b5',
                fontSize: 16
              }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              {data.vendorName} Reviews
            </Typography>
            <Button
              style={{
                textTransform: 'none',
                backgroundColor: '#ffffff'
              }}
              autoFocus
              size="small"
              onClick={handleClose}
            >
              close
            </Button>
          </Toolbar>
          <Grid container spacing={2}>
            <Grid
              className={classes.gridReview}
              item
              xs={12}
              sm={12}
              lg={3}
              xl={3}
            >
              <Box>
                <Typography
                  variant="h3"
                  style={{
                    marginTop: -5,
                    color: '#546e7a',
                    fontSize: 25,
                    fontFamily: 'ubuntu',
                    fontWeight: 'bold'
                  }}
                >
                  Are you Satisfied ?
                </Typography>
                <br />
                <Typography
                  style={{ color: 'gray', textAlign: 'justify' }}
                  variant="p"
                >
                  Show us your satisfaction by rating this vendor. Please do
                  well to confirm your satisfaction before leaving a review.
                </Typography>
              </Box>
              <Box>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  {[...Array(5)].map((item, index) => {
                    const givenRating = index + 1;
                    return (
                      <Star
                        value={givenRating}
                        style={{
                          fontSize: '35px',
                          display: 'inline-block',
                          cursor: 'pointer',
                          color:
                            givenRating < rate || givenRating === rate
                              ? '#3f51b5'
                              : '#bcbdc5'
                        }}
                        onClick={() => setRate(givenRating)}
                      />
                    );
                  })}
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Box>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      marginTop: 7,
                      color: '#546e7a',
                      fontWeight: 400,
                      fontSize: 20
                    }}
                  >
                    <Typography variant="p">
                      Tell us your feelings (Optional)
                    </Typography>
                  </InputLabel>
                  <BootstrapTextField
                    fullWidth
                    placeholder="Write your review"
                    margin="normal"
                    name="address"
                    multiline
                    rows={2}
                    type="text"
                    id="bootstrap-input-address"
                    required
                    value={review}
                    onChange={e => setReview(e.target.value)}
                    style={{ marginRight: 8 }}
                  />

                  <Button
                    className={classes.buttons}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={rate == 0 ? true : false}
                    onClick={handleReview}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={5}
              xl={5}
              style={{ marginBottom: 100 }}
            >
              {rating.map(result => (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px'
                  }}
                >
                  <CardActionArea>
                    <CardContent className={classes.cardReview}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Box>
                          <Typography
                            style={{
                              fontFamily: 'ubuntu',
                              fontWeight: 500,
                              fontSize: 14,
                              color: '#505056e3'
                            }}
                            gutterBottom
                            variant="h5"
                            component="div"
                          >
                            {result.username}
                          </Typography>
                        </Box>
                        <Box>
                          {[...Array(result.rating)].map(() => {
                            return (
                              <>
                                <Star color="primary" />
                              </>
                            );
                          })}
                        </Box>
                      </Box>
                      {result.review && (
                        <>
                          <Divider
                            style={{
                              backgroundColor: '#f4f4f5',
                              margin: '10px 0'
                            }}
                          />
                          <Typography
                            style={{
                              fontFamily: 'ubuntu',
                              fontWeight: 500,
                              fontSize: 16,
                              color: '#505056e3'
                            }}
                            gutterBottom
                            variant="h5"
                            component="div"
                          >
                            {result.review}
                          </Typography>
                        </>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Box>
              ))}
            </Grid>
          </Grid>
          <Footer />
        </Dialog>
      </Box>
    </Box>
  );
}
