import React, { useState } from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useAuthState } from 'src/context';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/jpg' ||
    file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/PNG/GIF file!');
  }
  return isJpgOrPng;
}

const Avatar = ({ parentCallback }) => {
  const { token } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const [res, setRes] = useState('image.png');

  const handleChange = async info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      var response = info.file.response;
      getBase64(info.file.originFileObj, url => {
        setLoading(false);
        setUrl(url);
      });
    }
    setRes(response?.productId);
    parentCallback(response?.imageURL);
    console.log(res);
    return response;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Upload
      name="image"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      action="https://api.fapshi.com/imageUpload"
      headers={{ 'x-access-token': token }}
      openFileDialogOnClick={true}
      progress
    >
      {url ? (
        <img src={url} alt="avatar" style={{ width: '100%' }} />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default Avatar;
