import React from 'react';
import Page from 'src/components/Page';
import Button from '@material-ui/core/Button';
import { Container, Grid, Box, Hidden } from '@material-ui/core';
import Bar from 'src/components/Bar';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import PaymentsTab from 'src/components/PaymentsTab';
import DataIndex from '../Data';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50%',
      textAlign: 'center',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
    },
    width: '100%',
    border: '1px  solid #e0e0e03d'
  },
  label: {
    marginLeft: 8
  },
  button: {
    textTransform: 'none',
    display: 'block',
    textAlign: 'left'
  },
  box: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  iconbutton: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    border: '1px solid rgb(127 127 133)',
    '&:hover': {
      background: 'none'
    }
  }
}));

const ListView = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const navigate = useNavigate();
  const handleSimpleInvoiceClick = () => {
    navigate('/app/payment-link/simple', { replace: true });
  };

  const handleAdvancedInvoiceClick = () => {
    navigate('/app/payment-link/donation', { replace: true });
  };
  const location = useLocation();
  const { data } = location.state;

  return (
    <Page title="Payments">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={2} xl={2}>
              <PaymentsTab val="links" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={10} xl={10}>
            <Bar
              title="Payment Link"
              btnText="Create a link"
              onClick={handleClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              style={{ marginTop: 3 }}
            >
              <Button
                onClick={handleSimpleInvoiceClick}
                fullWidth
                className={classes.button}
              >
                <Typography variant="p"> Simple link</Typography>
              </Button>
              <Divider style={{ backgroundColor: '#f4f4f5' }} />
              <Button
                onClick={handleAdvancedInvoiceClick}
                fullWidth
                className={classes.button}
              >
                <Typography variant="p"> Donation link</Typography>
              </Button>
            </Popover>
            <Box>
              <DataIndex data={data} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ListView;
