import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { alpha } from '@mui/material/styles';
import CustomButton from './Buttons/Button';
import { KeyboardArrowDown } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  introtitle: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 26,
    fontWeight: 700,
    color: '#1a1f36',
    display: 'inline-block'
  },
  inputfield: {
    width: '100%'
  },
  root: {
    width: '100%',
    margin: '50px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  items: {
    display: 'inline-block'
  },
  button: {
    float: 'right',
    textTransform: 'none',
    fontSize: 16,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    border: '1px solid #dfdee5',
    height: 35,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  }
}));

const Bar = ({ title, btnText, onClick, icon, styling }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} style={styling}>
      <Typography variant="h4" className={classes.introtitle}>
        {title}
      </Typography>
      <CustomButton
        btnTxt={btnText}
        startIcon={icon === 'add' ? <AddIcon /> : null}
        endIcon={icon && icon !== 'add' ? <KeyboardArrowDown /> : null}
        on={onClick}
        btnStyle={{ mb: 0 }}
      />
      {/* <Button
        variant="outlined"
        color="default"
        className={(classes.items, classes.Button, classes.button)}
        size="small"
        onClick={onClick}
      >
        {btnText}
      </Button> */}
    </Box>
  );
};

export default Bar;
