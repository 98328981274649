import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { makeStyles, Divider, Hidden, Toolbar } from '@material-ui/core';
import logo from './images/logo.svg';
import { Button } from '@material-ui/core';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  useCartState,
  updateCart,
  useCartDispatch,
  clearCart
} from 'src/context';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgb(255 255 255)',
    boxShadow: 'none',
    border: 'none'
  },
  logo: {
    height: '30%',
    width: '70px'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#fff' //rgba(0,0,30,0.2)
  },
  typo: {
    margin: '20px 0px 0px 20px',
    fontSize: 20
  },
  inputText: {
    fontSize: 20,
    color: '#505056e3'
  },
  button: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    border: '1px solid #ced4da',
    borderRadius: 20,
    height: 25,
    marginRight: 4,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ffffff'
    }
  },
  iconButton: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    border: '1px solid #ced4da',
    borderRadius: 20,
    height: 30,
    width: 30,
    marginRight: 15,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ffffff'
    }
  },
  toolBar: {
    borderBottom: '1px solid rgb(234 235 255)',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 2%)',
    minHeight: 35,
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      minHeight: 60
    }
  },
  drawer: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 300
    }
  },
  cardActions: { marginLeft: 250, marginTop: -100 },
  cardHeader: {
    marginTop: 100,
    [theme.breakpoints.down('md')]: {
      margin: '-15px 0px 0px 40px '
    }
  },
  card: {
    margin: '5px 10px 0px 10px',
    paddingTop: 0,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    height: 60,
    [theme.breakpoints.down('md')]: {
      height: 70,
      paddingTop: 12
    }
  }
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 14,
    width: '100%',
    height: '8px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#ced4da'
    }
  }
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  '& .MuiInputBase-input': {
    borderRadius: 20,
    position: 'relative',
    backgroundColor: '#fffffff',
    border: '1px solid rgb(234 235 255)',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 2%)',
    fontSize: 12,
    color: 'black',
    marginTop: 6,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    },
    padding: '6px 100px 5px 20px'
  },
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3)
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '67%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 99,
  marginLeft: 280,
  marginTop: 6,
  cursor: 'pointer',
  borderLeft: '1px solid #f5f5f5',
  '&:hover': {
    backgroundColor: '#ffffff',
    border: '1px solid rgb(234 235 255)',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    borderRadius: 20
  },
  color: 'black'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    borderRadius: 20,
    padding: '0px px 0px 0px',
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid rgb(234 235 255)',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 2%)',
    fontSize: 14,
    height: '13px',
    color: 'black',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: '#3f51b5'
  }
}));

export default function Header({ data }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [display, setDisplay] = useState(true);
  const [odisplay, setOdisplay] = useState(true);
  const [cdisplay, setCdisplay] = useState(false);

  const menuId = 'primary-search-account-menu';
  const [state, setState] = useState({
    right: false
  });

  var [subTotal, setSubTotal] = useState(0);
  const dispatch = useCartDispatch();
  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleOpenAccountClick = () => {
    navigate('/register');
  };

  const { cart } = useCartState();

  var [products, setProducts] = useState([
    { pid: 0, item: null, price: 0, qty: 0, unitPrice: 0 }
  ]);

  const removeItem = value => {
    console.log(value);

    const payload = cart.filter(cartItem => value._id !== cartItem._id);
    const np = products.filter(cartItem => value._id !== cartItem.pid);
    setProducts(np);
    const st = np.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);
    setSubTotal(st);
    updateCart(dispatch, payload);
  };

  const handleClearCart = () => {
    clearCart(dispatch);
    setProducts([]);
    setSubTotal(0);
  };

  const handlePriceChange = (event, item) => {
    const value = parseInt(event.target.value) || 0;

    console.log(products);
    const index = products.findIndex(function(val) {
      return val.pid == this;
    }, item._id);
    console.log(index);
    if (index == -1) {
      setProducts([
        ...products,
        {
          pid: String(item._id),
          qty: value,
          unitPrice: item.price,
          item: item.productName
        }
      ]);
    } else {
      products[index].qty = value;
    }

    const st = products.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);

    setSubTotal(st);
  };
  console.log(subTotal);
  console.log(products);

  const [customerValues, setCustomerValues] = useState({
    name: null,
    email: null,
    phone: null,
    address: null,
    message: null,
    content: []
  });

  const handleDetailsChange = e => {
    const { value, name } = e.target;
    setCustomerValues({ ...customerValues, [name]: value });
  };

  const list = anchor => (
    <Box
      className={classes.drawer}
      role="presentation"
      // onKeyDown={toggleDrawer(anchor, false)}
      style={{ height: '100%' }}
    >
      {cart.length > 0 ? (
        cart.map(item => (
          <Box style={{ display: display ? 'block' : 'none' }}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                avatar={
                  <Avatar
                    aria-label="recipe"
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 0,
                      // border: '1px solid #f4f4f5',
                      objectFit: 'cover'
                    }}
                  >
                    <img
                      style={{ width: 50, height: 50, borderRadius: 0 }}
                      src={item.productImage}
                    ></img>
                  </Avatar>
                }
                title={item.productName}
                subheader={`${formatAmount(item.price)} FCFA`}
              />
              <CardActions>
                <Box className={classes.cardActions}>
                  <StyledInputBase
                    style={{ fontFamily: 'ubuntu', padding: 5, width: 70 }}
                    type="number"
                    autoFocus
                    defaultValue={0}
                    onChange={() => handlePriceChange(event, item)}
                  />
                  <IconButton onClick={() => removeItem(item)}>
                    <RemoveCircleIcon style={{ color: '#d40000' }} />
                  </IconButton>
                </Box>
              </CardActions>
            </Card>
            <Button
              color="primary"
              style={{
                height: 30,
                width: 'auto',
                textTransform: 'none',
                margin: '10px 0px 0px 20px'
              }}
              onClick={() => handleClearCart()}
            >
              Clear cart
            </Button>
            <Box style={{ bottom: 10, position: 'fixed' }}>
              <Card
                sx={{}}
                style={{
                  margin: '5px 10px 0px 10px',
                  padding: 0,
                  boxShadow: 'none'
                }}
              >
                <CardHeader
                  title={<Typography variant="p">Sub total</Typography>}
                  subheader={`${formatAmount(subTotal)} FCFA`}
                />
                <CardActions>
                  <Button
                    variant="outlined"
                    color="default"
                    style={{
                      textTransform: 'none',
                      width: 200,
                      borderRadius: 8,
                      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
                    }}
                    onClick={handlePaymentsClick}
                  >
                    Place an order
                  </Button>
                  <Button
                    color="default"
                    style={{
                      height: 30,
                      width: 'auto',
                      textTransform: 'none',
                      marginLeft: 5
                    }}
                    onClick={toggleDrawer(anchor, false)}
                  >
                    Exit
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Box>
        ))
      ) : (
        <Box className={classes.typo}>
          <Typography variant="p">YOUR CART IS EMPTY</Typography>
          <Divider />
        </Box>
      )}
      <Box
        style={{
          width: '80%',
          margin: '10px auto',
          display: odisplay ? 'none' : 'block'
        }}
      >
        <Typography
          style={{
            color: 'rgb(26 27 37)',
            fontSize: 28,
            fontWeight: 700,
            fontFamily: 'Ubuntu'
          }}
        >
          Confirm your order
        </Typography>
        <Typography
          style={{
            color: '#505056e3',
            fontSize: 14,
            textAlign: 'justify',
            fontWeight: 400,
            fontFamily: 'Ubuntu'
          }}
        >
          Hello, for accountability purposes, our customers are not allowed to
          pay directly on orders. Once your order is approved by the
          vendor(owner of this store), you'll be contacted on how to proceed
          with payments. The payments waiting time is usually based on the
          vendor's availibity. This is usually within 1-2 business days.
        </Typography>
        <a href="https://documentation.fapshi.com/#store">
          <Button
            color="default"
            style={{
              marginBottom: 20,
              color: '#505056e3',
              fontSize: 15,
              textTransform: 'none'
            }}
            size="small"
            endIcon={<OpenInNewIcon />}
          >
            Read more
          </Button>
        </a>
        {error && (
          <Typography
            variant="p"
            style={{
              color: '#eb6558',
              fontWeight: 500,
              fontSize: 14
            }}
          >
            {eresponse}
          </Typography>
        )}
        <InputLabel shrink htmlFor="bootstrap-input" style={{ marginTop: 0 }}>
          <Typography className={classes.inputText} variant="p">
            Full names
          </Typography>
        </InputLabel>
        <BootstrapInput
          fullWidth
          placeholder="Enter your name"
          margin="normal"
          name="name"
          type="text"
          id="bootstrap-input"
          required
          autoFocus
          value={customerValues.name}
          onChange={handleDetailsChange}
        />
        <InputLabel shrink htmlFor="bootstrap-input" style={{ marginTop: 7 }}>
          <Typography className={classes.inputText} variant="p">
            Email address
          </Typography>
        </InputLabel>
        <BootstrapInput
          fullWidth
          placeholder="Enter your email"
          margin="normal"
          name="email"
          type="text"
          id="bootstrap-input"
          value={customerValues.email}
          onChange={handleDetailsChange}
          required
        />
        <InputLabel shrink htmlFor="bootstrap-input" style={{ marginTop: 7 }}>
          <Typography className={classes.inputText} variant="p">
            Phone number
          </Typography>
        </InputLabel>
        <BootstrapInput
          fullWidth
          placeholder="Ex: 676211496"
          margin="normal"
          name="phone"
          type="number"
          value={customerValues.phone}
          id="bootstrap-input"
          required
          onChange={handleDetailsChange}
        />{' '}
        <InputLabel shrink htmlFor="bootstrap-input" style={{ marginTop: 7 }}>
          <Typography className={classes.inputText} variant="p">
            Delivery address
          </Typography>
        </InputLabel>
        <BootstrapInput
          fullWidth
          placeholder="Enter your address"
          margin="normal"
          name="address"
          value={customerValues.address}
          type="text"
          id="bootstrap-input"
          required
          onChange={handleDetailsChange}
        />{' '}
        <InputLabel shrink htmlFor="bootstrap-input" style={{ marginTop: 7 }}>
          <Typography className={classes.inputText} variant="p">
            Note
          </Typography>
        </InputLabel>
        <BootstrapInput
          fullWidth
          placeholder="Enter any addional info"
          margin="normal"
          name="message"
          value={customerValues.message}
          type="text"
          id="bootstrap-input"
          required
          onChange={handleDetailsChange}
        />{' '}
        <Box style={{ marginTop: 10, marginBottom: 50 }}>
          <Button
            color="primary"
            onClick={handleConfirmation}
            variant="contained"
            className={classes.button2}
            size="small"
            style={{
              float: 'right',
              fontSize: 15,
              textTransform: 'none',
              borderRadius: 8,
              boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
            }}
          >
            {isLoading ? <CircularProgress size="14px" /> : 'Confirm'}
          </Button>
          <Button
            color="primary"
            type="reset"
            onClick={handleCancel}
            className={classes.button2}
            size="small"
            style={{
              float: 'right',
              fontSize: 15,
              textTransform: 'none'
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
      <Box style={{ display: cdisplay ? 'block' : 'none', margin: 20 }}>
        <Typography
          style={{
            color: 'rgb(26 27 37)',
            fontSize: 28,
            fontWeight: 700,
            fontFamily: 'Ubuntu'
          }}
        >
          Order Confirmed!
        </Typography>
        <Box style={{ backgroundColor: '#fff', padding: 20 }}>
          <Typography
            style={{
              color: '#505056e3',
              fontSize: 14,
              fontWeight: 400,
              textAlign: 'justify',
              fontFamily: 'Ubuntu'
            }}
          >
            Hey Joachim,
            <br />
            <br />
            Thank you for placing your order. An email has been sent to
            sureadsman@gmail.com concerning the details of your order. Feel free
            to reach out to us for any inquiries or concerns. We care about you
            and what you do on Fapshi. <br />
            <br /> Thank you for using Fapshi !.
          </Typography>
          <Button
            variant="outlined"
            color="default"
            style={{
              textTransform: 'none',
              marginTop: 10,
              borderRadius: 8,
              boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
            }}
            onClick={handleReturnToCart}
          >
            Return to cart
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const handlePaymentsClick = () => {
    // navigate('/payments/checkout', { replace: true });
    setDisplay(false);
    setOdisplay(false);
  };

  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    setOdisplay(true);
    setDisplay(true);
  };
  const handleConfirmation = () => {
    setIsLoading(true);
    setCdisplay(true);
    setOdisplay(true);
    const cartList = products.filter(c => {
      return c.qty != 0;
    });
    const values = {
      clientName: customerValues.name,
      clientEmail: customerValues.email,
      clientPhone: customerValues.phone,
      message: customerValues.message,
      address: customerValues.address,
      content: cartList
    };
    axios({
      method: 'post',
      data: values,
      url: 'https://api.fapshi.com/order/' + data.vendorId
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  const handleReturnToCart = () => {
    setOdisplay(true);
    setDisplay(true);
    setCdisplay(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar position="static" className={classes.toolBar}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ display: { sm: 'block' } }}
          style={{ color: '#454545' }}
        >
          <img className={classes.logo} src={logo}></img>
        </Typography>
        <Hidden mdDown>
          <Search
            style={{
              color: '#454545',
              width: 400,
              height: 40
            }}
          >
            <SearchIconWrapper>
              <SearchIcon style={{ fontSize: 22 }} />
            </SearchIconWrapper>
            <StyledInputBase
              style={{ fontFamily: 'ubuntu' }}
              placeholder="Search product name, price etc"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { md: 'flex' } }}>
          {['right'].map(anchor => (
            <React.Fragment key={anchor}>
              <IconButton
                onClick={toggleDrawer(anchor, true)}
                style={{ color: '#3f51b5' }}
                className={classes.iconButton}
                size="large"
                edge="end"
                aria-label="cart"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
              >
                <StyledBadge
                  badgeContent={cart.length || '0'}
                  color="secondary"
                >
                  <ShoppingCart style={{ fontSize: 20 }} />
                </StyledBadge>
              </IconButton>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop
                  }
                }}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            style={{
              textTransform: 'none',
              height: 25,
              fontSize: 14,
              margin: '4px 0px 0px 20px',
              borderRadius: 20
            }}
            size="small"
            onClick={handleOpenAccountClick}
          >
            Create an account
          </Button>
        </Box>
      </Toolbar>
    </Box>
  );
}
