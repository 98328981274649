import React from 'react';
import { Typography } from '@material-ui/core';

const Footer = () => {
  return (
    <div>
      <Typography
        style={{
          bottom: 0,
          textAlign: 'center',
          fontFamily: 'Ubuntu',
          fontSize: 15,
          position: 'fixed',
          left: 0,
          width: '100%',
          color: '#c0c1cb'
        }}
      >
        {' '}
        Powered by{' '}
        <span style={{ fontWeight: 'bold', fontSize: 15 }}>Fapshi, Inc.</span>
      </Typography>
    </div>
  );
};

export default Footer;
