import React, { useState } from 'react';
// import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputBase from '@mui/material/InputBase';
import { useAuthState } from 'src/context';
import axios from 'src/axios';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  introtitle: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 22,
    fontWeight: 600,
    color: '#1a1f36'
  },

  inputfield: {
    borderRadius: 10,
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '35px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    padding: '10px 12px'
  },
  typography: {
    padding: theme.spacing(2)
  },
  addButton: {
    width: '100%',
    display: 'block',
    textAlign: 'left',
    paddingRight: theme.spacing(4),
    fontWeight: '400',
    textTransform: 'lowercase'
  },
  button: {
    textTransform: 'none',
    height: 35,
    borderRadius: 8,
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    display: 'block'
  },
  btext: {
    color: 'blue',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  authPop: {
    width: '30%',
    marginLeft: '35%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 'auto'
    }
  }
}));

const Authentication = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openEl, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const [openPhone, setOpenPhone] = React.useState(false);

  const handlePhoneClickClose = () => {
    setOpenPhone(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { userDetails, token } = useAuthState();
  const { email, twoStep } = userDetails;

  console.log(twoStep);

  const values = {
    email,
    token
  };

  const [openDisableDialog, setDisableDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDisableClickOpen = () => {
    setDisableDialog(true);
  };

  const handleDisableClose = () => {
    setDisableDialog(false);
  };
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [opens, setSOpen] = React.useState(false);

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSOpen(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'put',
      url: '/enableTwoStep',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsLoading(false);
        setError(false);
        setSOpen(true);
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const handleDisableSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'put',
      url: '/disableTwoStep',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSOpen(true);
        setDisabled(true);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h5">Two-step Authentication</Typography>
      <Typography style={{ fontSize: 18 }} variant="p">
        Keep your account extra secure with a second authentication step.
      </Typography>
      {!twoStep ? (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          aria-describedby={id}
          onClick={handleClickOpen}
          style={{ marginTop: '10px' }}
          className={classes.button}
        >
          <Typography variant="h6">Add two-step authentication</Typography>
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          aria-describedby={id}
          onClick={handleDisableClickOpen}
          style={{ marginTop: '10px', display: 'block' }}
          className={classes.button}
        >
          <Typography variant="h6">Disable two-step authentication</Typography>
        </Button>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={openDisableDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        BackdropProps={{
          classes: {
            root: classes.backDrop
          }
        }}
      >
        <DialogTitle
          style={{
            borderBottom: '1px solid rgb(229 231 243)',
            padding: 10,
            fontSize: '12px'
          }}
          id="responsive-dialog-title"
        >
          Warning <span style={{ color: '#3f51b5' }}>!!!</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6">
              Are you sure you want to disable two-step authentication?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ borderTop: ' 1px solid rgb(239 239 239)', padding: 10 }}
        >
          <Button
            autoFocus
            onClick={handleDisableClose}
            color="primary"
            className={classes.button}
            variant="outlined"
            size="small"
          >
            <Typography variant="h6">Cancel</Typography>
          </Button>
          <Button
            className={classes.button}
            onClick={handleDisableSubmit}
            color="primary"
            autoFocus
            variant="contained"
            size="small"
          >
            <Typography variant="h6" style={{ color: '#ffffff' }}>
              {isLoading ? <CircularProgress size="14px" /> : 'Yes, I want to'}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      {disabled && (
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={opens}
            autoHideDuration={6000}
            onClose={handleSnackClose}
          >
            <Alert
              onClose={handleSnackClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              Two-step authentication disabled!
            </Alert>
          </Snackbar>
        </Stack>
      )}
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Button className={classes.button} onClick={handleClickOpen}>
          <Typography variant="p">
            {' '}
            Authenticate with your <span className={classes.btext}>EMAIL</span>
          </Typography>
        </Button>
      <Button className={classes.button} onClick={handlePhoneClickOpen}>
          <Typography variant="p">
            {' '}
            Authenticate via <span className={classes.btext}>SMS</span>{' '}
          </Typography>
        </Button> 
      </Popover> */}
      <Dialog
        open={openEl}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.authPop}
        BackdropProps={{
          classes: {
            root: classes.backDrop
          }
        }}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ borderBottom: '1px solid rgb(229 231 243)', padding: 10 }}
        >
          <Typography variant="p">
            <span style={{ fontSize: '16px' }}>
              Enable email two-step verification
            </span>
          </Typography>
        </DialogTitle>
        {snack && (
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              open={opens}
              autoHideDuration={6000}
              onClose={handleSnackClose}
            >
              <Alert
                onClose={handleSnackClose}
                severity="success"
                sx={{ width: '100%' }}
              >
                Two-step authentication enabled!
              </Alert>
            </Snackbar>
          </Stack>
        )}

        <DialogContent>
          <DialogContentText>
            <Typography variant="p">
              By clicking confirm, on your next login, an email will be sent to
              you concerning your two-step verification details.
            </Typography>
          </DialogContentText>
          {error && (
            <Typography
              variant="p"
              style={{
                color: '#eb6558',
                fontWeight: 500,
                fontSize: 14
              }}
            >
              {eresponse}
            </Typography>
          )}
          <InputBase
            autoFocus
            margin="dense"
            id="name"
            type="email"
            value={email}
            disabled
            variant="outlined"
            size="Small"
            className={classes.inputfield}
            style={{ fontFamily: 'Ubuntu' }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClickClose}
            color="secondary"
            size="small"
            variant="outlined"
            className={classes.button}
          >
            <Typography variant="p">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            size="small"
            variant="contained"
            className={classes.button}
          >
            <Typography variant="p">
              {' '}
              {isLoading ? <CircularProgress size="14px" /> : 'Confirm'}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        BackdropProps={{
          classes: {
            root: classes.backDrop
          }
        }}
        open={openPhone}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="p">Subscribe</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="p">
              {' '}
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </Typography>
          </DialogContentText>
          <InputBase
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlePhoneClickClose}
            size="small"
            color="primary"
            className={classes.button}
          >
            <Typography variant="p"> Cancel</Typography>
          </Button>
          <Button
            className={classes.button}
            onClick={handlePhoneClickClose}
            size="small"
            variant="contained"
            color="primary"
          >
            <Typography variant="p"> Subscribe</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Divider
        style={{
          margin: '5px 0px',
          width: '40%',
          backgroundColor: '#ffffff'
        }}
      />
      <Typography variant="p">
        Once you enable either SMS or Email, you'll be able to add security
        keys.
      </Typography>
    </Container>
  );
};

export default Authentication;
