import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import NoResults from 'src/components/NoResults';
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Hidden
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import StoreTab from 'src/components/StoreTab';
import axios from 'axios';
import { useAuthState } from 'src/context';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import { ShoppingBag as OrderIcon } from 'react-feather';
import { formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  inputfield: {
    width: '100%'
  },
  card: {
    marginTop: 0,
    padding: '10px 5px 5px 5px !important',
    // boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    border: '1px solid #dddfe685',
    marginBottom: '-20px'
  },
  descriptions: { height: 30 },
  bar: {
    margin: '10px 0px 10px 0px'
  },
  introtitle: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 26,
    fontWeight: 700,
    color: '#1a1f36'
  },
  root: {
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto'
      // maxWidth: 500
    }
  },
  button: {
    float: 'right',
    textTransform: 'none',
    fontSize: 14,
    borderRadius: 10
  },
  btn: {
    height: 25,
    marginTop: 20
  },
  alert: {
    display: 'inline-block'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    width: 40,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  },
  buttonI: {
    textTransform: 'none',
    display: 'block',
    textAlign: 'left',
    height: 30,
    overflowY: 'hidden',
    '&:hover': {
      backgroundColor: '#f4f4f5',
      color: 'gray'
    }
  }
}));

const Orders = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openI = Boolean(anchorEl);
  const id = openI ? 'simple-popover' : undefined;
  const { token } = useAuthState();

  const handleIconClose = () => {
    setAnchorEl(null);
  };

  const [oid, setOid] = useState(null);

  const handleViewClick = id => {
    console.log(id);
    navigate('/app/store/order-details', {
      replace: 'true',
      state: { id }
    });
  };
  const handleIconClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOid(id);
  };
  const handleDeleteClick = id => {
    axios({
      method: 'delete',
      url: 'https://api.fapshi.com/order/' + id,
      headers: { 'x-access-token': token }
    });
  };

  const [orders, setOrders] = useState([]);
  const [progressDisplay, setProgressDisplay] = useState(false);
  useEffect(() => {
    getOrders();
  }, []);
  const getOrders = () => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/order',
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setOrders(res.data);
        setProgressDisplay(false);
      })
      .catch(err => {
        console.log(err);
        setProgressDisplay(false);
      });
  };

  const newDate = date => {
    const d = new Date(date);
    return d.toLocaleString();
  };

  return (
    <Page title="Orders">
      <Container className={classes.root}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={4} xl={2}>
              <StoreTab val="orders" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={8} xl={10}>
            <Box className={classes.root}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: 700
                }}
              >
                <Box>
                  <Typography variant="h5" className={classes.introtitle}>
                    <Box className={classes.alert}>
                      <Typography variant="h4">Orders</Typography>
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: progressDisplay ? 'block' : 'none'
                }}
              >
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    backdropFilter: 'blur(2px)',
                    backgroundColor: '#ffffff59'
                  }}
                  open={open}
                >
                  <CircularProgress
                    size="1.5rem"
                    style={{
                      display: progressDisplay ? 'block' : 'none',
                      marginLeft: '20%',
                      marginTop: '15%',
                      marginBottom: '10%',
                      color: '#3f51b5'
                    }}
                  />
                </Backdrop>
              </Box>
              <Box
                style={{
                  display: progressDisplay ? 'none' : 'block'
                }}
                className={classes.container}
              >
                <Box>
                  {orders.length > 0 ? (
                    orders.map(data => (
                      <>
                        <List className={classes.root}>
                          <React.Fragment>
                            <ListItem
                              style={{
                                backgroundColor: '#ffffff',
                                paddingTop: 20
                              }}
                              alignItems="flex-start"
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    style={{ letterSpacing: 1 }}
                                    variant="h6"
                                  >
                                    {`Order Id: ${data.orderId}`}
                                  </Typography>
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      className={classes.inline}
                                      color="textPrimary"
                                    >
                                      {`Total: ${formatAmount(
                                        data.total
                                      )} FCFA`}
                                    </Typography>
                                    {data.seen ? (
                                      <>
                                        <Typography variant="P">
                                          {` — You received an order from ${
                                            data.clientName
                                          } on ${newDate(data.createdAt)}`}
                                        </Typography>
                                      </>
                                    ) : (
                                      <Typography variant="P">
                                        {` — You have received an order from ${
                                          data.clientName
                                        } on ${newDate(data.createdAt)}`}
                                      </Typography>
                                    )}
                                  </React.Fragment>
                                }
                              />
                              {!data.seen && (
                                <Box className={classes.alert}>
                                  <Stack direction="row" spacing={2}>
                                    <Chip
                                      label={
                                        <Typography variant="p">new</Typography>
                                      }
                                      style={{
                                        marginLeft: 5,
                                        marginTop: 5,
                                        marginRight: 5,
                                        color: '#ffffff',
                                        backgroundColor: '#1a8300'
                                      }}
                                      size="small"
                                      variant="filled"
                                    />
                                  </Stack>
                                </Box>
                              )}
                              {data.isDelivered && (
                                <Box className={classes.alert}>
                                  <Stack direction="row" spacing={2}>
                                    <Chip
                                      label={
                                        <Typography variant="p">
                                          Delivered
                                        </Typography>
                                      }
                                      style={{
                                        marginLeft: 5,
                                        marginTop: 5,
                                        marginRight: 5,
                                        color: '#ffffff',
                                        backgroundColor: '#8902c1'
                                      }}
                                      size="small"
                                      variant="filled"
                                    />
                                  </Stack>
                                </Box>
                              )}
                              <IconButton
                                className={classes.iconButton}
                                style={{
                                  height: 20
                                }}
                                onClick={event =>
                                  handleIconClick(event, data._id)
                                }
                              >
                                <MoreHorizIcon
                                  style={{ fontSize: 22, cursor: 'pointer' }}
                                />
                              </IconButton>
                            </ListItem>
                            <Divider
                              style={{ width: 150 }}
                              variant="middle"
                              component="li"
                            />
                          </React.Fragment>

                          {/* ) : ( */}

                          {/* )} */}
                        </List>
                      </>
                    ))
                  ) : (
                    <NoResults
                      title="orders created"
                      content="Orders simply show all orders made for your product."
                      link="https://documentation.fapshi.com/#orders"
                      icon={
                        <OrderIcon
                          size={35}
                          style={{
                            fontSize: 40,
                            border: '1px solid #bdc3c9',
                            boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                            borderRadius: 10,
                            color: '#bdc3c9',
                            padding: 5
                          }}
                        />
                      }
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={openI}
          anchorEl={anchorEl}
          onClose={handleIconClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Button
            className={classes.buttonI}
            fullWidth
            onClick={() => handleViewClick(oid)}
          >
            <Typography style={{ color: '#3f51b5' }} variant="p">
              {' '}
              View order
            </Typography>
          </Button>
          <Divider style={{ backgroundColor: '#f4f4f5' }} />
          <Button
            fullWidth
            className={classes.buttonI}
            onClick={() => handleDeleteClick(oid)}
          >
            <Typography variant="p" style={{ color: 'rgb(199, 30, 0)' }}>
              {' '}
              Delete order
            </Typography>
          </Button>
        </Popover>
      </Container>
    </Page>
  );
};

export default Orders;
