import React from 'react';
import Page from 'src/components/Page';
import { Container, Grid, Hidden } from '@material-ui/core';
import SimpleEditItem from '../SimpleEditItem';
import PaymentsTab from 'src/components/PaymentsTab';
import { useLocation } from 'react-router-dom';

const SimpleLink = () => {
  const location = useLocation();
  const data = location.state.data;
  return (
    <Page title="Edit Simple Link">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="links" />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            xl={10}
            style={{
              borderRight: '1px dashed #eaeaea6e'
            }}
          >
            <SimpleEditItem data={data} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SimpleLink;
