import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { Container, Grid, Hidden } from '@material-ui/core';
import Bar from 'src/components/Bar';
import TableData from './Table';
import TableCardData from './TableCard';
import PaymentsTab from 'src/components/PaymentsTab';
import { useServiceState } from 'src/context';
import axios from 'axios';
import { useAuthState } from 'src/context';

const TransactionsListView = () => {
  const [details, setDetails] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const { token } = useAuthState();
  const { singleUserService } = useServiceState();
  const [progressDisplay, setProgressDisplay] = useState(false);
  const [toggler, setToggler] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getProduct();
    getPendingCard();
  }, []);
  const getProduct = () => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/servicetrans/' + singleUserService.data?._id,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setProgressDisplay(false);
        setDetails(res.data);
      })
      .catch(() => {
        setProgressDisplay(false);
      });
  };
  const getPendingCard = () => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/pendingCard/' + singleUserService.data?._id,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setProgressDisplay(false);
        setCardDetails(res.data.trans);
        setTotal(res.data.pendingBalance);
      })
      .catch(() => {
        setProgressDisplay(false);
      });
  };

  const handleClick = () => {
    setToggler(!toggler);
  };
  return (
    <Page title="Merchant Transactions">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="transactions" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={9} xl={10}>
            <Bar
              title="Merchant Transactions"
              btnText={
                toggler
                  ? 'View unsettled transactions'
                  : 'View all transactions'
              }
              onClick={handleClick}
            />
            {toggler ? (
              <TableData details={details} progressDisplay={progressDisplay} />
            ) : (
              <TableCardData
                details={cardDetails}
                total={total}
                progressDisplay={progressDisplay}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default TransactionsListView;
