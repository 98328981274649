import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import NoResults from 'src/components/NoResults';
import {
  Container,
  Box,
  Grid,
  Hidden,
  Popover,
  Button
} from '@material-ui/core';
import Bar from 'src/components/Bar';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthState } from 'src/context';
import axios from 'src/axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@material-ui/core';
import PaymentsTab from 'src/components/PaymentsTab';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Repeat as TransferIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { formatAmount, ucFirst } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50%',
      textAlign: 'center',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
    },
    width: '100%',
    border: '1px  solid #e0e0e03d'
    // backgroundColor: '#fdfdff'
  },
  label: {
    marginLeft: 8
  },
  button: {
    textTransform: 'none',
    display: 'block',
    fontSize: 14,
    height: 30,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    '&:hover': {
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)'
    }
  },
  buttonTwo: {
    textTransform: 'none',
    display: 'block',
    textAlign: 'left',
    overflowY: 'hidden',
    height: 37,
    width: 120,
    fontSize: 16
  },
  box: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
  },
  showBox: {
    width: 400,
    overflowX: 'hidden'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    width: 40,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  }
}));

const TransferListView = () => {
  const classes = useStyles();
  const { token } = useAuthState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [progressDisplay, setProgressDisplay] = useState(false);
  const navigate = useNavigate();
  const openI = Boolean(anchorEl);
  const [bulk, setBulk] = useState(null);
  const [bulkData, setBulkData] = useState(null);

  const id = openI ? 'simple-popover' : undefined;

  const handleClickOpen = () => {
    navigate('/app/bulk-payments/add', { replace: false });
  };

  const [transfers, setTransfers] = useState([]);

  useEffect(() => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: '/user-bulk',
      headers: { 'x-access-token': token }
    }).then(response => {
      setProgressDisplay(false);
      const data = response.data;
      setTransfers(data);
    });
  }, []);

  const newDate = date => {
    const d = new Date(date);
    return d.toLocaleString();
  };

  const handleIconClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setBulk(data.bulkId);
    setBulkData(data.status);
  };

  const handleViewDetails = () => {
    axios({
      method: 'get',
      url: '/bulk-details/' + bulk,
      headers: { 'x-access-token': token }
    }).then(response => {
      navigate('/app/bulk-payments/view', {
        replace: false,
        state: { data: response.data, id: bulk }
      });
    });
  };

  const handleDeleteDetails = () => {
    axios({
      method: 'delete',
      url: '/delete-bulk/' + bulk,
      headers: { 'x-access-token': token }
    }).then(() => {
      const filter = transfers.filter(transfer => transfer.bulkId != bulk);
      setTransfers(filter);
      setAnchorEl(null);
    });
  };

  const handleUpdate = () => {
    navigate('/app/bulk-payments/edit/verify', {
      replace: false,
      state: { id: bulk }
    });
  };

  return (
    <Page title="Bulk Payments">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="bulk" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={9} xl={10}>
            <Bar
              title="Bulk Payments"
              btnText="Make a bulk payment"
              onClick={handleClickOpen}
            />
            <Box
              style={{
                display: progressDisplay ? 'block' : 'none'
              }}
            >
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: theme => theme.zIndex.drawer + 1,
                  backdropFilter: 'blur(2px)',
                  backgroundColor: '#ffffff59'
                }}
                open={open}
              >
                <CircularProgress
                  size="1.5rem"
                  style={{
                    display: progressDisplay ? 'block' : 'none',
                    marginLeft: '20%',
                    marginTop: '15%',
                    marginBottom: '10%',
                    color: '#3f51b5'
                  }}
                />
              </Backdrop>
            </Box>
            <List
              style={{
                display: progressDisplay ? 'none' : 'block'
              }}
              className={classes.root}
            >
              {transfers.length != 0 ? (
                transfers &&
                transfers.map(transfer => (
                  <React.Fragment>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={
                          transfer.status == 'CREATED' ? (
                            <>
                              <Typography variant="h6">
                                {ucFirst(transfer.senderName)} —{' '}
                                {transfer.bulkId}{' '}
                                <span
                                  style={{ color: 'red', letterSpacing: 1 }}
                                >
                                  (Draft)
                                </span>
                              </Typography>
                              <Box
                                style={{
                                  backgroundColor:
                                    transfer.status == 'CREATED' ||
                                    transfer.status == 'PROCESSING'
                                      ? '#686868'
                                      : 'green',
                                  width:
                                    transfer.status == 'CREATED' ||
                                    transfer.status == 'PROCESSING'
                                      ? 55
                                      : 30,
                                  borderRadius: 4,
                                  paddingLeft: 1
                                }}
                              >
                                <Typography
                                  style={{
                                    color: '#fff',
                                    fontSize: 12,
                                    fontWeight: 700
                                  }}
                                >
                                  {transfer.status === 'DONE'
                                    ? 'PAID'
                                    : transfer.status}
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Typography variant="h6">
                                {ucFirst(transfer.senderName)} —{' '}
                                {transfer.bulkId}
                              </Typography>
                              <Box
                                style={{
                                  backgroundColor:
                                    transfer.status == 'CREATED' ||
                                    transfer.status == 'PROCESSING'
                                      ? '#686868'
                                      : 'green',
                                  width:
                                    transfer.status == 'CREATED' ||
                                    transfer.status == 'PROCESSING'
                                      ? 55
                                      : 30,
                                  borderRadius: 4,
                                  paddingLeft: 1
                                }}
                              >
                                <Typography
                                  style={{
                                    color: '#fff',
                                    fontSize: 12,
                                    fontWeight: 700
                                  }}
                                >
                                  {transfer.status === 'DONE'
                                    ? 'PAID'
                                    : transfer.status}
                                </Typography>
                              </Box>
                            </>
                          )
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {newDate(transfer.createdAt)}
                            </Typography>
                            <Typography variant="h5">
                              {transfer.total >= 0 &&
                                `${formatAmount(transfer.total)} FCFA`}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <IconButton
                        className={classes.iconButton}
                        style={{
                          height: 20
                        }}
                        onClick={event => handleIconClick(event, transfer)}
                      >
                        <MoreHorizIcon
                          style={{ fontSize: 22, cursor: 'pointer' }}
                        />
                      </IconButton>
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                ))
              ) : (
                <NoResults
                  title="Payments made"
                  content="Send money to multiple mobile money accounts in a single click. Split payments in one click."
                  link="https://documentation.fapshi.com/#payment-links"
                  icon={
                    <TransferIcon
                      size={35}
                      style={{
                        fontSize: 40,
                        border: '1px solid #bdc3c9',
                        boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                        borderRadius: 10,
                        color: '#bdc3c9',
                        padding: 5
                      }}
                    />
                  }
                />
              )}
            </List>
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={openI}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {bulkData == 'CREATED' ? (
            <Box className={classes.popBox}>
              <Button
                onClick={handleUpdate}
                fullWidth
                className={classes.buttonTwo}
              >
                <Typography className={classes.buttonText} variant="p">
                  Resume
                </Typography>
              </Button>
              <Divider style={{ backgroundColor: '#f4f4f5' }} />
              <Button
                onClick={handleViewDetails}
                fullWidth
                className={classes.buttonTwo}
              >
                <Typography className={classes.buttonText} variant="p">
                  {' '}
                  Details
                </Typography>
              </Button>
              <Divider style={{ backgroundColor: '#f4f4f5' }} />
              <Button
                onClick={handleDeleteDetails}
                fullWidth
                className={classes.buttonTwo}
              >
                <Typography
                  className={classes.buttonText}
                  variant="p"
                  style={{ color: 'red' }}
                >
                  {' '}
                  Delete
                </Typography>
              </Button>
            </Box>
          ) : (
            <Box className={classes.popBox}>
              <Button
                onClick={handleViewDetails}
                fullWidth
                className={classes.buttonTwo}
              >
                <Typography className={classes.buttonText} variant="p">
                  {' '}
                  Details
                </Typography>
              </Button>
            </Box>
          )}
        </Popover>
      </Container>
    </Page>
  );
};

export default TransferListView;
