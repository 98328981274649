import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { Container, Typography, Box, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NoResults from 'src/components/NoResults';
import axios from 'axios';
import { useAuthState } from 'src/context';
import StoreProducts from './StoreProducts';
import StoreTab from 'src/components/StoreTab';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { ShoppingCart as StoreIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  inputfield: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
      overflowX: 'auto'
    }
  },
  card: {
    marginTop: 0,
    padding: '10px 5px 5px 5px !important',
    border: '1px solid #dddfe685',
    marginBottom: '-20px'
  },
  descriptions: { height: 30 },
  bar: {
    margin: '10px 0px 10px 0px'
  },
  introtitle: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 26,
    fontWeight: 700,
    color: '#1a1f36'
  },
  root: {
    width: '100%',
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
      overflowX: 'auto'
    }
  },
  button: {
    float: 'right',
    textTransform: 'none',
    fontSize: 14,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
  },
  btn: {
    height: 25,
    marginTop: 20
  },
  alert: {
    display: 'inline-block'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  }
}));

const StoreView = () => {
  const { token } = useAuthState();
  const [product, setProduct] = useState([]);
  const [progressDisplay, setProgressDisplay] = useState(false);

  useEffect(() => {
    getProduct();
  }, []);
  const getProduct = async () => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/vendorInfo',
      headers: { 'x-access-token': token }
    })
      .then(response => {
        setProgressDisplay(false);
        setProduct(response.data);
      })
      .catch(e => {
        setProgressDisplay(false);
        console.log(e);
      });
  };
  const classes = useStyles();

  return (
    <Page title={product ? product.vendorName : 'Store'}>
      <Container className={classes.root}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <StoreTab val="stores" />
            </Grid>
          </Hidden>
          <Box
            style={{
              display: progressDisplay ? 'block' : 'none'
            }}
          >
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.drawer + 1,
                backdropFilter: 'blur(2px)',
                backgroundColor: '#ffffff59'
              }}
              open={open}
            >
              <CircularProgress
                size="1.5rem"
                style={{
                  display: progressDisplay ? 'block' : 'none',
                  marginLeft: '20%',
                  marginTop: '15%',
                  marginBottom: '10%',
                  color: '#3f51b5'
                }}
              />
            </Backdrop>
          </Box>
          <Grid
            style={{
              display: progressDisplay ? 'none' : 'block'
            }}
            item
            xs={12}
            sm={12}
            lg={9}
            xl={10}
          >
            <Box style={{ marginTop: 15 }}>
              <Box>
                <Box>
                  <Typography variant="h5" className={classes.introtitle}>
                    <Box className={classes.alert}>
                      <Typography variant="h4">Store</Typography>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.container}>
              {product.vendorName ? (
                <Box>
                  <StoreProducts data={product} />
                </Box>
              ) : (
                <NoResults
                  title="store created"
                  content="Your store is a unified view of products which you add to it. With a store, people can see all your products in one place. "
                  link="https://documentation.fapshi.com/#stores"
                  icon={
                    <StoreIcon
                      size={35}
                      style={{
                        fontSize: 40,
                        border: '1px solid #bdc3c9',
                        boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                        borderRadius: 10,
                        color: '#bdc3c9',
                        padding: 5
                      }}
                    />
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default StoreView;
