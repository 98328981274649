import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Input, Row, Col, Divider, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from '@material-ui/core/Button';
import 'src/App.css';
import { useAuthState } from 'src/context';
import axios from 'axios';
import Snackbar from 'src/components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { getRevenue, getPrice } from 'src/components/FapshiRevenue';

const { Title } = Typography;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '20px',
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      width: '750px'
    }
  },
  header: {
    MarginTop: '-100px'
  },
  box: {
    paddingTop: 0
  },
  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '35px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  },
  button: {
    textTransform: 'none',
    borderRadius: 8,
    fontSize: 14,
    margin: 2,
    height: 35,
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  }
}));

const EditProductForm = ({ data }) => {
  const { token } = useAuthState();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [requiredMark, setRequiredMarkType] = useState('optional');

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const classes = useStyles();

  const [values, setValues] = useState({
    productName: data?.productName,
    description: data?.description,
    productImage: data?.productImage
  });

  const [price, setPrice] = useState(data.price);
  const [revenue, setRevenue] = useState();

  const handleInputChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handlePriceChange = e => {
    const target = e.target.value;
    setPrice(target);
    const rev = getRevenue(target);
    setRevenue(rev);
  };

  const handleRevenueChange = event => {
    const target = event.target.value;
    setRevenue(target);
    const price = getPrice(target);
    setPrice(price);
  };

  const handleSubmit = async e => {
    const datas = {
      productName: values.productName,
      description: values.description,
      price: parseInt(price),
      productImage: values.productImage
    };
    setIsLoading(true);
    e.preventDefault();
    try {
      const response = await axios({
        method: 'put',
        url: 'https://api.fapshi.com/product/' + data.id,
        data: datas,
        headers: { 'x-access-token': token }
      });
      if (response.status === 200) {
        setIsLoading(false);
        setSnack(true);
        navigate('/app/products', { replace: false });
      } else {
        setError(true);
        setEresponse(response.response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError(true);
      setEresponse(error.response?.data.message);
      console.log(error);
    }
  };
  return (
    <Box className={classes.box} style={{ paddingTop: 0 }}>
      <Container className={classes.root}>
        <Title level={4}>Edit Product Information</Title>
        {snack && <Snackbar data="Product edited successfully" />}
        {error && (
          <Typography
            variant="p"
            style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
          >
            {eresponse}
          </Typography>
        )}
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            requiredMarkValue: requiredMark
          }}
          onValuesChange={onRequiredTypeChange}
          requiredMark={requiredMark}
        >
          <form>
            <Row>
              <Col span={8}>
                <Form.Item
                  label="Name"
                  required
                  tooltip="Give a concise product name"
                >
                  <InputBase
                    defaultValue={data?.productName}
                    placeholder="enter product name"
                    onChange={handleInputChange}
                    value={values.productName}
                    name="productName"
                    required
                    className={classes.input}
                  />
                </Form.Item>
                <Form.Item
                  label="Description"
                  tooltip={{
                    title:
                      'Enter the product description. Give details a customer will want to see',
                    icon: <InfoCircleOutlined />
                  }}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder="Enter product description"
                    defaultValue={data?.description}
                    onChange={handleInputChange}
                    value={values.description}
                    name="description"
                    className={classes.input}
                  />
                </Form.Item>
                <Divider />
              </Col>
            </Row>
            <Col span={8}>
              <Title level={4}>Edit Price Information</Title>
              <Form.Item
                label="Price"
                required
                tooltip="What is the product price? Fill in"
              >
                <InputBase
                  onChange={handlePriceChange}
                  value={price}
                  name="price"
                  addonAfter="FCFA"
                  defaultValue={data?.price}
                  type="number"
                  required
                  className={classes.input}
                />
              </Form.Item>
              <Form.Item
                label="Revenue"
                required
                tooltip="Revenue refers to income after rate has been deducted"
              >
                <InputBase
                  addonAfter="FCFA"
                  value={revenue}
                  onChange={event => handleRevenueChange(event)}
                  type="number"
                  className={classes.input}
                />
              </Form.Item>
              <Form.Item
                label="Price description"
                tooltip={{
                  title:
                    'Enter the product price description. Give details a customer will want to see',
                  icon: <InfoCircleOutlined />
                }}
              >
                <Input.TextArea
                  rows={2}
                  placeholder="Enter price description"
                  className={classes.input}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="submit"
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={event => handleSubmit(event)}
                >
                  {isLoading ? (
                    <CircularProgress size="14px" />
                  ) : (
                    'Save product'
                  )}
                </Button>
              </Form.Item>
            </Col>
          </form>
        </Form>
      </Container>
    </Box>
  );
};

export default EditProductForm;
