import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  textOne: {
    color: '#161c1f',
    fontSize: 22,
    fontWeight: 600
  },
  textTwo: {
    color: '#3e4040',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 0.1
  },
  icon: {
    fontSize: 40,
    border: '1px solid #bdc3c9',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
    borderRadius: 10,
    color: '#bdc3c9',
    padding: 5
  },
  container: {
    marginTop: '25%',
    textAlign: 'center'
  },
  link: {
    color: '#4763ff',
    fontSize: 14
  }
});

const NoResults = props => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      {props.icon}{' '}
      <Typography variant="h5" className={classes.textOne}>
        No {props.title}
      </Typography>
      <Typography variant="p" className={classes.textTwo}>
        {props.content}
      </Typography>
      <br />
      <a target="_blank" className={classes.link} href={props.link}>
        Learn more
        <FaExternalLinkAlt style={{ marginLeft: 4 }} />
      </a>
    </Container>
  );
};

export default NoResults;
