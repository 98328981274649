import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Box, makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
      overflowY: 'auto'
    },
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    height: 40
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '8px 8px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: 'inherit'
    },
    '&:active': {
      color: '#3f51b5',
      backgroundColor: '#ffffff'
    },
    '&:focus': {
      color: '#3f51b5',
      backgroundColor: '#ffffff'
    }
  },
  icon: {
    marginRight: theme.spacing(0.3),
    color: '#3f51b5',
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 8
    }
  },
  iconBtn: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    height: 30,
    width: 30,
    padding: 5,
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      fontSize: 8
    },
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  title: {
    marginRight: 'auto',
    color: '#3e4040',
    fontWeight: 700,
    letterSpacing: 0.5,
    fontSize: 14,
    wordWrap: 'break-word',
    marginLeft: 5,
    [theme.breakpoints.down('md')]: {
      fontSize: 15
    },
    '&:hover': {
      color: '#3f51b5',
      padding: '0 0px',
      borderRadius: 20
    },
    '&:focus': {
      color: '#3f51b5'
    }
  },
  active: {
    color: '#3f51b5'
  }
}));

const NavItem = ({ className, href, icon: Icon, title, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.item, className)} disableGutters {...rest}>
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && (
          <IconButton className={classes.iconBtn}>
            <Icon className={classes.icon} size="20" />
          </IconButton>
        )}
        <span activeClassName={classes.active} className={classes.title}>
          {title}
        </span>
      </Button>
    </Box>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
