import React from 'react';
import Page from 'src/components/Page';
import { Box, Container, Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SingleProductDetails from './SingleProductDetails';
import StoreTab from 'src/components/StoreTab';

const useStyles = makeStyles(theme => ({
  introtitle: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 22,
    fontWeight: 600,
    color: '#1a1f36'
  },
  inputfield: {
    width: '100%'
  },

  root: {
    flexGrow: 1,
    marginTop: 5,
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      maxWidth: 500
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  button: {
    display: 'block',
    margin: 5,
    width: 200
  },
  text: {
    fontSize: 12,
    fontFamily: 'Ubuntu',
    color: '#505056e3'
  }
}));

const ViewProduct = () => {
  const classes = useStyles();

  const location = useLocation();
  const data = location.state?.products;

  return (
    <Page className={classes.introtitle} title="View Product">
      <Box className={classes.root}>
        <Container>
          <Grid container spacing={1}>
            <Hidden mdDown>
              <Grid item xs={12} sm={12} lg={3} xl={3}>
                <StoreTab val="products" />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} lg={9} xl={9}>
              <Box className={classes.paper}>
                <Box>
                  <SingleProductDetails data={data} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export default ViewProduct;
