import React, { useState } from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthState } from 'src/context';

const MultipleImage = ({ parentCallback }) => {
  const [state, setState] = useState({
    previewVisible: true,
    previewImage: '',
    previewTitle: '',
    fileList: []
  });
  const handleCancel = () => setState({ previewVisible: false });

  const [res, setRes] = useState([]);
  const data = [];

  const handleChange = ({ fileList }) => {
    fileList.map(file => {
      data.push(file.response?.imageURL);
      setRes(data);
    });
    setState({ fileList });
  };
  parentCallback(res);

  const { previewImage, fileList } = state;
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const { token } = useAuthState();

  return (
    <div className="clearfix">
      <Upload
        multiple="true"
        name="image"
        action="https://api.fapshi.com/imageUpload"
        headers={{ 'x-access-token': token }}
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        maxCount={4}
      >
        {fileList?.length >= 4 ? null : uploadButton}
      </Upload>
      <Modal footer={null} onCancel={handleCancel} title={state.previewTitle}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default MultipleImage;
