import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Logo from './logo.svg';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import axios from 'src/axios';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';
import ServiceList from './ServiceList';
import { useAuthState } from 'src/context';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 5,
      padding: '30px 5px'
    },
    marginLeft: '10%',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)'
  },
  logo: {
    width: '80px',
    height: '50%'
  },
  introbox: {
    marginTop: 50,
    marginLeft: 66,
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      marginLeft: 20
    }
  },
  error: {
    textAlign: 'left'
  },
  box: {},
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 10,
    height: 40
  },
  inputText: {
    fontSize: 20,
    color: '#181819',
    fontWeight: 900,
    letterSpacing: 2
  },
  boxOne: {
    marginTop: 30,
    marginLeft: 50,
    // backgroundColor: '#f7fafc',
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      marginLeft: 20
    }
  },
  gridOne: {
    borderRight: '1px dashed rgb(234 235 255)',
    borderLeft: '1px solid rgb(234 235 255)',
    marginLeft: 100,
    // backgroundColor: '#f7fafc',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '17px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd',
      border: '1px solid #ced4da'
    }
  }
}));
const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useAuthState();
  useEffect(() => {
    axios({
      method: 'get',
      url: '/token',
      headers: { 'x-access-token': token }
    }).then(res => {
      if (res.status !== 200) navigate('/login', { replace: true });
    });
  }, []);
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      phone: ''
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .max(255)
        .required('Username is required'),
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string()
        .min(6)
        .required('Password is required'),
      phone: Yup.string()
        .max(255)
        .required('Phone number is required')
    }),

    onSubmit: values => {
      setIsLoading(true);
      const data = values;
      console.log(data);
      axios
        .post(`/auth/signup`, data)
        .then(res => {
          console.log(res);
          console.log(res.data);
          if (res.status === 201) {
            setIsLoading(true);
            navigate('/verify-email', {
              replace: true,
              state: { email: data.email }
            });
          }
        })
        .catch(error => {
          setIsLoading(false);
          setError(true);
          setEresponse(error.response?.data.message);
          console.log(error);
        });
    }
  });

  const [isPass, setIsPass] = useState(true);
  const handleClickShowPassword = () => {
    setIsPass(!isPass);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const [check, setCheck] = useState(false);
  const handleCheck = () => {
    setCheck(!check);
  };
  return (
    <Grid container spacing={2} style={{ height: '900px' }}>
      <Grid className={classes.gridOne} item lg={5} sm={8} xl={4} xs={12}>
        <Box className={classes.boxOne}>
          <ServiceList />
        </Box>
      </Grid>
      <Grid
        item
        style={{ borderRight: '1px solid rgb(234 235 255)' }}
        lg={6}
        sm={10}
        xl={6}
        xs={12}
      >
        <Box className={classes.introbox} mb={2}>
          <img className={classes.logo} src={Logo} alt="logo" />
          <Typography color="textSecondary" gutterBottom variant="p">
            <br />
            Welcome! Sign up to get started with us in very simple steps.
          </Typography>
        </Box>
        <Page className={classes.root} title="Fapshi|Register">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            className={classes.box}
          >
            <Container maxWidth="sm">
              {error ? (
                <Typography
                  variant="p"
                  style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                >
                  {eresponse}
                </Typography>
              ) : (
                <></>
              )}
              <form onSubmit={formik.handleSubmit}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Username
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  error={Boolean(
                    formik.touched.username && formik.errors.username
                  )}
                  fullWidth
                  helperText={formik.touched.username && formik.errors.username}
                  placeholder="Enter your username"
                  margin="normal"
                  name="username"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="username"
                  value={formik.values.username}
                  id="bootstrap-input"
                  required
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 30 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Email address
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  placeholder="Enter your email"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                  id="bootstrap-input"
                  required
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 30 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Password
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  error={Boolean(
                    formik.touched.password && formik.errors.password
                  )}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  placeholder="Enter your password"
                  margin="normal"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={isPass ? 'password' : 'text'}
                  value={formik.values.password}
                  id="bootstrap-input"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          border: '1px solid #ced4da',
                          borderRadius: 8,
                          height: 39
                        }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {isPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 30 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Phone number
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  error={Boolean(formik.touched.phone && formik.errors.phone)}
                  fullWidth
                  helperText={formik.touched.phone && formik.errors.phone}
                  placeholder="Enter your phone"
                  margin="normal"
                  name="phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="number"
                  value={formik.values.phone}
                  id="bootstrap-input"
                  required
                />
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                  style={{ marginTop: 20, marginBottom: 30 }}
                >
                  <Checkbox
                    checked={check}
                    name="policy"
                    onChange={handleCheck}
                    size="small"
                  />
                  <Typography
                    color="textSecondary"
                    style={{ marginTop: 10 }}
                    variant="p"
                  >
                    By checking this box, you agree that you have read our{' '}
                    <a
                      color="primary"
                      target="_blank"
                      href="https://fapshi.com/privacy-policy/"
                    >
                      Privacy Policy
                    </a>
                    {' and '}
                    <a
                      color="primary"
                      href="https://fapshi.com/terms-and-conditions/"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>
                  </Typography>
                </Box>
                {Boolean(formik.touched.policy && formik.errors.policy) && (
                  <FormHelperText error>{formik.errors.policy}</FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    size="large"
                    disabled={!check}
                  >
                    <Typography variant="p">
                      {' '}
                      {isLoading ? (
                        <CircularProgress my={2} size="20px" />
                      ) : (
                        'Sign up now'
                      )}
                    </Typography>
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="p">
                  Have an account?{' '}
                  <Link component={RouterLink} to="/login" variant="p">
                    Sign in
                  </Link>
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="p"
                  style={{ float: 'right' }}
                >
                  <Link component={RouterLink} to="/resend-code" variant="p">
                    Verify your email
                  </Link>
                </Typography>
              </form>
            </Container>
          </Box>
        </Page>
      </Grid>
    </Grid>
  );
};

export default RegisterView;
