import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import NoResults from 'src/components/NoResults';
import {
  Container,
  Grid,
  Hidden,
  makeStyles,
  Box,
  Button,
  Typography
} from '@material-ui/core';
import ExportBar from 'src/components/ExportBar';
import PaymentsTab from 'src/components/BalanceTab';
import { Flag as DisputesIcon } from 'react-feather';
import axios from 'axios';
import { useAuthState } from 'src/context';
import { useNavigate } from 'react-router';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
  mainbox: {
    marginBottom: 10,
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #f4f6f9',
    borderBottom: '1px solid #f4f6f9',
    padding: 10,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      height: 100
    }
  },
  button: {
    textTransform: 'none',
    borderRadius: 10,
    fontSize: 16,
    margin: 2,
    height: 35,
    textDecoration: 'underline'
  },
  backDrop: {
    backdropFilter: 'blur(1000px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  }
}));

const DisputesView = () => {
  const classes = useStyles();
  const { token } = useAuthState();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [progressDisplay, setProgressDisplay] = useState(false);

  useEffect(() => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/user-dispute',
      headers: { 'x-access-token': token }
    }).then(res => {
      setData(res.data);
      console.log(data);
      console.log(res.data);
      setProgressDisplay(false);
    });
  }, []);

  const handleSubmit = values => {
    navigate('/app/disputes/details', {
      replace: 'false',
      state: values
    });
  };
  return (
    <Page title="Disputes">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="disputes" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={9} xl={10}>
            <ExportBar title="Disputes" />
            <Box
              style={{
                display: progressDisplay ? 'block' : 'none'
              }}
            >
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: theme => theme.zIndex.drawer + 20,
                  backdropFilter: 'blur(2px)',
                  backgroundColor: '#ffffff59'
                }}
                open={open}
              >
                <CircularProgress
                  size="1.5rem"
                  style={{
                    display: progressDisplay ? 'block' : 'none',
                    marginLeft: '20%',
                    marginTop: '15%',
                    marginBottom: '10%',
                    color: '#3f51b5'
                  }}
                />
              </Backdrop>
            </Box>
            {data?.length > 0 ? (
              data.map(item => {
                return (
                  <Box
                    className={classes.flexBox}
                    style={{
                      display: progressDisplay ? 'none' : 'flex'
                    }}
                  >
                    <Box>
                      <Typography
                        style={{ fontWeight: 700, fontFamily: 'Ubuntu' }}
                      >
                        {item.subject}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        className={classes.button}
                        onClick={() => handleSubmit(item)}
                      >
                        View details
                      </Button>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <>
                <NoResults
                  title="Disputes"
                  content="Disputes occurs when customer is reported for futher actions to be taken."
                  link="https://documentation.fapshi.com/#disputes"
                  icon={
                    <DisputesIcon
                      size={35}
                      style={{
                        fontSize: 40,
                        border: '1px solid #bdc3c9',
                        boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                        borderRadius: 10,
                        color: '#bdc3c9',
                        padding: 5
                      }}
                    />
                  }
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DisputesView;
