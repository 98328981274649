import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Hidden
} from '@material-ui/core';
import { Repeat } from 'react-feather';
import axios from 'src/axios';
import { useAuthState } from 'src/context';
import { useNavigate } from 'react-router-dom';
import { formatAmount } from 'src/utils/helperFunctions';
const useStyles = makeStyles(theme => ({
  root: {
    // height: '80%',
    // margin: 0,
    // padding: 0
    borderRight: '1px dashed #f4f4f5',
    // boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
    cursor: 'pointer',
    textAlign: 'center'
  },
  mainBox: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  avatar: {
    backgroundColor: '#f4f6f8',
    height: 35,
    width: 35
  },
  differenceIcon: {
    color: '#545454b8'
  },
  differenceValue: {
    color: '#545454b8',
    marginRight: theme.spacing(1)
  }
}));

const Balance = ({ className, ...rest }) => {
  const classes = useStyles();
  const [balance, setbalance] = useState(null);
  const { token } = useAuthState();

  useEffect(() => {
    axios({
      method: 'get',
      url: '/getBalance/',
      headers: { 'x-access-token': token }
    })
      .then(response => {
        const data = response.data;
        setbalance(data.balance);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  const navigate = useNavigate();
  return (
    // <Card >
    <CardContent
      className={clsx(classes.root, className)}
      {...rest}
      onClick={() => navigate('/app/balances', { replace: false })}
    >
      <Grid container spacing={3} className={classes.mainBox}>
        <Grid item>
          <Typography
            color="textPrimary"
            style={{
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5rem',
              textAlign: 'center'
            }}
            gutterBottom
            variant="h5"
          >
            Balance
            <Repeat size="16" style={{ marginLeft: '5px', color: '#696bdb' }} />
          </Typography>
          <Hidden lgUp>
            <Typography color="textPrimary" variant="h1">
              {balance >= 0 && formatAmount(balance)}
              <span style={{ fontSize: 18, fontWeight: 70 }}> FCFA</span>
            </Typography>
          </Hidden>
          <Hidden mdDown>
            <Typography color="textPrimary" variant="h1">
              {balance >= 0 && formatAmount(balance)}
              <span style={{ fontSize: 18, fontWeight: 700 }}> FCFA</span>
            </Typography>
          </Hidden>
        </Grid>
      </Grid>
    </CardContent>
  );
};

Balance.propTypes = {
  className: PropTypes.string
};

export default Balance;
