import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { visuallyHidden } from '@mui/utils';
import NoResults from 'src/components/NoResults';
import Typography from '@material-ui/core/Typography';
import { formatAmount } from 'src/utils/helperFunctions';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount (FCFA)'
  },

  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default function PopupTable({ data }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const newDate = date => {
    const d = new Date(date);
    return d.toLocaleString();
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {data?.length !== 0 ? (
          <>
            <Paper sx={{ width: '100%', mb: 2, mt: 5 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 600 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={data?.length}
                  />
                  <TableBody style={{ backgroundColor: 'rgb(247 250 252' }}>
                    {data?.map(rows => (
                      <TableRow
                        hover
                        onClick={event => handleClick(event, rows.transferId)}
                        role="checkbox"
                        tabIndex={-1}
                        key={rows.transferId}
                      >
                        <TableCell>{rows.payerName || 'Anonymous'}</TableCell>
                        <TableCell align="right">
                          {rows.received >= 0 && formatAmount(rows.received)}
                        </TableCell>
                        <TableCell align="right">
                          {newDate(rows.dateConfirmed)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={dense}
                  onChange={handleChangeDense}
                />
              }
              label={<Typography variant="p">{'Dense padding'}</Typography>}
            />
          </>
        ) : (
          <>
            <NoResults title="Payments" />
          </>
        )}
      </Box>
    </>
  );
}
