import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useAuthState } from 'src/context';
import axios from 'src/axios';
import { Form, Input, Space } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  form: {
    width: '50%',
    height: '30px',
    display: 'block'
  },
  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '35px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    height: 40,
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    borderRadius: 8,
    [theme.breakpoints.down('md')]: {
      marginTop: 65
    }
  }
}));

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  const [display, setDisplay] = useState(true);
  const [values, setValues] = useState({
    oldPass: '',
    newPass: ''
  });
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const { token } = useAuthState();
  const handleSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'put',
      url: '/reset-password',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsLoading(false);
        setDisplay(true);
        setError(false);
        setValues('');
        setOpen(true);
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <>
        <CardHeader
          subheader={
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography variant="p" style={{ color: '#3e4040' }}>
                Update password
              </Typography>
              <IconButton
                style={{ border: '1px solid #f4f4f5', marginTop: -20 }}
                color="primary"
                size="small"
                onClick={() => setDisplay(!display)}
              >
                <EditIcon style={{ fontSize: 20 }} />
              </IconButton>
            </Box>
          }
          title="Password"
        />
        {snack && (
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: '100%' }}
              >
                Password updated successfully!
              </Alert>
            </Snackbar>
          </Stack>
        )}
        <Box style={{ display: display ? 'none' : 'block' }}>
          {error && (
            <Typography
              variant="p"
              style={{
                color: '#eb6558',
                fontWeight: 500,
                fontSize: 14
              }}
            >
              {eresponse}
            </Typography>
          )}
          <CardContent>
            <Space direction="vertical">
              <Form.Item label="Old password">
                <Input.Password
                  onChange={handleChange}
                  placeholder="Old password"
                  value={values.password}
                  name="oldPass"
                  required
                  className={classes.input}
                />
              </Form.Item>
              <Form.Item label="New password">
                <Input.Password
                  placeholder="New password"
                  iconRender={visible =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  onChange={handleChange}
                  value={values.confirm}
                  name="newPass"
                  required
                  className={classes.input}
                />
              </Form.Item>
            </Space>
          </CardContent>
          <Box
            style={{ marginTop: '-110px' }}
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleSubmit}
              className={classes.button}
            >
              {isLoading ? <CircularProgress size="14px" /> : 'Update'}
            </Button>
          </Box>
        </Box>
      </>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
