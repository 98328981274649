const arrangeNumber = num => {
  if (!num) return num;
  num = String(num);
  if (num.length < 5) return num;
  const arrNum = [];
  for (let x of num) {
    arrNum.push(x);
    num = num.slice(1);
    if (num.length % 3 == 0 && num.length != 0) arrNum.push(',');
  }
  return arrNum.join('');
};

export default arrangeNumber;
