import React, { useState, useEffect } from 'react';
import Header from './Header';
import NameCard from './NameCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { makeStyles, Box } from '@material-ui/core';
import SingleCard from './SingleCard';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Footer from './Footer';

const useStyles = makeStyles(theme => ({
  container: { width: '100%', height: 'auto', marginBottom: 100 },
  cardBody: {
    margin: '30px 0px 100px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '30px 0px 0px 0px'
    }
  }
}));

const Stores = () => {
  const classes = useStyles();
  const params = useParams();
  localStorage.setItem('cart', []);

  const [product, setProduct] = useState({});

  useEffect(() => {
    const id = params?.id;

    const getProduct = async id => {
      var response = await axios({
        method: 'get',
        url: 'https://api.fapshi.com/productStore/' + id
      });
      setProduct(response.data);

      return response;
    };
    getProduct(id);
  }, []);

  // console.log(product.products);
  return (
    <div>
      <Header data={product} />
      <Container className={classes.container}>
        <Box style={{ marginTop: 20 }}>
          <NameCard data={product} style={{ width: 'auto', height: 1000 }} />
        </Box>
        <div className={classes.cardBody}>
          <Grid container spacing="5">
            <SingleCard data={product} />
          </Grid>
        </div>
        <Footer />
      </Container>
    </div>
  );
};

export default Stores;
