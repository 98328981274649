import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verificationLogin, useAuthDispatch } from 'src/context';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'src/axios';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Logo from './logo.svg';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '20px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#e3e8fd',
      border: '1px solid #ced4da'
    }
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 100,
    marginLeft: '35%',
    [theme.breakpoints.down('md')]: {
      marginTop: 120,
      marginLeft: 5
    }
  },
  error: {
    textAlign: 'left'
  },
  box: {
    width: '35%',
    marginLeft: '35%',
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: 310,
    marginTop: '0%',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      marginLeft: 10,
      marginRight: 50,
      padding: 0
    }
  }
}));

const TwoStep = () => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAuthDispatch();

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: Yup.object().shape({
      code: Yup.string()
        .min(5)
        .max(5)
        .required('Verification code is required')
    }),
    onSubmit: async values => {
      const data = {
        id: location.state.verificationId,
        twoStepCode: values.code
      };
      console.log(data);
      axios
        .post(`/auth/two-step-verification`, data)
        .then(res => {
          console.log(res);
          console.log(res.data);
          if (res.status === 200) {
            verificationLogin(dispatch, res.data);
            navigate('/app/dashboard', { replace: true });
          }
        })
        .catch(error => {
          setError(true);
          setEresponse(error.response?.data.message);
          console.log(error);
        });
    }
  });

  return (
    <Page className={classes.root} title="Two step">
      <img className={classes.logo} src={Logo} alt="logo" />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        textAlign="center"
        className={classes.box}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h3">
                Two step Verification
              </Typography>
              <Typography variant="p" color="textSecondary" gutterBottom>
                Your two step verification code has been sent to your email
                (Check your spam folder, if you don't see it in your inbox).
                Fill in this code to access your dashboard.
              </Typography>
            </Box>
            {error ? (
              <Typography
                variant="p"
                style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
              >
                {eresponse}
              </Typography>
            ) : (
              <></>
            )}
            <BootstrapInput
              error={Boolean(formik.touched.code && formik.errors.code)}
              fullWidth
              helperText={formik.touched.code && formik.errors.code}
              label="Passcode"
              margin="normal"
              placeholder="Enter code"
              name="code"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="code"
              value={formik.values.code}
              variant="outlined"
              size="small"
              required
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{ textTransform: 'none', borderRadius: 10 }}
              >
                Confirm
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default TwoStep;
