import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormGroup,
  Typography,
  Grid,
  Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { Editor } from '@tinymce/tinymce-react';

import theme from 'src/assets/theme';
import FormInput from 'src/components/Inputs/FormInput';
import BackIcon from 'src/components/Buttons/BackIcon';
import CustomButton from 'src/components/Buttons/Button';

import useEndpoint from 'src/utils/useEndpoint';
import texts from 'src/assets/texts';
import { apiConfig } from 'src/utils/apiConfig';
import { CalculateRevenue, formatAmount } from 'src/utils/helperFunctions';
import { useAuthState } from 'src/context';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';

const { colors, bordered } = theme;

const styles = {
  infoBox: {
    my: 2
  },
  pageTitle: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontWeight: 'bold',
    my: 2
  },
  sectionTitle: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontWeight: 'bold',
    fontSize: 20
  },
  text: {
    fontSize: 14
  },
  divider: {
    borderBottomWidth: 0,
    borderColor: colors.inputBorderColor,
    mt: 1
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    mt: 1
  },
  errorTxt: {
    color: colors.error,
    textAlign: 'center',
    fontSize: 13
  },
  error: {
    fontSize: 11,
    color: colors.error,
    mt: -1,
    mb: 1
  },
  itemsCard: {
    borderRadius: 2,
    padding: 2,
    marginBottom: 4,
    minWidth: '50%'
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  smallText: {
    fontSize: 12,
    color: colors.lightBlack,
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  formControl: {
    width: '100%'
  },
  label: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 14,
    marginBottom: '0.5rem'
  },
  pictureBox: { mb: 2, display: 'flex' },
  coverPhoto: {
    maxHeight: '100px',
    objectFit: 'cover'
  }
};

const InfoBox = ({ title, infoText }) => {
  return (
    <Box sx={styles.infoBox}>
      <Typography variant="h5" sx={styles.sectionTitle}>
        {title}
      </Typography>
      <Typography variant="p" sx={styles.text}>
        {infoText}
      </Typography>
    </Box>
  );
};

const CreateEvent = () => {
  const navigate = useNavigate();
  const { token } = useAuthState();
  const [errors, setErrors] = useState({});
  const [coverPhoto, setCoverPhoto] = useState('');
  const [amountErrors, setAmountErrors] = useState({});
  const [amounts, setAmounts] = useState([]);
  const [amountsForm, setAmountsForm] = useState({
    amount: '',
    desc: ''
  });

  const [form, setForm] = useState({
    title: '',
    description: '',
    date: '',
    location: '',
    time: ''
  });

  const onInputChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });

    if (value === '') {
      if (name === 'title') {
        setErrors(prev => {
          return {
            ...prev,
            [name]: texts.titlerequired
          };
        });
      }
    } else setErrors({});
  };

  const onAmountChange = ({ name, value }) => {
    setAmountsForm({ ...amountsForm, [name]: value });
  };

  const onAddAmount = () => {
    if (amountsForm.amount === '') {
      setAmountErrors(prev => {
        return {
          ...prev,
          amount: texts.priceerror
        };
      });
    }

    if (amountsForm.desc === '') {
      setAmountErrors(prev => {
        return {
          ...prev,
          desc: texts.categoryerror
        };
      });
    }

    if (amountsForm.amount !== '' && amountsForm.desc !== '') {
      setAmounts(prev => {
        return [
          ...prev,
          {
            amount: parseInt(amountsForm.amount),
            desc: amountsForm.desc
          }
        ];
      });
      setAmountsForm({
        amount: '',
        desc: ''
      });
      setAmountErrors({});
    }
  };

  const onRemove = i => {
    const newAmounts = amounts.filter((amount, index) => index !== i);
    setAmounts(newAmounts);
  };

  const onSubmit = () => {
    if (!form.title.trim() || !form.title) {
      setErrors(prev => {
        return { ...prev, title: texts.titlerequired };
      });
    }

    if (amounts.length === 0) {
      setAmountErrors(prev => {
        return {
          ...prev,
          amount: texts.priceerror,
          desc: texts.categoryerror
        };
      });
    }

    if (Object.values(errors).every(item => !item) && amounts.length !== 0) {
      createEvent(form);
    }
  };

  // Use custom endpoint hook
  const { loading, error: err, modifyData } = useEndpoint();

  const props = {
    name: 'image',
    action: apiConfig.imageUploadUrl,
    maxCount: 1,
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status === 'done') {
        setCoverPhoto(info.file.response.imageURL);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068'
      },
      strokeWidth: 3,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`
    }
  };

  // Use custom endpoint hook to create event
  const createEvent = async ({ title, description, location, date, time }) => {
    const succeeded = await modifyData({
      url: apiConfig.createEventUrl,
      requestData: {
        title,
        description,
        location,
        time,
        amounts,
        expiryDate: date,
        eventImg: coverPhoto
      },
      requestMethod: 'post',
      action: texts.processing,
      successMsg: texts.eventcreated,
      errMsg: texts.failedeventcreate
    });

    succeeded && navigate('./..');
  };

  return (
    <Page title="Create Event" style={{ marginTop: 0 }}>
      <Grid container>
        <Grid md={2} xl={3}></Grid>
        <Grid
          md={8}
          xl={6}
          sx={{
            mt: 4,
            px: 2
          }}
        >
          <BackIcon path={'./..'} text={'Back to Events'} />
          <Loader text={loading}>
            <Box sx={{ mx: '2%' }}>
              <form noValidate autoComplete="off">
                <Typography variant="h5" sx={styles.pageTitle}>
                  Create an Event
                </Typography>

                {/* Basic Info Section */}
                <Box>
                  <FormGroup>
                    <FormControl>
                      <FormInput
                        inputLabel={'Event Title'}
                        inputPlaceholder={'Be clear & concise'}
                        value={form.title}
                        onChange={e => {
                          onInputChange({
                            name: 'title',
                            value: e.target.value
                          });
                        }}
                        required="true"
                      />
                      {errors.title && (
                        <Typography variant="p" sx={styles.error}>
                          {errors.title}
                        </Typography>
                      )}
                    </FormControl>
                  </FormGroup>
                </Box>
                <Divider sx={styles.divider} />

                {/* Price Details Section */}
                <Box>
                  <InfoBox
                    title={'Event Spot(s)'}
                    infoText={
                      "Set your spot categories and their prices. You'll see how much income you get for each spot sold. Save the spot category before proceeding."
                    }
                  />
                  <FormGroup>
                    <Grid container>
                      {amounts.length ? (
                        <Box sx={[styles.itemsCard, bordered, { pb: 0 }]}>
                          {amounts.map((row, index) => {
                            return (
                              <Box
                                key={index}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  paddingBottom: 1,
                                  width: '100%',
                                  marginBottom:
                                    index !== amounts.length - 1 ? 1 : 0,
                                  borderBottom:
                                    index !== amounts.length - 1
                                      ? '1px solid'
                                      : 'none',
                                  borderBottomColor: colors.borderColor
                                }}
                              >
                                <Box sx={{ marginRight: 5, minWidth: '50%' }}>
                                  <Box sx={styles.cardRow}>
                                    <Typography
                                      variant="h6"
                                      sx={styles.smallText}
                                    >
                                      <strong>Category</strong>:
                                    </Typography>
                                    <Typography
                                      variant="p"
                                      sx={styles.smallText}
                                    >
                                      {row.desc ? row.desc : ''}
                                    </Typography>
                                  </Box>
                                  <Box sx={styles.cardRow}>
                                    <Typography
                                      variant="h6"
                                      sx={styles.smallText}
                                    >
                                      <strong>Price</strong>:
                                    </Typography>
                                    <Typography
                                      variant="p"
                                      sx={styles.smallText}
                                    >
                                      {row.amount
                                        ? formatAmount(row.amount)
                                        : ''}{' '}
                                      FCFA
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ alignContent: 'end' }}>
                                  <CustomButton
                                    size="small"
                                    btnStyle={{
                                      mb: 0,
                                      backgroundColor: colors.error,
                                      fontSize: 11,
                                      '&:hover': {
                                        backgroundColor: colors.errorDark
                                      }
                                    }}
                                    on={() => onRemove(index)}
                                    btnTxt="Remove Category"
                                  />
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      ) : null}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={styles.formControl}>
                          <FormInput
                            inputLabel="Spot Category"
                            inputPlaceholder="e.g. VIP, Regular, etc"
                            multitext={true}
                            onChange={e => {
                              onAmountChange({
                                name: 'desc',
                                value: e.target.value
                              });
                            }}
                            value={amountsForm.desc}
                          />
                          {amountErrors.desc && (
                            <Typography variant="p" sx={styles.error}>
                              {amountErrors.desc}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={styles.formControl}>
                          <FormInput
                            inputLabel="Price"
                            name="price"
                            inputPlaceholder="e.g. 10000"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={amountsForm.amount}
                            onChange={e => {
                              const regex = /^[0-9\b]+$/;
                              if (
                                e.target.value === '' ||
                                regex.test(e.target.value)
                              ) {
                                onAmountChange({
                                  name: 'amount',
                                  value: e.target.value
                                });
                              }
                            }}
                          />
                          {amountErrors.amount && (
                            <Typography variant="p" sx={styles.error}>
                              {amountErrors.amount}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={styles.formControl}>
                          <FormInput
                            inputLabel="Income"
                            inputPlaceholder={0}
                            disable={true}
                            value={
                              !amountsForm.amount
                                ? ''
                                : formatAmount(
                                    CalculateRevenue(amountsForm.amount)[0]
                                  ) +
                                  ' ' +
                                  'FCFA'
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <CustomButton
                      size="small"
                      btnStyle={{ maxWidth: '10rem' }}
                      on={() => onAddAmount()}
                      btnTxt="Save spot category"
                    />
                  </FormGroup>
                </Box>
                <Divider sx={styles.divider} />

                {/* Optional Info Section */}
                <Box>
                  <InfoBox
                    title={'Optional Info'}
                    infoText={
                      'The following fields are optional, however, having them will make your event appear captivating.'
                    }
                  />
                  <FormGroup>
                    <Grid container spacing={2} sx={styles.pictureBox}>
                      <Grid item xs={12} sm={6}>
                        <FormControl sx={styles.formControl}>
                          <label style={styles.label}>
                            Upload a Cover Image (ideally, the event flyer)
                          </label>
                          <Upload {...props}>
                            <Button icon={<UploadOutlined />}>
                              Click to Upload
                            </Button>
                          </Upload>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {coverPhoto && (
                          <img src={coverPhoto} style={styles.coverPhoto} />
                        )}
                      </Grid>
                    </Grid>
                    <FormControl sx={[styles.formControl, { mb: 4 }]}>
                      <label
                        style={{
                          fontFamily: ['Ubuntu', 'sans-serif'].join(','),
                          fontSize: 14,
                          marginBottom: '0.5rem'
                        }}
                      >
                        Description
                      </label>
                      <Editor
                        apiKey="8tg6r6puogb0avislexte5wq1elopwf1ufqjwliog1isfsnf"
                        value={form.description}
                        init={{
                          height: 250,
                          menu: false,
                          plugins:
                            'lists advlist link table image hr emoticons',
                          fontFamily: ['Ubuntu', 'sans-serif'].join(',')
                        }}
                        onEditorChange={value => {
                          onInputChange({ name: 'description', value });
                        }}
                      />
                      {errors.description && (
                        <Typography variant="p" sx={styles.error}>
                          {errors.description}
                        </Typography>
                      )}
                    </FormControl>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={styles.formControl}>
                          <FormInput
                            inputLabel={'Event Location'}
                            inputPlaceholder={
                              'If your event will be online, use "Online".'
                            }
                            value={form.location}
                            onChange={e => {
                              onInputChange({
                                name: 'location',
                                value: e.target.value
                              });
                            }}
                          />
                          {errors.location && (
                            <Typography variant="p" sx={styles.error}>
                              {errors.location}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={styles.formControl}>
                          <FormInput
                            inputLabel={'Event Date'}
                            type={'date'}
                            value={form.date}
                            onChange={e => {
                              onInputChange({
                                name: 'date',
                                value: e.target.value
                              });
                            }}
                          />
                          {errors.date && (
                            <Typography variant="p" sx={styles.error}>
                              {errors.date}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={styles.formControl}>
                          <FormInput
                            inputLabel={'Start Time'}
                            inputPlaceholder={'Set the start time.'}
                            value={form.time}
                            onChange={e => {
                              onInputChange({
                                name: 'time',
                                value: e.target.value
                              });
                            }}
                          />
                          {errors.time && (
                            <Typography variant="p" sx={styles.error}>
                              {errors.time}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </FormGroup>
                  {err && (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="p" sx={styles.errorTxt}>
                        {err}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Grid container sx={styles.btnGroup} spacing={1}>
                  <Grid item>
                    <CustomButton
                      size="small"
                      on={() => {
                        navigate('./..');
                      }}
                      btnTxt="Cancel"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <CustomButton
                      size="small"
                      on={() => onSubmit()}
                      btnTxt="Create Event"
                      disable={loading}
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Loader>
        </Grid>
        <Grid md={2} xl={3}></Grid>
      </Grid>
    </Page>
  );
};

export default CreateEvent;
