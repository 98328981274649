import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Upload, message, Button as B } from 'antd';
import { useAuthState } from 'src/context';
import { Icon } from '@ant-design/compatible';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '80%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 12
  },
  text: {
    fontSize: 15,
    color: '#3f51b5',
    fontWeight: 400
  },
  mainText: {
    fontSize: 15,
    color: '#3f51b5',
    fontWeight: 400
  },
  buttons: {
    marginTop: 10,
    textTransform: 'none',
    fontSize: 18
  },
  headingText: {
    color: '#3f51b5',
    fontWeight: 400,
    fontSize: 18
  },
  mainbox: {
    marginBottom: 10,
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const Legal = () => {
  const classes = useStyles();
  const { token, userDetails } = useAuthState();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = React.useState({
    companyName: null,
    registrationNum: null,
    taxPayerNum: null
  });
  React.useEffect(() => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/merchant/legal',
      headers: { 'x-access-token': token }
    }).then(res => {
      setValues(res.data);
    });
  }, []);
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/merchant/legal',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setValues(res.data);
        setIsLoading(false);
        navigate('/app/activate/confirmation', {
          replace: 'true'
        });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  const propOne = {
    name: 'image',
    action: 'https://api.fapshi.com/merchant/upload/front',
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const propTwo = {
    name: 'image',
    action: 'https://api.fapshi.com/merchant/upload/back',
    headers: {
      authorization: 'authorization-text',
      'x-access-token': token
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  return (
    <Page title="Account Activation">
      <Box className={classes.root} sx={{ flexGrow: 1 }}>
        <Box className={classes.mainbox}>
          <Typography
            style={{
              color: 'rgb(26 27 37)',
              fontSize: 35,
              fontWeight: 700,
              fontFamily: 'Ubuntu'
            }}
          >
            Activate your account
          </Typography>
          <Typography
            style={{
              color: '#3e4040',
              fontSize: 14,
              textAlign: 'justify',
              fontWeight: 400,
              fontFamily: 'Ubuntu'
            }}
          >
            Hello {userDetails.username}, Your account is pending activation.
            You won't be able to perform certain transactions. Start by
            activating your account in very simple steps.
          </Typography>
        </Box>

        <Typography
          style={{
            color: '#3f51b5',
            fontSize: 20,
            fontWeight: 700,
            fontFamily: 'Ubuntu',
            marginBottom: 20
          }}
        >
          Merchant account
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Business structure
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Business representative
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Business details
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Fulfilment details
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBoxActive}>
              <Box className={classes.boxActive}>
                <Typography className={classes.mainText} variant="p">
                  Legal fulfilment
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Confirmation
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            style={{
              // backgroundColor: 'rgb(247 250 252)',
              borderRadius: 4,
              padding: 25,
              border: '1px solid #e7eaf5'
            }}
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
          >
            <Box>
              <Typography variant="h3">Legal fulfilment</Typography>
              <br />
              <Typography
                style={{
                  marginTop: 7,
                  color: '#3f51b5',
                  fontWeight: 400,
                  fontSize: 16
                }}
                variant="p"
              >
                Please give us some insights about the legal bindings of your
                business.
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box>
                {error && (
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      color: '#eb6558',
                      fontWeight: 500,
                      fontSize: 20,
                      marginTop: 7
                    }}
                  >
                    <Typography variant="p"> {eresponse}</Typography>
                  </InputLabel>
                )}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-name"
                  style={{
                    marginTop: 7,
                    color: '#3f51b5',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Company name</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Company name"
                  margin="normal"
                  name="companyName"
                  type="text"
                  value={values.companyName}
                  onChange={handleChange}
                  id="bootstrap-input-name"
                  required
                  style={{ marginRight: 8 }}
                />
                <Box style={{ marginTop: 10 }}>
                  <Typography
                    style={{
                      marginTop: 12,
                      color: '#3f51b5',
                      fontWeight: 400,
                      fontSize: 15
                    }}
                    variant="p"
                  >
                    National ID
                  </Typography>
                  <br />
                  <small
                    style={{
                      color: '#3f51b5',
                      fontWeight: 400,
                      fontSize: 12
                    }}
                  >
                    Upload your Id. Make sure the ID card belongs to you. We
                    will use it for accountability purposes.
                  </small>
                  <br />
                  <br />
                  <Upload {...propOne}>
                    <B
                      style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}
                    >
                      <Icon type="upload" /> Upload a picture of the front side
                      of your ID
                    </B>
                  </Upload>
                  <br />
                  <Upload {...propTwo}>
                    <B
                      style={{ boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)' }}
                    >
                      <Icon type="upload" /> Upload a picture of the back side
                      of your ID
                    </B>
                  </Upload>
                  <br />
                </Box>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    marginTop: 7,
                    color: '#3f51b5',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                ></InputLabel>

                <Box
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    className={classes.buttons}
                    variant="outlined"
                    color="default"
                    startIcon={<ArrowBackIcon>send</ArrowBackIcon>}
                    type="submit"
                    onClick={() =>
                      navigate('/app/activate/fulfilment', { replace: 'true' })
                    }
                    size="small"
                  >
                    Previous
                  </Button>
                  <Button
                    className={classes.buttons}
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIcon>send</ArrowForwardIcon>}
                    onClick={handleSubmit}
                    size="small"
                  >
                    {isLoading ? <CircularProgress size="14px" /> : 'Confirm'}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Legal;
