import axios from 'axios';
import { apiConfig } from './apiConfig';

let headers = {};

const getToken = async () => {
  try {
    let userInfo = await localStorage.getItem('currentUser');
    const user = JSON.parse(userInfo);
    return user.accessToken;
  } catch (e) {
    console.log(e);
  }
};

const axiosInstance = axios.create({
  baseURL: apiConfig.baseUrl,
  headers
});

axiosInstance.interceptors.request.use(
  async config => {
    const token = await getToken();
    config.headers['x-access-token'] = token;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
