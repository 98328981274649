import axios from 'axios';
import React, { createContext, useEffect, useState, useMemo } from 'react';
import { apiConfig } from '../utils/apiConfig';

export const RatesContext = createContext();

export const RatesProvider = ({ children }) => {
  const [rates, setRates] = useState({});

  const getRates = async ctrl => {
    try {
      const res = await axios.get(`${apiConfig.baseUrl}/rates`, {
        signal: ctrl.signal
      });
      setRates(res.data.rates);
    } catch (err) {
      if (err.isAxiosError) {
        if (err.response) {
          // Server was able to send us a response, so this is an API Error.
          console.log(err.response.data);
        } else {
          // Axios was not able to get a response at all. This is a Network-Level Error.
          // Some other errors
          console.log('[Network Error]: No Response Received');
        }
      } else {
        // Standard JS Error (Syntax, etc...)
        console.log('[Non-HTTP Error]:', err);
      }
    }
  };

  useEffect(() => {
    const ctrl = new AbortController();
    getRates(ctrl);
    return () => ctrl.abort();
  }, []);

  const { donationRate } = rates;

  return (
    <RatesContext.Provider
      value={useMemo(() => ({ donationRate }), [donationRate])}
    >
      {children}
    </RatesContext.Provider>
  );
};
