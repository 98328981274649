import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'src/context';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '80%'
  },
  logo: {
    width: '90px',
    height: '50px',
    marginTop: 150
  },
  introbox: {
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginTop: 100,
    width: 'auto'
  },
  error: {
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    display: 'block',
    borderRadius: 2,
    height: 50
  },
  boxActive: {
    borderLeft: '1px dotted #f4f4f5',
    borderTop: '1px dotted #f4f4f5',
    paddingLeft: 10,
    height: 10
  },
  box: { paddingLeft: 10 },
  textActive: {
    borderRight: '2px solid #3f51b5'
  },
  mainBox: {
    marginBottom: 0
  },
  mainBoxActive: {
    marginBottom: 12
  },
  text: {
    fontSize: 15,
    color: '#546e7a',
    fontWeight: 400
  },
  mainText: {
    fontSize: 15,
    color: '#3f51b5',
    fontWeight: 400
  },
  buttons: {
    marginTop: 10,
    textTransform: 'none',
    fontSize: 18
  },
  headingText: {
    color: '#546e7a',
    fontWeight: 400,
    fontSize: 18
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 20%)',
    fontSize: 16,
    width: '100%',
    height: '12px',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const Representative = () => {
  const classes = useStyles();
  const [active, setActive] = React.useState(true);
  const { token } = useAuthState();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = React.useState({
    industry: null,
    website: null,
    activity: null
  });
  React.useEffect(() => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/merchant/details',
      headers: { 'x-access-token': token }
    }).then(res => {
      setValues(res.data);
    });
  }, []);
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/merchant/details',
      data: values,
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setValues(res.data);
        setIsLoading(false);
        navigate('/app/activate/fulfilment', {
          replace: 'true'
        });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  return (
    <Page title="Account Activation">
      <Box className={classes.root} sx={{ flexGrow: 1 }}>
        <Box style={{ marginBottom: 10 }}>
          <Typography
            style={{
              color: 'rgb(26 27 37)',
              fontSize: 35,
              fontWeight: 700,
              fontFamily: 'Ubuntu'
            }}
          >
            Activate your accounts
          </Typography>
          <Typography
            style={{
              color: '#546e7a',
              fontSize: 14,
              textAlign: 'justify',
              fontWeight: 400,
              fontFamily: 'Ubuntu'
            }}
          >
            Hello, for you to have access to our APIs (Merchant) or be a vendor
            on our platform, you must comply with us by providing some details
            about you, your business or its representatives. This usually takes
            at most 10mins. We are sorry for the inconvenience. It is important
            to us and our partners that we know whom we are dealing with.
          </Typography>
        </Box>
        <Box style={{ marginBottom: 30 }}>
          <Button
            color="primary"
            style={{ borderRadius: 20, marginRight: 5 }}
            variant={active ? 'contained' : 'outlined'}
            size="small"
            onClick={() => {
              setActive(true);
              navigate('/app/activate/structure', { replace: true });
            }}
          >
            Activate Merchant Account
          </Button>
          <Button
            color="primary"
            style={{ borderRadius: 20 }}
            variant={active ? 'outlined' : 'contained'}
            onClick={() => {
              setActive(false);
              navigate('/app/activate/vendor', { replace: true });
            }}
            size="small"
          >
            Activate Vendor Account
          </Button>
        </Box>
        <Typography
          style={{
            color: '#546e7a',
            fontSize: 20,
            fontWeight: 700,
            fontFamily: 'Ubuntu',
            marginBottom: 20
          }}
        >
          Merchant account
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Business structure
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Business representative
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBoxActive}>
              <Box className={classes.boxActive}>
                <Typography className={classes.mainText} variant="p">
                  Business details
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Fulfilment details
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Legal fulfilment
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBox}>
              <Box className={classes.box}>
                <Typography className={classes.text} variant="p">
                  Confirmation
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            style={{
              // backgroundColor: 'rgb(247 250 252)',
              borderRadius: 4,
              padding: 25,
              border: '1px solid #e7eaf5'
            }}
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
          >
            <Box>
              <Typography
                style={{
                  marginTop: -5,
                  color: '#546e7a'
                }}
                variant="h3"
              >
                Business details
              </Typography>
              <br />
              <Typography
                style={{
                  marginTop: 7,
                  color: '#546e7a',
                  fontWeight: 400,
                  fontSize: 16
                }}
                variant="p"
              >
                Tell us some basics about your business
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box>
                {error && (
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      color: '#eb6558',
                      fontWeight: 500,
                      fontSize: 20,
                      marginTop: 7
                    }}
                  >
                    <Typography variant="p"> {eresponse}</Typography>
                  </InputLabel>
                )}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-industry"
                  style={{
                    marginTop: 7,
                    color: '#546e7a',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">Industry</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Financial services"
                  margin="normal"
                  name="industry"
                  type="text"
                  id="bootstrap-input-industry"
                  value={values.industry}
                  onChange={handleChange}
                  required
                  style={{ marginRight: 8 }}
                />

                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-website"
                  style={{
                    marginTop: 7,
                    color: '#546e7a',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">
                    Business website(Optional)
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="fapshi.com"
                  margin="normal"
                  name="website"
                  type="text"
                  value={values.website}
                  onChange={handleChange}
                  id="bootstrap-input-website"
                  style={{ marginTop: 5, marginRight: 8 }}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-activity"
                  style={{
                    marginTop: 7,
                    color: '#546e7a',
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  <Typography variant="p">
                    What does your business do ?
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Give us a summary of your business"
                  margin="normal"
                  name="activity"
                  type="text"
                  id="bootstrap-input-activity"
                  value={values.activity}
                  onChange={handleChange}
                  required
                  style={{ marginTop: 5, marginRight: 8 }}
                  multiline
                  rows={2}
                />
                <Box
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    className={classes.buttons}
                    variant="outlined"
                    color="default"
                    startIcon={<ArrowBackIcon />}
                    type="submit"
                    onClick={() =>
                      navigate('/app/activate/representative', {
                        replace: 'true'
                      })
                    }
                    size="small"
                  >
                    Previous
                  </Button>
                  <Button
                    className={classes.buttons}
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleSubmit}
                    size="small"
                    disabled={
                      values.industry == null || values.activity == null
                        ? true
                        : false
                    }
                  >
                    {isLoading ? <CircularProgress size="14px" /> : 'Next'}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Representative;
