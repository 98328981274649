import React from 'react';
import { useNavigate } from 'react-router';
import { Button, Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import theme from 'src/assets/theme';

const { colors } = theme;

const styles = {
  returnBtn: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: '1rem',
    textTransform: 'none',
    color: colors.primaryColor,
    padding: 0,
    ml: '-6px',
    '&:hover': {
      backgroundColor: 'none'
    }
  }
};

const BackIcon = ({ text, path }) => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate(path, { replace: true });
  };
  return (
    <Box>
      <Button onClick={handleReturn} sx={styles.returnBtn}>
        <ChevronLeftIcon /> {text}
      </Button>
    </Box>
  );
};

export default BackIcon;
