import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import Button from '@material-ui/core/Button';
import NoResults from 'src/components/NoResults';
import { Container, Grid, Box, Hidden, Popover } from '@material-ui/core';
import Bar from 'src/components/Bar';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@material-ui/core/';
import { useNavigate } from 'react-router-dom';
import PaymentsTab from 'src/components/PaymentsTab';
import axios from 'axios';
import { useAuthState } from 'src/context';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { alpha } from '@mui/material/styles';
import { CreditCard as PaymentIcon } from 'react-feather';
import { formatAmount } from 'src/utils/helperFunctions';
import ShareBtn from 'src/components/Buttons/ShareButton';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50%',
      textAlign: 'center',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
    },
    width: '100%',
    borderTop: '1px  solid #e0e0e03d',
    borderLeft: '1px  solid #e0e0e03d',
    borderRight: '1px  solid #e0e0e03d'

    // backgroundColor: '#fdfdff'
  },
  label: {
    marginLeft: 8
  },
  button: {
    textTransform: 'none',
    display: 'block',
    textAlign: 'left',
    overflowY: 'hidden'
  },
  box: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    width: 40,
    border: '1px solid #fff',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  },
  buttonText: {
    fontSize: 14
  },
  popBox: {
    minWidth: 120
  }
}));

const ListView = () => {
  const classes = useStyles();
  const [bid, setBid] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorOEl, setAnchorOEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleIconClick = (event, data) => {
    setBid(data);
    setAnchorOEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleIconClose = () => {
    setAnchorOEl(null);
  };

  const openI = Boolean(anchorEl);
  const id = openI ? 'simple-popover' : undefined;
  const openO = Boolean(anchorOEl);
  const oid = openO ? 'simple-popover' : undefined;
  const navigate = useNavigate();
  const { token } = useAuthState();
  const handleSimpleInvoiceClick = () => {
    navigate('/app/payment-link/simple', { replace: true });
  };
  const [result, setResult] = useState([]);
  const [progressDisplay, setProgressDisplay] = useState(false);
  useEffect(() => {
    setProgressDisplay(true);
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/donation',
      headers: { 'x-access-token': token }
    }).then(response => {
      setProgressDisplay(false);
      const data = response.data;
      setResult(data);
      console.log(result);
    });
  }, []);
  const handleAdvancedInvoiceClick = () => {
    navigate('/app/payment-link/donation', { replace: true });
  };
  const handleVariableClick = () => {
    navigate('/app/payment-link/variable', { replace: true });
  };

  const handleDeleteClick = id => {
    const newResult = result.filter(res => {
      return res._id != id;
    });
    axios({
      method: 'delete',
      url: 'https://api.fapshi.com/donation/' + id,
      headers: { 'x-access-token': token }
    }).then(() => {
      setResult(newResult);
    });
  };

  const handleViewClick = data => {
    navigate('/app/payment-link/details', { replace: true, state: { data } });
  };
  const handleEditClick = data => {
    if (data.type === 'Donation') {
      navigate('/app/payment-link/donation/edit', {
        replace: false,
        state: { data }
      });
    } else {
      if (data.amounts?.length === 0 && data.type === 'Payment link') {
        navigate('/app/payment-link/simple/edit', {
          replace: false,
          state: { data }
        });
      } else {
        navigate('/app/payment-link/variable/edit', {
          replace: false,
          state: { data }
        });
      }
    }
  };
  const handlePreviewClick = data => {
    if (data.type === 'Donation') {
      navigate('/app/donation/' + data.donationId, {
        replace: false,
        state: { data }
      });
    } else {
      navigate('/app/link/' + data.donationId, {
        replace: false,
        state: { data }
      });
    }
  };
  return (
    <Page title="Payment Links">
      <Container maxWidth={false}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="links" />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            xl={10}
            style={{ marginBottom: 50 }}
          >
            <Bar
              title="Payment Links"
              btnText="Create a link"
              onClick={handleClick}
              icon={true}
            />
            <Popover
              id={id}
              open={openI}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              style={{ marginTop: 4 }}
            >
              <Box className={classes.popBox}>
                <Button
                  onClick={handleSimpleInvoiceClick}
                  fullWidth
                  className={classes.button}
                >
                  <Typography className={classes.buttonText} variant="p">
                    {' '}
                    Simple Link
                  </Typography>
                </Button>
                <Divider style={{ backgroundColor: '#f4f4f5' }} />
                <Button
                  onClick={handleAdvancedInvoiceClick}
                  fullWidth
                  className={classes.button}
                >
                  <Typography className={classes.buttonText} variant="p">
                    {' '}
                    Donation Link
                  </Typography>
                </Button>
                <Divider style={{ backgroundColor: '#f4f4f5' }} />
                <Button
                  onClick={handleVariableClick}
                  fullWidth
                  className={classes.button}
                >
                  <Typography className={classes.buttonText} variant="p">
                    {' '}
                    Variable Link
                  </Typography>
                </Button>
              </Box>
            </Popover>
            <Box
              style={{
                display: progressDisplay ? 'block' : 'none'
              }}
            >
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: theme => theme.zIndex.drawer + 20,
                  backdropFilter: 'blur(2px)',
                  backgroundColor: '#ffffff59'
                }}
                open={open}
              >
                <CircularProgress
                  size="1.5rem"
                  style={{
                    display: progressDisplay ? 'block' : 'none',
                    marginLeft: '20%',
                    marginTop: '15%',
                    marginBottom: '10%',
                    color: '#3f51b5'
                  }}
                />
              </Backdrop>
            </Box>
            <Box
              style={{
                display: progressDisplay ? 'none' : 'block'
              }}
            >
              {result.length > 0 ? (
                result.map(data => (
                  <List className={classes.root}>
                    <React.Fragment>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              {data.organisation} ({data.noOfDonation})
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                className={classes.inline}
                                color="textPrimary"
                                variant="p"
                              >
                                Created on{' '}
                                {moment(data.createdAt).format('DD/MM/YYYY')}
                                {' at '}
                                {moment(data.createdAt).format('h:mm:ss A')}
                              </Typography>
                              <Typography variant="h6">
                                {` — ${
                                  data.type
                                }: Total income received (${formatAmount(
                                  data.totalReceived
                                )} FCFA)  `}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <Box style={{ margin: '2px 10px 0px 0px' }}>
                          <ShareBtn
                            url={
                              data?.type === 'Donation'
                                ? `https://checkout.fapshi.com/donation/${data.donationId}`
                                : `https://checkout.fapshi.com/link/${data.donationId}`
                            }
                            text="Share Link"
                            type="btn"
                            icon="share"
                          />
                        </Box>
                        <IconButton
                          className={classes.iconButton}
                          style={{
                            height: 20
                          }}
                          onClick={event => handleIconClick(event, data)}
                        >
                          <MoreHorizIcon
                            style={{ fontSize: 22, cursor: 'pointer' }}
                          />
                        </IconButton>
                      </ListItem>
                    </React.Fragment>
                  </List>
                ))
              ) : (
                <NoResults
                  title="Payment links created"
                  content="Payment links allow anyone to collect payments by creating and sharing a link."
                  link="https://documentation.fapshi.com/#payment-links"
                  icon={
                    <PaymentIcon
                      size={35}
                      style={{
                        fontSize: 40,
                        border: '1px solid #bdc3c9',
                        boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                        borderRadius: 10,
                        color: '#bdc3c9',
                        padding: 5
                      }}
                    />
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Popover
          style={{ marginTop: 3 }}
          id={oid}
          open={openO}
          anchorEl={anchorOEl}
          onClose={handleIconClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Button
            onClick={() => handlePreviewClick(bid)}
            fullWidth
            className={classes.button}
          >
            <Typography variant="p" className={classes.buttonText}>
              {' '}
              Preview
            </Typography>
          </Button>
          <Divider style={{ backgroundColor: '#f4f4f5' }} component="li" />
          <Button
            onClick={() => handleViewClick(bid)}
            fullWidth
            className={classes.button}
          >
            <Typography variant="p" className={classes.buttonText}>
              {' '}
              View details
            </Typography>
          </Button>
          <Divider style={{ backgroundColor: '#f4f4f5' }} component="li" />
          <Button
            onClick={() => handleEditClick(bid)}
            fullWidth
            className={classes.button}
          >
            <Typography variant="p" className={classes.buttonText}>
              {' '}
              Edit details
            </Typography>
          </Button>
          <Divider style={{ backgroundColor: '#f4f4f5' }} component="li" />
          <Button
            onClick={() => handleDeleteClick(bid._id)}
            fullWidth
            className={classes.button}
          >
            <Typography
              variant="p"
              className={classes.buttonText}
              style={{ color: '#d40000' }}
            >
              Delete link
            </Typography>
          </Button>
        </Popover>
      </Container>
    </Page>
  );
};

export default ListView;
