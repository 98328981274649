import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Typography,
  Popover,
  Divider,
  Grid
} from '@material-ui/core';
import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  Repeat as BillingIcon,
  CheckCircle as ComplianceIcon,
  ChevronDown as DownIcon,
  CreditCard as PaymentIcon,
  Package as ProductsIcon,
  Activity as TransactionIcon,
  Edit as InvoiceIcon,
  Repeat as TransferIcon,
  ShoppingBag as OrderIcon,
  ShoppingCart as StoreIcon
} from 'react-feather';
import { GiPayMoney } from 'react-icons/gi';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NavItems from './NavItems';
import NavTopItem from './NavTopItem';
import StorefrontTwoToneIcon from '@material-ui/icons/StorefrontTwoTone';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Input } from 'antd';
import { useAuthState } from 'src/context';
import { useServiceState } from 'src/context';
import axios from 'src/axios';
import { UserService, useServiceDispatch, getUserService } from 'src/context';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from 'src/components/Snackbar';
import Backdrop from '@mui/material/Backdrop';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 250
  },
  desktopDrawer: {
    width: 250,
    top: 30,
    borderRight: '2px dashed #F4F6F8', //ffffff
    backgroundColor: '#ffffff', //#f6faffab
    // marginTop: -70,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  name: {
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: 0.7,
    color: '#000',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      fontWeight: '600',
      color: '#000000b5'
    }
  },
  itemlist: {
    marginBottom: '-16px !important',
    marginLeft: '5px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px !important'
    }
  },
  orderItemlist: {
    marginTop: '-11px',
    marginBottom: '-20px',
    marginLeft: '10px'
  },
  itemlistfirst: {
    marginBottom: '-15px !important',
    marginLeft: '5px',
    marginTop: '-11px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px !important'
    }
  },
  itemlistlast: {
    marginLeft: '5px',
    marginBottom: '0px !important'
  },
  usernameBox: {
    cursor: 'pointer',
    height: 'auto',
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      maxWidth: '100%'
    }
  },
  viewMoreBtn: {
    textTransform: 'none',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    borderRadius: 20,
    height: 24,
    color: '#000',
    border: '1px solid #ffffff',
    '&:hover': {
      border: '1px solid #f4f4f5',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
      backgroundColor: '#ffffff',
      color: '#3f51b5'
    }
  },
  list: {
    [theme.breakpoints.down('md')]: {
      fontSize: 8
    }
  },
  box: {
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  menuItem: {
    marginTop: -10,
    [theme.breakpoints.down('md')]: {
      marginLeft: 5
    },
    minWidth: '200px',
    paddingTop: 10,
    '&:hover': {
      backgroundColor: '#f4f4f5'
    },
    '&:focus': {
      backgroundColor: '#fff'
    }
  },
  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '35px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState('optional');
  const { token, userDetails } = useAuthState();
  const [snack, setSnack] = useState(false);
  const [error, setError] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const navigate = useNavigate();

  const [display, setDisplay] = useState(true);

  const [orderDisplay, setOrderDisplay] = useState(true);

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useServiceDispatch(event);

  const handleClick = async event => {
    setAnchorEl(event.currentTarget);
    await UserService(dispatch, token);
  };

  const handleDisplay = () => {
    setDisplay(!display);
  };

  const handleOrderDisplay = () => {
    setOrderDisplay(!orderDisplay);
  };

  const { userService, singleUserService } = useServiceState();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);

  const handleServiceOpen = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handleServiceClose = () => {
    setOpen(false);
  };

  const [service, setService] = useState(null);
  const [domain, setDomain] = useState(null);

  const serviceValues = {
    serviceName: service,
    domain: domain
  };

  const handleServiceSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: '/userService',
      data: serviceValues,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setIsLoading(false);
        setSnack(true);
        setService(null);
        setDomain(null);
      })
      .catch(error => {
        setIsLoading(false);
        setError(true);
        setEresponse(error.response?.data.message);
        if (error.response?.status == 401)
          navigate('/login', { replace: true });
      });
  };

  const handleServiceSwitch = async id => {
    setAnchorEl(null);
    setBackdrop(true);
    axios({
      method: 'get',
      url: '/userService/' + id,
      headers: { 'x-access-token': token }
    }).then(res => {
      getUserService(dispatch, res);
      setTimeout(() => {
        setBackdrop(false);
        window.location.reload();
      }, 3000);
    });
  };

  const [anchorCEl, setAnchorCEl] = React.useState(null);
  const openI = Boolean(anchorCEl);
  const id = openI ? 'simple-popover' : undefined;

  const handleIconClick = event => {
    setAnchorCEl(event.currentTarget);
  };

  const handleIconClose = () => {
    setAnchorCEl(null);
  };

  const handleSnackClose = () => {
    setSnack(false);
  };

  const content = (
    <>
      <Hidden mdDown>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
            backdropFilter: 'blur(20px)',
            backgroundColor: '#ffffff59'
          }}
          open={backdrop}
        >
          <CircularProgress size={22} color="primary" />
          <Typography>Switching</Typography>
        </Backdrop>
        <Snackbar
          duration={4000}
          close={handleSnackClose}
          data="Service created successfully!"
          open={snack}
        />
        <Dialog
          open={open}
          onClose={handleServiceClose}
          aria-labelledby="form-dialog-title"
          fullwidth={true}
          BackdropProps={{
            classes: {
              root: classes.backDrop
            }
          }}
        >
          <DialogTitle
            style={{
              borderBottom: '1px solid rgb(229 231 243)',
              padding: 10
            }}
            id="form-dialog-title"
          >
            <Typography variant="h5" style={{ letterSpacing: 0.4 }}>
              Create a new service
            </Typography>
          </DialogTitle>
          <DialogContent>
            {error && (
              <Box style={{ marginLeft: 10 }}>
                <Typography
                  variant="p"
                  style={{
                    color: '#eb6558',
                    fontWeight: 700,
                    fontSize: 14
                  }}
                >
                  {eresponse}
                </Typography>
              </Box>
            )}
            <Form
              form={form}
              layout="horizontal"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 16 }}
              initialValues={{
                requiredMarkValue: requiredMark
              }}
              onValuesChange={onRequiredTypeChange}
              requiredMark={requiredMark}
            >
              <Form.Item label="Service name" required tooltip="Service name">
                <Input
                  required
                  onChange={e => setService(e.target.value)}
                  placeholder="Enter the service name"
                  value={service}
                  className={classes.input}
                />
              </Form.Item>
              <Form.Item label="Domain name" required tooltip="Domain name">
                <Input
                  required
                  onChange={e => setDomain(e.target.value)}
                  placeholder="e.g.,: fapshi.com"
                  value={domain}
                  className={classes.input}
                />
              </Form.Item>
              <Form.Item label="Country" required tooltip="country">
                <Input
                  className={classes.input}
                  placeholder="Cameroon"
                  disabled
                />
              </Form.Item>
              <DialogActions
                style={{
                  borderTop: '1px solid rgb(229 231 243)',
                  padding: 10
                }}
              >
                <Button
                  onClick={handleServiceClose}
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={{
                    marginRight: '5px',
                    textTransform: 'none',
                    fontWeight: '700',
                    letterSpacing: 0.7
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleServiceSubmit}
                  color="primary"
                  variant="contained"
                  size="small"
                  type="submit"
                  style={{
                    textTransform: 'none',
                    fontWeight: '700',
                    letterSpacing: 0.7
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size="14px" />
                  ) : (
                    'Create Service'
                  )}
                </Button>
              </DialogActions>
            </Form>
          </DialogContent>
        </Dialog>
        <Box
          height="100%"
          display="flex"
          flexDirection="row"
          alignItems={'center'}
          justifyContent={'space-between'}
          className={classes.box}
        >
          <Box
            className={classes.usernameBox}
            display="flex"
            flexDirection="row"
            alignItems={'center'}
            onClick={handleClick}
          >
            <StorefrontTwoToneIcon
              style={{
                height: 18,
                width: 18,
                color: '#3f51b5',
                marginRight: '2px'
              }}
            />
            {singleUserService == '' ? (
              <Typography
                className={classes.name}
                color="textPrimary"
                variant="h6"
              >
                Merchants
              </Typography>
            ) : (
              <Typography
                className={classes.name}
                color="textPrimary"
                variant="h6"
              >
                {singleUserService.data.serviceName.length < 7
                  ? singleUserService.data.serviceName
                  : singleUserService.data.serviceName.slice(0, 7) + '...'}
              </Typography>
            )}
            <DownIcon
              size="16"
              color="#9d9ca0"
              style={{ marginTop: '3px', marginLeft: '3px' }}
            />
          </Box>
          <Box className={classes.menu}>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.menu}
            >
              {userService &&
                userService.map(data => (
                  <MenuItem
                    onClick={() => handleServiceSwitch(data.serviceId)}
                    className={classes.menuItem}
                    key={data._id}
                    value={data.apiUser}
                  >
                    <ListItemIcon>
                      <StorefrontTwoToneIcon
                        style={{
                          fontSize: 22,
                          '&:hover': { color: '#ffffff' }
                        }}
                      />
                    </ListItemIcon>
                    <Typography
                      style={{ marginLeft: '-33px' }}
                      variant="inherit"
                    >
                      {data.serviceName}
                    </Typography>
                  </MenuItem>
                ))}
              <Button
                style={{ margin: '15px', textTransform: 'none' }}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleServiceOpen}
                disabled={!userDetails.isVendor}
              >
                New Service
              </Button>
            </Menu>
          </Box>
          <List className={classes.list}>
            <NavTopItem
              key={'dashboard'}
              href="/app/dashboard"
              title="Dashboard"
              icon={HomeIcon}
            />
            <NavTopItem
              key={'compliance'}
              href={
                userDetails.isVendor
                  ? '/app/activate/vendor/confirmation'
                  : '/app/activate/vendor'
              }
              title="Compliance"
              icon={ComplianceIcon}
            />
            <NavTopItem
              key={'events'}
              href="/app/events"
              title="Events"
              icon={BillingIcon}
              className={classes.navlist}
            />
            <NavTopItem
              key={'balances'}
              href="/app/balances"
              title="Balances"
              icon={BillingIcon}
            />
            <NavTopItem
              key={'settings'}
              href="/app/settings"
              title="Settings"
              icon={SettingsIcon}
            />
            <Button
              className={classes.viewMoreBtn}
              variant="outlined"
              color="default"
              onClick={handleIconClick}
            >
              More
              <KeyboardArrowDownIcon
                style={{ fontSize: 20, marginLeft: 2, marginTop: 2 }}
              />
            </Button>
            <Box>
              <Popover
                style={{ paddingTop: 20, width: 700, marginTop: 5 }}
                id={id}
                open={openI}
                anchorEl={anchorCEl}
                onClose={handleIconClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <Box
                  style={{
                    width: 220,
                    padding: 20,
                    backgroundColor: '#ffffff'
                  }}
                >
                  <Typography style={{ fontSize: 18 }} variant="h4">
                    Payments
                  </Typography>
                  <Divider
                    width="100%"
                    style={{
                      backgroundColor: 'inherit',
                      margin: '5px -20px 10px -20px'
                    }}
                  />
                  <Grid
                    container
                    style={{
                      backgroundColor: '#fdfeff'
                    }}
                  >
                    <Grid item>
                      <NavItems
                        href="/app/transfers"
                        title="Transfers"
                        icon={TransferIcon}
                      />
                      <NavItems
                        href="/app/bulk-payments"
                        title="Bulk payments"
                        icon={GiPayMoney}
                      />
                      <NavItems
                        href="/app/invoices"
                        title="Invoices"
                        icon={InvoiceIcon}
                      />
                      <NavItems
                        href="/app/transactions"
                        title="Transactions"
                        icon={TransactionIcon}
                        onClick={handleDisplay}
                      />
                      <NavItems
                        href="/app/payment-link"
                        title="Payment link"
                        icon={PaymentIcon}
                      />
                    </Grid>
                  </Grid>
                  <Divider
                    width="100%"
                    style={{
                      backgroundColor: 'inherit',
                      margin: '5px -20px 10px -20px'
                    }}
                  />
                  <Typography style={{ fontSize: 18 }} variant="h4">
                    Store
                  </Typography>
                  <Divider
                    width="100%"
                    style={{
                      backgroundColor: 'inherit',
                      margin: '5px -20px 10px -20px'
                    }}
                  />
                  <Grid
                    container
                    style={{
                      backgroundColor: '#fdfeff'
                    }}
                  >
                    <Grid item>
                      <NavItems
                        onClick={handleOrderDisplay}
                        href="/app/store"
                        title="Store"
                        icon={StoreIcon}
                      />
                      <NavItems
                        href="/app/products"
                        title="Products"
                        icon={ProductsIcon}
                      />
                      <NavItems
                        onClick={handleOrderDisplay}
                        href="/app/store/orders"
                        title="Orders"
                        icon={OrderIcon}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Popover>
            </Box>
          </List>
        </Box>
      </Hidden>
    </>
  );

  return (
    <>
      <Hidden lgUp>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
            backdropFilter: 'blur(3px)',
            backgroundColor: '#ffffff59'
          }}
          open={backdrop}
        >
          <CircularProgress size={18} color="primary" />
        </Backdrop>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box>{content}</Box>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
