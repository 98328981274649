import React, { useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  Divider,
  IconButton,
  Hidden
} from '@material-ui/core';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Page from 'src/components/Page';
import axios from 'axios';
import { useAuthState } from 'src/context';
import { useLocation } from 'react-router-dom';
import Snackbar from 'src/components/Snackbar';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {},
  introbox: {},
  error: {
    textAlign: 'left'
  },
  box: {
    width: 480,
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  button: {
    textTransform: 'none',
    borderRadius: 8,
    fontSize: 14,
    margin: 2,
    height: 35,
    border: '1px solid #ced4da',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)'
  },
  inputText: {
    fontSize: 20,
    color: '#181819',
    fontWeight: 900,
    letterSpacing: 1
  },
  btnOne: {
    marginTop: 10,
    marginLeft: 180,
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      marginLeft: 0
    }
  },
  btnTwo: {
    marginTop: 10,
    marginLeft: 95,
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      marginLeft: 0,
      marginBottom: 50
    }
  },
  btn: {
    marginTop: 10,
    width: 334,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '70%',
    height: '12px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const SimpleItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;
  const classes = useStyles();
  const { token } = useAuthState();
  const [values, setValues] = useState({
    name: data.clientName,
    email: data.clientEmail,
    phone: data.clientPhone,
    address: data.address,
    note: data.reason,
    amount: data.total,
    date: null,
    items: null,
    price: null,
    quantity: null,
    shipping: data.deliveryFee
  });

  const handleChange = e => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };
  const [itemsCon, setItemsCon] = useState([]);
  useEffect(() => {
    const st = data.invoiceContent.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);
    setTotal(st);
    return setItemsCon(data.invoiceContent);
  }, []);
  const [total, setTotal] = useState(null);
  const handleItems = e => {
    e.preventDefault();
    const data = {
      id: Math.ceil(Math.random() * 1000),
      item: values.items,
      qty: parseInt(values.quantity),
      unitPrice: parseInt(values.price),
      stotal: values.price * values.quantity
    };
    itemsCon.push(data);
    setItemsCon(itemsCon);
    const st = itemsCon.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);
    setTotal(st);
  };
  const handleSaveItems = e => {
    e.preventDefault();
    const data = {
      id: Math.ceil(Math.random() * 1000),
      item: values.items,
      qty: parseInt(values.quantity),
      unitPrice: parseInt(values.price),
      stotal: values.price * values.quantity
    };
    itemsCon.push(data);
    setItemsCon(itemsCon);
    const st = itemsCon.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);
    setTotal(st);
    setValues({ ...values, items: '', quantity: '', price: '' });
  };
  const handleRemoveItem = id => {
    console.log(id);
    const rs = itemsCon.filter(res => res.id !== id);
    setItemsCon(rs);
    const st = rs.reduce((total, val) => {
      return total + val.qty * val.unitPrice;
    }, 0);
    setTotal(st);
  };
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    const val = {
      clientName: values.name,
      clientEmail: values.email,
      address: values.address,
      phone: values.phone,
      reason: values.note,
      content: itemsCon,
      dueDate: values.date,
      shipping: parseInt(values.shipping)
    };
    axios({
      method: 'post',
      data: val,
      url: 'https://api.fapshi.com/invoice',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsLoading(false);
        setValues({});
        navigate('/app/invoices', { replace: false });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          borderRight: '2px solid #eaeaea6e'
        }}
      >
        <Page className={classes.root} title="Login">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.box}
          >
            <Container maxWidth="sm">
              <Box style={{ display: 'flex' }}>
                <Typography variant="h5" style={{ marginBottom: 30 }}>
                  Invoice Details
                </Typography>
              </Box>
              {snack && <Snackbar data="Invoice sent successfully" />}
              {error && (
                <Typography
                  variant="p"
                  style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                >
                  {eresponse}
                </Typography>
              )}
              <form>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Customer name
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter customer name"
                  margin="normal"
                  name="name"
                  type="text"
                  value={values.name}
                  id="bootstrap-input"
                  required
                  autoFocus
                  onChange={handleChange}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Customer email
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter customer email"
                  margin="normal"
                  name="email"
                  type="text"
                  value={values.email}
                  id="bootstrap-input"
                  required
                  onChange={handleChange}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Phone
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter phone"
                  margin="normal"
                  name="phone"
                  type="number"
                  value={values.phone}
                  id="bootstrap-input"
                  required
                  onChange={handleChange}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Address
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter customer address"
                  margin="normal"
                  name="address"
                  type="text"
                  value={values.address}
                  id="bootstrap-input"
                  required
                  onChange={handleChange}
                />{' '}
                <Box className={classes.btn}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    size="small"
                    style={{ float: 'right' }}
                    onClick={handleSubmit}
                  >
                    Save details
                  </Button>
                  <Button
                    color="primary"
                    type="reset"
                    className={classes.button}
                    size="small"
                    style={{ float: 'right' }}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.box}
          >
            <Container maxWidth="sm">
              <Typography variant="h5" style={{ marginBottom: 30 }}>
                {' '}
                Item Details
              </Typography>
              {itemsCon?.length >= 0 ? (
                <Box style={{ width: 330 }}>
                  <Divider style={{ backgroundColor: 'rgb(247 249 250)' }} />
                  {itemsCon.map(data => {
                    return (
                      <>
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Box style={{ width: 250 }}>
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                style={{ color: '#546e7a', fontSize: 12 }}
                                variant="p"
                              >
                                Item name
                              </Typography>
                              <Typography
                                style={{ color: '#546e7a', fontSize: 12 }}
                                variant="p"
                              >
                                {data.item}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                style={{ color: '#546e7a', fontSize: 12 }}
                                variant="p"
                              >
                                Quantity
                              </Typography>
                              <Typography
                                style={{ color: '#546e7a', fontSize: 12 }}
                                variant="p"
                              >
                                {data.qty}
                              </Typography>
                            </Box>
                            <Divider
                              style={{ backgroundColor: 'rgb(247 249 250)' }}
                            />
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                style={{ color: '#546e7a', fontSize: 12 }}
                                variant="p"
                              >
                                Unit price
                              </Typography>
                              <Typography
                                style={{ color: '#546e7a', fontSize: 12 }}
                                variant="p"
                              >
                                {data.unitPrice >= 0 &&
                                  formatAmount(data.unitPrice)}{' '}
                                FCFA
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                style={{ color: '#546e7a', fontSize: 12 }}
                                variant="p"
                              >
                                Sub total
                              </Typography>
                              <Typography
                                style={{ color: '#546e7a', fontSize: 12 }}
                                variant="p"
                              >
                                {data.stotal >= 0 && formatAmount(data.stotal)}{' '}
                                FCFA
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <IconButton
                              onClick={() => handleRemoveItem(data.id)}
                            >
                              <RemoveCircleIcon style={{ color: '#d40000' }} />
                            </IconButton>
                          </Box>
                        </Box>
                        <Divider
                          style={{ backgroundColor: 'rgb(247 249 250)' }}
                        />
                      </>
                    );
                  })}
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 250
                    }}
                  >
                    <Typography style={{ color: '#546e7a' }} variant="p">
                      Total
                    </Typography>
                    <Typography style={{ color: '#546e7a' }} variant="p">
                      {total >= 0 && formatAmount(total)} FCFA
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ marginTop: 7 }}
              >
                <Typography className={classes.inputText} variant="p">
                  Item name
                </Typography>
              </InputLabel>
              <BootstrapInput
                fullWidth
                placeholder="Enter Items name"
                margin="normal"
                name="items"
                type="text"
                value={values.items}
                id="bootstrap-input"
                required
                onChange={handleChange}
              />
              <Hidden mdDown>
                <Box style={{ display: 'inline-flex' }}>
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-quantity"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputText} variant="p">
                        Quantity
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="1"
                      margin="normal"
                      name="quantity"
                      type="number"
                      value={values.quantity}
                      id="bootstrap-input-quantity"
                      required
                      style={{ width: 60 }}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-price"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputText} variant="p">
                        Unit price
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="100"
                      margin="normal"
                      name="price"
                      type="number"
                      value={values.price}
                      id="bootstrap-input-price"
                      required
                      style={{ width: 120, marginRight: 2 }}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-amount"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputText} variant="p">
                        Total
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="100"
                      margin="normal"
                      name="amount"
                      type="number"
                      value={values.price * values.quantity}
                      id="bootstrap-input-amount"
                      required
                      style={{ width: 133 }}
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
              </Hidden>
              <Hidden lgUp>
                <Box>
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-quantity"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputText} variant="p">
                        Quantity
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="1"
                      margin="normal"
                      name="quantity"
                      type="number"
                      value={values.quantity}
                      id="bootstrap-input-quantity"
                      required
                      onChange={handleChange}
                    />
                  </Box>
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-price"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputText} variant="p">
                        Unit price
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="100"
                      margin="normal"
                      name="price"
                      type="number"
                      value={values.price}
                      id="bootstrap-input-price"
                      required
                      onChange={handleChange}
                    />
                  </Box>
                  <Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-amount"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputText} variant="p">
                        Total
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="100"
                      margin="normal"
                      name="amount"
                      type="number"
                      value={values.price * values.quantity}
                      id="bootstrap-input-amount"
                      required
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
              </Hidden>
              <Box className={classes.btnOne}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  size="small"
                  onClick={handleItems}
                >
                  <Typography variant="p">Add Item</Typography>
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  size="small"
                  onClick={handleSaveItems}
                >
                  Save
                </Button>
              </Box>
              <Box style={{ marginTop: 50 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Shipping cost
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  margin="normal"
                  name="shipping"
                  type="number"
                  value={values.shipping}
                  id="bootstrap-input"
                  required
                  onChange={handleChange}
                />{' '}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Due date
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  margin="normal"
                  name="phone"
                  type="date"
                  value={values.date}
                  id="bootstrap-input"
                  required
                  onChange={handleChange}
                />{' '}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Note
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Additional info"
                  margin="normal"
                  name="note"
                  type="text"
                  value={values.note}
                  id="bootstrap-input"
                  required
                  multiline
                  rows={3}
                  onChange={handleChange}
                />{' '}
                <Box className={classes.btnTwo}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    size="small"
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <CircularProgress size="14px" />
                    ) : (
                      'Save & send invoice'
                    )}
                  </Button>
                  <Button
                    color="primary"
                    type="reset"
                    className={classes.button}
                    size="small"
                    onClick={() =>
                      navigate('/app/invoices', { replace: false })
                    }
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Container>
          </Box>
        </Page>
      </Grid>
    </Grid>
  );
};

export default SimpleItems;
