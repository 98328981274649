import React from 'react';
import Page from 'src/components/Page';
import { Container, Grid, Hidden } from '@material-ui/core';
import Bar from 'src/components/Bar';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from './Table';
import PaymentsTab from 'src/components/PaymentsTab';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50%',
      textAlign: 'center',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
    },
    width: '100%',
    border: '1px  solid #e0e0e03d'
    // backgroundColor: '#fdfdff'
  }
}));

const BulkView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const details = location.state.data;
  const classes = useStyles();
  const bulkId = location.state.id;

  const handleClickOpen = () => {
    navigate('/app/bulk-payments/edit', {
      replace: false,
      state: { id: bulkId }
    });
  };

  return (
    <Page title="Bulk Payments">
      <Container maxWidth={false} className={classes.root}>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} lg={3} xl={2}>
              <PaymentsTab val="bulk" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} lg={9} xl={10}>
            <Bar
              title="Bulk Payments"
              btnText="Update CSV"
              onClick={handleClickOpen}
            />
            <Table details={details} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default BulkView;
