import React, { useState } from 'react';
import { useAuthState } from 'src/context';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
// import Switch from '@material-ui/core/Switch';
import Snackbar from 'src/components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { getRevenue, getPrice } from 'src/components/FapshiRevenue';

import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto'
    }
  },
  introbox: {},
  error: {
    textAlign: 'left'
  },
  box: {},
  button: {
    textTransform: 'none',
    borderRadius: 8,
    fontSize: 14,
    margin: 2,
    height: 35,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    border: '1px solid #ced4da'
  },
  inputText: {
    color: '#3e4040',
    fontWeight: 500,
    fontSize: 20,
    fontFamily: 'Ubuntu'
  },
  inputLabel: {
    color: '#3e4040',
    fontWeight: 500,
    fontSize: 20,
    fontFamily: 'Ubuntu'
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '70%',
    height: '10px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

const SimpleItems = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useAuthState();
  // const [switcher, setSwitcher] = React.useState(true);

  const [values, setValues] = useState({
    name: null,
    note: null,
    website: null,
    minamount: null,
    date: null,
    phone: null
  });
  const [amount, setAmount] = useState(null);
  const [revenue, setRevenue] = useState('');

  const handlePriceChange = event => {
    const target = event.target.value;
    setAmount(target);
    const rev = getRevenue(target);
    setRevenue(rev);
  };
  const handleRevenueChange = event => {
    const target = event.target.value;
    setRevenue(target);
    const price = getPrice(target);
    setAmount(price);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // const handleSwitcher = () => {
  //   setSwitcher(!switcher);
  // };
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = e => {
    setIsLoading(true);
    e.preventDefault();
    const val = {
      organisation: values.name,
      phone: values.phone,
      description: values.note,
      websiteLink: values.wesbite,
      minAmt: parseInt(values.minamount),
      fixAmt: parseInt(amount),
      expiryDate: values.date,
      // showTrans: switcher,
      type: 'Payment link'
    };
    axios({
      method: 'post',
      data: val,
      url: 'https://api.fapshi.com/newDonation',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
        setIsLoading(false);
        setValues({});
        navigate('/app/payment-link', { replace: false });
      })
      .catch(err => {
        setIsLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };

  const handleReturnButton = () => {
    navigate('/app/payment-link', { replace: true });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Page className={classes.root} title="Create Simple Link">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.box}
            style={{ width: 480, marginTop: 40 }}
          >
            <Container maxWidth="sm" className={classes.container}>
              <Box style={{ display: 'flex' }}>
                <Button
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  style={{ marginTop: -5, height: 30, textTransform: 'none' }}
                  onClick={handleReturnButton}
                >
                  Return
                </Button>
              </Box>
              <Typography
                style={{
                  marginBottom: 30,
                  color: '#3e4040',
                  fontSize: 25,
                  fontWeight: 900,
                  fontFamily: 'Ubuntu'
                }}
              >
                {' '}
                Simple Link Details
              </Typography>
              {snack && <Snackbar data="Invoice sent successfully" />}
              {error && (
                <Typography
                  variant="p"
                  style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
                >
                  {eresponse}
                </Typography>
              )}
              <form>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Payment link name
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter a name for your link"
                  margin="normal"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  id="bootstrap-input"
                  required
                  autoFocus
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Amount
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter amount"
                  margin="normal"
                  name="amount"
                  onChange={handlePriceChange}
                  type="number"
                  value={amount}
                  id="bootstrap-input"
                  required
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Revenue
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Revenue"
                  margin="normal"
                  name="revenue"
                  onChange={handleRevenueChange}
                  type="number"
                  value={revenue}
                  id="bootstrap-input"
                  required
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Due date(optional)
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Enter amount"
                  margin="normal"
                  name="date"
                  onChange={handleChange}
                  type="date"
                  value={values.date}
                  id="bootstrap-input"
                  required
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Description
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Link description"
                  margin="normal"
                  name="note"
                  onChange={handleChange}
                  row={4}
                  type="text"
                  value={values.note}
                  id="bootstrap-input"
                  required
                  multiline
                  rows={2}
                />{' '}
                {/* <InputLabel
                  shrink
                  htmlFor="bootstrap-input-switch"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputLabel} variant="p">
                    Show payments transactions details ?
                  </Typography>
                </InputLabel> */}
                {/* <Switch
                  checked={switcher}
                  onChange={() => {
                    handleSwitcher(event);
                  }}
                  name="switch"
                  size="small"
                  className={classes.inputLabel}
                /> */}
                <Box style={{ marginTop: 10, width: 330 }}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    size="small"
                    style={{ float: 'right' }}
                    onClick={handleSubmit}
                  >
                    {isLoading ? <CircularProgress size="14px" /> : 'Confirm'}{' '}
                  </Button>
                  <Button
                    color="primary"
                    type="reset"
                    className={classes.button}
                    size="small"
                    style={{ float: 'right' }}
                    onClick={() =>
                      navigate('/app/payment-link', { replace: false })
                    }
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Page>
      </Grid>
    </Grid>
  );
};

export default SimpleItems;
