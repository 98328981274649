import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './logo.svg';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '50%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  box: {
    width: '35%',
    marginLeft: '35%',
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: 310,
    marginTop: '10%',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      marginLeft: 10,
      marginRight: 50,
      padding: 0,
      marginTop: '30%'
    }
  },
  logo: {
    width: 110,
    height: 40
  },
  error: {
    textAlign: 'left'
  }
}));

const Success = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title="Verification">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        textAlign="center"
        className={classes.box}
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <img className={classes.logo} src={Logo} alt="logo"></img>
            <Typography color="textPrimary" variant="h3">
              Sign up verification status
            </Typography>
            <Typography variant="p" color="textSecondary" gutterBottom>
              Congratulations ! Your email has been successfully verified.
            </Typography>
          </Box>
          <Box my={2}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ textTransform: 'none' }}
              onClick={() => navigate('/login', { replace: true })}
            >
              Proceed to login
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default Success;
