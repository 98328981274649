import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Popover from '@mui/material/Popover';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import { useAuthState } from 'src/context';
import NoResults from 'src/components/NoResults';
import { IconButton, Button, Typography, Divider } from '@material-ui/core';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from 'src/components/Snackbar';
import { alpha } from '@mui/material/styles';
import { Edit as InvoiceIcon } from 'react-feather';
import { capitalize, formatAmount } from 'src/utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50%',
      textAlign: 'center',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
    },
    width: '100%',
    border: '1px  solid #e0e0e03d'
    // backgroundColor: '#fdfdff'
  },
  label: {
    marginLeft: 8
  },
  button: {
    textTransform: 'none',
    display: 'block',
    textAlign: 'left',
    height: 50,
    width: 190,
    overflowY: 'hidden'
  },
  box: {
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff59' //rgba(0,0,30,0.2)
  },
  buttonText: {
    fontSize: 18
  },
  iconButton: {
    color: '#3f51b5',
    margin: '2px 3px 0 0',
    width: 40,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ced4da',
      borderRadius: 10
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '1px solid #7592c7',
      borderRadius: 10
    }
  }
}));

const headCells = [
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Transaction ID'
  },

  {
    id: 'customer',
    numeric: true,
    disablePadding: false,
    label: 'Client'
  },
  // {
  //   id: 'name',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Name'
  // },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone'
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Total (FCFA)'
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status'
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date Initiated'
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: ''
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography
                style={{
                  fontFamily: 'ubuntu',
                  fontSize: 16,
                  fontWeight: 900
                }}
              >
                {' '}
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default function PopupTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);

  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = details.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };
  const [details, setDetails] = useState([]);

  const { token } = useAuthState();
  const [progressDisplay, setProgressDisplay] = useState(false);

  useEffect(() => {
    getProduct();
  }, []);
  const getProduct = async () => {
    setProgressDisplay(true);
    var response = await axios({
      method: 'get',
      url: 'https://api.fapshi.com/invoice/',
      headers: { 'x-access-token': token }
    });
    if (response.status === 200) {
      setProgressDisplay(false);
    }
    setDetails(response.data);
    console.log(response);
    console.log(details);
  };

  const newDate = date => {
    const d = new Date(date);
    return d.toLocaleString();
  };
  const [bid, setBid] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleIconClick = (event, id) => {
    setBid(id);
    setAnchorEl(event.currentTarget);
  };
  const handleIconClose = () => {
    setAnchorEl(null);
  };
  const openI = Boolean(anchorEl);
  const id = openI ? 'simple-popover' : undefined;
  const navigate = useNavigate();

  const handleDuplicate = data => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/transDetails/' + data.transferId,
      headers: { 'x-access-token': token }
    }).then(res => {
      const resData = res.data;
      res.data.invoiceContent?.length == 0
        ? navigate('/app/invoices/duplicate/simple', {
            replace: true,
            state: { data: resData }
          })
        : navigate('/app/invoices/duplicate/advanced', {
            replace: true,
            state: { data: resData }
          });
    });
  };
  const handleEdit = data => {
    axios({
      method: 'get',
      url: 'https://api.fapshi.com/transDetails/' + data.transferId,
      headers: { 'x-access-token': token }
    }).then(res => {
      const resData = res.data;
      res.data.invoiceContent?.length == 0
        ? navigate('/app/invoices/edit/simple', {
            replace: false,
            state: { data: resData, id: data.transferId }
          })
        : navigate('/app/invoices/edit/advanced', {
            replace: false,
            state: { data: resData, id: data.transferId }
          });
    });
  };
  const [error, setError] = useState(false);
  const [snack, setSnack] = useState(false);
  const [esnack, setEsnack] = useState(false);
  const [rerror, rsetError] = useState(false);
  const [rsnack, rsetSnack] = useState(false);
  const [dsnack, dsetSnack] = useState(false);
  const [resnack, rsetEsnack] = useState(false);
  const handleReminder = id => {
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/invoice-reminder/' + id,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setSnack(true);
      })
      .catch(err => {
        setError(true);
        setEsnack(err.response?.data.message);
      });
    console.log(id);
  };

  const handleResend = id => {
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/resend-invoice/' + id,
      headers: { 'x-access-token': token }
    })
      .then(() => {
        rsetSnack(true);
      })
      .catch(err => {
        rsetError(true);
        rsetEsnack(err.response?.data.message);
      });
  };
  const handleDelete = id => {
    const filter = details.filter(data => {
      return data.transferId != id;
    });
    axios({
      method: 'delete',
      url: 'https://api.fapshi.com/invoice/' + id,
      headers: { 'x-access-token': token }
    }).then(() => {
      dsetSnack(true);
      setDetails(filter);
    });
  };
  const handleClose = () => {
    setSnack(false);
  };
  const handleResendClose = () => {
    rsetSnack(false);
  };
  const handleDeleteClose = () => {
    dsetSnack(false);
  };
  return (
    <>
      <Snackbar
        duration={6000}
        open={snack}
        close={handleClose}
        data="Invoice sent successfully"
      />
      {error && (
        <Typography
          variant="p"
          style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
        >
          <Snackbar
            severity="error"
            duration={6000}
            close={handleClose}
            data={esnack}
            open={snack}
          />
        </Typography>
      )}

      <Snackbar
        close={handleResendClose}
        data="Invoice sent successfully"
        open={rsnack}
        duration={6000}
      />
      <Snackbar
        close={handleDeleteClose}
        data="Deleted successfully"
        open={dsnack}
        duration={6000}
        severity="error"
      />
      {rerror && (
        <Typography
          variant="p"
          style={{ color: '#eb6558', fontWeight: 900, fontSize: 16 }}
        >
          {rsnack && (
            <Snackbar
              severity="error"
              data={resnack}
              duration={6000}
              close={handleResendClose}
            />
          )}
        </Typography>
      )}
      <Box
        style={{
          display: progressDisplay ? 'block' : 'none'
        }}
      >
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
            backdropFilter: 'blur(2px)',
            backgroundColor: '#ffffff59'
          }}
          open={open}
        >
          <CircularProgress
            size="1.5rem"
            style={{
              display: progressDisplay ? 'block' : 'none',
              marginLeft: '20%',
              marginTop: '10%',
              marginBottom: '10%',
              color: '#3f51b5'
            }}
          />
        </Backdrop>
      </Box>
      <Box
        style={{
          display: progressDisplay ? 'none' : 'block'
        }}
        sx={{ width: '100%' }}
      >
        {details.length !== 0 ? (
          <>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={details.length}
                  />
                  <TableBody style={{ backgroundColor: '#ffffff' }}>
                    {details.map(rows => (
                      <TableRow
                        onClick={event => handleClick(event, rows.code)}
                        role="checkbox"
                        tabIndex={-1}
                        key={rows.code}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            style={{
                              color: '#3e4040',
                              fontFamily: 'ubuntu',
                              fontSize: 16
                            }}
                          >
                            {rows.transferId}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            style={{
                              color: '#3e4040',
                              fontFamily: 'ubuntu',
                              fontSize: 16
                            }}
                          >
                            {capitalize(rows.clientName)}
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="right">
                          <Typography
                            style={{
                              color: '#3e4040',
                              fontFamily: 'ubuntu',
                              fontSize: 16
                            }}
                          >
                            {' '}
                            {rows.clientName}
                          </Typography>
                        </TableCell> */}
                        <TableCell align="right">
                          <Typography
                            style={{
                              color: '#3e4040',
                              fontFamily: 'ubuntu',
                              fontSize: 16
                            }}
                          >
                            {rows.clientPhone}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            style={{
                              color: '#3e4040',
                              fontFamily: 'ubuntu',
                              fontSize: 16
                            }}
                          >
                            {rows.total >= 0 && formatAmount(rows.total)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            style={{
                              color: '#3e4040',
                              fontFamily: 'ubuntu',
                              fontSize: 16
                            }}
                          >
                            {rows.status === 'SUCCESSFUL' ? 'Paid' : 'Pending'}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            style={{
                              color: '#3e4040',
                              fontFamily: 'ubuntu',
                              fontSize: 16
                            }}
                          >
                            {newDate(rows.dateInitiated)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            className={classes.iconButton}
                            style={{
                              height: 20
                            }}
                            onClick={event => handleIconClick(event, rows)}
                          >
                            <MoreHorizIcon
                              style={{ fontSize: 22, cursor: 'pointer' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Popover
                  id={id}
                  open={openI}
                  anchorEl={anchorEl}
                  onClose={handleIconClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  style={{ marginTop: 3 }}
                >
                  <Button
                    onClick={() => handleEdit(bid)}
                    fullWidth
                    className={classes.button}
                  >
                    <Typography variant="p" className={classes.buttonText}>
                      Edit invoice
                    </Typography>
                  </Button>
                  <Divider style={{ backgroundColor: '#f4f4f5' }} />
                  <a
                    href={
                      'https://dashboard.fapshi.com/app/invoices/' +
                      bid?.getCode
                    }
                  >
                    <Button fullWidth className={classes.button}>
                      <Typography variant="p" className={classes.buttonText}>
                        Preview invoice
                      </Typography>
                    </Button>
                  </a>
                  <Divider style={{ backgroundColor: '#f4f4f5' }} />
                  <Button
                    onClick={() => handleDuplicate(bid)}
                    fullWidth
                    className={classes.button}
                  >
                    <Typography variant="p" className={classes.buttonText}>
                      Duplicate invoice
                    </Typography>
                  </Button>

                  <Divider style={{ backgroundColor: '#f4f4f5' }} />
                  <Button
                    onClick={() => handleReminder(bid.transferId)}
                    fullWidth
                    className={classes.button}
                  >
                    <Typography variant="p" className={classes.buttonText}>
                      Send a reminder
                    </Typography>
                  </Button>
                  <Divider style={{ backgroundColor: '#f4f4f5' }} />
                  <Button
                    onClick={() => handleResend(bid.transferId)}
                    fullWidth
                    className={classes.button}
                  >
                    <Typography variant="p" className={classes.buttonText}>
                      Resend invoice
                    </Typography>
                  </Button>
                  <Divider style={{ backgroundColor: '#f4f4f5' }} />
                  {bid?.status === 'CREATED' && (
                    <Button
                      onClick={() => handleDelete(bid.transferId)}
                      fullWidth
                      className={classes.button}
                      style={{ color: '#eb6558' }}
                    >
                      <Typography variant="p" className={classes.buttonText}>
                        Delete invoice
                      </Typography>
                    </Button>
                  )}
                </Popover>
              </TableContainer>
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </>
        ) : (
          <>
            <NoResults
              title="Invoice created"
              content="Create branded invoices for your clients. Invoices serve as proof of payment."
              link="https://documentation.fapshi.com/#invoicing"
              icon={
                <InvoiceIcon
                  size={35}
                  style={{
                    fontSize: 40,
                    border: '1px solid #bdc3c9',
                    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 30%)',
                    borderRadius: 10,
                    color: '#bdc3c9',
                    padding: 5
                  }}
                />
              }
            />
          </>
        )}
      </Box>
    </>
  );
}
