import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import theme from 'src/assets/theme';
import { apiConfig } from 'src/utils/apiConfig';
import { capitalize, formatAmount, ucFirst } from 'src/utils/helperFunctions';
import useEndpoint from 'src/utils/useEndpoint';

const { colors } = theme;

const styles = {
  header: {
    backgroundColor: colors.bgColor
  },
  headerText: {
    fontWeight: 'bold',
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    borderBottom: 'none'
  },
  text: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    '.MuiListItemText-primary': {
      fontFamily: ['Ubuntu', 'sans-serif'].join(',')
    },
    border: 0,
    padding: '8px 16px'
  },
  eventRow: {
    borderBottom: '1px solid',
    borderColor: colors.borderColor,
    '&:last-child td, &:last-child th': { border: 0 }
  },
  listItem: {
    padding: 0
  }
};

const headers = [
  { title: 'Date' },
  { title: 'Category' },
  { title: 'Tickets' },
  { title: 'Paid via' },
  { title: 'Amount (FCFA)' },
  { title: 'Income (FCFA)' },
  { title: 'Status' },
  { title: 'Email' },
  { title: 'Transaction ID' }
];

export default function BookingsTable({ data, eventId }) {
  const { data: successfulBookings, modifyData } = useEndpoint();

  // Use custom endpoint hook to delete event
  const getSuccessfulBookings = () => {
    modifyData({
      url: `${apiConfig.eventTransUrl}/${eventId}/?status=successful`,
      requestMethod: 'get'
    });
  };

  useEffect(() => {
    getSuccessfulBookings();
  }, []);

  return (
    <TableContainer sx={{ minWidth: '300px', maxHeight: '90vh' }}>
      <Table
        aria-label="bookings table"
        sx={{
          overflow: 'scroll'
        }}
        id={successfulBookings ? 'all-bookings' : 'bookings-table'}
      >
        <TableHead>
          <TableRow sx={styles.header}>
            <TableCell sx={styles.headerText}>Name</TableCell>
            {headers.map(header => (
              <TableCell
                sx={styles.headerText}
                key={header.title}
                align="right"
              >
                {header.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} sx={styles.eventRow}>
              <TableCell sx={styles.text} component="th" scope="row">
                {capitalize(row.payerName)}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {moment(row.dateConfirmed).format('DD/MM/YYYY')}
                {', '}
                {moment(row.dateConfirmed).format('h:mm:ss A')}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {capitalize(row.category)}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {row.quantity}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {ucFirst(row.medium)}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {formatAmount(row.amount)}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {formatAmount(row.received)}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {capitalize(row.status.toLowerCase())}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {row.payerEmail}
              </TableCell>
              <TableCell sx={styles.text} align="right">
                {row.transferId}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Table Showing only successful transactions */}
      {successfulBookings && (
        <Table
          aria-label="bookings table"
          sx={{
            overflow: 'scroll',
            display: 'none'
          }}
          id={successfulBookings ? 'bookings-table' : 'all-bookings'}
        >
          <TableHead>
            <TableRow sx={styles.header}>
              <TableCell sx={styles.headerText}>Name</TableCell>
              {headers.map(header => (
                <TableCell
                  sx={styles.headerText}
                  key={header.title}
                  align="right"
                >
                  {header.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {successfulBookings?.map((row, index) => (
              <TableRow key={index} sx={styles.eventRow}>
                <TableCell sx={styles.text} component="th" scope="row">
                  {capitalize(row.payerName)}
                </TableCell>
                <TableCell sx={styles.text} align="right">
                  {moment(row.dateConfirmed).format('DD/MM/YYYY')}
                  {', '}
                  {moment(row.dateConfirmed).format('h:mm:ss A')}
                </TableCell>
                <TableCell sx={styles.text} align="right">
                  {capitalize(row.category)}
                </TableCell>
                <TableCell sx={styles.text} align="right">
                  {row.quantity}
                </TableCell>
                <TableCell sx={styles.text} align="right">
                  {ucFirst(row.medium)}
                </TableCell>
                <TableCell sx={styles.text} align="right">
                  {formatAmount(row.amount)}
                </TableCell>
                <TableCell sx={styles.text} align="right">
                  {formatAmount(row.received)}
                </TableCell>
                <TableCell sx={styles.text} align="right">
                  {capitalize(row.status.toLowerCase())}
                </TableCell>
                <TableCell sx={styles.text} align="right">
                  {row.payerEmail}
                </TableCell>
                <TableCell sx={styles.text} align="right">
                  {row.transferId}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
