import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
// import Divider from '@material-ui/core/Divider';
import { useServiceState, setApiKey, useServiceDispatch } from 'src/context';
import { useAuthState } from 'src/context';
import { Box, Grid, Popover } from '@material-ui/core';
import axios from 'src/axios';
import { FaPaperclip } from 'react-icons/fa';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from 'src/components/Snackbar';
import { message } from 'antd';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { HiClipboard } from 'react-icons/hi';
import secretImg from './svg/secret@2x.82c33757b4d6c58d7775ace9527b461d.png';

const useStyles = makeStyles(theme => ({
  root: {
    // width: '96%',
    margin: 20,
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')

    // boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 12%)',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: '#4361ac',
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    letterSpacing: 0.3
  },
  typo: {
    margin: '15px 0px 0px 0px',
    fontSize: 24
  },
  text: {
    color: '#3e4040'
  },
  box: {
    marginBottom: 0,
    marginTop: 10,
    border: '1px solid #5f616817',
    width: '100%',
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  button: {
    textTransform: 'none',
    borderRadius: 10,
    height: 30,
    border: '.5px solid #ced4da',
    marginLeft: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      marginBottom: 10
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '.5px solid #ced4da',
      borderRadius: 7
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '.5px solid #7592c7',
      borderRadius: 7
    }
  },
  buttons: {
    textTransform: 'none',
    borderRadius: 10,
    height: 25,
    border: '.5px solid #ced4da',
    marginLeft: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      marginBottom: 10
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '.5px solid #ced4da',
      borderRadius: 7
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '.5px solid #7592c7',
      borderRadius: 7
    }
  },
  buttonTwo: {
    textTransform: 'none',
    borderRadius: 7,
    height: 40,
    fontSize: 16,
    marginLeft: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      marginBottom: 10
    },
    '&:hover': {
      backgroundColor: '#3f51b5',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '.5px solid #3f51b5',
      borderRadius: 7
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '.5px solid #3f51b5',
      borderRadius: 7
    }
  },
  secretbutton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  veributton: {
    float: 'right',
    textTransform: 'none',
    fontSize: 14,
    borderRadius: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    border: '1px solid #dfdee5',
    height: 30
  },
  buttonThree: {
    textTransform: 'none',
    borderRadius: 7,
    height: 40,
    fontSize: 16,
    border: '.5px solid #3f51b5',
    marginTop: 10,
    marginLeft: 5,
    float: 'right',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    [theme.breakpoints.down('md')]: {
      marginBottom: 10
    },
    '&:hover': {
      backgroundColor: '#3f51b5',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '.5px solid #3f51b5',
      borderRadius: 7
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '.5px solid #3f51b5',
      borderRadius: 7
    }
  },
  buttonFour: {
    textTransform: 'none',
    borderRadius: 7,
    height: 40,
    fontSize: 16,
    border: '.5px solid #eeefee',
    marginTop: 10,
    marginLeft: 5,
    float: 'right',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 2%)',
    [theme.breakpoints.down('md')]: {
      marginBottom: 10
    },
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '.5px solid #fff',
      borderRadius: 7
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: '.5px solid #fff',
      borderRadius: 7
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#ffffff8c' //rgba(0,0,30,0.2)
  },
  blurredImg: {
    height: 35,
    width: 400,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: '200px 24px',
    margin: '-2px 0px',
    [theme.breakpoints.down('md')]: {
      width: 200
    }
  },
  blurredImgTest: {
    height: 35,
    width: 250,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: '200px 24px',
    margin: '-2px 0px',
    [theme.breakpoints.down('md')]: {
      width: 200
    }
  },
  boxTwo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  flexBox: {
    width: '34rem',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  flexBoxTwo: {
    width: '45rem',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginTop: 30
    }
  },
  inputText: {
    color: '#3e4040',
    fontWeight: 500,
    fontSize: 20,
    fontFamily: 'Ubuntu'
  },
  popBox: {
    height: 170,
    width: 500,
    padding: 20,
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  hookBox: {
    width: 360,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    height: '10px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf'
    }
  }
}));

export default function Accordation() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { singleUserService } = useServiceState();
  const { userDetails, token } = useAuthState();
  const dispatch = useServiceDispatch();
  const [webhook, setWebhook] = useState('');
  const [webhookData, setWebhookData] = useState('');
  const [testWebhookData, setTestWebhookData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVLoading, setIsVLoading] = useState(false);
  const [eresponse, setEresponse] = useState(null);
  const [error, setError] = useState(false);
  const [webresponse, setWebresponse] = useState(null);
  const [testWebresponse, setTestWebresponse] = useState(null);
  const [weberror, setWeberror] = useState(false);
  const [testWeberror, setTestWeberror] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);
  const [isTestLoading, setTestIsLoading] = useState(false);
  const [snack, setSnack] = useState(false);
  const [snackTwo, setSnackTwo] = useState(false);
  const [openDisableDialog, setDisableDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [secret, setSecret] = useState(null);
  const [show, setShow] = useState(false);
  const [testUser, setTestUser] = useState(null);
  const [testKey, setTestKey] = useState(null);
  const [testWebhook, setTestWebhook] = useState(null);

  const handleDialogClose = () => {
    setAnchorEl(null);
  };
  const handleDisableClose = () => {
    setDisableDialog(false);
  };

  const serviceId = singleUserService?.data?._id;
  useEffect(() => {
    if (serviceId)
      axios({
        method: 'get',
        url: '/webhook/' + serviceId,
        headers: { 'x-access-token': token }
      })
        .then(res => {
          setWebhook(res.data.webhook);
        })
        .catch(e => {
          console.log(e);
        });
  }, [serviceId]);

  useEffect(() => {
    axios({
      method: 'get',
      url: '/testkeys',
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setTestKey(res.data.apiKey);
        setTestUser(res.data.apiUser);
        setTestWebhook(res.data.webhook);
      })
      .catch(e => {
        console.log(e);
      });
  });

  const [anchorTwoEl, setAnchorTwoEl] = React.useState(null);

  const handleTwoClick = () => {
    setOpens(true);
  };

  const handleTwoClose = () => {
    setAnchorTwoEl(null);
  };

  const openTwo = Boolean(anchorTwoEl);
  const idTwo = openTwo ? 'simple-popover' : undefined;
  const [password, setPassword] = useState(null);

  const handleSubmit = id => {
    setIsLoadingTwo(true);
    axios({
      method: 'post',
      url: '/userservice/newkey/' + id,
      data: { password: password },
      headers: { 'x-access-token': token }
    })
      .then(res => {
        setApiKey(dispatch, res.data.apiKey);
        setSecret(res.data.apiKey);
        setIsLoadingTwo(false);
        setSnackTwo(true);
        setAnchorTwoEl(null);
        setDisableDialog(true);
        setWeberror(false);
      })
      .catch(() => {
        setIsLoadingTwo(false);
      });
  };
  const [opens, setOpens] = React.useState(false);

  const handleClickClose = () => {
    setError(false);
    setOpens(false);
    setPassword('');
  };
  const verifyAccount = () => {
    setIsVLoading(true);
    axios({
      method: 'post',
      data: { password },
      url: 'https://api.fapshi.com/user/password',
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setAnchorTwoEl(event.currentTarget);
        setOpens(false);
        setError(false);
        setIsVLoading(false);
      })
      .catch(err => {
        setIsVLoading(false);
        setError(true);
        setEresponse(err.response?.data.message);
      });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorTestEl, setTestAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleTestClick = event => {
    setTestAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const testOpen = Boolean(anchorTestEl);
  const testId = testOpen ? 'simple-popover' : undefined;

  const handleWebSubmit = () => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: '/webhook/' + serviceId,
      data: { webhook: webhookData },
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setIsLoading(false);
        setWebhookData('');
        setSnack(true);
        axios({
          method: 'get',
          url: '/webhook/' + serviceId,
          headers: { 'x-access-token': token }
        })
          .then(res => {
            setWebhook(res.data.webhook);
            setWeberror(false);
            setAnchorEl(null);
          })
          .catch(() => {
            setIsLoading(false);
          });
      })
      .catch(err => {
        setTestIsLoading(false);
        setTestWeberror(true);
        setWebresponse(err.response?.data.message);
      });
  };

  const handleTestWebSubmit = () => {
    setTestIsLoading(true);
    axios({
      method: 'post',
      url: '/test-webhook/',
      data: { webhook: testWebhookData },
      headers: { 'x-access-token': token }
    })
      .then(() => {
        setTestIsLoading(false);
        setTestWebhookData('');
        setSnack(true);
        axios({
          method: 'get',
          url: '/testkeys/',
          headers: { 'x-access-token': token }
        })
          .then(res => {
            setTestWebhook(res.data.webhook);
            setTestWeberror(false);
            setTestAnchorEl(null);
          })
          .catch(() => {
            setTestIsLoading(false);
          });
      })
      .catch(err => {
        setTestIsLoading(false);
        setTestWeberror(true);
        setTestWebresponse(err.response?.data.message);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setWeberror(true);
  };

  const handleTestClose = () => {
    setTestAnchorEl(null);
    setTestWeberror(true);
  };

  const handleSnackClose = () => {
    setSnack(false);
  };
  const handleSnackTwoClose = () => {
    setSnackTwo(false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.typo}>
        For developers
      </Typography>
      <Snackbar
        duration={6000}
        close={handleSnackClose}
        data="Webhook added"
        open={snack}
      />
      <Snackbar
        duration={6000}
        close={handleSnackTwoClose}
        data="Secret key generated"
        open={snackTwo}
      />
      <Box className={classes.box}>
        <Box className={classes.flexBox}>
          <Box className={classes.boxTwo}>
            <Box>
              <Typography variant="h5" className={classes.introtitle}>
                Test API keys
              </Typography>
              <Typography variant="p" className={classes.introtitle}>
                These keys will allow you to simulate authentication to API
                requests.{' '}
                <a
                  href="https://documentation.fapshi.com/dev-tools.html"
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                >
                  Learn more
                </a>
              </Typography>
            </Box>
          </Box>
          <Box style={{ marginTop: 10 }}>
            <Grid container>
              <Grid item xs={6} sm={6} lg={6} xl={6}>
                NAME
              </Grid>
              <Grid item xs={6} sm={6} lg={6} xl={6}>
                TOKEN
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} sm={6} lg={6} xl={6}>
                API user
              </Grid>
              <Grid item xs={6} sm={6} lg={6} xl={6}>
                <Box
                  onClick={() => {
                    navigator.clipboard.writeText(testUser);
                    message.success('Copied');
                  }}
                >
                  <Typography> {testUser}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} sm={6} lg={6} xl={6}>
                API key
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Box
                  onClick={() => {
                    navigator.clipboard.writeText(testKey);
                    message.success('Copied');
                  }}
                >
                  <Typography>
                    <img
                      src={secretImg}
                      className={classes.blurredImgTest}
                    ></img>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.flexBoxTwo}>
          <Box className={classes.boxTwo}>
            <Box className={classes.hookBox}>
              <Typography variant="h5" className={classes.introtitle}>
                Test Webhooks
              </Typography>
              <Typography variant="p" className={classes.introtitle}>
                Create a test webhook endpoint, so that Fapshi can notify your
                integration when asynchronous events occur.
              </Typography>
            </Box>
            <Box>
              <Button
                color="primary"
                aria-describedby={id}
                variant="contained"
                onClick={handleTestClick}
                className={classes.buttonTwo}
              >
                Add a test endpoint
              </Button>
            </Box>
            <Popover
              id={testId}
              open={testOpen}
              anchorEl={anchorTestEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              sx={{ mt: 0.5 }}
            >
              <Box className={classes.popBox}>
                {testWeberror && (
                  <Typography
                    variant="p"
                    style={{
                      color: '#eb6558',
                      fontWeight: 700,
                      fontSize: 16
                    }}
                  >
                    {testWebresponse}
                  </Typography>
                )}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ marginTop: 7 }}
                >
                  <Typography className={classes.inputText} variant="p">
                    Endpoint URL
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="https://"
                  margin="normal"
                  name="name"
                  type="text"
                  id="bootstrap-input"
                  required
                  autoFocus
                  value={testWebhookData}
                  onChange={e => {
                    setTestWebhookData(e.target.value);
                  }}
                />
                <Box>
                  <Button
                    color="primary"
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleTestWebSubmit}
                    className={classes.buttonThree}
                  >
                    {isTestLoading ? (
                      <CircularProgress size="14px" />
                    ) : (
                      'Submit'
                    )}{' '}
                  </Button>
                  <Button
                    aria-describedby={id}
                    variant="outlined"
                    onClick={handleTestClose}
                    className={classes.buttonFour}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
          <Box style={{ marginTop: 10 }}>
            {testWebhook ? (
              <>
                <Grid container>
                  <Grid item xs={6} sm={6} lg={7} xl={7}>
                    ENDPOINT
                  </Grid>
                  <Grid item xs={6} sm={6} lg={5} xl={5}>
                    DESCRIPTION
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={12} xs={12} lg={7} xl={7}>
                    {testWebhook}
                  </Grid>
                  <Grid item xs={12} sm={12} lg={5} xl={5}>
                    <Typography variant="p">—</Typography>
                  </Grid>
                </Grid>{' '}
              </>
            ) : (
              <>
                <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <FaPaperclip size={18} />
                  <Typography
                    variant="p"
                    style={{ fontSize: 16, fontWeight: 900 }}
                  >
                    Test webhook endpoint not set.
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5">{'Get your Live API data'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.box}>
            <Box className={classes.flexBox}>
              <Box className={classes.boxTwo}>
                <Box>
                  <Typography variant="h5" className={classes.introtitle}>
                    API keys
                  </Typography>
                  <Typography variant="p" className={classes.introtitle}>
                    These keys will allow you to authenticate API requests.{' '}
                    <a
                      href="https://documentation.fapshi.com/dev-tools.html"
                      target="_blank"
                      style={{ textDecoration: 'underline' }}
                    >
                      Learn more
                    </a>
                  </Typography>
                </Box>
              </Box>
              <Box style={{ marginTop: 10 }}>
                <Grid container>
                  <Grid item xs={6} sm={6} lg={6} xl={6}>
                    NAME
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} xl={6}>
                    TOKEN
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sm={6} lg={6} xl={6}>
                    API user
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} xl={6}>
                    {singleUserService == '' ? (
                      <Typography variant="h6">
                        no service available.
                      </Typography>
                    ) : (
                      <Box
                        onClick={() => {
                          navigator.clipboard.writeText(
                            singleUserService.data.apiUser
                          );
                          message.success('Copied');
                        }}
                      >
                        <Typography>
                          {' '}
                          {singleUserService.data.apiUser}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sm={6} lg={6} xl={6}>
                    API key
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6} xl={6}>
                    {singleUserService == '' ? (
                      <Typography variant="h6">
                        no service available.
                      </Typography>
                    ) : (
                      <>
                        <Box>
                          {singleUserService && (
                            <Button
                              className={classes.button}
                              aria-describedby={id}
                              onClick={handleTwoClick}
                            >
                              Generate new API key
                            </Button>
                          )}
                          <Popover
                            id={idTwo}
                            open={openTwo}
                            anchorEl={anchorTwoEl}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'left'
                            }}
                            sx={{ mt: 0.5 }}
                          >
                            <Box className={classes.popBox}>
                              <Typography variant="p" style={{ fontSize: 16 }}>
                                Generating a new API key will annul the current
                                API key. Do you still want to continue with this
                                request?
                              </Typography>
                              <Box>
                                <Button
                                  color="primary"
                                  aria-describedby={id}
                                  variant="contained"
                                  onClick={() => {
                                    handleSubmit(singleUserService.data._id);
                                  }}
                                  className={classes.buttonThree}
                                >
                                  {isLoadingTwo ? (
                                    <CircularProgress size="14px" />
                                  ) : (
                                    'Proceed'
                                  )}{' '}
                                </Button>
                                <Button
                                  aria-describedby={id}
                                  variant="outlined"
                                  onClick={handleTwoClose}
                                  className={classes.buttonFour}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </Box>
                          </Popover>
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className={classes.flexBoxTwo}>
              <Box className={classes.boxTwo}>
                <Box className={classes.hookBox}>
                  <Typography variant="h5" className={classes.introtitle}>
                    Webhooks
                  </Typography>
                  <Typography variant="p" className={classes.introtitle}>
                    Create webhook endpoints, so that Fapshi can notify your
                    integration when asynchronous events occur.
                  </Typography>
                </Box>
                <Box>
                  {singleUserService && (
                    <Button
                      color="primary"
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      className={classes.buttonTwo}
                    >
                      Add endpoint
                    </Button>
                  )}
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <Box className={classes.popBox}>
                    {weberror && (
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 700,
                          fontSize: 16
                        }}
                      >
                        {webresponse}
                      </Typography>
                    )}
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      style={{ marginTop: 7 }}
                    >
                      <Typography className={classes.inputText} variant="p">
                        Endpoint URL
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="https://"
                      margin="normal"
                      name="name"
                      type="text"
                      id="bootstrap-input"
                      required
                      autoFocus
                      value={webhookData}
                      onChange={e => {
                        setWebhookData(e.target.value);
                      }}
                    />
                    <Box>
                      <Button
                        color="primary"
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleWebSubmit}
                        className={classes.buttonThree}
                      >
                        {isLoading ? (
                          <CircularProgress size="14px" />
                        ) : (
                          'Submit'
                        )}{' '}
                      </Button>
                      <Button
                        aria-describedby={id}
                        variant="outlined"
                        onClick={handleClose}
                        className={classes.buttonFour}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Popover>
              </Box>
              <Box style={{ marginTop: 10 }}>
                {webhook ? (
                  <>
                    <Grid container>
                      <Grid item xs={6} sm={6} lg={7} xl={7}>
                        ENDPOINT
                      </Grid>
                      <Grid item xs={6} sm={6} lg={5} xl={5}>
                        DESCRIPTION
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item sm={12} xs={12} lg={7} xl={7}>
                        {webhook}
                      </Grid>
                      <Grid item xs={12} sm={12} lg={5} xl={5}>
                        <Typography variant="p">—</Typography>
                      </Grid>
                    </Grid>{' '}
                  </>
                ) : (
                  <>
                    <Box
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      <FaPaperclip size={18} />
                      <Typography
                        variant="p"
                        style={{ fontSize: 16, fontWeight: 900 }}
                      >
                        Webhook endpoint not set.
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {'Build your payments integration'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="p" className={classes.text}>
            {
              'Browse our developer docs to integrate Fapshi into your website or app.'
            }{' '}
            <br />
            <br />
            <a href="https://documentation.fapshi.com" target="_blank">
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
              >
                {'Explore docs'}
                <ArrowRightIcon size="16" />
              </Button>
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {!userDetails.isVendor ? (
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {'Activate your account by adding your business details'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="p" className={classes.text}>
              {
                'Give us some insights about you and your business to activate your account'
              }{' '}
              <br />
              <br />
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  userDetails.isVendor
                    ? navigate('/app/activate/confirmation', { replace: false })
                    : navigate('/app/activate/vendor/', {
                        replace: false
                      });
                }}
                style={{ textTransform: 'none' }}
              >
                {'Start now'}
                <ArrowRightIcon size="16" />
              </Button>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {'Activate international payments on your account'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="p" className={classes.text}>
              {
                'Start accepting international payments on your application by giving us some extra details about your business.'
              }{' '}
              <br />
              <br />
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => navigate('/app/settings', { replace: false })}
                style={{ textTransform: 'none' }}
              >
                {'Proceed'}
                <ArrowRightIcon size="16" />
              </Button>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={openDisableDialog}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
        BackdropProps={{
          classes: {
            root: classes.backDrop
          }
        }}
      >
        <DialogTitle
          style={{
            borderBottom: '1px solid rgb(229 231 243)',
            padding: 20,
            fontSize: '16px'
          }}
          id="responsive-dialog-title"
        >
          <Typography variant="h3">Copy your API key</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: 30 }}>
            <Typography variant="p">
              Please, take note that you can only copy this API key once. If you
              lose your API key, you'll only have to generate a new API.
            </Typography>
          </DialogContentText>
          <Box className={classes.secretbutton}>
            {show ? (
              <Typography variant="h5">{secret}</Typography>
            ) : (
              <img src={secretImg} className={classes.blurredImg}></img>
            )}
            <Button
              onClick={() => {
                navigator.clipboard.writeText(secret);
                message.success('Copied');
                setShow(true);
              }}
              className={classes.button}
              variant="outlined"
              startIcon={<HiClipboard color="primary" />}
            >
              <Typography variant="p" style={{ fontSize: 18 }}>
                Copy
              </Typography>
            </Button>
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            borderTop: ' 1px solid rgb(239 239 239)',
            padding: 10
          }}
        >
          <Button
            autoFocus
            onClick={handleDisableClose}
            color="primary"
            className={classes.button}
            variant="outlined"
            size="small"
          >
            <Typography variant="h6">Yes, I have copied.</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        BackdropProps={{
          classes: {
            root: classes.backDrop
          }
        }}
        open={opens}
        aria-labelledby="form-dialog-title"
        className={classes.box}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            borderBottom: '1px solid rgb(229 231 243)',
            padding: 10
          }}
        >
          <Typography
            style={{
              color: '#3e4040',
              fontWeight: 700,
              fontSize: 20,
              marginLeft: 10
            }}
            variant="h6"
          >
            Verification required
          </Typography>
        </DialogTitle>

        <DialogContent>
          {error && (
            <Typography
              variant="p"
              style={{
                color: '#eb6558',
                fontWeight: 700,
                fontSize: 16
              }}
            >
              {eresponse}
            </Typography>
          )}
          <Box style={{ marginBottom: 15 }}>
            <Typography
              style={{
                color: '#3e4040',
                fontWeight: 400,
                fontSize: 16
              }}
              variant="h6"
            >
              To access certain parts of your dashboard, an extra step of
              verification is required.
            </Typography>
          </Box>
          <InputLabel
            shrink
            htmlFor="bootstrap-input-password"
            style={{
              color: '#3e4040',
              fontWeight: 700,
              fontSize: 22
            }}
          >
            <Typography variant="p">Enter your password</Typography>
          </InputLabel>
          <BootstrapInput
            name="password"
            id="password"
            type="password"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            placeholder="Enter password"
            size="small"
            onChange={e => setPassword(e.target.value)}
            value={password}
          />
        </DialogContent>
        <DialogActions
          style={{
            borderTop: '1px solid rgb(229 231 243)',
            padding: 10
          }}
        >
          <Button
            onClick={handleClickClose}
            color="primary"
            variant="outlined"
            size="small"
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            onClick={verifyAccount}
            className={classes.veributton}
          >
            {isVLoading ? <CircularProgress size="14px" /> : 'Verify'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
