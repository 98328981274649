import Button from '@mui/material/Button';
import theme from 'src/assets/theme';

const { colors } = theme;

const styles = {
  bgBtn: {
    borderRadius: '4px',
    backgroundColor: colors.primaryColor,
    textTransform: 'none',
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    mb: 2,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primaryDark
    }
  },
  noBgBtn: {
    borderRadius: '4px',
    backgroundColor: 'none',
    border: '1px solid',
    borderColor: colors.primaryColor,
    color: colors.primaryColor,
    mb: 2,
    textTransform: 'none',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  }
};

const CustomButton = ({
  btnTxt,
  on,
  variant = 'contained',
  size,
  btnStyle,
  disable,
  startIcon,
  endIcon
}) => {
  return (
    <Button
      sx={[
        variant === 'outlined' ? styles.noBgBtn : styles.bgBtn,
        disable && { backgroundColor: colors.borderColor },
        btnStyle
      ]}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      variant={variant}
      disabled={disable}
      onClick={on}
      disableElevation
    >
      {btnTxt}
    </Button>
  );
};

export default CustomButton;
